import { FunctionComponent, useEffect, useMemo, useState } from "react";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useIntl } from "react-intl";
import { Button } from "react-bootstrap";

import { useHtmlClassService } from "_metronic/layout";
import { toAbsoluteUrl } from "_metronic/_helpers";

import { ComponentsStyles, CustomSelect } from "app/_components/CustomSelect";

import { ISelectedSort } from "app/_utils/tasks";

import "./Sorter.scss";

const IconWrapper: FunctionComponent = ({ children }) => (
  <span className="svg-icon svg-icon-md svg-icon-primary m-0">{children}</span>
);

export interface SorterProps {
  disabled?: boolean;
  selectedSort: ISelectedSort;
  setSelectedSort: Function;
  option: { type: string; label: string }[];
  style: React.CSSProperties;
  parentStyle: React.CSSProperties;
}

export const Sorter: FunctionComponent<SorterProps> = ({
  disabled = false,
  selectedSort,
  setSelectedSort,
  option,
  style,
  parentStyle,
}) => {
  const intl = useIntl();
  const [isAscending, setIsAscending] = useState(true);

  const customSelectStyles: ComponentsStyles = {
    control: {
      minHeight: "40px",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      margin: 0,
      ...style,
    },
  };
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray300: objectPath.get(uiService.config, "js.colors.gray.gray300"),
      colorsGrayGray400: objectPath.get(uiService.config, "js.colors.gray.gray400"),
    };
  }, [uiService]);

  useEffect(() => {
    setSelectedSort({
      selected: { type: selectedSort?.selected?.type, label: selectedSort?.selected?.label },
      isAscending: isAscending,
    });
  }, [isAscending]);

  const UpIcon = <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Up-2.svg")} />;
  const DownIcon = <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Down-2.svg")} />;

  /* eslint no-useless-computed-key: "off" */
  const buttonStyles: any = {
    ["--borderColor"]: layoutProps.colorsGrayGray300,
    ["--borderHoverColor"]: "#9cb8ff",
    ["--borderDisabledColor"]: layoutProps.colorsGrayGray400,
  };

  return (
    <div className="sorter d-flex" style={parentStyle}>
      <Button
        className="direction-btn"
        disabled={disabled}
        style={buttonStyles}
        onClick={() => {
          setIsAscending(!isAscending);
        }}
      >
        {isAscending ? <IconWrapper>{UpIcon}</IconWrapper> : <IconWrapper>{DownIcon}</IconWrapper>}
      </Button>

      <CustomSelect
        disabled={disabled}
        styles={customSelectStyles}
        clearable={true}
        options={option.map((value) => {
          return { type: value.type, label: intl.formatMessage({ id: value.label }) };
        })}
        getOptionValue={(o) => (o as { type: string })?.type}
        placeholder={`${intl.formatMessage({
          id: "COMMON.SORTED.BY",
        })}`}
        onChange={(selected) => {
          setSelectedSort({
            selected: selected,
            isAscending: isAscending,
          });
        }}
        defaultValue={undefined}
        selected={undefined}
      />
    </div>
  );
};

export default Sorter;
