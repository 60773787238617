import { ProfilePictureThumbnail } from "./ProfilePictureThumbnail";
import { Highlighter } from "react-bootstrap-typeahead";
import { formatDisplayNameIntl } from "../_utils/userUtils";
import React, { FC } from "react";
import { useIntl } from "react-intl";

type Props = {
  user: any;
  searchText?: string;
  shape?: string;
};
export const UserDropdownItem: FC<Props> = ({ user, searchText = "", shape }) => {
  const intl = useIntl();
  return (
    <div className="d-flex align-items-center">
      <ProfilePictureThumbnail user={user} shape={shape} />
      <div className="d-flex justify-content-center flex-column ml-3">
        <div className="font-weight-bold">
          <Highlighter
            search={searchText}
            highlightClassName={"bg-light-primary p-0"}
            children={formatDisplayNameIntl(intl, user, false)}
          />
        </div>
        {(!!user?.firstName || !!user?.lastName || user?.company?.name) && (
          <div className="text-muted">
            <Highlighter
              search={searchText}
              highlightClassName={"bg-light-primary p-0"}
              children={user?.email}
            />
          </div>
        )}
      </div>
    </div>
  );
};
