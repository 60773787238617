import { Auth } from "aws-amplify";

export const masterEmailsRestriction = ["master@immotool.lu"];

export async function getUserGroups() {
  try {
    const currentSession = await Auth.currentSession();
    if (
      currentSession?.accessToken?.payload &&
      currentSession?.accessToken?.payload["cognito:groups"]
    ) {
      return currentSession.accessToken.payload["cognito:groups"];
    }
  } catch (e) {
    // Do nothing, session may not be initialized
  }
  return [];
}

export const isAdmin = (groups = [], session = {}) => {
  return (
    groups?.map((g) => g.toLowerCase()).includes("admin") ||
    session?.rights?.find((r) => r.entityType === "*" && r.rightType === "*")
  );
};

export async function isAdminUser() {
  return isAdmin(await getUserGroups());
}

export const canEditUser = (groups, session, user) => {
  let res = false;
  user?.userTypes?.forEach((userType) => {
    res = res || canEdit(groups, session, userType);
  });
  return res;
};

export const canDeleteUser = (groups, session, user) => {
  let res = true;
  user?.userTypes?.forEach((userType) => {
    res = res && canDelete(groups, session, userType);
  });
  return res || isAdmin(groups, session);
};

export const isStaffMember = (groups, session) => {
  return (
    isAdmin(groups, session) ||
    (!!session?.rights?.find((r) => r.entityType === "CLIENT" && r.rightType === "READ") &&
      !!session?.rights?.find((r) => r.entityType === "PRODUCT" && r.rightType === "READ") &&
      !!session?.rights?.find((r) => r.entityType === "LEAD" && r.rightType === "READ"))
  );
};

export const canSeeSettingsWheel = (groups, session) => {
  // TODO: this is close to be good
  return isAdmin(groups, session);
};

export const canAddOrRequestClientFilesOnLeadView = (groups, session) => {
  return (
    isAdmin(groups, session) ||
    !!session?.rights?.find((r) => r.entityType === "USER" && r.rightType === "UPDATE")
  );
};

export const canRemoveClientFromLead = (groups) => {
  return false;
  // return intersection(groups, ['Admin']).length > 0;
};

export const canCreate = (groups, session, entityType) => {
  return (
    isAdmin(groups, session) ||
    !!session?.rights?.find((r) => r.entityType === entityType && r.rightType === "CREATE")
  );
};

export const canReadAll = (groups, session, entityType) => {
  return (
    isAdmin(groups, session) ||
    !!session?.rights?.find((r) => r.entityType === entityType && r.rightType === "READ")
  );
};

export const canEdit = (groups, session, entityType) => {
  return (
    isAdmin(groups, session) ||
    !!session?.rights?.find((r) => r.entityType === entityType && r.rightType === "UPDATE")
  );
};

export const canDelete = (groups, session, entityType) => {
  return (
    isAdmin(groups, session) ||
    !!session?.rights?.find((r) => r.entityType === entityType && r.rightType === "DELETE")
  );
};

export const canShare = (groups, session, entityType) => {
  return (
    isAdmin(groups, session) ||
    !!session?.rights?.find((r) => r.entityType === entityType && r.rightType === "SHARE")
  );
};

export const canGoOnTabAnalysis = (groups, session) => {
  return (
    isAdmin(groups, session) ||
    !!session?.rights?.find((r) => r.entityType === "PROJECT" && r.rightType === "ANALYSIS")
  );
};

export const canSeeAuditTrail = (groups, session, entityType) => {
  return (
    isAdmin(groups, session) ||
    !!session?.rights?.find((r) => r.entityType === entityType && r.rightType === "AUDIT_TRAIL")
  );
};

export const canSeeNote = (groups, session, entityType) => {
  return (
    isAdmin(groups, session) ||
    !!session?.rights?.find((r) => r.entityType === entityType && r.rightType === "NOTE")
  );
};

export const canDeletePhoto = (groups, session, entityType) => {
  return (
    isAdmin(groups, session) ||
    !!session?.rights?.find((r) => r.entityType === entityType && r.rightType === "DELETE_PHOTO")
  );
};

export const canUploadPhoto = (groups, session, entityType) => {
  return (
    isAdmin(groups, session) ||
    !!session?.rights?.find((r) => r.entityType === entityType && r.rightType === "UPLOAD_PHOTO")
  );
};

export const canUpdatePhoto = (groups, session, entityType) => {
  return (
    isAdmin(groups, session) ||
    !!session?.rights?.find((r) => r.entityType === entityType && r.rightType === "UPDATE_PHOTO")
  );
};

export const canSeeHiddenLine = (groups, session) => {
  return (
    isAdmin(groups, session) ||
    !!session?.rights?.find((r) => r.entityType === "BUDGET" && r.rightType === "HIDDEN_LINE")
  );
};

export const canReadAllDocument = (groups, session, entityType) => {
  return (
    isAdmin(groups, session) ||
    !!session?.rights?.find(
      (r) => r.entityType === entityType && r.rightType === "CONFIDENTIAL_DOCUMENT"
    )
  );
};

export const canReadAllUserDocument = (groups, session, user) => {
  let res = false;
  user?.userTypes.forEach((userType) => {
    res = res || canReadAllDocument(groups, session, userType);
  });
  return res;
};

export const isOwner = (session, entity) => {
  return entity?.createdByUserId === session.id;
};
