import * as uiHelpers from "../ProjectsUIHelpers";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import {
  NoRecordsFoundMessage,
  sortCaret,
  toAbsoluteUrl,
} from "../../../../../../_metronic/_helpers";
import React, { useState } from "react";
import { canEdit, canReadAll } from "../../../../../_utils/authUtils";
import { useAppSelector } from "../../../../../../redux/hooks";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { FormattedMessage, useIntl } from "react-intl";
import { useProjectsUIContext } from "../ProjectsUIContext";
import { ProjectSubcontractorsDialog } from "../project-dialogs/ProjectSubcontractorsDialog";
import { useEntityDeleteDialogContext } from "../../entity-delete-dialog/EntityDeleteDialogContext";
import * as actions from "../../../_redux/projects/projectsActions";
import { NumberInput } from "../../../../../_utils/formUtils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useCustomLocationState } from "../../../../../_utils/useCustomLocationState";
import { useSubheader } from "../../../../../../_metronic/layout";
import { accurateFloatOperation } from "../../../../../_utils/mathUtils";
import {
  FullNameFormatter,
  ProgressBarFormatter,
} from "../../../../../_components/column-formatters";
import { formatDisplayNameColumn } from "../../../../../_utils/userUtils";
import { SUFFIX_EURO_CURRENCY } from "../../../../../_utils/suffixUtils";

export function ProjectSubcontractorsTable() {
  const intl = useIntl();
  const { readOnly, filters } = useProjectsUIContext();
  const { project, groups, session } = useAppSelector((state) => ({
    project: state.projects.projectForEdit?.current,
    groups: state.auth.groups,
    session: state.auth.session,
  }));

  const { setDeleteEntityDialog } = useEntityDeleteDialogContext();
  const { setFromUrlTo } = useCustomLocationState();
  const subheader = useSubheader();

  const openSubcontractorPage = (row: any) => {
    subheader.setTitle(row.name);
    setFromUrlTo({ url: `/subcontractors/${row.userId}`, name: project?.name });
  };

  const openDeleteProjectSubcontractor = React.useCallback(
    (id: string) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      setDeleteEntityDialog({
        action: {
          fn: actions.removeProjectSubcontractor,
          props: { projectId: project?.id, userId: id },
        },
        entityType: "PROJECT_SUBCONTRACTOR",
        customSubmitLabel: "COMMON.ACTION.REMOVE",
      });
    },
    [project]
  );

  const [openProjectSubcontractorsDialog, setOpenProjectSubcontractorsDialog] = useState(false);
  // Table columns
  const columns = [
    {
      dataField: "userId",
      text: "",
      sort: false,
      hidden: true,
    },
    {
      dataField: "name",
      text: intl.formatMessage({
        id: "COMMON.NAME",
      }),
      sort: true,
      sortCaret: sortCaret,
      formatter: FullNameFormatter,
      sortValue: (cell: any, row: any) => formatDisplayNameColumn(row),
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
    },
    {
      dataField: "quotes",
      text: intl.formatMessage({
        id: "FINANCE.QUOTES",
      }),
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      sortValue: (cell: number | undefined) => cell ?? "-",
      formatter: (cell: number | undefined) => {
        return typeof cell !== "undefined" ? (
          <NumberInput
            value={cell}
            displayType="text"
            decimalScale={2}
            suffix={SUFFIX_EURO_CURRENCY}
          />
        ) : (
          <>-</>
        );
      },
    },
    {
      dataField: "supplementary_agreements",
      text: intl.formatMessage({
        id: "BUDGET.SUPPLEMENTARY_AGREEMENTS",
      }),
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      sortValue: (cell: number | undefined) => cell ?? "-",
      formatter: (cell: number | undefined) => {
        return typeof cell !== "undefined" ? (
          <NumberInput
            value={cell}
            displayType="text"
            decimalScale={2}
            suffix={SUFFIX_EURO_CURRENCY}
          />
        ) : (
          <>-</>
        );
      },
    },
    {
      dataField: "orders",
      text: intl.formatMessage({
        id: "FINANCE.ORDERS",
      }),
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      sortValue: (cell: number | undefined) => cell ?? "-",
      formatter: (cell: number | undefined) => {
        return typeof cell !== "undefined" ? (
          <NumberInput
            value={cell}
            displayType="text"
            decimalScale={2}
            suffix={SUFFIX_EURO_CURRENCY}
          />
        ) : (
          <>-</>
        );
      },
    },
    {
      dataField: "invoices",
      text: intl.formatMessage({
        id: "FINANCE.INVOICES",
      }),
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      sortValue: (cell: number | undefined) => cell ?? "-",
      formatter: (cell: number | undefined) => {
        return typeof cell !== "undefined" ? (
          <NumberInput
            value={cell}
            displayType="text"
            decimalScale={2}
            suffix={SUFFIX_EURO_CURRENCY}
          />
        ) : (
          <>-</>
        );
      },
    },
    {
      dataField: "progress",
      text: intl.formatMessage({
        id: "BUDGET.INVOICED_PERCENT",
      }),
      sort: true,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      sortValue: (cell: any, row: any) =>
        row.orders || row.supplementary_agreements
          ? accurateFloatOperation(
              ((row.invoices ?? 0) / ((row.orders ?? 0) + (row.supplementary_agreements ?? 0))) *
                100,
              0
            )
          : -1,
      sortCaret: sortCaret,
      formatter: (cell: any, row: any) =>
        ProgressBarFormatter(
          cell,
          row,
          row.invoices,
          (row.orders || row.supplementary_agreements) &&
            (row.orders ?? 0) + (row.supplementary_agreements ?? 0)
        ),
    },
    {
      dataField: "action",
      text: intl.formatMessage({
        id: "COMMON.ACTION",
      }),
      headerClasses: "text-right",
      classes: "text-right",
      formatter: (_: any, row: any) => (
        <OverlayTrigger
          overlay={
            <Tooltip id="project-delete-tooltip">
              <FormattedMessage id="COMMON.ACTION.DELETE" />
            </Tooltip>
          }
        >
          <button
            className="btn btn-icon btn-light btn-hover-danger btn-sm"
            data-cy="button-project-subcontractors-delete-row"
            onClick={openDeleteProjectSubcontractor(row.userId)}
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
          </button>
        </OverlayTrigger>
      ),
      style: {
        minWidth: "100px",
      },
      hidden: readOnly || !canEdit(groups, session, "PROJECT"),
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: project?.projectSubcontractors?.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: filters.pageSize,
    page: filters.pageNumber,
  };

  return (
    <>
      <div className={"d-flex mb-3"}>
        {!readOnly && (
          <button
            className={"btn btn-primary ml-auto"}
            onClick={() => setOpenProjectSubcontractorsDialog(true)}
          >
            <FormattedMessage id={"PROJECT.ADD_SUBCONTRACTORS"} />
          </button>
        )}
      </div>
      <ProjectSubcontractorsDialog
        show={openProjectSubcontractorsDialog}
        id={openProjectSubcontractorsDialog}
        onHide={() => {
          setOpenProjectSubcontractorsDialog(false);
        }}
      />
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination paginationProps={paginationProps}>
              <BootstrapTable
                {...paginationTableProps}
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                bordered={false}
                keyField="userId"
                data={project?.projectSubcontractors || []}
                columns={columns}
                defaultSorted={[{ dataField: "name", order: "asc" }]}
                noDataIndication={() => (
                  <NoRecordsFoundMessage entities={project?.projectSubcontractors} />
                )}
                hover
                rowEvents={{
                  onClick: (e, row, rowIndex) => {
                    if (canReadAll(groups, session, "SUBCONTRACTOR")) {
                      openSubcontractorPage(row);
                    }
                  },
                }}
                rowStyle={{ cursor: "pointer" }}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
