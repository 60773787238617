import { useCallback, useState } from "react";
import { Route } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDidMount, useDidUpdate } from "rooks";

import { useSubheader } from "_metronic/layout";

import { TaskEditDialog } from "./task-edit-dialog/TaskEditDialog";
import { TasksUIProvider } from "./TasksUIContext";
import { TasksCard } from "./TasksCard";
import {
  TasksContentViewMode,
  useTasksSwitchContentView,
} from "./TasksCard/TasksCardContent/TasksSwitchContentView";

export const TasksPage = ({ history }) => {
  const [ganttShowDetails, setGanttShowDetails] = useState(false);

  const { contentView, toggleContentView } = useTasksSwitchContentView();

  const intl = useIntl();
  const subheader = useSubheader();

  //--------------------------------------------------------------------------//

  const updateUrlParams = useCallback(() => {
    const params = new URLSearchParams(history.location.search);

    if (contentView === TasksContentViewMode.GANTT_CHART) {
      params.set("viewMode", "gantt");

      if (ganttShowDetails) {
        params.set("showDetails", true);
      } else {
        params.has("showDetails") && params.delete("showDetails");
      }
    } else {
      params.has("viewMode") && params.delete("viewMode");
      params.has("showDetails") && params.delete("showDetails");
    }

    history.push({
      search: params.toString(),
    });
  }, [contentView, ganttShowDetails]);

  //--------------------------------------------------------------------------//

  const hideHandler = (history) => () => {
    history.push("/tasks");
    updateUrlParams();
  };

  const toggleGanttChartDetails = (showDetails) => {
    showDetails !== undefined
      ? setGanttShowDetails(showDetails)
      : setGanttShowDetails((previousValue) => !previousValue);
  };

  const tasksUIEvents = {
    newTaskButtonClick: () => {
      history.push("/tasks/new");
    },
    openEditTaskPage: (id) => {
      history.push(`/tasks/${id}/edit`);
    },
    toggleContentView,
    toggleGanttChartDetails,
  };

  useDidMount(() => {
    const { search } = history.location;

    subheader.setTitle(
      intl.formatMessage({
        id: "TASK.TITLE",
      })
    );

    if (!search) {
      toggleContentView(TasksContentViewMode.COLUMNS);
      return;
    }

    const params = new URLSearchParams(search);
    const viewMode = params.has("viewMode");
    const showDetails = params.has("showDetails") && params.get("showDetails") === "true";

    toggleContentView(viewMode ? TasksContentViewMode.GANTT_CHART : TasksContentViewMode.COLUMNS);
    setGanttShowDetails(showDetails);
  });

  useDidUpdate(() => {
    updateUrlParams();
  }, [updateUrlParams]);

  //--------------------------------------------------------------------------//

  return (
    <TasksUIProvider {...{ contentView, ganttShowDetails, tasksUIEvents }}>
      <Route
        path="/tasks/new"
        exact
        render={({ history, match }) => (
          <TaskEditDialog mode="create" show={match !== null} onHide={hideHandler(history)} />
        )}
      />

      <Route
        path="/tasks/:id/edit"
        exact
        render={({ history, match }) => (
          <TaskEditDialog
            mode="edit"
            show={match !== null}
            id={match?.params?.id}
            onHide={hideHandler(history)}
          />
        )}
      />

      <TasksCard />
    </TasksUIProvider>
  );
};

export default TasksPage;
