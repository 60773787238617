import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { requestLeadFileSignature } from "../../../../_redux/leads/leadsActions";
import { fetchUsersByUserType } from "../../../../../UsersManagement/_redux/usersActions";
import { useLeadFilesUIContext } from "../LeadFilesUIContext";
import { RequestSignatureDialog } from "../../../../../../_components/RequestSignatureDialog";
import { CLIENT, SUBCONTRACTOR, USER } from "../../../../../../_utils/userTypes";

export function LeadRequestSignature() {
  useEffect(() => {
    dispatch(fetchUsersByUserType({ userTypes: [USER, CLIENT, SUBCONTRACTOR] }));
  }, []);
  const dispatch = useDispatch();
  const { users, leadFile } = useSelector(
    (state: any) => ({
      users: state.users.entities,
      leadFile: state.leads?.leadForEdit?.current?.files,
    }),
    shallowEqual
  );

  return (
    <RequestSignatureDialog
      useFilesUIContext={useLeadFilesUIContext}
      userList={[...users]}
      fileList={leadFile}
      requestFileSignature={requestLeadFileSignature}
    />
  );
}
