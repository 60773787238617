import React, { useState } from "react";
import { ModalProgressBar } from "../../../../../../../_metronic/_partials/controls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import * as actions from "../../../../_redux/usersActions";
import { Switch } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

export function RequestUserInformationModal({ user, actionsLoading, showModal, setShowModal }) {
  const [sendSms, setSendSms] = useState(false);
  const dispatch = useDispatch();

  const { session } = useSelector(
    (state) => ({
      session: state.auth.session,
    }),
    shallowEqual
  );

  const sendRequest = () => {
    const { protocol, host, port } = window.location;

    const userActionParams = {
      createdByUserId: session.id,
      userId: user.id,
      actionType: "FILLOUT_PERSONAL_INFORMATION",
      messageDestinationTypes: sendSms ? ["SMS"] : [],
      url: [`${protocol}//${host}`].join(`${port}`),
    };
    dispatch(actions.createUserAction(userActionParams));
    setShowModal(false);
  };

  const smsSwitchChange = (e) => {
    setSendSms(e.target.checked);
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="user-request-info-modal"
      centered
      backdrop="static"
    >
      {actionsLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="user-request-info-modal-title">
          <FormattedMessage id="USER.ACTION.REQUEST.INFO" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!actionsLoading && (
          <div className="form-group row">
            <div className="col-lg-12">
              <Switch
                // onBlur={handleBlur}
                onChange={smsSwitchChange}
                data-cy="switch-send-sms"
                // name="notifyUser"
                checked={sendSms}
              />
              <FormattedMessage id="USER.ACTION.NOTIFY.SMS" />
            </div>
          </div>
        )}
        {actionsLoading && (
          <span>
            <FormattedMessage id="USER.ACTION.REQUEST.SENT" />
          </span>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setShowModal(false)}
            className="btn btn-light btn-elevate"
          >
            <FormattedMessage id="COMMON.ACTION.CANCEL" />
          </button>
          <> </>
          <button
            type="button"
            data-cy="button-user-action-confirm"
            onClick={sendRequest}
            className="btn btn-primary font-weight-bold"
            disabled={actionsLoading}
          >
            <FormattedMessage id="COMMON.ACTION.REQUEST" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
