/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../../../_metronic/_partials/controls";
import { useIntl } from "react-intl";

export function UserFileEditDialogHeader({ userFileForEdit, isUploadingFile, showEditFileDialog }) {
  const intl = useIntl();
  const [title, setTitle] = useState("");

  const { actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: state.actionsLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    let filename = "";
    let prefixId;
    if (!userFileForEdit?.id) {
      prefixId = isUploadingFile ? "FILE.ACTIONS.UPLOAD.NEW" : "FILE.ACTIONS.UPLOAD.REQUEST";
    } else {
      prefixId = "COMMON.ACTION.EDIT";
      filename = userFileForEdit?.friendlyName;
    }

    setTitle(`${intl.formatMessage({ id: prefixId })} ${filename}`);
    // eslint-disable-next-line
  }, [userFileForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{showEditFileDialog && title}</Modal.Title>
      </Modal.Header>
    </>
  );
}
