import * as React from "react";

export enum IconType {
  COLUMNS = "columns",
  GANTT_CHART = "gantt-chart",
}

export type TIconType = `${IconType}`;

type TStringOrNumber = string | number;

export interface IconProps {
  name: TIconType;
  className?: string;
  width?: TStringOrNumber;
  height?: TStringOrNumber;
}

const svgMap: {
  [key in TIconType]: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
} = {
  columns: ({ className, width, height }) => (
    <svg
      {...{ className, width, height }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      stroke="currentColor"
    >
      <path d="M19.893 3.001H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h15.893c1.103 0 2-.897 2-2V5a2.003 2.003 0 0 0-2-1.999zM8 19.001H4V8h4v11.001zm6 0h-4V8h4v11.001zm2 0V8h3.893l.001 11.001H16z" />
    </svg>
  ),

  "gantt-chart": ({ className, width, height }) => (
    <svg
      {...{ className, width, height }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      fill="currentColor"
      stroke="currentColor"
    >
      <path d="M352 300.8v38.5C352 345.6 358.4 352 364.8 352h102.5C473.6 352 480 345.6 480 339.3v-38.5C480 294.4 473.6 288 467.3 288h-102.5C358.4 288 352 294.4 352 300.8zM192 204.8v38.5C192 249.6 198.4 256 204.8 256h166.5C377.6 256 384 249.6 384 243.3v-38.5C384 198.4 377.6 192 371.3 192h-166.5C198.4 192 192 198.4 192 204.8zM480 416H64V63.1C64 46.33 49.67 32 32 32S0 46.33 0 63.1V448c0 17.6 14.4 32 32 32h448C497.7 480 512 465.7 512 448S497.7 416 480 416zM140.8 160h134.5C281.6 160 288 153.6 288 147.3v-38.5C288 102.4 281.6 96 275.3 96h-134.5C134.4 96 128 102.4 128 108.8v38.5C128 153.6 134.4 160 140.8 160z" />
    </svg>
  ),
};

export const Icon: React.FunctionComponent<IconProps> = ({ name, ...svgProps }) => {
  const IconFn = svgMap[name];

  if (!IconFn) return null;

  return IconFn(svgProps);
};

export default Icon;
