import React from "react";
import { useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import { useIntl } from "react-intl";
import { createPortal } from "react-dom";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }
  return classes.join(" ");
};

export function DatePickerField({ field, customHandleChange, withFeedbackLabel = true, ...props }) {
  const intl = useIntl();
  if (field.value) {
    field.value = (new Date(field.value)).getTime();
  }
  const { setFieldValue, handleChange, errors, touched } = useFormikContext();
  return (
    <>
      {props.label && <label className={"mr-2"}>{props.label}</label>}
      <DatePicker
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        {...field}
        locale={intl.locale}
        showTimeSelect
        popperContainer={({ children }) => createPortal(children, document.body)}
        popperPlacement="top"
        popperClassName="custom-popper-zindex"
        timeIntervals={10}
        dateFormat="Pp"
        selected={(field.value && new Date(field.value)) || undefined}
        onChange={(val) => {
          if(!!customHandleChange){
            customHandleChange({
              target: { name: field.name, value: val },
            })(handleChange)
          }else{
            setFieldValue(field.name, val);
          }
        }}
        {...props}
      />
      {errors[field.name] && touched[field.name] ? (
        <div className="invalid-feedback d-block">{errors[field.name].toString()}</div>
      ) : (
        <>
          {withFeedbackLabel && (
            <div className="feedback">
              Please enter <b>{props.label}</b> in 'mm/dd/yyyy' format
            </div>
          )}
        </>
      )}
    </>
  );
}
