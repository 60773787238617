import React, { lazy, Suspense, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import { DashboardPage } from "./modules/Dashboard/DashboardPage";
import { Auth } from "aws-amplify";
import { injectIntl, useIntl } from "react-intl";
import { connect } from "react-redux";
import * as auth from "./modules/Auth/_redux/authRedux";
import WebSocketProvider from "./modules/WebSocket/WebSocketProvider";
import * as Yup from "yup";
import { isValidBIC, isValidIBANNumber } from "./_utils/formUtils";

import PropertiesManagementPage from "./modules/PropertiesManagement/pages/PropertiesManagementPage";
import { EntityDeleteDialogProvider } from "./modules/PropertiesManagement/pages/entity-delete-dialog/EntityDeleteDialogContext";
import { useAppDispatch } from "../redux/hooks";
import { fetchChats } from "./modules/Chat/_redux/chatActions";

const ChatsPage = lazy(() => import("./modules/Chat/ChatsPage"));

const UsersManagementPage = lazy(() =>
  import("./modules/UsersManagement/pages/UsersManagementPage")
);

const SettingsManagementPage = lazy(() => import("./modules/Settings/SettingsManagementPage"));

const SignPage = lazy(() => import("./modules/Sign/pages/SignPage"));

function BasePage({ logout, requestUserSession }) {
  const intl = useIntl();
  Yup.addMethod(Yup.mixed, "bic", function () {
    return this.test("test-bic", function (value) {
      const { path, createError } = this;
      const test = isValidBIC(value, intl);
      if (typeof test === "string") {
        return createError({ path, message: test });
      }
      return true;
    });
  });

  Yup.addMethod(Yup.mixed, "iban", function () {
    return this.test("test-iban", function (value) {
      const { path, createError } = this;
      const test = isValidIBANNumber(value);
      if (test === false) {
        return createError({ path, message: "IBAN is too short" });
      } else if (test !== 1) {
        return createError({ path, message: "IBAN is incorrect" });
      }
      return true;
    });
  });
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(requestUserSession)
      .catch((err) => {
        console.log("currentAuthenticatedUser err:", err);
        Auth.signOut()
          .then((signOutRes) => console.log("signOutRes:", signOutRes))
          .catch((signOutErr) => console.log("signOutErr:", signOutErr));
        logout();
      });
  }, [logout, requestUserSession]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchChats());
  }, []);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <EntityDeleteDialogProvider>
        <WebSocketProvider>
          <Switch>
            {
              /* Redirect from root URL to /dashboard. */
              <Redirect exact from="/" to="/dashboard" />
            }
            <ContentRoute path="/dashboard" component={DashboardPage} />
            <Route path="/profile" component={UsersManagementPage} />
            <Route path="/settings" component={SettingsManagementPage} />
            <Route path="/sign" component={SignPage} />
            <Route path="/chats" component={ChatsPage} />
            <Route path="/" component={PropertiesManagementPage} />
            {/*TODO: custom error page */}
            <Redirect to="error/error-v1" />
          </Switch>
        </WebSocketProvider>
      </EntityDeleteDialogProvider>
    </Suspense>
  );
}

export default injectIntl(connect(null, auth.actions)(BasePage));
