import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../../../_metronic/layout";
import { FormattedMessage, useIntl } from "react-intl";
import { canCreate } from "../../../../../_utils/authUtils";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useUsersUIContext } from "../UsersUIContext";
import { UsersTable } from "./UsersTable";
import { TaskDraftDialog } from "../../../../../_components/TaskDraftDialog";
import { Filter } from "../../../../../_components/Filter";

export function UsersCard() {
  const CREATE = "create";

  const [showTaskDraftModal, setShowTaskDraftModal] = useState(false);

  //TODO to be improved, postponed to get merge done
  const config = {
    USER: {
      titleId: "USER.TITLE",
      titleListId: "USER.TITLE.LIST",
      actionNewId: "USER.ACTION.NEW",
      actionNextId: "COMMON.ACTION.NEXT",
      actionSelectId: "USER.ACTION.LIST.SELECT",
    },
    SUBCONTRACTOR: {
      titleId: "SUBCONTRACTOR.TITLE",
      titleListId: "SUBCONTRACTOR.TITLE.LIST",
      actionNewId: "SUBCONTRACTOR.ACTION.NEW",
      actionNextId: "COMMON.ACTION.NEXT",
      actionSelectId: "SUBCONTRACTOR.ACTION.LIST.SELECT",
    },
    CLIENT: {
      titleId: "CLIENT.TITLE",
      titleListId: "CLIENT.TITLE.LIST",
      actionNewId: "CLIENT.ACTION.NEW",
      actionNextId: "COMMON.ACTION.NEXT",
      actionSelectId: "CLIENT.SELECT",
    },
    LEAD_CREATION: {
      mode: CREATE,
      target: "OPEN_LEAD_PAGE",
      titleId: "LEAD.TITLE.NEW",
    },
    MULTI_TASK_CREATION: {
      mode: CREATE,
      target: "OPEN_TASK_DRAFT_DIALOG",
      // titleId: "MULTI_TASK.TITLE.NEW",
      select: "CLIENT.SELECT",
    },
  };

  const intl = useIntl();
  const ctx = useUsersUIContext();
  const history = useHistory();
  const ctxProps = useMemo(() => {
    return {
      ids: ctx.ids,
      queryParams: ctx.queryParams,
      setQueryParams: ctx.setQueryParams,
      newEntityButtonClick: ctx.newEntityButtonClick,
      openCreateLeadPage: ctx.openCreateLeadPage,
      openEntityDetailsPage: ctx.openEntityDetailsPage,
      openTasksPage: ctx.openTasksPage,
      context: ctx.context,
      action: ctx.action,
      userType: ctx.userType,
    };
  }, [ctx]);

  const { groups, session } = useSelector(
    (state) => ({
      session: state.auth.session,
      groups: state.auth.groups,
    }),
    shallowEqual
  );

  const subheader = useSubheader();
  useEffect(() => {
    subheader.setTitle(
      formatMessage(
        intl,
        ctxProps.action ? config?.[ctxProps.action]?.titleId : config?.[ctxProps.userType]?.titleId
      )
    );
  }, [subheader]);

  const executeAction = (target, ids, params = {}) => {
    switch (target) {
      case "OPEN_TASK_DRAFT_DIALOG":
        setShowTaskDraftModal(true);
        break;
      case "OPEN_TASKS_PAGE":
        setShowTaskDraftModal(true);
        break;
      case "OPEN_LEAD_PAGE":
        ctxProps.openCreateLeadPage(ids);
        break;
      default:
    }
  };

  function formatMessage(intl, id) {
    if (id) {
      return intl.formatMessage({
        id: id,
      });
    } else return "NO_ID";
  }

  return (
    <Card>
      <TaskDraftDialog
        show={showTaskDraftModal}
        ids={ctxProps.ids}
        history={history}
        setShowModal={setShowTaskDraftModal}
        onHide={(id) => {
          console.log("onHide", id);
          history.push(`/subcontractors/${id}?t=actions`);
        }}
      />
      <CardHeader
        title={formatMessage(
          intl,
          ctxProps.action && config[ctxProps?.action].mode === CREATE
            ? config?.[ctxProps.userType]?.actionSelectId
            : config?.[ctxProps.userType]?.titleListId
        )}
      >
        <CardHeaderToolbar>
          {!ctxProps.action && canCreate(groups, session, ctxProps.userType) && (
            <>
              <button
                type="button"
                className="btn btn-primary"
                onClick={ctxProps.newEntityButtonClick}
                data-cy="button-users-new"
              >
                <FormattedMessage id={config[ctxProps.userType].actionNewId} />
              </button>
            </>
          )}

          {config?.[ctxProps.action]?.mode === CREATE && (
            <>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => executeAction(config?.[ctxProps.action]?.target, ctxProps.ids)}
                disabled={!ctxProps?.ids || ctxProps.ids.length === 0}
                data-cy="button-lead-creation-next"
              >
                <FormattedMessage id="COMMON.ACTION.NEXT" />
              </button>
            </>
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Filter useEntityUIContext={useUsersUIContext} />
        <UsersTable />
      </CardBody>
    </Card>
  );
}
