import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Modal } from "react-bootstrap";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Form, Formik } from "formik";
import { v4 as uuid } from "uuid";

import { USER } from "../_utils/userTypes";
import { formatAddress, formatDisplayNameIntl } from "../_utils/userUtils";

import * as actions from "../modules/PropertiesManagement/_redux/tasks/tasksActions";

import { CustomSelect } from "app/_components/CustomSelect";

import { TextEditor } from "./TextEditor/TextEditor";

export const TaskDraftDialog = ({ ids, show, setShowModal, onHide, history }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  function closeModal() {
    setShowModal(false);
    onHide(history.location.state.assignee?.id);
  }

  const initTask = {
    id: undefined,
    name: "",
    description: "",
    descriptionShort: "",
    status: "PLANNED",
    assignee: "",
    taskType: "CUSTOM",
    dueDate: undefined,
    createdByUserId: "",
    relatedTo: undefined,
    reminders: [],
    comments: [],
  };

  const { session, users, relatedToItemOptions } = useSelector(
    (state) => ({
      session: state.auth.session,
      groups: state.auth.groups,
      users: state.users.entities,
      relatedToItemOptions: state.tasks.relatedToItemOptions,
    }),
    shallowEqual
  );

  const [relatedToOptionsLoading, setRelatedToOptionsLoading] = useState(false);
  const openRelatedToSelect = (e) => {
    if (!relatedToItemOptions?.length) {
      setRelatedToOptionsLoading(true);
      dispatch(actions.fetchRelatedToItems(intl)).then(() => setRelatedToOptionsLoading(false));
    }
  };

  const taskDrafts = [];

  function createMultipleTasks(ids, values) {
    for (let i = 0; i < ids.length; i++) {
      const targetUser = users.find((user) => user.id === ids[i]);

      const taskDraft = {
        id: uuid(),
        createdByUserId: session?.id,
        name: intl.formatMessage(
          { id: "TASK.TEMPLATE.CONTACT_PEOPLE.TITLE" },
          { displayName: formatDisplayNameIntl(intl, targetUser, true) }
        ),
        taskType: "CUSTOM",
        status: "PLANNED",
        descriptionShort: values.descriptionShort,
        description: assembleDescription(intl, targetUser, values.description),
        //dynamically injected data instead of hardcoded values -> temporarily disabled (until further notice)
        // parameters: {
        //   displayName: { id: ids[i], type: USER },
        //   mobile: { id: ids[i], type: USER },
        //   email: { id: ids[i], type: USER },
        //   address: { id: ids[i], type: USER },
        // },
        attachments: [{ id: ids[i], type: USER }],
        relatedTo: values.relatedTo,
        assigneeId: history.location.state?.assignee?.id,
      };

      taskDrafts.push(taskDraft);
    }

    //persist tasks
    for (let i = 0; i < taskDrafts.length; i++) {
      taskDrafts[i].associatedTasks = taskDrafts
        .filter((item) => item.id !== taskDrafts[i].id)
        .map((item) => item.id);
      dispatch(actions.createTask(taskDrafts[i], i === ids.length - 1));
    }

    closeModal();
  }

  function assembleDescription(intl, targetUser, description) {
    const { mobile, email, address } = targetUser;

    const fDisplayName = formatDisplayNameIntl(intl, targetUser);
    const fMobile = formatPhoneNumberIntl(mobile);
    const fAddress = formatAddress(intl, address, "<br/>", true);

    const emailParam = email;

    const defaultDescription = `\n${intl.formatMessage({
      id: "TASK.TEMPLATE.CONTACT_PEOPLE.DESCRIPTION",
    })}`;
    const fullNameText = fDisplayName
      ? `<br/>${intl.formatMessage(
          {
            id: "TASK.TEMPLATE.CONTACT_PEOPLE.FULL_NAME",
          },
          { displayName: fDisplayName }
        )}`
      : "";
    const mobileText = fMobile
      ? `<br/>${intl.formatMessage(
          {
            id: "TASK.TEMPLATE.CONTACT_PEOPLE.DETAILS.MOBILE",
          },
          { mobile: fMobile }
        )}`
      : "";
    const emailText = emailParam
      ? `<br/>${intl.formatMessage(
          {
            id: "TASK.TEMPLATE.CONTACT_PEOPLE.DETAILS.EMAIL",
          },
          { email: emailParam }
        )}`
      : "";
    const addressText = fAddress
      ? `<br/>${intl.formatMessage(
          {
            id: "TASK.TEMPLATE.CONTACT_PEOPLE.DETAILS.ADDRESS",
          },
          { address: fAddress }
        )} `
      : "";

    return `\n${
      description || defaultDescription
    }${fullNameText}${mobileText}${emailText}${addressText}`;
  }

  return (
    <>
      {
        <Modal
          show={show}
          aria-labelledby="create-task-modal"
          centered
          backdrop="static"
          size={"l"}
          scrollable
          // dialogClassName={"task-edit-dialog"}
        >
          {/*{(listLoading || isLoading) && <ModalProgressBar variant="query" />}*/}
          <Modal.Header closeButton>
            <Modal.Title id="create-task-modal-title">
              <FormattedMessage id="USER.ACTION.TASK.CONTACT_PEOPLE" />
            </Modal.Title>
          </Modal.Header>
          <Formik
            enableReinitialize={true}
            initialValues={initTask}
            onSubmit={(values) => {
              createMultipleTasks(ids, values);
            }}
          >
            {({ handleChange, dirty, setFieldValue, values, submitForm, resetForm }) => (
              <>
                <Modal.Body>
                  <Form>
                    <div className="form-group row">
                      <div className="col-lg-12">
                        <label>
                          <FormattedMessage id="TASK.LABEL.RELATED_TO" />
                        </label>
                        <CustomSelect
                          placeholder={intl.formatMessage({
                            id: "COMMON.ACTION.SELECT.RELATED_TO",
                          })}
                          name="relatedTo"
                          options={relatedToItemOptions}
                          clearable={true}
                          onFocus={openRelatedToSelect}
                          onChange={(selected) => {
                            if (selected) {
                              setFieldValue(
                                "relatedTo",
                                `${selected?.type?.toUpperCase()}#${selected.value.id}`
                              );
                            } else {
                              setFieldValue("relatedTo", "");
                            }
                          }}
                          isLoading={relatedToOptionsLoading}
                          loadingMessage={() => intl.formatMessage({ id: "COMMON.LOADING" })}
                          getOptionValue={(o) => o.value?.id}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-12">
                        {/*<div className="form-group row">*/}
                        {/*  <div className="col-lg-12">*/}
                        {/*    <Field*/}
                        {/*      className="no-validation"*/}
                        {/*      name="descriptionShort"*/}
                        {/*      data-cy="input-task-summary"*/}
                        {/*      component={Input}*/}
                        {/*      placeholder={intl.formatMessage({*/}
                        {/*        id: "TASK.LABEL.SUMMARY",*/}
                        {/*      })}*/}
                        {/*      label={intl.formatMessage({*/}
                        {/*        id: "TASK.LABEL.SUMMARY",*/}
                        {/*      })}*/}
                        {/*      withFeedbackLabel={false}*/}
                        {/*    />*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                        <div className="form-group row">
                          <div className="col-lg-12">
                            <label>
                              <FormattedMessage id="TASK.LABEL.DESCRIPTION" />
                            </label>
                            <TextEditor name={"description"} saveMethod={setFieldValue} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <div>
                    <button
                      type="button"
                      onClick={() => closeModal()}
                      className="btn btn-light btn-elevate"
                    >
                      <FormattedMessage id="COMMON.ACTION.CANCEL" />
                    </button>
                    <button
                      type="button"
                      data-cy="btn-task-submit"
                      className="btn btn-primary font-weight-bold ml-2"
                      onClick={submitForm}
                    >
                      <FormattedMessage id="COMMON.ACTION.CREATE" />
                    </button>
                  </div>
                </Modal.Footer>
              </>
            )}
          </Formik>
        </Modal>
      }
    </>
  );
};
