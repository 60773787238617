import React from "react";

export function ClientsActions({ openAddClientToLead }) {
  return (
    <>
      <div className="form-filtration">
        <div className="row align-items-center">
          <div className="col-md-6 margin-bottom-10-mobile" />
          <div className="col-md-4 margin-bottom-10-mobile" />
          <div className="col-md-2 text-right margin-bottom-10-mobile">
            <button
              type="button"
              className="btn btn-primary my-3"
              onClick={() => openAddClientToLead()}
            >
              Add client
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
