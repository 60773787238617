import * as React from "react";
import { FormattedMessage } from "react-intl";

import {
  UserEntityType,
  TUserEntityTypes,
  UserRightType,
  TUserRightTypes,
  IUserRight,
} from "data/schemas";

import {
  CRUD_RIGHTS_LIST,
  ADDITIONAL_RIGHTS_LIST,
  ADDITIONAL_RIGHTS_LIST_I18N_LABEL_KEY,
  RIGHT_USER_I18N_PREFIX_KEY,
} from "../definitions";

export type TUpdateRightFn = (selection: IUserRight) => void;

export interface UserRightsSelectionProps {
  label: string;
  entityType: TUserEntityTypes;
  updateRight: TUpdateRightFn;
  disabledFields: TUserRightTypes[];
  userRights: IUserRight[];

  readOnly?: boolean;
  isMaster?: boolean;
}

const filterDifference = (targetList: string[], avoidList: string[]) =>
  targetList.filter((check) => !avoidList.includes(check));

export const UserRightsSelectionInner: React.FunctionComponent<UserRightsSelectionProps> = ({
  label,
  entityType,
  updateRight,
  disabledFields = [],
  userRights,

  readOnly = false,
  isMaster = false,
}) => {
  const [crudList, additionalRightsList] = React.useMemo(() => {
    if (disabledFields.length === 0) return [CRUD_RIGHTS_LIST, ADDITIONAL_RIGHTS_LIST];

    return [
      filterDifference(CRUD_RIGHTS_LIST, disabledFields),
      filterDifference(ADDITIONAL_RIGHTS_LIST, disabledFields),
    ] as [TUserRightTypes[], TUserRightTypes[]];
  }, [disabledFields]);

  const isCheckDisabled = (rightType: string) =>
    readOnly || (rightType === UserRightType.DELETE && !isMaster);

  const isChecked = (rightType: string) =>
    userRights.length === 0
      ? false
      : userRights.find((userRight) => {
          const { entityType: userEntityType, rightType: userRightType } = userRight;
          return (
            (userEntityType === UserEntityType.ALL && userRightType === UserRightType.ALL) ||
            (userEntityType === entityType && userRightType === rightType)
          );
        }) !== undefined;

  const checkChangeHandler =
    (selection: IUserRight) => (_: React.SyntheticEvent<HTMLInputElement>) => {
      updateRight(selection);
    };

  const mapRightOption = (rightType: TUserRightTypes) => (
    <div key={rightType} className="row">
      <label className="col-7 pt-2 pl-7">
        <FormattedMessage id={`${RIGHT_USER_I18N_PREFIX_KEY}.${rightType}`} />
      </label>

      <label className="checkbox checkbox-single justify-content-end col-5 justify-content-center">
        <input
          type="checkbox"
          disabled={isCheckDisabled(rightType)}
          checked={isChecked(rightType)}
          onChange={checkChangeHandler({ entityType, rightType })}
        />
        <span />
      </label>
    </div>
  );

  return (
    <div className="col-lg-4 border-top m-10 m-lg-0">
      <div className="form-group">
        <div className="row">
          <label className="h4 p-3 w-100 font-weight-bold">{label}</label>
        </div>

        {crudList.map(mapRightOption)}

        {additionalRightsList.length > 0 && (
          <>
            <label className="mt-8 mb-2 w-100 h6">
              <FormattedMessage id={ADDITIONAL_RIGHTS_LIST_I18N_LABEL_KEY} />
            </label>

            {additionalRightsList.map(mapRightOption)}
          </>
        )}
      </div>
    </div>
  );
};

export const UserRightsSelection = React.memo(UserRightsSelectionInner);

export default UserRightsSelection;
