/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { AuthPage, Logout } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { VersionPage } from "./pages/VersionPage";
import { ResourceUnavailablePage } from "./modules/ExceptionPages/ResourceUnavailablePage";
import { masterEmailsRestriction } from "./_utils/authUtils";
import { RequestsPage } from "./modules/Requests/pages/RequestsPage";

export function Routes() {
  const { isAuthorized, session, userEmail } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.tokens?.accessToken != null,
      session: auth.session,
      userEmail: auth.user?.attributes?.email,
    }),
    shallowEqual
  );
  const location = useLocation();
  const redirectQueryParam = new URLSearchParams(location?.search).get("redirect_uri");

  return (
    <Switch>
      <Route path="/version" component={VersionPage} />
      <Route path="/logout" component={Logout} />
      <Route path={"/requests"}>
        <RequestsPage />
      </Route>
      {!session ||
      !isAuthorized ||
      (!session.gdprApprovedAt && !masterEmailsRestriction.includes(userEmail)) ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect
          strict
          from="/auth"
          to={redirectQueryParam ? decodeURIComponent(redirectQueryParam) : "/"}
        />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/unavailable" component={ResourceUnavailablePage} />

      {isAuthorized && (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
