import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch } from "react-redux";
import * as actions from "../../../_redux/projects/projectsActions";
import * as actionsUsers from "../../../../UsersManagement/_redux/usersActions";

import { formatDisplayNameIntl } from "../../../../../_utils/userUtils";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { FormattedMessage, useIntl } from "react-intl";
import { UserDropdownItem } from "../../../../../_components/UserDropdownItem";
import { SUBCONTRACTOR } from "../../../../../_utils/userTypes";
import { useAppSelector } from "../../../../../../redux/hooks";

export function ProjectSubcontractorsDialog({ id, show, onHide, loadProject = false }) {
  const intl = useIntl();
  const [filteredUsers, setFilteredUsers] = useState([]);
  const dispatch = useDispatch();
  const { isLoadingProject, users, project, session, isLoadingUser } = useAppSelector(
    (state) => ({
      isLoadingProject: state.projects.actionsLoading || state.projects.listLoading,
      users: state.users.entities,
      project: state.projects.projectForEdit.saved,
      session: state.auth.session,
      isLoadingUser: state.users.actionsLoading || state.users.listLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!id) {
      onHide();
    } else {
      if (loadProject) {
        dispatch(actions.fetchProject(id));
      }
      dispatch(actionsUsers.fetchUsersByUserType({ userTypes: [SUBCONTRACTOR] }));
    }
  }, [id]);

  useEffect(() => {
    if (!isLoadingProject && project) {
      setFilteredUsers(
        users.filter(
          (u) =>
            session?.id !== u.id && !project.projectSubcontractors?.find((ps) => ps.userId === u.id)
        )
      );
    }
  }, [isLoadingProject, users, setFilteredUsers, project, session]);

  const [newProjectSubcontractors, setNewProjectSubcontractors] = useState([]);
  const updateProjectSubcontractors = (projectSubcontractors) => {
    setNewProjectSubcontractors(projectSubcontractors);
  };

  const submitProjectSubcontractors = () => {
    for (const user of newProjectSubcontractors) {
      dispatch(actions.createProjectSubcontractor({ projectId: project.id, userId: user.id }));
    }
    setNewProjectSubcontractors([]);
    onHide();
  };

  const typeaheadRef = useRef(null);

  return (
    <Modal show={show} onHide={onHide} centered size={"md"} backdrop="static" scrollable>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id={"PROJECT.ADD_SUBCONTRACTORS_TO"} values={{ name: project?.name }} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-6">
        <div className="d-flex">
          <Typeahead
            id="share-typeahead-input"
            ref={typeaheadRef}
            multiple
            positionFixed
            labelKey={"displayName"}
            filterBy={["displayName", "email"]}
            selected={newProjectSubcontractors}
            onChange={(selected) => {
              updateProjectSubcontractors(selected);
            }}
            options={filteredUsers.map((u) => {
              const user = { ...u };
              user.displayName = formatDisplayNameIntl(intl, user, false);
              return user;
            })}
            placeholder={intl.formatMessage({ id: "COMMON.SELECT_USER" })}
            disabled={isLoadingProject || isLoadingUser}
            className={"flex-grow-1"}
            renderMenu={(users, menuProps) => (
              <Menu {...menuProps}>
                {users.map((user, index) => (
                  <MenuItem option={user} position={index} key={index}>
                    <UserDropdownItem user={user} searchText={typeaheadRef?.current?.state?.text} />
                  </MenuItem>
                ))}
              </Menu>
            )}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button type="button" onClick={onHide} className={`btn btn-light btn-elevate`}>
            <FormattedMessage id="COMMON.ACTION.CANCEL" />
          </button>
          <button
            type="button"
            onClick={submitProjectSubcontractors}
            disabled={isLoadingProject || isLoadingUser}
            className={`btn btn-primary btn-elevate ml-4 ${
              isLoadingProject && "spinner spinner-dark spinner-right"
            }`}
          >
            <FormattedMessage id="COMMON.ACTION.ADD" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
