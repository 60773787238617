import * as React from "react";
import { FormSectionField, FormSectionRegistryRow } from "../FormSection";
import { Field } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { Input, Select } from "_metronic/_partials/controls";
import SVG from "react-inlinesvg";
import { IPropertySurfaceDetails } from "../../../../../../../../data/schemas";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers";
import { OUTDOOR_STATUS } from "../../../../../../../_utils/listUtils";

interface SurfaceFieldsProps {
  parentName: string;
  disabled: boolean;
  index: number;
  entity: IPropertySurfaceDetails;
  handle: any;
  removeEntity: Function;
}

export const OutdoorSurfaceFields: React.FC<SurfaceFieldsProps> = ({
  parentName,
  disabled,
  index,
  entity,
  handle,
  removeEntity,
}) => {
  const { fieldChangeHandler, handleChange, numberFieldChangeHandler } = handle;
  const intl = useIntl();
  const SUFFIX_UNIT_AREA = intl.formatMessage({ id: "COMMON.AREA.UNIT" });

  return (
    <div className={"d-flex justify-content-berween my-4 bg-gray-100 h-100"}>
      <div className={"w-100 h-100 pb-4"}>
        <div className={"w-100 mx-2 form-row justify-content-between justify-content-lg-start"}>
          <FormSectionRegistryRow
            {...{
              disabled,
              onFieldChange: fieldChangeHandler(handleChange),
              suffix: {
                surface: SUFFIX_UNIT_AREA,
              },

              lot: `${parentName}.${index}.parcel`,
              registry: `${parentName}.${index}.landRegistryNumber`,

              surface: {
                value: entity?.surface ?? 0,
                onValueChange: numberFieldChangeHandler({
                  name: `${parentName}.${index}.surface`,
                  defaultValue: 0,
                  handleChange,
                }),
              },

              thousands: {
                value: entity?.thousands ?? 0,
                onValueChange: numberFieldChangeHandler({
                  name: `${parentName}.${index}.thousands`,
                  handleChange,
                }),
              },
            }}
          />
        </div>
        <div className={"w-100 mx-2 form-row justify-content-between justify-content-lg-start"}>
          <FormSectionField>
            <Field
              name={`${parentName}.${index}.landArchitectNumber`}
              onChange={fieldChangeHandler(handleChange)}
              label={intl.formatMessage({
                id: "PRODUCT.INFO.LAND_ARCHITECT_NUMBER",
              })}
              component={Input}
              disabled={disabled}
            />
          </FormSectionField>
          <FormSectionField className={"col-sm-6 col-md-5 mt-3"}>
            <Field
              name={`${parentName}.${index}.numberBlockStaircaseLevelCadastreCellar`}
              onChange={fieldChangeHandler(handleChange)}
              label={intl.formatMessage({
                id: "PRODUCT.INFO.NUMBER_BLOCK_STAIRCASE",
              })}
              component={Input}
              disabled={disabled}
            />
          </FormSectionField>
          <FormSectionField className={"col-sm-6 col-md-2 mt-3"}>
            <Select
              name={`${parentName}.${index}.status`}
              value={entity?.status}
              onChange={fieldChangeHandler(handleChange)}
              disabled={disabled}
              customFeedbackLabel={true}
              label={<FormattedMessage id={"COMMON.STATUS"} />}
            >
              {OUTDOOR_STATUS.map((value, index2) => (
                <option key={index2} value={value.key}>
                  {intl.formatMessage({ id: value.label })}
                </option>
              ))}
            </Select>
          </FormSectionField>
        </div>
      </div>

      <div
        className="pb-4 ml-2 d-flex flex-column justify-content-end btn px-0 btn-hover-danger"
        onClick={(e) => removeEntity(e)}
      >
        {!disabled && (
          <button style={{ minHeight: "36px" }} className={"btn btn-icon btn btn-sm my-auto"}>
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
          </button>
        )}
      </div>
    </div>
  );
};
