import { IImmotoolEntity } from "./ImmotoolEntity";

export enum ETaskStatusTranslationKeyPrefix {
  TITLE = "TASK.TITLE.STATUS.",
  LABEL = "TASK.STATUS.",
}

export enum ETaskStatus {
  PLANNED = "PLANNED",
  IN_PROGRESS = "IN_PROGRESS",
  STUCK = "STUCK",
  DONE = "DONE",
}

export const TASK_STATUS_I18N_TITLE = {
  [`${ETaskStatus.PLANNED}`]: "TASK.STATUS.PLANNED",
  [`${ETaskStatus.IN_PROGRESS}`]: "TASK.STATUS.IN_PROGRESS",
  [`${ETaskStatus.STUCK}`]: "TASK.STATUS.STUCK",
  [`${ETaskStatus.DONE}`]: "TASK.STATUS.DONE",
};

export const TASK_STATUS_ICON_NAME = {
  [`${ETaskStatus.PLANNED}`]: "dot-circle",
  [`${ETaskStatus.IN_PROGRESS}`]: "arrow-alt-circle-right",
  [`${ETaskStatus.STUCK}`]: "exclamation-circle",
  [`${ETaskStatus.DONE}`]: "check-circle",
};

export const TASK_STATUS_COLOR = {
  [`${ETaskStatus.PLANNED}`]: "#ebecf0",
  [`${ETaskStatus.IN_PROGRESS}`]: "#ffa800",
  [`${ETaskStatus.STUCK}`]: "#e8534f",
  [`${ETaskStatus.DONE}`]: "#50ab9f",
};

export type TTaskStatus = `${ETaskStatus}`;

export interface ITask extends IImmotoolEntity {
  parentId?: string;
  name: string;
  description: string;
  notifyUser: boolean;
  url: string;
  relatedTo: string;
  relatedToName: string;
  plannedStartDate?: Date;
  dueDate?: Date;
  doneDate: Date;
  status: TTaskStatus;
  assignee?: any; //User;
  assigneeId?: string;
  taskType: string;
  descriptionShort: string;
  reminders: any[]; //Reminder[];
  attachments: any[];
  comments: any[]; //TaskComment[];
  associatedTasks: any[];
  subtasks: ITask[];
}
