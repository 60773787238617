import React, { useMemo } from "react";
import { useProjectFilesUIContext } from "./ProjectFilesUIContext";
import { FormattedMessage } from "react-intl";
import { Filter } from "../../../../../_components/Filter";

export function ProjectFilesFilter() {
  const filesUIContext = useProjectFilesUIContext();
  const filesUIProps = useMemo(() => {
    return {
      setQueryParams: filesUIContext.setQueryParams,
      openNewFileDialog: filesUIContext.openNewFileDialog,
      queryParams: filesUIContext.queryParams,
      readOnly: filesUIContext.readOnly,
    };
  }, [filesUIContext]);

  return (
    <>
      <div className="form-filtration d-md-flex">
        <div className={"margin-bottom-10-mobile w-100"} style={{ maxWidth: "100%" }}>
          <Filter useEntityUIContext={useProjectFilesUIContext} />
        </div>
        <div className="text-right margin-bottom-10-mobile d-flex align-items-start mx-md-2">
          {!filesUIProps.readOnly && (
            <button
              type="button"
              className="btn btn-primary w-100 w-md-auto"
              style={{ maxWidth: "100%" }}
              onClick={() => filesUIProps.openNewFileDialog()}
            >
              <FormattedMessage id="FILE.ACTIONS.UPLOAD.NEW" />
            </button>
          )}
        </div>
      </div>
    </>
  );
}
