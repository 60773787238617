import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { useLanguage } from "../../../../_metronic/i18n";
import React, { useEffect, useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import en_US from "@react-pdf-viewer/locales/lib/en_US.json";
import { renderToolbar } from "./CustomToolbar";

export function PdfViewer({ fileUrl }) {
  const { selectedLanguage: lang } = useLanguage();
  const localeMapping = {
    "en-GB": "en_US",
    "fr-FR": "fr_FR",
    "de-DE": "de_DE",
    "es-ES": "es_ES",
    "it-IT": "it_IT",
  };
  const [pdfTexts, setPdfTexts] = useState(en_US);
  useEffect(() => {
    (async () => {
      const response = await import(
        "@react-pdf-viewer/locales/lib/" + localeMapping[lang] + ".json"
      );
      setPdfTexts(response);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
  });

  return (
    <Worker workerUrl="/pdf.worker.min.js">
      <Viewer
        fileUrl={fileUrl}
        plugins={[defaultLayoutPluginInstance]}
        localization={pdfTexts}
        renderLoader={(percentages) => <div className={"spinner spinner-primary spinner-lg"} />}
      />
    </Worker>
  );
}
