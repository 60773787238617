import { usePublicRequests } from "../hooks/usePublicRequests";
import * as React from "react";
import { CardPDFSignedViewer } from "../../../_components/CardPDFSignedViewer";

interface UnAuthPdfViewerProps {
  signature: { signedDate: Date; signedBy: string };
}

export const CardPDFSignedViewerPublicWrapper: React.FC<UnAuthPdfViewerProps> = ({ signature }) => {
  const { file } = usePublicRequests();

  return (
    <>
      <CardPDFSignedViewer fileUrl={file?.url} signatureInfo={signature} />
    </>
  );
};
