import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { shallowEqual, useDispatch } from "react-redux";
import { KycAmlFilloutWizard } from "./form-kyc-aml/KycAmlFilloutWizard";
import { PersonalInfoFilloutWizard } from "./form-personal-information/PersonalInfoFilloutWizard";
import { CardLoaderSpinner } from "../../../../_components/CardLoaderSpinner";
import * as actions from "../../_redux/forms/formsActions";
import { useAppSelector } from "../../../../../redux/hooks";
import { FormsUIProvider } from "./FormsUIProvider";

export const WizardPage = () => {
  const { id } = useParams();

  let history = useHistory();
  const dispatch = useDispatch();

  const { session, action } = useAppSelector(
    (state) => ({
      action: state.forms.action?.current,
      session: state.auth.session,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (!action && session?.id && id) {
      dispatch(actions.fetchAction(session.id, id));
    }
  }, [session, id, history]);

  switch (action?.actionType) {
    case "FILLOUT_AML_KYC_FORM":
      return (
        <FormsUIProvider
          pFormType={action?.actionType}
          pTitle="KYC.TITLE"
          pBreadcrumps={["KYC.TITLE"]}
          pFormId={id}
        >
          <KycAmlFilloutWizard />
        </FormsUIProvider>
      );
    case "FILLOUT_PERSONAL_INFORMATION":
      return (
        <FormsUIProvider
          pFormType={action?.actionType}
          pTitle={"FORM.TITLE.PERSONAL_INFORMATION"}
          pBreadcrumps={["KYC.TITLE"]}
          pFormId={id}
        >
          <PersonalInfoFilloutWizard />
        </FormsUIProvider>
      );
    default:
      return <CardLoaderSpinner />;
  }
};
