import React from "react";
import { FormattedMessage } from "react-intl";

export function FooterCompact({ today, footerClasses, footerContainerClasses }) {
  return (
    <>
      {/* begin::Footer */}
      <div
        className={`footer bg-white py-4 d-flex flex-lg-column  ${footerClasses}`}
        id="kt_footer"
      >
        {/* begin::Container */}
        <div
          className={`${footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
        >
          {/* begin::Copyright */}
          <div className="text-dark order-2 order-md-1 d-flex align-items-center">
            <span className="text-muted font-weight-bold mr-2">{today} &copy;</span>
            {` `}
            <a
              href="https://www.kodehyve.com/"
              rel="noopener noreferrer"
              target="_blank"
              className="text-dark-75 text-hover-primary pr-3"
            >
              kodehyve
            </a>
            <a
              href="https://y.at/%F0%9F%8E%AE%F0%9F%8C%95%F0%9F%90%9D"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link pl-3 pr-0"
            >
              🎮🌕🐝
            </a>
          </div>
          {/* end::Copyright */}
          {` `}
          {/* begin::Nav */}
          <div className="nav nav-dark order-1 order-md-2">
            <a
              href="https://kodehyve.atlassian.net/wiki/spaces/PUG/overview"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link pr-3 pl-0"
            >
              <FormattedMessage id={"COMMON.USER_GUIDE"} />
            </a>
            <button
              onClick={window.showCollectorDialog}
              id="feedback-button"
              className="btn btn-text p-0 nav-link pl-3 pr-0"
            >
              <FormattedMessage id={"COMMON.REPORT_BUG"} />
            </button>
          </div>
          {/* end::Nav */}
        </div>
        {/* end::Container */}
      </div>
      {/* end::Footer */}
    </>
  );
}
