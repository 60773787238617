import React, { useEffect, useMemo, useState } from "react";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
import {
  UserNotificationsDropdown
} from "../../../../app/_components/UserNotificationsDropdown/UserNotificationsDropdown";
import { QuickActionsDropdown } from "../extras/dropdowns/QuickActionsDropdown";
import { MyCartDropdown } from "../extras/dropdowns/MyCartDropdown";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import { chatsSelectors } from "../../../../app/modules/Chat/_redux/chatSlice";
import { sumBy } from "lodash-es";

export function Topbar() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(uiService.config, "extras.search.display"),
      viewNotificationsDisplay: objectPath.get(uiService.config, "extras.notifications.display"),
      viewQuickActionsDisplay: objectPath.get(uiService.config, "extras.quick-actions.display"),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(uiService.config, "extras.quick-panel.display"),
      viewLanguagesDisplay: objectPath.get(uiService.config, "extras.languages.display"),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display")
    };
  }, [uiService]);

  const { chats, session } = useAppSelector((state) => ({
    chats: chatsSelectors.selectAll(state),
    session: state.auth.session
  }));

  const [unseenMessages, setUnseenMessages] = useState(false);
  useEffect(() => {
    setUnseenMessages(
      sumBy(chats, (chat) => {
        const activeUser = chat.users.find((u) => u.userId === session.id);
        return +(
          chat.lastMessageSentBy !== session.id &&
          (!activeUser?.lastMessageSeenAt ||
            new Date(chat.lastMessageSentAt) > new Date(activeUser.lastMessageSeenAt))
        );
      })
    );
  }, [chats, session]);

  return (
    <div className="topbar">
      {layoutProps.viewSearchDisplay && <SearchDropdown />}

      <UserNotificationsDropdown />
      {/*{layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />}*/}

      {layoutProps.viewQuickActionsDisplay && <QuickActionsDropdown />}

      {layoutProps.viewCartDisplay && <MyCartDropdown />}

      {layoutProps.viewQuickPanelDisplay && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-panel-tooltip">Quick panel</Tooltip>}
        >
          <div
            className="topbar-item"
            data-toggle="tooltip"
            title="Quick panel"
            data-placement="right"
          >
            <div
              className="btn btn-icon btn-hover-transparent-white btn-lg mr-1"
              id="kt_quick_panel_toggle"
            >
              <span className="svg-icon svg-icon-xl">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")} />
              </span>
            </div>
          </div>
        </OverlayTrigger>
      )}

      {/* CHAT */}
      <div className="topbar-item"
           data-cy="link-chats"
           data-aws-analytics-on="click"
           data-aws-analytics-name="link-chats">
        <Link
          to={"/chats"}
          className="btn btn-icon btn-hover-transparent-white btn-lg mr-1 position-relative pulse pulse-primary"
        >
          <span className="svg-icon svg-icon-xl">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group-chat.svg")} />
          </span>
          {!!unseenMessages && (
            <>
              <span className="pulse-ring" />
              <div>
                <span
                  className="pulse-ring position-absolute"
                  style={{ bottom: "4px", right: "1px", top: "auto" }}
                />
                <span
                  className="badge badge-danger rounded-circle position-absolute"
                  style={{ fontSize: "70%", bottom: "4px", right: "4px", top: "auto" }}
                >
                  {unseenMessages}
                </span>
              </div>
            </>
          )}
        </Link>
      </div>

      {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />}

      {layoutProps.viewUserDisplay && <QuickUserToggler />}
    </div>
  );
}
