import * as React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export interface TooltipProps {
  disabled?: boolean;
  id: string;
  message: React.ReactNode;
  children: JSX.Element;
}

export const TooltipHandler: React.FunctionComponent<TooltipProps> = ({
  disabled = false,
  id,
  message,
  children,
}) =>
  disabled ? (
    <>{children}</>
  ) : (
    <OverlayTrigger overlay={<Tooltip id={id}>{message}</Tooltip>}>{children}</OverlayTrigger>
  );

export default TooltipHandler;
