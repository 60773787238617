import { createSlice } from "@reduxjs/toolkit";
import { isEqual, set, sumBy } from "lodash-es";
import { isFinanceFile, FinanceFileTypeToSubcontractorFinance } from "../../../../data/schemas";

const initialUsersState = {
  listLoading: false,
  actionsLoading: false,
  entities: [],
  me: undefined,
  entityForEdit: {
    saved: undefined,
    current: undefined,
  },
  chat: {},
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const usersSlice = (name) =>
  createSlice({
    name: name,
    initialState: initialUsersState,
    reducers: {
      catchError: (state, action) => {
        // state.error = `${action.type}: ${action.payload.error}`;
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else {
          state.actionsLoading = false;
        }
      },
      startCall: (state, action) => {
        state.error = null;
        if (action.payload.callType === callTypes.list) {
          state.listLoading = true;
          if (!isEqual(action.payload?.queryParams, state.queryParams)) {
            state.entities = [];
          }
        } else {
          state.actionsLoading = true;
        }
      },
      userFetched: (state, action) => {
        state.actionsLoading = false;
        state.entityForEdit = {
          saved: action.payload.entityForEdit,
          current: action.payload.entityForEdit,
        };
        state.error = null;
      },
      userMe: (state, action) => {
        state.actionsLoading = false;
        state.me = {
          current: action.payload.me,
        };
        state.error = null;
      },
      usersFetched: (state, action) => {
        const { entities } = action.payload;
        state.listLoading = false;
        state.error = null;
        state.entities = entities;
        // state.entityForEdit = initialUsersState.entityForEdit;
      },
      userCreated: (state, action) => {
        state.actionsLoading = false;
        state.error = null;
        state.entities.push(action.payload.user);
        state.entityForEdit = {
          saved: action.payload.user,
          current: action.payload.user,
        };
      },
      userFieldUpdatedLocally: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        if (!state.entityForEdit.current) {
          state.entityForEdit.current = {};
        }
        set(state.entityForEdit.current, action.payload.key, action.payload.value);
      },
      userUpdatedLocally: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.entityForEdit.current = action.payload.entityForEdit;
      },
      userUpdated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.entityForEdit.saved = {
          ...action.payload.user,
        };
        if (state.entityForEdit?.current?.files) {
          state.entityForEdit.saved.files = state.entityForEdit.current.files;
        }
        state.entities = state.entities.map((entity) => {
          if (entity.id === action.payload.user.id) {
            return action.payload.user;
          }
          return entity;
        });
      },
      userActionCreated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        const { action: newAction } = action.payload;
        [("current", "saved")].forEach((key) => {
          if (
            !state.entityForEdit?.[key]?.actions ||
            !state.entityForEdit?.[key]?.actions?.length === 0
          ) {
            state.entityForEdit[key].actions = [newAction];
            state.entityForEdit[key].actions = [newAction];
          } else {
            const indexAction = state.entityForEdit[key].actions?.findIndex((a) => {
              return a?.id === newAction?.id;
            });
            if (indexAction !== -1) {
              state.entityForEdit[key].actions[indexAction] = newAction;
            } else {
              state.entityForEdit[key].actions.push(newAction);
            }
          }
        });
      },
      userRightsUpdated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.entityForEdit.saved = action.payload.user;
        state.entities = state.entities.map((entity) => {
          if (entity.id === action.payload.user.id) {
            return action.payload.user;
          }
          return entity;
        });
      },
      userDeleted: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.entities = state.entities.filter((el) => el.id !== action.payload.id);
      },

      fileCreated: (state, action) => {
        const file = action.payload.file;
        state.error = null;
        state.actionsLoading = false;
        state.entityForEdit.current.files.push(file);
        state.entityForEdit.saved.files.push(file);

        const project = action.payload.project;
        if (project) {
          const currentUserProject = state.entityForEdit.current.projects?.find(
            (p) => p.projectId === file.projectId
          );
          if (currentUserProject) {
            currentUserProject[project.financeType] =
              (currentUserProject[project.financeType] ?? 0) + project.value;
          }
          const savedUserProject = state.entityForEdit.saved.projects?.find(
            (p) => p.projectId === file.projectId
          );
          if (savedUserProject) {
            savedUserProject[project.financeType] =
              (savedUserProject[project.financeType] ?? 0) + project.value;
          }
        }
      },
      fileUpdated: (state, action) => {
        const file = action.payload.file;
        state.error = null;
        state.actionsLoading = false;
        const currentFileIndex = state.entityForEdit.current.files.findIndex(
          (f) => f.id === file.id
        );
        state.entityForEdit.current.files[currentFileIndex] = file;
        const savedFileIndex = state.entityForEdit.current.files.findIndex((f) => f.id === file.id);
        state.entityForEdit.saved.files[savedFileIndex] = file;

        const project = action.payload.project;
        if (project) {
          const currentUserProject = state.entityForEdit.current.projects?.find(
            (p) => p.projectId === file.projectId
          );
          if (currentUserProject) {
            currentUserProject[project.financeType] =
              (currentUserProject[project.financeType] ?? 0) + project.value;
          }
          const savedUserProject = state.entityForEdit.saved.projects?.find(
            (p) => p.projectId === file.projectId
          );
          if (savedUserProject) {
            savedUserProject[project.financeType] =
              (savedUserProject[project.financeType] ?? 0) + project.value;
          }
        }
      },
      fileDeleted: (state, action) => {
        const file = action.payload.file;
        state.error = null;
        state.actionsLoading = false;
        state.entityForEdit.current.files = state.entityForEdit.current.files.filter(
          (f) => f.id !== file.id
        );
        state.entityForEdit.saved.files = state.entityForEdit.saved.files.filter(
          (f) => f.id !== file.id
        );

        if (file.projectId && isFinanceFile(file.fileType)) {
          let financeType = FinanceFileTypeToSubcontractorFinance[file.fileType];
          const sum = sumBy(file.categories, ({ lines }) => sumBy(lines, "amount"));
          const currentUserProject = state.entityForEdit.current.projects?.find(
            (p) => p.projectId === file.projectId
          );
          if (currentUserProject) {
            currentUserProject[financeType] -= sum;
          }
          const savedUserProject = state.entityForEdit.saved.projects?.find(
            (p) => p.projectId === file.projectId
          );
          if (savedUserProject) {
            savedUserProject[financeType] -= sum;
          }
        }
      },
      avatarCreated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
      },
      signatureRequestCreated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        const currentFileIndex = state.entityForEdit.current.files.findIndex(
          (file) => file.id === action.payload.signature.fileId
        );
        state.entityForEdit.current.files[currentFileIndex].signatures.push(
          action.payload.signature
        );
        const savedFileIndex = state.entityForEdit.current.files.findIndex(
          (file) => file.id === action.payload.signature.fileId
        );
        state.entityForEdit.saved.files[savedFileIndex].signatures.push(action.payload.signature);
      },
      auditTrailFetched: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.entityForEdit.events = action.payload.events;
      },
    },
  });
