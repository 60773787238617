import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TasksWidget } from "./widgets/TasksWidget";
import { EmptyWidget } from "./widgets/EmptyWidget";
import { LeadsCounterWidget } from "./widgets/LeadsCounterWidget";
import { PropertiesCounterWidget } from "./widgets/PropertiesCounterWidget";
import { ClientsCounterWidget } from "./widgets/ClientsCounterWidget";
import { ProfileWidget } from "./widgets/ProfileWidget";
import { isStaffMember } from "../../_utils/authUtils";
import { ActiveFilesWidget } from "./widgets/ActiveFilesWidget";
import * as actionsTasks from "../../modules/PropertiesManagement/_redux/tasks/tasksActions";
import { useIntl } from "react-intl";
import * as dashboardActions from "./_redux/dashboardActions";
import { useSubheader } from "../../../_metronic/layout";

export function DashboardPage() {
  const intl = useIntl();
  const { session, groups } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!groups?.map((g) => g.toLowerCase()).includes("admin")) {
      dispatch(actionsTasks.fetchTasks({ userId: session?.id, groups }));
    }
    dispatch(dashboardActions.loadCounters());
  }, []);

  const subheader = useSubheader();
  useEffect(() => {
    subheader.setTitle(
      intl.formatMessage({
        id: "DASHBOARD.TITLE",
      })
    );
  }, [subheader]);

  return (
    <>
      {/* begin::Dashboard */}
      {/* begin::Row */}
      <div className="row">
        <div className="col-lg-6">
          <div className="row">
            {/*<div className="col-xl-3">*/}
            {/*  <TilesWidget3 className="gutter-b" widgetHeight="150px"/>*/}
            {/*</div>*/}
            <div className="col-lg-12">
              <ProfileWidget className="gutter-b" widgetHeight="150px" />
            </div>
          </div>

          {isStaffMember(groups, session) ? (
            <>
              <div className="row">
                <div className="col-lg-8">
                  <ClientsCounterWidget className="gutter-b" />
                  <div className="row">
                    <div className="col-xl-6">
                      <PropertiesCounterWidget
                        className="gutter-b"
                        baseColor="primary"
                        widgetHeight="150px"
                      />
                    </div>
                    <div className="col-xl-6">
                      <LeadsCounterWidget
                        className="gutter-b"
                        iconColor="success"
                        widgetHeight="150px"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <EmptyWidget className="gutter-b card-stretch" />
                </div>
                {/*<div className="col-xl-6">*/}
                {/*  <TilesWidget14 className="gutter-b card-stretch"/>*/}
                {/*</div>*/}
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-lg-8">
                  <ActiveFilesWidget className="gutter-b" />
                </div>
                <div className="col-lg-4">
                  <EmptyWidget className="gutter-b card-stretch" />
                </div>
                {/*<div className="col-xl-6">*/}
                {/*  <TilesWidget14 className="gutter-b card-stretch"/>*/}
                {/*</div>*/}
              </div>
            </>
          )}
        </div>

        <div className="col-lg-6">
          <TasksWidget className="card-stretch gutter-b" intl={intl} />
        </div>
      </div>
      {/* end::Row */}

      {/*/!* begin::Row *!/*/}
      {/*<div className="row">*/}
      {/*  <div className="col-xl-4">*/}
      {/*    <TilesWidget1 className="gutter-b card-stretch" chartColor="danger"/>*/}
      {/*  </div>*/}
      {/*  <div className="col-xl-8">*/}
      {/*    <div className="row">*/}
      {/*      <div className="col-xl-3">*/}
      {/*        <TilesWidget3 className="gutter-b" widgetHeight="150px"/>*/}
      {/*      </div>*/}
      {/*      <div className="col-xl-9">*/}
      {/*        <TilesWidget10 className="gutter-b" widgetHeight="150px"/>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    <div className="row">*/}
      {/*      <div className="col-xl-6">*/}
      {/*        <TilesWidget13 className="gutter-b"/>*/}
      {/*        <div className="row">*/}
      {/*          <div className="col-xl-6">*/}
      {/*            <TilesWidget11 className="gutter-b" baseColor="primary" widgetHeight="150px"/>*/}
      {/*          </div>*/}
      {/*          <div className="col-xl-6">*/}
      {/*            <TilesWidget12 className="gutter-b" iconColor="success" widgetHeight="150px"/>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}

      {/*      <div className="col-xl-6">*/}
      {/*        <TilesWidget14 className="gutter-b card-stretch"/>*/}
      {/*      </div>*/}

      {/*    </div>*/}

      {/*  </div>*/}
      {/*</div>*/}
      {/*/!* end::Row *!/*/}

      {/*/!* begin::Row *!/*/}
      {/*<div className="row">*/}
      {/*  <div className="col-lg-6 col-xxl-4">*/}
      {/*    <MixedWidget6 className="gutter-b card-stretch" chartColor="danger"/>*/}
      {/*  </div>*/}

      {/*  <div className="col-lg-6 col-xxl-8">*/}
      {/*    <AdvanceTablesWidget1 className="card-stretch gutter-b"/>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*/!* end::Row *!/*/}

      {/*/!* begin::Row *!/*/}
      {/*<div className="row">*/}
      {/*  <div className="col-xl-4">*/}
      {/*    <MixedWidget10 className="card-stretch gutter-b"/>*/}
      {/*  </div>*/}

      {/*  <div className="col-xl-4">*/}
      {/*    <MixedWidget11 className="card-stretch gutter-b"/>*/}
      {/*  </div>*/}

      {/*  <div className="col-xl-4">*/}
      {/*    <MixedWidget12 className="card-stretch gutter-b"/>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*/!* end::Row *!/*/}

      {/*/!* begin::Row *!/*/}
      {/*<div className="row">*/}
      {/*  <div className="col-lg-6">*/}
      {/*    <ListsWidget10 className="card-stretch gutter-b"/>*/}
      {/*  </div>*/}
      {/*  <div className="col-lg-6">*/}
      {/*    <ListsWidget11 className="card-stretch gutter-b"/>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*/!* end::Row *!/*/}

      {/* end::Dashboard */}
    </>
  );
}
