import React from "react";
import { UserFilesFilter } from "./UserFilesFilter";
import { UserFilesTable } from "./UserFilesTable";
import { UserFileEditDialog } from "./user-file-edit-dialog/UserFileEditDialog";
import { UserRequestSignature } from "./user-file-edit-dialog/UserRequestSignature";

export function UserFiles() {
  return (
    <>
      <UserFileEditDialog />
      <UserRequestSignature />
      <div className="form margin-b-30">
        <UserFilesFilter />
      </div>
      <UserFilesTable />
    </>
  );
}
