import type { StylesConfig } from "react-select";

//----------------------------------------------------------------------------//

export const maxWidthPixel = 250;
export const widthViewport = 50;

//----------------------------------------------------------------------------//

export const baseStyles: StylesConfig = {
  singleValue: (defaultStyles) => ({
    ...defaultStyles,
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
  }),
  placeholder: (defaultSyles) => ({
    ...defaultSyles,
    color: "#b3b3c2",
  }),
  menuPortal: (defaultSyles) => ({
    ...defaultSyles,
    zIndex: 5,
  }),
};

export const baseControlStyles: StylesConfig = {
  control: (defaultSyles, { isFocused }) => ({
    ...defaultSyles,
    ":hover": { border: "1px solid #9cb8ff" },
    border: isFocused ? "1px solid #9cb8ff" : "1px solid #e4e6ef",
    outline: "none",
    boxShadow: "none",
    borderRadius: ".42rem",
    width: `${widthViewport}vw`,
    maxWidth: maxWidthPixel,
  }),
};
