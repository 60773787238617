import { createSlice } from "@reduxjs/toolkit";
import { formatDisplayName } from "../../../../_utils/userUtils";
import { set } from "lodash-es";

const initialLeadsState = {
  listLoading: false,
  actionsLoading: false,
  entities: [],
  leadForEdit: {
    saved: undefined,
    current: undefined,
  },
  project: undefined,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const leadsSlice = (name) =>
  createSlice({
    name: name,
    initialState: initialLeadsState,
    reducers: {
      catchError: (state, action) => {
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else {
          state.actionsLoading = false;
        }
      },
      startCall: (state, action) => {
        if (action.payload.callType === callTypes.list) {
          state.listLoading = true;
        } else {
          state.actionsLoading = true;
        }
      },
      leadFetched: (state, action) => {
        state.actionsLoading = false;
        state.leadForEdit = {
          saved: action.payload.leadForEdit,
          current: action.payload.leadForEdit,
        };
        state.project = undefined;
        state.chat = undefined;
      },
      leadsFetched: (state, action) => {
        const { entities } = action.payload;
        state.listLoading = false;
        state.entities = entities;
        state.leadForEdit = initialLeadsState.leadForEdit;
      },
      leadCreated: (state, action) => {
        state.actionsLoading = false;
        state.entities.push(action.payload.lead);
        state.leadForEdit = {
          saved: action.payload.lead,
          current: action.payload.lead,
        };
      },
      leadFieldUpdatedLocally: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        if (!state.leadForEdit.current) {
          state.leadForEdit.current = {};
        }
        set(state.leadForEdit.current, action.payload.key, action.payload.value);
      },
      leadUpdated: (state, action) => {
        state.actionsLoading = false;
        state.leadForEdit.saved = action.payload.lead;
        state.entities = state.entities.map((entity) => {
          if (entity.id === action.payload.lead.id) {
            return action.payload.lead;
          }
          return entity;
        });
      },
      leadDeleted: (state, action) => {
        state.actionsLoading = false;
        state.entities = state.entities.filter((el) => el.id !== action.payload.id);
      },
      leadProductFetched: (state, action) => {
        console.log("leadProductFetched: ", action);
        state.actionsLoading = false;
        const { product } = action.payload;
        const newState = {
          ...state.leadForEdit.current,
          product,
          sellingPrice: product.financialDetails.price,
          shareOfLandSellingPrice: product.financialDetails.shareOfLand,
          architectEngineeringFees: product.financialDetails.architectEngineeringFees,
        };
        if (!state.leadForEdit.current?.name) {
          newState.name = product.name;
        }
        state.leadForEdit.current = newState;
      },
      leadClientsFetched: (state, action) => {
        console.log("leadClientsFetched: ", action);
        state.actionsLoading = false;
        state.leadForEdit.current = {
          ...state.leadForEdit.current,
          users: action.payload.users,
          name:
            state.leadForEdit.current?.name?.split("-")[0].trim() +
            " - " +
            action.payload.users.map((c) => formatDisplayName(c)).join(", "),
        };
      },
      leadClientCreated: (state, action) => {
        state.actionsLoading = false;
        state.leadForEdit.current.users =
          state.leadForEdit.current?.users && state.leadForEdit.current.users.length > 0
            ? [...state.leadForEdit.current.users, action.payload.user]
            : [action.payload.user];
        state.leadForEdit.saved.users =
          state.leadForEdit.saved?.users && state.leadForEdit.saved.users.length > 0
            ? [...state.leadForEdit.saved.users, action.payload.user]
            : [action.payload.user];
      },
      leadClientDeleted: (state, action) => {
        state.actionsLoading = false;
        state.leadForEdit.current.users =
          state.leadForEdit.current?.users && state.leadForEdit.current.users.length > 0
            ? [...state.leadForEdit.current.users].filter(
                (user) => user.id !== action.payload.user.id
              )
            : [];
        state.leadForEdit.saved.users =
          state.leadForEdit.saved?.users && state.leadForEdit.saved.users.length > 0
            ? [...state.leadForEdit.saved.users].filter(
                (user) => user.id !== action.payload.user.id
              )
            : [];
      },
      projectFetched: (state, action) => {
        state.actionsLoading = false;
        state.project = action.payload.project;
      },
      fileCreated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.leadForEdit.current.files.push(action.payload.file);
        state.leadForEdit.saved.files.push(action.payload.file);
      },
      fileUpdated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        const currentFileIndex = state.leadForEdit.current.files.findIndex(
          (file) => file.id === action.payload.file.id
        );
        state.leadForEdit.current.files[currentFileIndex] = action.payload.file;
        const savedFileIndex = state.leadForEdit.current.files.findIndex(
          (file) => file.id === action.payload.file.id
        );
        state.leadForEdit.saved.files[savedFileIndex] = action.payload.file;
      },
      fileDeleted: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.leadForEdit.current.files = state.leadForEdit.current.files.filter(
          (file) => file.id !== action.payload.file.id
        );
        state.leadForEdit.saved.files = state.leadForEdit.saved.files.filter(
          (file) => file.id !== action.payload.file.id
        );
      },
      auditTrailFetched: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.leadForEdit.events = action.payload.events;
      },
      signatureRequestCreated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        const currentFileIndex = state.leadForEdit.current.files.findIndex(
          (file) => file.id === action.payload.signature.fileId
        );
        state.leadForEdit.current.files[currentFileIndex].signatures.push(action.payload.signature);
        const savedFileIndex = state.leadForEdit.current.files.findIndex(
          (file) => file.id === action.payload.signature.fileId
        );
        state.leadForEdit.saved.files[savedFileIndex].signatures.push(action.payload.signature);
      },
    },
  });
