import * as React from "react";
import cn from "clsx";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { DragDropContext } from "@react-forked/dnd";

import { ITask } from "data/schemas";

import { ISelectedSort } from "app/_utils/tasks";
import { LANES_DEFINITION as lanesDefinition } from "app/_utils/listUtils";

import SkeletonTaskLane from "app/_skeletons/SkeletonTaskLane";

import * as actions from "app/modules/PropertiesManagement/_redux/tasks/tasksActions";

import { TaskLaneHeader, TasksLane } from "./TasksLane";

import "./TasksBoard.scss";

export interface TasksBoardProps {
  isTab?: boolean;
  filterText?: string;
  isTasksLoading?: boolean;
  selectedSort: ISelectedSort;
  tasks: ITask[];
  rawTasks: ITask[];
}

export const TasksBoard: React.FunctionComponent<TasksBoardProps> = ({
  isTab = false,
  selectedSort,
  filterText,
  isTasksLoading,
  tasks,
  rawTasks,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [sourceLaneIdDragging, setSourceLaneIdDragging] = React.useState("");

  function onDragStart({ source }: any) {
    setSourceLaneIdDragging(lanesDefinition[source.droppableId]?.id);
  }

  function onDragEnd(result: any) {
    let { destination } = result;
    const { draggableId, source } = result;

    if (!destination) {
      destination = source;
    }
    const targetStatus = lanesDefinition[destination.droppableId];
    const taskId = draggableId;

    const task = rawTasks.find((t) => t.id === taskId);

    setSourceLaneIdDragging("");
    if (task && destination.droppableId !== source.droppableId) {
      dispatch(actions.updateTask({ ...task, status: targetStatus.id }));
    }
  }

  return (
    <div className="tasks-board" data-cy="board-tasks">
      <div className={cn("tasks-board--content", { "no-bottom": isTab })}>
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          {lanesDefinition.map((laneDefinition, index) => (
            <div
              key={`lane-${index}`}
              className={"d-flex flex-column col-10 col-sm-8 col-md-5 col-lg-3 pl-2 pr-2"}
            >
              <TaskLaneHeader
                index={index}
                title={intl.formatMessage({ id: laneDefinition.title }).toUpperCase()}
              />

              <div className="tasks-board--top-shadow"></div>

              {isTasksLoading ? (
                <SkeletonTaskLane
                  className="w-100 rounded border flex-grow-1 h-100"
                  key={`skeleton-lane-${index}`}
                />
              ) : (
                <TasksLane
                  {...{
                    key: `lane-${index}`,
                    ind: index,
                    filterText,
                    selectedSort,
                    laneDefinition,
                    tasks,
                    disableDrop: sourceLaneIdDragging
                      ? laneDefinition.id === sourceLaneIdDragging
                      : false,
                    hideLane: sourceLaneIdDragging
                      ? !(laneDefinition.id === sourceLaneIdDragging)
                      : false,
                  }}
                />
              )}

              <div className="tasks-board--bottom-shadow"></div>
            </div>
          ))}
        </DragDropContext>
      </div>
    </div>
  );
};

export default TasksBoard;
