import React from "react";
import { IntlShape } from "react-intl";
import { selectedLeadStatus } from "../../_utils/formUtils";

export const StatusColumnFormatter = (
  cellContent: string,
  row: Object,
  rowIndex: number,
  extraParams: {
    intl: IntlShape;
  }
) => {
  const leadStatus = selectedLeadStatus(cellContent ?? {});

  return (
    <span
      className={`label label-lg label-light-${leadStatus.className || "primary"} label-inline`}
    >
      {extraParams.intl.formatMessage({ id: leadStatus.name })}
    </span>
  );
};
