/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useRef, useState } from "react";
import cn from "clsx";
import { shallowEqual, useDispatch } from "react-redux";
import { cloneDeep, isEqual, mergeWith } from "lodash-es";
import { useQueryState } from "react-router-use-location-state";
import SVG from "react-inlinesvg";
import { FormattedMessage, useIntl } from "react-intl";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import * as actions from "../../../_redux/projects/projectsActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  ModalProgressBar,
} from "../../../../../../_metronic/_partials/controls";
import { ProjectEditForm } from "./ProjectEditForm";
import { Locker } from "../../../../../_components/Locker";
import { useSubheader } from "../../../../../../_metronic/layout";
import { ProjectFilesUIProvider } from "../project-files/ProjectFilesUIContext";
import { ProjectFiles } from "../project-files/ProjectFiles";
import { ProductsTable } from "../../products/products-table/ProductsTable";
import { ProductsUIProvider, useProductsUIContext } from "../../products/ProductsUIContext";
import { ProjectAnalysis } from "../project-analysis/ProjectAnalysis";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import {
  canCreate,
  canEdit,
  canGoOnTabAnalysis,
  canReadAll,
  canSeeAuditTrail,
  canSeeNote,
  canShare,
} from "../../../../../_utils/authUtils";
import { ProjectShareDialog } from "../project-dialogs/ProjectShareDialog";
import { useCustomLocationState } from "../../../../../_utils/useCustomLocationState";
import { useAutoSave } from "../../../../../_utils/useAutoSave";
import { AuditTrail } from "../../../../../_components/AuditTrail";
import { ProjectSubcontractorsTable } from "../project-subcontractors/ProjectSubcontractorsTable";
import { ProjectsUIProvider } from "../ProjectsUIContext";
import { Filter } from "../../../../../_components/Filter";
import { TextEditor } from "../../../../../_components/TextEditor/TextEditor";
import { Sorter } from "app/_components/Sorter";
import { TASK_SORTER_OPTION } from "../../../../../_utils/listUtils";
import { LeadTab } from "../../../../Lead/components/LeadTab";
import { TabNavHeader } from "../../../../../_components/TabNavHeader";
import { BudgetTab } from "../../../../Budget/components/BudgetTab";
import { isBudgetServiceAvailable } from "../../../../../_utils/configUtils";
import { useAppSelector } from "../../../../../../redux/hooks";
import { ProjectDashboard } from "../ProjectDashboard";
import { PhotosTable } from "../project-photo/PhotosTable";

import { TasksPageTab } from "app/modules/PropertiesManagement/pages/tasks/TasksPageTab";
import {
  TasksContentViewMode,
  TasksSwitchContentView,
  useTasksSwitchContentView,
} from "app/modules/PropertiesManagement/pages/tasks/TasksCard/TasksCardContent/TasksSwitchContentView";

const initProject = {
  id: undefined,
  name: "",
  description: "",
  address: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    stateOrRegion: "",
    postalCode: "",
    countryCode: "",
  },
  availability: "",
  constructionYear: "",
  pictureSetup: false,
  pictureUrl: "",
  pictureUrlExpires: undefined,
  pictureKey: "",
  urls: [],
  specification: "",
  photos: [],
  photosOrder: [],
};

const mergeWithInitProject = (obj) => {
  return mergeWith(cloneDeep(initProject), obj, (dest, src) => (src === null ? dest : undefined));
};

export function ProjectEdit({
  history,
  match: {
    params: { id },
  },
}) {
  const intl = useIntl();
  // Subheader
  const subheader = useSubheader();

  // Tabs
  const [tab, setTab] = useQueryState("t", "general");
  const dispatch = useDispatch();

  const isBudgetAvailable = React.useMemo(() => isBudgetServiceAvailable(), []);

  const { contentView, toggleContentView } = useTasksSwitchContentView();
  const [triggerOpenNewTaskDialog, setTriggerOpenNewTaskDialog] = useState("");

  const { actionsLoading, projectForEdit, originalProjectForEdit, userGroups, session, events } =
    useAppSelector(
      (state) => ({
        actionsLoading: state.projects.actionsLoading || state.budgets.actionsLoading,
        projectForEdit: state.projects.projectForEdit.current
          ? state.projects.projectForEdit.current
          : state.projects.entities?.find((entity) => entity.id === id),
        originalProjectForEdit: state.projects.projectForEdit.saved
          ? state.projects.projectForEdit.saved
          : state.projects.entities?.find((entity) => entity.id === id),
        userGroups: state.auth.groups,
        session: state.auth.session,
        events: state.projects?.projectForEdit?.events || [],
      }),
      shallowEqual
    );

  useAutoSave(projectForEdit, originalProjectForEdit, actions.updateProject, [
    "projectSubcontractors",
  ]);

  const readOnly = id
    ? !canEdit(userGroups, session, "PROJECT")
    : !canCreate(userGroups, session, "PROJECT");

  const { setFromUrlTo, goBack } = useCustomLocationState();

  useEffect(() => {
    dispatch(actions.fetchProject(id));
  }, [id]);

  useEffect(() => {
    if (tab === "history") {
      dispatch(actions.fetchAuditTrail(id));
    }
  }, [tab]);

  useEffect(() => {
    let _title = "";
    if (!id || originalProjectForEdit?.id === id) {
      _title = !id ? intl.formatMessage({ id: "PROJECT.TITLE.NEW" }) : originalProjectForEdit.name;
      subheader.setTitle(_title);
    }
  }, [originalProjectForEdit, id]);

  const saveProjectFields = (key, value) => {
    if (Array.isArray(key) && key.length === value.length) {
      for (const k of Object.keys(key)) {
        dispatch(actions.updateProjectFieldLocally(key[k], value[k]));
      }
    } else {
      dispatch(actions.updateProjectFieldLocally(key, value));
    }
  };

  const projectEditFormCallbackRef = useRef(null);
  const submitProject = () => {
    const notes = `<h2>${projectForEdit?.name} - Notes</h2><p>&nbsp;</p><p>&nbsp;</p>`;
    if (!id) {
      dispatch(
        actions.createProject(
          mergeWithInitProject({
            ...projectForEdit,
            notes,
          })
        )
      ).then((project) => {
        if (!!project?.id) {
          history.push(`/projects/${project?.id}${history.location.search}`);
        }
      });
    }
  };

  const backButtonClick = () => {
    goBack("/projects");
  };

  const productsUIEvents = {
    openProductPage: (product) => {
      subheader.setTitle(product.name);
      setFromUrlTo({ url: `/products/${product.id}`, name: projectForEdit.name });
    },
  };

  const [openShareProjectDialog, setOpenShareProjectDialog] = useState(false);

  const openTaskDetails = () => {
    setTriggerOpenNewTaskDialog(new Date().toISOString());
  };

  const [selectedSort, setSelectedSort] = useState();

  return (
    <>
      {id && <ProjectDashboard {...{ readOnly, tab }} />}

      <Card style={{ height: "auto" }}>
        <ProjectShareDialog
          show={openShareProjectDialog}
          id={id}
          onHide={() => {
            setOpenShareProjectDialog(false);
          }}
        />

        {actionsLoading && <ModalProgressBar />}
        <CardHeader title={!id ? intl.formatMessage({ id: "PROJECT.TITLE.NEW" }) : ""}>
          <CardHeaderToolbar>
            <button
              type="button"
              data-cy="button-back"
              onClick={backButtonClick}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left" />
              {id ? (
                <FormattedMessage id="COMMON.ACTION.BACK" />
              ) : (
                <FormattedMessage id="COMMON.ACTION.CANCEL" />
              )}
            </button>
            {canShare(userGroups, session, "PROJECT") && id && (
              <OverlayTrigger
                overlay={
                  <Tooltip id="project-share-tooltip">
                    <FormattedMessage id="COMMON.ACTION.SHARE" />
                  </Tooltip>
                }
              >
                <a
                  className="btn btn-icon btn-light btn-hover-info ml-3"
                  data-cy="btn-share-project"
                  onClick={() => setOpenShareProjectDialog(true)}
                >
                  <span className="svg-icon svg-icon-md svg-icon-info">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Share.svg")} />
                  </span>
                </a>
              </OverlayTrigger>
            )}
            {!readOnly && !id && (
              <button
                type="submit"
                className="btn btn-primary ml-2"
                data-cy="button-project-create"
                onClick={projectEditFormCallbackRef?.current}
                disabled={actionsLoading}
              >
                <FormattedMessage id="COMMON.ACTION.CREATE" />
              </button>
            )}
            {tab === "tasks" && canCreate(userGroups, session, "TASK") && (
              <div className={"ml-4 d-flex"}>
                <Sorter
                  selectedSort={selectedSort}
                  setSelectedSort={setSelectedSort}
                  option={TASK_SORTER_OPTION}
                  disabled={contentView === TasksContentViewMode.GANTT_CHART}
                />

                <TasksSwitchContentView
                  {...{
                    className: "ml-4",
                    mode: contentView,
                    onChange: toggleContentView,
                  }}
                />

                {canCreate(userGroups, session, "TASK") && (
                  <button
                    type="button"
                    className="btn btn-primary ml-2"
                    onClick={() => openTaskDetails()}
                  >
                    <FormattedMessage id="TASK.TITLE.NEW" />
                  </button>
                )}
              </div>
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody className={cn(tab === "tasks" && "d-flex flex-column")}>
          <ul className="nav nav-tabs nav-tabs-line" role="tablist">
            <li className="nav-item" onClick={() => setTab("general")}>
              <a
                className={`nav-link ${tab === "general" && "active"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "general").toString()}
              >
                <FormattedMessage id="COMMON.GENERAL" />
              </a>
            </li>
            {id && (
              <>
                <li className="nav-item" onClick={() => setTab("files")}>
                  <a
                    className={`nav-link ${tab === "files" && "active"}`}
                    data-toggle="tab"
                    role="button"
                    aria-selected={(tab === "files").toString()}
                  >
                    <FormattedMessage id="FILE.TITLE" />
                  </a>
                </li>
                <li className="nav-item" onClick={() => setTab("photos")}>
                  <a
                    className={`nav-link ${tab === "photos" && "active"}`}
                    data-toggle="tab"
                    role="button"
                    aria-selected={(tab === "photos").toString()}
                  >
                    <FormattedMessage id="COMMON.TAB.TITLE.PHOTO" />
                  </a>
                </li>
                {canSeeNote(userGroups, session, "PROJECT") && (
                  <li className="nav-item" onClick={() => setTab("notes")}>
                    <a
                      className={`nav-link ${tab === "notes" && "active"}`}
                      data-toggle="tab"
                      role="tab"
                      aria-selected={(tab === "notes").toString()}
                    >
                      <Locker />
                      <FormattedMessage id="NOTE.TITLE" />
                      {!isEqual(projectForEdit?.notes || "", originalProjectForEdit?.notes || "") &&
                        "*"}
                    </a>
                  </li>
                )}
                <li className="nav-item" onClick={() => setTab("properties")}>
                  <a
                    className={`nav-link ${tab === "properties" && "active"}`}
                    data-toggle="tab"
                    role="tab"
                    aria-selected={(tab === "properties").toString()}
                  >
                    <FormattedMessage id="PRODUCT.TITLE" />
                  </a>
                </li>
                {canReadAll(userGroups, session, "LEAD") && (
                  <TabNavHeader
                    name={"leads"}
                    tab={tab}
                    setTab={setTab}
                    translationId={"COMMON.TAB.TITLE.LEAD"}
                    showLocker={true}
                  />
                )}
                {canEdit(userGroups, session, "PROJECT") && (
                  <li className="nav-item" onClick={() => setTab("subcontractors")}>
                    <a
                      className={`nav-link ${tab === "subcontractors" && "active"}`}
                      data-toggle="tab"
                      role="tab"
                      aria-selected={(tab === "subcontractors").toString()}
                    >
                      <Locker />
                      <FormattedMessage id="SUBCONTRACTORS" />
                    </a>
                  </li>
                )}
                <li className="nav-item" onClick={() => setTab("tasks")}>
                  <a
                    className={`nav-link ${tab === "tasks" && "active"}`}
                    data-toggle="tab"
                    role="tab"
                    data-cy="task-user-tab"
                    aria-selected={(tab === "tasks").toString()}
                  >
                    <FormattedMessage id="TASK.TITLE" />
                  </a>
                </li>
                {isBudgetAvailable &&
                  canReadAll(userGroups, session, "BUDGET") &&
                  canReadAll(userGroups, session, "PROJECT") && (
                    <TabNavHeader
                      name={"budgets"}
                      tab={tab}
                      setTab={setTab}
                      translationId={"BUDGET.TITLE"}
                      showLocker={true}
                    />
                  )}
                {canGoOnTabAnalysis(userGroups, session) && (
                  <li className="nav-item" onClick={() => setTab("analysis")}>
                    <a
                      className={`nav-link ${tab === "analysis" && "active"}`}
                      data-toggle="tab"
                      role="tab"
                      aria-selected={(tab === "analysis").toString()}
                    >
                      <Locker />
                      <FormattedMessage id="FINANCE.TITLE.ANALYSIS" />
                    </a>
                  </li>
                )}
                {canSeeAuditTrail(userGroups, session, "PROJECT") && (
                  <li className="nav-item" onClick={() => setTab("history")}>
                    <a
                      className={`nav-link ${tab === "history" && "active"}`}
                      data-toggle="tab"
                      role="tab"
                      aria-selected={(tab === "history").toString()}
                    >
                      <Locker />
                      <FormattedMessage id="HISTORY.TITLE" />
                    </a>
                  </li>
                )}
              </>
            )}
          </ul>
          <div className={cn("mt-5", tab === "tasks" && "flex-grow-1")}>
            {tab === "general" && (
              <ProjectEditForm
                actionsLoading={actionsLoading}
                project={mergeWithInitProject(projectForEdit)}
                saveProjectFields={saveProjectFields}
                submitProject={submitProject}
                disabled={readOnly}
                onSubmitRef={(callback) => {
                  projectEditFormCallbackRef.current = callback;
                }}
              />
            )}
            {tab === "files" && projectForEdit && (
              <ProjectFilesUIProvider currentProjectId={id} readOnly={readOnly}>
                <ProjectFiles />
              </ProjectFilesUIProvider>
            )}
            {tab === "photos" && id && !!projectForEdit && <PhotosTable />}
            {tab === "notes" && projectForEdit && canSeeNote(userGroups, session, "PROJECT") && (
              <>
                <TextEditor
                  name={"notes"}
                  data={projectForEdit?.notes}
                  saveMethod={saveProjectFields}
                  disabled={readOnly}
                />
              </>
            )}
            {tab === "properties" && projectForEdit && (
              <>
                <ProductsUIProvider
                  queryParamsInit={{ projectId: id }}
                  productsUIEvents={productsUIEvents}
                  readOnly={readOnly}
                  context={"projectProducts"}
                >
                  <div className="form-filtration">
                    <div className="row align-items-center">
                      <div className="col-md-6 margin-bottom-10-mobile">
                        <Filter useEntityUIContext={useProductsUIContext} />
                      </div>
                      <div className="col-md-2 margin-bottom-10-mobile" />
                      <div className="col-md-4 text-right margin-bottom-10-mobile " />
                    </div>
                    <ProductsTable />
                  </div>
                  {/*{!readOnly && (*/}
                  {/*<>*/}
                  {/*  <ProductsActions openAddClientToLead={() => {*/}
                  {/*    setAction("addClient");*/}
                  {/*  }}*/}
                  {/*  />*/}
                  {/*</>*/}
                  {/*)}*/}
                </ProductsUIProvider>
              </>
            )}
            {tab === "leads" && projectForEdit && canReadAll(userGroups, session, "LEAD") && (
              <LeadTab
                context={"projectLeads"}
                name={projectForEdit.name}
                queryParam={{ projectId: id }}
                readOnly={readOnly}
              />
            )}
            {tab === "subcontractors" && canEdit(userGroups, session, "PROJECT") && (
              <ProjectsUIProvider readOnly={readOnly}>
                <ProjectSubcontractorsTable />
              </ProjectsUIProvider>
            )}
            {tab === "tasks" && projectForEdit && id && (
              <TasksPageTab
                {...{
                  triggerOpenNewTaskDialog,
                  contentView,
                  toggleContentView,
                  history,
                  selectedSort,
                  relatedToName: projectForEdit.name,
                  relatedTo: `PROJECT#${projectForEdit.id}`,
                }}
              />
            )}
            {isBudgetAvailable &&
              tab === "budgets" &&
              projectForEdit &&
              canReadAll(userGroups, session, "BUDGET") &&
              canReadAll(userGroups, session, "PROJECT") && (
                <BudgetTab projectId={projectForEdit.id} />
              )}
            {tab === "analysis" && projectForEdit && canGoOnTabAnalysis(userGroups, session) && (
              <ProjectAnalysis project={projectForEdit} />
            )}
            {tab === "history" && canSeeAuditTrail(userGroups, session, "PROJECT") && (
              <AuditTrail events={events} />
            )}
          </div>
        </CardBody>
      </Card>
    </>
  );
}
