import React from "react";

export const PlatformAccessColumnFormatter = (
  cellContent: boolean,
  row: { isDisabled: boolean; id: string }
) => {
  if (!row.isDisabled || !row.id) {
    return (
      <div className="text-center pr-6">
        <div className="symbol symbol-35 bg-white-o-30 flex-shrink-0">
          <i className="fas fa-check-square text-success icon-lg" />
        </div>
      </div>
    );
  }
};
