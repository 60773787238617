import React from "react";
import { NumberInput } from "../../_utils/formUtils";
import { ColumnFormatter } from "react-bootstrap-table-next";
import { SUFFIX_EURO_CURRENCY } from "../../_utils/suffixUtils";

export const TotalColumnFormatter: ColumnFormatter<any, any, string> = (cellContent) => (
  <NumberInput
    value={cellContent}
    suffix={SUFFIX_EURO_CURRENCY}
    decimalScale={2}
    displayType="text"
  />
);
