import React, { ChangeEvent, useEffect } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../../redux/hooks";
import { productsSlice } from "../../../_redux/products/productsSlice";
import * as projectActions from "../../../_redux/projects/projectsActions";
import * as productActions from "../../../_redux/products/productsActions";
import { FormattedMessage } from "react-intl";
import { Photos } from "../../../../../_components/Photos";
import { IProduct } from "../../../../../../data/schemas";
import { projectsSlice } from "../../../_redux/projects/projectsSlice";

export const PhotosTable = () => {
  const dispatch = useDispatch();

  const actionProduct = productsSlice("products").actions;
  const { actions: actionProject } = projectsSlice;

  const { project, productForEdit, isLoadingProduct, isLoadingProject } = useAppSelector(
    (state: any) => ({
      project: state?.projects?.projectForEdit?.current,
      productForEdit: state?.products?.productForEdit?.current,
      isLoadingProduct: state.products.actionsLoading || state.products.listLoading,
      isLoadingProject: state.projects.actionsLoading || state.projects.listLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (project?.products?.length > 0)
      dispatch(actionProduct.productFetched({ productForEdit: project?.products![0] }));
  }, []);

  useEffect(() => {
    dispatch(projectActions.fetchPhotos(project?.id));
  }, [project?.id]);

  useEffect(() => {
    if (productForEdit) {
      const productForEditId = productForEdit.id;
      dispatch(
        actionProject.productUpdate(
          project?.products.map((product: IProduct) =>
            productForEditId === product?.id ? productForEdit : product
          )
        )
      );
    }
  }, [productForEdit]);

  return (
    <div>
      {!!project && (
        <Photos
          item={project}
          actions={projectActions}
          parentType={"project"}
          displayLabel={true}
          isLoading={isLoadingProject}
        />
      )}
      {project?.products?.length > 0 && (
        <div>
          <label>
            <FormattedMessage id="COMMON.PRODUCTS.PHOTOS" />
          </label>
          <select
            className="form-control form-control-solid"
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              dispatch(
                actionProduct.productFetched({ productForEdit: project?.products[e.target.value] })
              );
            }}
          >
            {project?.products.map((value: any, index: number) => (
              <option key={index} value={index}>
                {value.name}
              </option>
            ))}
          </select>
          {!!productForEdit && (
            <Photos
              item={productForEdit}
              actions={productActions}
              parentType={"product"}
              displayLabel={false}
              isLoading={isLoadingProduct}
            />
          )}
        </div>
      )}
    </div>
  );
};
