// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { shallowEqual } from "react-redux";
import * as actions from "../../../_redux/projects/projectsActions";
import * as uiHelpers from "../ProjectsUIHelpers";
import {
  NoRecordsFoundMessage,
  sortCaret,
  toAbsoluteUrl,
} from "../../../../../../_metronic/_helpers";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useProjectsUIContext } from "../ProjectsUIContext";
import { canDelete, canReadAll, canSeeHiddenLine, canShare } from "../../../../../_utils/authUtils";
import { useIntl } from "react-intl";
import { useEntityDeleteDialogContext } from "../../entity-delete-dialog/EntityDeleteDialogContext";
import { ProjectShareDialog } from "../project-dialogs/ProjectShareDialog";
import { searchUtils, selectField } from "../../../../../_utils/searchUtils";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { accurateFloatOperation } from "../../../../../_utils/mathUtils";
import {
  ActionsColumnFormatter,
  DateTimeColumnFormatter,
  PhotoColumnFormatter,
  ProgressBarFormatter,
} from "../../../../../_components/column-formatters";
import SVG from "react-inlinesvg";
import cn from "clsx";
import { ProjectsMacroView } from "./ProjectsMacroView";
import { DateUtils } from "../../../../../_utils/DateUtils";

export function ProjectsTable() {
  const intl = useIntl();

  const projectsUIContext = useProjectsUIContext();

  const projectsUIProps = useMemo(() => {
    return {
      setIds: projectsUIContext.setIds,
      queryParams: projectsUIContext.queryParams,
      filters: projectsUIContext.filters,
      openProjectPage: projectsUIContext.openProjectPage,
      readOnly: projectsUIContext.readOnly,
      filterList: projectsUIContext.filterList,
    };
  }, [projectsUIContext]);

  const { entities, listLoading, userGroups, username, session } = useAppSelector(
    (state) => ({
      entities: state.projects.entities,
      listLoading: state.projects.listLoading,
      userGroups: state.auth.groups,
      session: state.auth.session,
      username: state.auth.session?.id,
    }),
    shallowEqual
  );
  const [isViewList, setIsViewList] = useState(true);
  const dispatch = useAppDispatch();
  useEffect(() => {
    projectsUIProps.setIds([]);
    if (canReadAll(userGroups, session, "PROJECT")) {
      dispatch(actions.fetchProjects(projectsUIProps.queryParams));
    } else {
      dispatch(actions.fetchProjectsForReseller(username));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsUIProps.queryParams, dispatch]);

  const { setDeleteEntityDialog } = useEntityDeleteDialogContext();
  const openDeleteProjectDialog = (id) => {
    setDeleteEntityDialog({
      action: { fn: actions.deleteProject, props: id },
      entityType: "PROJECT",
    });
  };

  const [openShareProjectDialog, setOpenShareProjectDialog] = useState(false);

  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "",
      sort: false,
      formatter: PhotoColumnFormatter,
      formatExtraData: {
        icon: "fas fa-layer-group text-info icon-md",
      },
    },
    {
      dataField: "name",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "COMMON.NAME",
      }),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "address.city",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "ADDRESS.CITY",
      }),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "productsCount",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "PRODUCT.TITLE",
      }),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "availability",
      text: intl.formatMessage({
        id: "PROJECT.INFO.AVAILABILITY_DATE",
      }),
      sort: true,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      sortCaret: sortCaret,
      formatter: DateTimeColumnFormatter,
      formatExtraData: { intl, showTime: false },
      sortValue: (cell) => (cell ? DateUtils.format(new Date(cell), intl, false) : "-"),
    },
    {
      dataField: "tasksProgress",
      text: intl.formatMessage({
        id: "COMMON.PROGRESS",
      }),
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      sort: true,
      sortValue: (cell, row) =>
        row.tasksCount
          ? accurateFloatOperation(((row.tasksDoneCount ?? 0) / row.tasksCount) * 100, 0)
          : -1,
      sortCaret: sortCaret,
      formatter: (cell, row) => ProgressBarFormatter(cell, row, row.tasksDoneCount, row.tasksCount),
    },
    {
      dataField: "startDate",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "COMMON.START.DATE",
      }),
      sort: true,
      sortCaret: sortCaret,
      formatter: DateTimeColumnFormatter,
      formatExtraData: { intl, showTime: false },
      sortValue: (cell) => (cell ? DateUtils.format(new Date(cell), intl, false) : "-"),
    },
    {
      dataField: "action",
      text: intl.formatMessage({
        id: "COMMON.ACTION",
      }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openShareProjectDialog: setOpenShareProjectDialog,
        openDeleteDialog: openDeleteProjectDialog,
        actionList: ["SHARE", "TRASH"],
        canDelete: (row) => {
          if (row.budgetsCount) {
            if (row.hiddenLineExist) {
              return (
                canDelete(userGroups, session, "PROJECT") &&
                canDelete(userGroups, session, "BUDGET") &&
                canSeeHiddenLine(userGroups, session)
              );
            }
            return (
              canDelete(userGroups, session, "PROJECT") && canDelete(userGroups, session, "BUDGET")
            );
          }
          return canDelete(userGroups, session, "PROJECT");
        },
        canShare: (row) => {
          return canShare(userGroups, session, "PROJECT");
        },
        entityType: "PROJECT",
      },
      headerClasses: "text-right",
      classes: "text-right",
      style: {
        minWidth: "100px",
      },
      hidden: projectsUIProps.readOnly,
    },
  ];
  const [entitiesFiltered, setEntitiesFiltered] = useState([]);
  useEffect(() => {
    setEntitiesFiltered(
      searchUtils(projectsUIProps?.filters?.freeText, entities, selectField(projectsUIProps), intl)
    );
  }, [entities, projectsUIProps?.filters?.freeText, projectsUIProps?.filters?.filterSelected]);
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: entitiesFiltered.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: projectsUIProps.filters.pageSize,
    page: projectsUIProps.filters.pageNumber,
  };
  return (
    <>
      <ProjectShareDialog
        show={!!openShareProjectDialog}
        id={openShareProjectDialog}
        onHide={() => {
          setOpenShareProjectDialog(false);
        }}
        loadProject={true}
      />
      <div data-cy="table-list">
        <div className={"d-flex justify-content-end mb-4"}>
          <div>
            <button
              type={"button"}
              className={cn(
                "btn font-weight-bold rounded-right-0",
                isViewList ? "btn-primary" : "btn-light-primary btn-hover-primary"
              )}
              onClick={() => {
                setIsViewList(true);
              }}
            >
              <span className={"svg-icon svg-icon-md"}>
                <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Bullet-list.svg")} />
              </span>
            </button>
          </div>
          <div>
            <button
              type={"button"}
              className={cn(
                "btn font-weight-bold rounded-left-0",
                isViewList ? "btn-light-primary btn-hover-primary" : "btn-primary"
              )}
              onClick={() => {
                setIsViewList(false);
              }}
            >
              <span className={"svg-icon svg-icon-md"}>
                <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-grid.svg")} />
              </span>
            </button>
          </div>
        </div>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <Pagination isLoading={listLoading} paginationProps={paginationProps}>
                {isViewList ? (
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center"
                    bootstrap4
                    bordered={false}
                    keyField="id"
                    data={entitiesFiltered || []}
                    columns={columns}
                    defaultSorted={uiHelpers.defaultSorted}
                    noDataIndication={() => <NoRecordsFoundMessage entities={entitiesFiltered} />}
                    // selectRow={getSelectRow({
                    //   entities,
                    //   ids: projectsUIProps.ids,
                    //   setIds: projectsUIProps.setIds,
                    // })}
                    hover
                    rowEvents={{
                      onClick: (e, row, rowIndex) => {
                        projectsUIProps.openProjectPage(row);
                      },
                    }}
                    rowStyle={{ cursor: "pointer" }}
                    {...paginationTableProps}
                  />
                ) : (
                  <ProjectsMacroView
                    projects={entitiesFiltered || []}
                    shareProjectDialog={[openShareProjectDialog, setOpenShareProjectDialog]}
                    openDeleteProjectDialog={openDeleteProjectDialog}
                  />
                )}
              </Pagination>
            );
          }}
        </PaginationProvider>
      </div>
    </>
  );
}
