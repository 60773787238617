import output from "./output.json";

console.log(
  `   _                 _            _        _  _
  | |__    ___    __| |    ___   | |_     | || |  __ __    ___
  | / /   / _ \\  / _\` |   / -_)  | ' \\     \\_, |  \\ V /   / -_)
  |_\\_\\   \\___/  \\__,_|   \\___|  |_||_|   _|__/   _\\_/_   \\___|
_|"""""|_|"""""|_|"""""|_|"""""|_|"""""|_| """"|_|"""""|_|"""""|
"\`-0-0-'"\`-0-0-'"\`-0-0-'"\`-0-0-'"\`-0-0-'"\`-0-0-'"\`-0-0-'"\`-0-0-'
`
);
console.log("https://www.kodehyve.com/");

if (process.env.REACT_APP_PRINT_LOG !== "true") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

export const outputConfig = output;
console.log("outputConfig:", outputConfig);

export const USER_POOL_CLIENT_ID = outputConfig.UserPoolClientId;
export const USER_POOL_ID = outputConfig.UserPoolId;
export const REGION = outputConfig.Region;
export const API_ENDPOINT = outputConfig.ApiEndpoint;
export const APPLICATION_URL = outputConfig.URL;
export const BRAND_URL = outputConfig.BrandURL;
export const WS_ENDPOINT = outputConfig.WSEndpoint;
export const SERVICES = outputConfig.Services;
