// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import * as uiHelpers from "./ProductFilesUIHelper";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import { useProductFilesUIContext } from "./ProductFilesUIContext";
import {
  ActionsColumnFormatter,
  UploadedDateTimeColumnFormatter,
} from "../../../../../_components/column-formatters";
import { DocumentTypeColumnFormatter } from "../../../../Common/DocumentTypeColumnFormatter";
import * as actions from "../../../_redux/products/productsActions";
import { useIntl } from "react-intl";
import { useEntityDeleteDialogContext } from "../../entity-delete-dialog/EntityDeleteDialogContext";
import { searchUtils, selectField } from "../../../../../_utils/searchUtils";
import { PROJECT_PRODUCT_FILE_TYPES } from "../../../../../_utils/listUtils";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { canEdit, isAdmin } from "../../../../../_utils/authUtils";
import { USER } from "../../../../../_utils/userTypes";
import { displayColumnIsConfidential } from "../../../../../_utils/columnUtils";
import { DateUtils } from "../../../../../_utils/DateUtils";

export function ProductFilesTable() {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { setDeleteEntityDialog } = useEntityDeleteDialogContext();
  const productFilesUIContext = useProductFilesUIContext();

  const productFilesUIProps = useMemo(() => {
    return {
      ids: productFilesUIContext.ids,
      setIds: productFilesUIContext.setIds,
      filters: productFilesUIContext.filters,
      setFilters: productFilesUIContext.setFilters,
      productId: productFilesUIContext.productId,
      openRequestSignatureDialog: productFilesUIContext.openRequestSignatureDialog,
      openFileDialog: productFilesUIContext.openFileDialog,
      openEditFileDialog: productFilesUIContext.openEditFileDialog,
      readOnly: productFilesUIContext.readOnly,
      context: productFilesUIContext.context,
      filterList: productFilesUIContext.filterList,
    };
  }, [productFilesUIContext]);

  const [entitiesFiltered, setEntitiesFiltered] = useState([]);

  const { totalCount, entities, userId, userGroups, session } = useAppSelector(
    (state) => ({
      totalCount: state.products?.productForEdit?.current?.files?.length,
      entities:
        productFilesUIProps.context === "lead"
          ? state.leads?.leadForEdit?.saved?.product?.files
          : state.products?.productForEdit?.current?.files,
      userId: state.auth.session.id,
      userGroups: state.auth.groups,
      session: state.auth.session,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (productFilesUIProps.context !== "lead" && !entities) {
      dispatch(actions.fetchProduct(productFilesUIProps.productId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFilesUIProps.filters, productFilesUIProps.productId]);
  useEffect(() => {
    setEntitiesFiltered(
      searchUtils(
        productFilesUIProps?.filters?.freeText,
        entities,
        selectField(productFilesUIProps),
        intl
      )
    );
  }, [
    entities,
    productFilesUIProps?.filters?.freeText,
    productFilesUIProps?.filters?.filterSelected,
  ]);
  const openDeleteFileDialog = (id) => {
    setDeleteEntityDialog({
      action: { fn: actions.deleteFile, props: { id, productId: productFilesUIProps.productId } },
      entityType: "PRODUCT.FILE",
    });
  };

  const columns = [
    {
      dataField: "id",
      text: "",
      sort: false,
      formatter: DocumentTypeColumnFormatter,
    },
    {
      dataField: "friendlyName",
      text: intl.formatMessage({
        id: "COMMON.NAME",
      }),
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
    },
    {
      dataField: "fileType",
      text: intl.formatMessage({
        id: "COMMON.TYPE",
      }),
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      formatter: (cell) => (
        <span>{intl.formatMessage({ id: PROJECT_PRODUCT_FILE_TYPES[cell] })}</span>
      ),
      sortValue: (cell) => intl.formatMessage({ id: PROJECT_PRODUCT_FILE_TYPES[cell ?? "-"] }),
    },
    {
      dataField: "uploadedAt",
      text: intl.formatMessage({
        id: "COMMON.UPLOADED.DATE",
      }),
      sort: true,
      sortCaret: sortCaret,
      formatter: UploadedDateTimeColumnFormatter,
      formatExtraData: {
        openUploadFileDialog: productFilesUIProps.openUploadFileDialog,
        readOnly: productFilesUIProps.readOnly,
        userId,
        intl,
      },
      sortValue: (cell) => (cell ? DateUtils.format(new Date(cell), intl) : "-"),
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
    },
    {
      dataField: "action",
      text: intl.formatMessage({
        id: "ACTION.TITLE",
      }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openRequestSignatureDialog: productFilesUIProps.openRequestSignatureDialog,
        openEditDialog: productFilesUIProps.openEditFileDialog,
        openDeleteDialog: openDeleteFileDialog,
        actionList: ["SIGNATURE", "WRITE", "TRASH"],
        canRequestSignature: () => {
          return isAdmin(userGroups, session) || session?.userTypes?.includes(USER);
        },
        canDelete: (row) => {
          return canEdit(userGroups, session, "PRODUCT");
        },
        canEdit: (row) => {
          return canEdit(userGroups, session, "PRODUCT");
        },
        entityType: "FILE",
      },
      classes: "text-right",
      headerClasses: "text-right",
      style: {
        minWidth: "100px",
      },
      hidden: productFilesUIProps.readOnly,
    },
  ];
  displayColumnIsConfidential({ entitiesFiltered, columns });

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: productFilesUIProps.filters.pageSize,
    page: productFilesUIProps.filters.pageNumber,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center"
                bordered={false}
                bootstrap4
                keyField="id"
                data={entitiesFiltered || []}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                hover
                rowEvents={{
                  onClick: (e, row, rowIndex) => {
                    dispatch(actions.openFile(row));
                  },
                }}
                rowStyle={{ cursor: "pointer" }}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
