import type { DateType, TimelineGroup, TimelineItem } from "vis-timeline/types";
import { DataSet } from "vis-data/esnext";
import moment from "moment";

import { ITask } from "data/schemas";

import { GanttChartGroupsAndItemsFunction, ITimelineItem, TIME_FORMAT, TimelineGroupNested } from "../definitions";

/**
 * Build the objects needed by the vis-timeline
 */
export const ganttChartGroupsAndItems: GanttChartGroupsAndItemsFunction = (tasks) => {
  const groupsObjs: TimelineGroup[] = [];
  const itemsObjs: TimelineItem[] = [];
  const ids2tasks = new Map<string, ITask>();

  const buildGroupAndItem = (task: ITask, parentGroup?: TimelineGroup): void => {
    const {
      id: taskId,
      name,
      status,
      subtasks = [],
      createdAt,
      updatedAt,
      doneDate,
      plannedStartDate,
      dueDate: plannedEndDate,
    } = task;

    ids2tasks.set(taskId!, task);

    const groupId = `group:${taskId}`;
    const group: TimelineGroupNested = {
      id: groupId,
      title: name,
      content: name,
      className: status,
      nestedGroups: undefined,
      treeLevel: parentGroup ? 2 : 1,
    };

    groupsObjs.push(group);

    if (parentGroup) {
      if (!parentGroup.nestedGroups) parentGroup.nestedGroups = [];
      parentGroup.nestedGroups.push(groupId);
    }

    let progress = 0;
    let itemBgEnd: DateType = "";

    if (status === "DONE" && doneDate) {
      progress = 100;
      itemBgEnd = doneDate;
    } else if (!updatedAt) {
      progress = 0;
      const hasPlannedEndDate = Boolean(plannedEndDate);
      const endDate = moment(hasPlannedEndDate ? plannedEndDate : createdAt).add(
        hasPlannedEndDate ? 2 : 5,
        "days"
      );
      itemBgEnd = endDate.format(TIME_FORMAT);
    } else if (Boolean(plannedEndDate)) {
      const startedDate = moment(createdAt);
      const plannedDate = moment(plannedEndDate);
      const updatedDate = moment(updatedAt);
      const endDate = (updatedDate.isAfter(plannedDate) ? updatedDate : plannedDate).add(2, "days");
      itemBgEnd = endDate.format(TIME_FORMAT);

      const diffTotalDays = endDate.diff(startedDate, "days");
      const diffDays = endDate.diff(updatedAt, "days");

      if (diffDays === diffTotalDays) {
        const diffTotalMinutes = endDate.diff(startedDate, "minutes");
        const diffMinutes = endDate.diff(updatedDate, "minutes");
        if (diffTotalMinutes === diffMinutes) {
          progress = 0;
        } else {
          progress = Math.round(((diffTotalMinutes - diffMinutes) / diffTotalMinutes) * 100);
        }
      } else {
        progress = Math.round(((diffTotalDays - diffDays) / diffTotalDays) * 100);
      }
    } else {
      const startedDate = moment(createdAt);
      const updatedDate = moment(updatedAt);
      const endDate = moment(updatedAt).add(5, "days");
      itemBgEnd = endDate.format(TIME_FORMAT);

      const diffTotalDays = endDate.diff(startedDate, "days");
      const diffDays = endDate.diff(updatedDate, "days");

      if (diffDays === diffTotalDays) {
        const diffTotalMinutes = endDate.diff(startedDate, "minutes");
        const diffMinutes = endDate.diff(updatedDate, "minutes");
        if (diffTotalMinutes === diffMinutes) {
          progress = 0;
        } else {
          progress = Math.round(((diffTotalMinutes - diffMinutes) / diffTotalMinutes) * 100);
        }
      } else {
        progress = Math.round(((diffTotalDays - diffDays) / diffTotalDays) * 100);
      }
    }

    const itemBgId = `task-bg:${taskId}`;
    const itemBg: ITimelineItem = {
      id: itemBgId,
      content: "&nbsp;",
      start: createdAt!,
      end: itemBgEnd,
      group: groupId,
      className: `${status} background`,
      progress,
    };

    itemsObjs.push(itemBg);

    const showTaskItem = Boolean(plannedStartDate && plannedEndDate);

    if (showTaskItem) {
      const taskItemId = `task:${taskId}`;

      const item: TimelineItem = {
        id: taskItemId,
        content: "&nbsp;",
        start: plannedStartDate!,
        end: plannedEndDate,
        group: groupId,
        className: status,
      };

      itemsObjs.push(item);
    }

    if (subtasks.length > 0) {
      subtasks.forEach((subtask) => buildGroupAndItem(subtask, group));
    }
  };

  tasks.forEach((task) => buildGroupAndItem(task));

  return {
    groups: new DataSet<TimelineGroup>(groupsObjs),
    items: new DataSet<TimelineItem>(itemsObjs),
    ids2tasks,
  };
};

export const extractTaskId = (ganttChartItemId: string) => {
  const parts = ganttChartItemId.split(":");
  if (parts.length < 2) return;
  return parts[1];
};

export default ganttChartGroupsAndItems;
