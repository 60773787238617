import {
  processSackbarNotification,
  SNACKBAR_MESSAGE,
} from "../modules/Common/SnackbarNotificationsHandler";
import { createSignatureRequest } from "../modules/UsersManagement/_redux/usersCrud";

export const requestFileSignature = (
  actions: any,
  signatureRequest: any,
  dispatch: Function,
  callTypes: any
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return createSignatureRequest(signatureRequest)
    .then((response) => {
      const signature = { ...response };
      processSackbarNotification(SNACKBAR_MESSAGE.SUCCESS.REQUEST_FILE_SIGNATURE, dispatch);
      dispatch(actions.signatureRequestCreated({ signature }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.REQUEST_FILE_SIGNATURE, dispatch);
    });
};
