import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as requestFromServer from "./chatCrud";
import {
  processSackbarNotification,
  SNACKBAR_MESSAGE,
} from "../../Common/SnackbarNotificationsHandler";
import { Chat, ChatMessage, ChatUser } from "./models";

export const ChatActions = {
  FETCH_CHATS: "chats/fetchChats",
  REFRESH_CHATS: "chats/refreshChats",
  FETCH_CHAT_BY_ID: "chats/fetchChatById",
  CREATE: "chats/create",
  UPDATE: "chats/update",
  SEND_MESSAGE: "chats/sendMessage",
  SAVE_DRAFT: "chats/saveDraft",
  READ_MESSAGES: "chats/readMessages",
  SET_ACTIVE_CHAT: "chats/setActiveChat",
  ADD_NEW_CHAT: "chats/addNewChat",
  ADD_NEW_CHAT_MESSAGE: "chats/addNewChatMessage",
};

export const fetchChats = createAsyncThunk(
  ChatActions.FETCH_CHATS,
  async (params, { dispatch, rejectWithValue }) => {
    try {
      return await requestFromServer.fetchChats();
    } catch (err) {
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_LEAD, dispatch);
      return rejectWithValue(err);
    }
  }
);

export const refreshChats = createAsyncThunk(ChatActions.REFRESH_CHATS, async () => {
  return await requestFromServer.fetchChats();
});

export const fetchChatById = createAsyncThunk(
  ChatActions.FETCH_CHAT_BY_ID,
  async (chatId: string, thunkAPI) => {
    return (await requestFromServer.fetchChatById(chatId)) as Chat;
  }
);

export const createChat = createAsyncThunk(ChatActions.CREATE, async (chat: any, thunkApi) => {
  return await requestFromServer.createChat(chat);
});

export const updateChat = createAsyncThunk(ChatActions.UPDATE, async (chat: Chat, thunkAPI) => {
  return await requestFromServer.updateChat(chat);
});

export const sendMessage = createAsyncThunk(
  ChatActions.SEND_MESSAGE,
  async (message: { message: string; chatId: string }, thunkApi) => {
    return await requestFromServer.sendMessage(message);
  }
);

export const saveDraftMessage = createAsyncThunk(
  ChatActions.SAVE_DRAFT,
  async (chatUser: ChatUser, thunkApi) => {
    return await requestFromServer.saveDraftMessage(chatUser);
  }
);

export const readMessages = createAsyncThunk(
  ChatActions.READ_MESSAGES,
  async ({ chatId, messageIds }: { chatId: string; messageIds: string[] }, thunkApi) => {
    return await requestFromServer.readMessages(chatId, messageIds);
  }
);

export const setActiveChat = createAction(
  ChatActions.SET_ACTIVE_CHAT,
  (chatId: string | undefined) => ({
    payload: { chatId },
  })
);

export const addNewChat = createAction<{ chat: Chat }>(ChatActions.ADD_NEW_CHAT);

export const addNewChatMessage = createAction<{ chat: Chat; message: ChatMessage }>(
  ChatActions.ADD_NEW_CHAT_MESSAGE
);
