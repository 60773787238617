import { createContext, FC, ReactNode, useState } from "react";
import PropTypes from "prop-types";
import { API } from "aws-amplify";

export interface PublicRequestsContextValue {
  errorMessage: string | undefined;
  isLoading: boolean;
  formData: any;
  setFormData: (data: any) => void;
  user: any;
  settings: any;
  action: any;
  file: any;
  getPublicRequestsRelatedFile: (
    requestId: string | undefined,
    fileId: string | undefined
  ) => Promise<void>;
  updateGDPR: (requestId: string | undefined) => Promise<void>;
  getPublicRequestsKycAml: (requestId: string | undefined) => Promise<void>;
  postPublicRequestsKycAml: (
    requestId: string | undefined,
    formDataUploadBody: any
  ) => Promise<void>;
}

interface PublicRequestsProviderProps {
  children: ReactNode;
}

export const PublicRequestsContext = createContext<PublicRequestsContextValue>({
  errorMessage: undefined,
  isLoading: false,
  settings: undefined,
  formData: {},
  file: undefined,
  setFormData: Function,
  user: undefined,
  action: undefined,
  updateGDPR: (requestId) => Promise.resolve(),
  getPublicRequestsRelatedFile: (requestId, fileId) => Promise.resolve(),
  getPublicRequestsKycAml: (requestId) => Promise.resolve(),
  postPublicRequestsKycAml: (requestId) => Promise.resolve(),
});

export const PublicRequestsProvider: FC<PublicRequestsProviderProps> = (props) => {
  const { children } = props;
  const [settings, setSettings] = useState<undefined | Object>();
  const [user, setUser] = useState<undefined | Object>();
  const [action, setAction] = useState<undefined | Object>();
  const [formData, setFormDataDefault] = useState<undefined | Object>(undefined);
  const [file, setFile] = useState<undefined | Object>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const errorMessageByStatusCode = (statusCode: number) => {
    switch (statusCode) {
      case 401:
        return "UNAUTH_REQUEST.ERROR.EXPIRED_ACCESS";
      case 403:
        return "UNAUTH_REQUEST.ERROR.NOT_PERMISSION";
      case 404:
        return "UNAUTH_REQUEST.ERROR.RANDOM";
      default:
        return "UNAUTH_REQUEST.ERROR.RANDOM";
    }
  };

  const getPublicRequestsKycAml = async (requestId: string | undefined) => {
    setIsLoading(true);
    API.get("API", `/requests/${requestId}/kyc-aml`, {})
      .then((response) => {
        setUser(response?.user);
        setAction(response?.action);
        setSettings(response?.settings);
      })
      .catch((error) => {
        setErrorMessage(errorMessageByStatusCode(error.response.status));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const postPublicRequestsKycAml = async (
    requestId: string | undefined,
    formDataUploadBody: any
  ) => {
    setIsLoading(true);
    API.post("API", `/requests/${requestId}/kyc-aml`, { body: formDataUploadBody })
      .then((response) => {
        setAction(response?.action);
        setFile(response?.file);
      })
      .catch((error) => {
        setErrorMessage(errorMessageByStatusCode(error.response.status));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getPublicRequestsRelatedFile = async (
    requestId: string | undefined,
    fileId: string | undefined
  ) => {
    setIsLoading(true);
    API.get("API", `/requests/${requestId}/files/${fileId}`, {})
      .then((response) => {
        setFile(response);
      })
      .catch((error) => {
        setErrorMessage(errorMessageByStatusCode(error.response.status));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateGDPR = async (requestId: string | undefined) => {
    setIsLoading(true);
    API.post("API", `/requests/${requestId}/gdpr`, {})
      .then((response) => {
        setUser(response?.user);
      })
      .catch((error) => {
        setErrorMessage(errorMessageByStatusCode(error.response.status));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setFormData = (data: Object) => {
    setFormDataDefault(data);
  };

  return (
    <PublicRequestsContext.Provider
      value={{
        errorMessage,
        isLoading,
        file,
        settings,
        formData,
        setFormData,
        user,
        action,
        updateGDPR,
        getPublicRequestsRelatedFile,
        getPublicRequestsKycAml,
        postPublicRequestsKycAml,
      }}
    >
      {children}
    </PublicRequestsContext.Provider>
  );
};

PublicRequestsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const PublicRequestsConsumer = PublicRequestsContext.Consumer;
