import { IImmotoolEntity } from "./ImmotoolEntity";
import { ICompany } from "./Company";
import { IAddress } from "./Address";

export enum UserType {
  CLIENT = "CLIENT",
  USER = "USER",
  SUBCONTRACTOR = "SUBCONTRACTOR",
}

export type TUserTypes = `${UserType}`;

export enum UserRoleType {
  ADMIN = "ADMIN",
  COMPLIANCE = "COMPLIANCE",
  CONTENT_MANAGER = "CONTENT_MANAGER",
  CONTRIBUTOR = "CONTRIBUTOR",
  FINANCIAL_CONTROLLER = "FINANCIAL_CONTROLLER",
  MASTER = "MASTER",
  PROJECT_MANAGER = "PROJECT_MANAGER",
  PROJECT_RESELLER = "PROJECT_RESELLER",
  SALES_MANAGER = "SALES_MANAGER",
  VIEWER = "VIEWER",
  NONE = "NONE",
  CUSTOM = "CUSTOM",
}

export type TUserRoleTypes = `${UserRoleType}`;

export enum UserEntityType {
  ALL = "*",

  PROJECT = "PROJECT",
  BUDGET = "BUDGET",
  PRODUCT = "PRODUCT",
  USER = "USER",
  CLIENT = "CLIENT",
  SUBCONTRACTOR = "SUBCONTRACTOR",
  LEAD = "LEAD",
  TASK = "TASK",
  CHAT = "CHAT",
  USER_ACTION = "USER_ACTION",
  USER_RIGHTS = "USER_RIGHTS",
  PROPCO = "PROPCO",
}

export type TUserEntityTypes = `${UserEntityType}`;

export enum UserRightType {
  ALL = "*",

  CREATE = "CREATE",
  READ = "READ",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  AUDIT_TRAIL = "AUDIT_TRAIL",
  SHARE = "SHARE",
  ANALYSIS = "ANALYSIS",
  HIDDEN_LINE = "HIDDEN_LINE",
  NOTE = "NOTE",
  UPLOAD_PHOTO = "UPLOAD_PHOTO",
  UPDATE_PHOTO = "UPDATE_PHOTO",
  DELETE_PHOTO = "DELETE_PHOTO",
  CONFIDENTIAL_DOCUMENT = "CONFIDENTIAL_DOCUMENT",
}

export type TUserRightTypes = `${UserRightType}`;

export interface IUserRight {
  entityType: TUserEntityTypes;
  rightType: TUserRightTypes;
}

// represents the User entity properties sent to the frontend
// cdk/immootool-layers/src/commonLayer/nodejs/entities/users.ts
export interface IUser extends IImmotoolEntity {
  lastSeenAt: Date;
  legalEntityType: string;
  title: string;
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  profilePictureSetup: boolean;
  profilePictureUrl: string;
  profilePictureUrlExpires: Date;
  profilePictureKey: string;
  isDisabled: boolean;
  address: IAddress;
  notes: string;
  userTypes: UserType[];
  language: string;
  gdprApprovedAt: Date;
  referrer: string;
  company: ICompany;
  rights: IUserRight[];
}
