/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Redirect, Switch, useLocation } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import ChangePassword from "./ChangePassword";
import { shallowEqual, useSelector } from "react-redux";
import { BRAND_URL } from "../../../../configuration";
import AcceptGDPR from "./AcceptGDPR";
import ForgotPassword from "./ForgotPassword";
import { LanguageSelectorDropdown } from "../../../../_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown";

export function AuthPage() {
  const { challengeName, session, isAuthorized } = useSelector(({ auth }) => {
    return {
      challengeName: auth.challengeName,
      session: auth.session,
      isAuthorized: auth.tokens?.accessToken != null,
    };
  }, shallowEqual);
  const location = useLocation();
  const redirectQueryParam =
    new URLSearchParams(location?.search).get("redirect_uri") ||
    (location.pathname.includes("/auth") || location.pathname.includes("/logout")
      ? ""
      : encodeURIComponent(location.pathname + location.search));

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-30 p-lg-10"
            style={{
              backgroundImage: `url(${BRAND_URL}/bg_left_desktop_login.png)`,
            }}
          />
          {/*end::Aside*/}

          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            {/*begin::Languages*/}
            <div className="d-flex  justify-content-end">
              <LanguageSelectorDropdown />
            </div>
            {/*end::Languages*/}
            {/* begin::Content body */}

            <div className="d-flex flex-column flex-column-fluid flex-center mb-10 mb-sm-20">
              <img
                src={`${BRAND_URL}/logo_dark.png`}
                alt="Logo"
                className="max-w-180px max-w-lg-200px max-h-120px mb-10 mb-sm-20"
              />
              <Switch>
                {challengeName === "NEW_PASSWORD_REQUIRED" ? (
                  <Redirect from="/auth/login" to="/auth/change-password" />
                ) : (
                  !isAuthorized && <Redirect from="/auth/change-password" to="/auth/login" />
                )}
                {session && isAuthorized && !session.gdprApprovedAt && (
                  <Redirect from="/auth/change-password" to="/auth/gdpr" />
                )}
                {session && isAuthorized && !session.gdprApprovedAt && (
                  <Redirect from="/auth/login" to="/auth/gdpr" />
                )}
                {!isAuthorized && <Redirect from="/auth/gdpr" to="/auth/login" />}
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute path="/auth/change-password" component={ChangePassword} />
                <ContentRoute path="/auth/gdpr" component={AcceptGDPR} />
                <ContentRoute path="/auth/forgot-password" component={ForgotPassword} />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect
                  to={`/auth/login${
                    redirectQueryParam ? `?redirect_uri=${redirectQueryParam}` : ""
                  }`}
                />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex flex-column-auto align-items-center mt-5">
              <div className="text-dark-75 d-flex">
                <span className="text-muted font-weight-bold mr-2">
                  {new Date().getFullYear()} &copy;
                </span>
                {` `}
                <a
                  href="https://www.kodehyve.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-dark-75 text-hover-primary pr-3 font-weight-bold"
                >
                  kodehyve
                </a>
              </div>
              <a
                href="https://y.at/%F0%9F%8E%AE%F0%9F%8C%95%F0%9F%90%9D"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link pl-3 pr-0"
              >
                🎮🌕🐝
              </a>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
