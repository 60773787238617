import { toAbsoluteUrl } from "../../_metronic/_helpers";

export const AVAILABLE_LANGUAGES = [
  {
    lang: "en-GB",
    name: "English",
    flag: toAbsoluteUrl("/media/svg/flags/260-united-kingdom.svg"),
    shortCode: "EN",
  },
  {
    lang: "fr-FR",
    name: "Français",
    flag: toAbsoluteUrl("/media/svg/flags/195-france.svg"),
    shortCode: "FR",
  },
  {
    lang: "de-DE",
    name: "Deutsch",
    flag: toAbsoluteUrl("/media/svg/flags/017-germany.svg"),
    shortCode: "DE",
  },
];
