import { useLayoutEffect, useState } from "react";

/**
 * This hook is used for listening to the window size change
 * It returns an array width the window width and height
 */
export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    let resizeTimeout: any = null;

    function updateSize() {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        setSize([window.innerWidth, window.innerHeight]);
      }, 30);
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};
