import { shallowEqual, useSelector } from "react-redux";
import { useUserFilesUIContext } from "../UserFilesUIContext";
import { requestUserFileSignature } from "../../../../_redux/usersActions";
import { RequestSignatureDialog } from "../../../../../../_components/RequestSignatureDialog";

export function UserRequestSignature() {
  const { userForEdit } = useSelector(
    (state: any) => ({
      userForEdit: state.users.entityForEdit?.saved,
    }),
    shallowEqual
  );

  return (
    <RequestSignatureDialog
      useFilesUIContext={useUserFilesUIContext}
      userList={[userForEdit]}
      fileList={userForEdit?.files}
      requestFileSignature={requestUserFileSignature}
    />
  );
}
