/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import cn from "clsx";

export interface DropdownCustomTogglerProps {
  customClass?: string;
  onClick: any,
  children: any
}
export const DropdownCustomToggler = React.forwardRef<HTMLAnchorElement, DropdownCustomTogglerProps>((props, ref) => {
  return (
    <a
      ref={ref}
      className={cn("btn btn-clean btn-hover-light-primary btn-sm btn-icon", props.customClass)}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        props.onClick(e);
      }}
    >
      {props.children}
    </a>
  );
});
