import { TASK_STATES } from "./tasks";
import ReactHtmlParser from "react-html-parser";

// const formatUrl = (link) => {
//   const linkWithoutHttp = link?.split("//")?.[1];
//   if (!linkWithoutHttp) {
//     return "";
//   }
//   const linkSplit = linkWithoutHttp.split("/");
//   linkSplit.shift();
//   return linkSplit.join("/");
// };

export const formatNotification = (notification, intl) => {
  const { notificationType, parameters } = notification;

  const labelValue = formatLabel("IN_APP_NOTIFICATION." + notificationType, parameters, intl);
  const notificationFormatDefinitions = {
    // TESTING
    TEST: "TEST",

    // TASKS
    TASK_ASSIGNED_TO_USER: {
      tabKey: "tasks",
      label: labelValue,
      colorClass: "text-info",
      icon: "fa fa-info-circle",
      url: `/tasks/${parameters.taskId}/edit`,
    },
    TASK_UNASSIGNED_TO_USER: {
      tabKey: "tasks",
      label: labelValue,
      colorClass: "text-info",
      icon: "fa fa-info-circle",
      url: `/tasks/${parameters.taskId}/edit`,
    },
    TASK_DELETE: {
      tabKey: "tasks",
      label: labelValue,
      colorClass: "text-warning",
      icon: "fa fa-info-circle",
      url: undefined,
    },
    TASK_DEADLINE_REMINDER: {
      tabKey: "tasks",
      label: labelValue,
      colorClass: "text-warning",
      icon: "fas fa-exclamation-triangle",
      url: `/tasks/${parameters.taskId}/edit`,
    },
    TASK_DONE_SIMPLE: {
      tabKey: "tasks",
      label: labelValue,
      colorClass: "text-success",
      icon: "flaticon2-check-mark",
      url: `/tasks/${parameters.taskId}/edit`,
    },
    TASK_STATUS_CHANGED_WITH_USER_EXEC: {
      tabKey: "tasks",
      label: labelValue,
      colorClass: "text-info",
      icon: "fa fa-info-circle",
      url: `/tasks/${parameters.taskId}/edit`,
    },

    // AUTOGENERATED STUFF / ACTIONS
    FILLOUT_AML_KYC_FORM: {
      tabKey: "requests",
      label: labelValue,
      colorClass: "text-info",
      icon: "fa fa-info-circle",
      url: parameters.url,
    },
    FILLOUT_PERSONAL_INFORMATION: {
      tabKey: "requests",
      label: labelValue,
      colorClass: "text-info",
      icon: "fa fa-info-circle",
      url: parameters.url,
    },
    FILE_UPLOAD_REQUEST: {
      tabKey: "requests",
      label: labelValue,
      colorClass: "text-info",
      icon: "fa fa-info-circle",
      url: `/profile/me?t=files&h=${parameters?.attachments?.[0]?.id}`,
    },
    SIGN_DOCUMENT: {
      tabKey: "requests",
      label: labelValue,
      colorClass: "text-info",
      icon: "flaticon2-writing",
      url: parameters.url,
    },
    PROJECT_SHARED: {
      tabKey: "notifications",
      label: labelValue,
      colorClass: "text-info",
      icon: "fas fa-layer-group",
      url: parameters.url,
    },

    //DONE
    FILLOUT_AML_KYC_FORM_DONE: {
      tabKey: "requests",
      label: labelValue,
      colorClass: "text-success",
      icon: "flaticon2-check-mark",
      url: `/clients/${parameters.userTargetId}?t=files&h=${parameters?.attachments?.[0]?.id}`,
    },
    FILE_UPLOAD_REQUEST_DONE: {
      tabKey: "requests",
      label: labelValue,
      colorClass: "text-success",
      icon: "flaticon2-check-mark",
      url: `/${
        parameters.attachments?.[0]?.userType
          ? `${parameters.attachments[0].userType.toLowerCase()}s`
          : "clients"
      }/${parameters.userTargetId}?t=files&h=${parameters?.attachments?.[0]?.id}`,
    },
    FILLOUT_PERSONAL_INFORMATION_DONE: {
      tabKey: "requests",
      label: labelValue,
      colorClass: "text-success",
      icon: "flaticon2-check-mark",
      url: `/clients/${parameters.userTargetId}`,
    },
    SIGN_DOCUMENT_DONE: {
      tabKey: "requests",
      label: labelValue,
      colorClass: "text-success",
      icon: "flaticon2-check-mark",
      url: `/${parameters.userType ? `${parameters.userType.toLowerCase()}s` : "clients"}/${
        parameters.userTargetId
      }?t=files&h=${parameters?.attachments?.[0]?.id}`,
    },
  };
  return { ...notification, ...notificationFormatDefinitions[notificationType] };
};

export function formatLabel(messageTemplateId, params, intl) {
  const preprocessedParams = { ...params };
  Object.keys(preprocessedParams).forEach((param) => {
    switch (param) {
      case "taskName":
        preprocessedParams.taskName = preprocessedParams?.taskName?.includes("_")
          ? intl.formatMessage({ id: preprocessedParams?.taskName })
          : preprocessedParams?.taskName;
        break;
      case "taskStatusOld":
        preprocessedParams.taskStatusOld = intl.formatMessage({
          id: TASK_STATES[preprocessedParams.taskStatusOld],
        });
        break;
      case "taskStatusNew":
        preprocessedParams.taskStatusNew = intl.formatMessage({
          id: TASK_STATES[preprocessedParams.taskStatusNew],
        });
        break;
      case "attachments":
        preprocessedParams.friendlyName = preprocessedParams?.attachments[0]?.name;
        break;
      default:
        break;
    }
  });
  return ReactHtmlParser(intl.formatMessage({ id: messageTemplateId }, preprocessedParams));
}
