import { API } from "aws-amplify";
import { getTimeAgoStringForIntl } from "app/_utils/tasks";
import { formatNotification } from "../../../_utils/notificationUtils";

export async function getLatest(intl) {
  const notifications = await API.get("API", `/notifications`, {});

  const formattedNotifications = [];
  for (const notification of notifications) {
    const { notificationType, parameters } = notification;
    const templateString = intl
      .formatMessage({ id: "IN_APP_NOTIFICATION." + notificationType })
      .includes("timeRemaining");
    if (intl && templateString) {
      parameters.timeRemaining = getTimeAgoStringForIntl(
        undefined,
        parameters?.amount,
        parameters?.timeUnit,
        intl
      );
    }

    formattedNotifications.push(formatNotification(notification, intl));
  }
  return formattedNotifications;
}

export async function updateNotification(notification) {
  return await API.post("API", `/notifications/${notification.id}`, {});
}
