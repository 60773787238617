import { FC, useEffect, useState } from "react";
import { Auth } from "@aws-amplify/auth";
import { WS_ENDPOINT } from "../../../configuration";
import * as actions from "../../modules/Notifications/_redux/inAppNotificationsActions";
import { useIntl } from "react-intl";
import { addNewChat, addNewChatMessage } from "../Chat/_redux/chatActions";
import { useAppDispatch } from "../../../redux/hooks";
import { fetchAction } from "../PropertiesManagement/_redux/forms/formsActions";

const WebSocketProvider: FC = ({ children }) => {
  const intl = useIntl();
  const [connectionOpen, setConnectionOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(actions.fetchLatestNotifications(intl, userId));
  }, []);

  useEffect(() => {
    if (!connectionOpen) {
      Auth.currentUserInfo()
        .then((userInfo) => {
          if (userInfo?.attributes?.sub) {
            console.log("sub:", userInfo.attributes?.sub);
            const websocket = new WebSocket(`${WS_ENDPOINT}?user=${userInfo.attributes.sub}`);
            setUserId(userInfo.attributes?.sub);
            websocket.onopen = (ev) => {
              console.log("websocket.onopen", new Date().toISOString());
              setConnectionOpen(true);
            };
            websocket.onclose = (ev) => {
              console.log("websocket.onclose", new Date().toISOString());
              setConnectionOpen(false);
            };
            websocket.onmessage = (ev) => {
              console.log("websocket.onmessage");
              console.log(ev);
              const data = JSON.parse(ev.data);
              console.log(data);
              switch (data.type) {
                case "REFRESH_IN_APP_NOTIFICATION":
                  dispatch(actions.fetchLatestNotifications(intl, userId));
                  break;
                case "ADD_NEW_CHAT":
                  dispatch(addNewChat(data.parameters));
                  break;
                case "ADD_NEW_CHAT_MESSAGE":
                  dispatch(addNewChatMessage(data.parameters));
                  break;
                case "DOCUMENT_GENERATED":
                  dispatch(fetchAction(data.parameters.file.userId, data.parameters.action.id));
                  break;
                default:
                  break;
              }
            };
            websocket.onerror = (ev) => {
              console.log("websocket.onerror");
              console.log(ev);
            };
          }
        })
        .catch();
    }
  }, [connectionOpen]);

  return <>{children}</>;
};

export default WebSocketProvider;
