import React from "react";
import { ProjectFilesFilter } from "./ProjectFilesFilter";
import { ProjectFilesTable } from "./ProjectFilesTable";
import { ProjectFileEditDialog } from "./project-file-edit-dialog/ProjectFileEditDialog";
import { ProjectRequestSignature } from "./project-file-edit-dialog/ProjectRequestSignature";

export function ProjectFiles() {
  return (
    <>
      <ProjectFileEditDialog />
      <ProjectRequestSignature />
      <div className="form margin-b-30">
        <ProjectFilesFilter />
      </div>
      <ProjectFilesTable />
    </>
  );
}
