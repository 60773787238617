import React, { useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { ProjectsTable } from "./projects-table/ProjectsTable";
import { useProjectsUIContext } from "./ProjectsUIContext";
import { canCreate } from "../../../../_utils/authUtils";
import { shallowEqual, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useSubheader } from "../../../../../_metronic/layout";
import { Filter } from "../../../../_components/Filter";

export const ProjectsCard = () => {
  const intl = useIntl();
  const { userGroups, session } = useSelector(
    (state) => ({
      userGroups: state.auth.groups,
      session: state.auth.session,
    }),
    shallowEqual
  );

  const projectsUIContext = useProjectsUIContext();
  const projectsUIProps = useMemo(() => {
    return {
      ids: projectsUIContext.ids,
      queryParams: projectsUIContext.queryParams,
      setQueryParams: projectsUIContext.setQueryParams,
      newProjectButtonClick: projectsUIContext.newProjectButtonClick,
    };
  }, [projectsUIContext]);

  const subheader = useSubheader();
  useEffect(() => {
    subheader.setTitle(
      intl.formatMessage({
        id: "PROJECT.TITLE",
      })
    );
  }, [subheader]);

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({
          id: "PROJECT.TITLE.LIST",
        })}
      >
        <CardHeaderToolbar>
          {canCreate(userGroups, session, "PROJECT") && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={projectsUIProps.newProjectButtonClick}
              data-cy="button-projects-new"
            >
              <FormattedMessage id="PROJECT.ACTION.NEW" />
            </button>
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Filter useEntityUIContext={useProjectsUIContext} />
        <ProjectsTable />
      </CardBody>
    </Card>
  );
};
