// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import * as actions from "../../../_redux/projects/projectsActions";
import * as uiHelpers from "./ProjectFilesUIHelper";
import { Pagination } from "../../../../../../_metronic/_partials/controls";

import { DocumentTypeColumnFormatter } from "../../../../Common/DocumentTypeColumnFormatter";
import {
  ActionsColumnFormatter,
  UploadedDateTimeColumnFormatter,
} from "../../../../../_components/column-formatters";

import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import { useProjectFilesUIContext } from "./ProjectFilesUIContext";
import { useIntl } from "react-intl";
import { useEntityDeleteDialogContext } from "../../entity-delete-dialog/EntityDeleteDialogContext";
import { searchUtils, selectField } from "../../../../../_utils/searchUtils";
import { PROJECT_PRODUCT_FILE_TYPES } from "../../../../../_utils/listUtils";
import { canEdit, isAdmin } from "../../../../../_utils/authUtils";
import { USER } from "../../../../../_utils/userTypes";
import { displayColumnIsConfidential } from "../../../../../_utils/columnUtils";
import { DateUtils } from "../../../../../_utils/DateUtils";

export function ProjectFilesTable() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const filesUIContext = useProjectFilesUIContext();
  const { setDeleteEntityDialog } = useEntityDeleteDialogContext();

  const filesUIProps = useMemo(() => {
    return {
      ids: filesUIContext.ids,
      setIds: filesUIContext.setIds,
      filters: filesUIContext.filters,
      setFilters: filesUIContext.setFilters,
      projectId: filesUIContext.projectId,
      openRequestSignatureDialog: filesUIContext.openRequestSignatureDialog,
      openEditFileDialog: filesUIContext.openEditFileDialog,
      openUploadFileDialog: filesUIContext.openUploadFileDialog,
      readOnly: filesUIContext.readOnly,
      context: filesUIContext.context,
      filterList: filesUIContext.filterList,
    };
  }, [filesUIContext]);

  const [entitiesFiltered, setEntitiesFiltered] = useState([]);

  const { totalCount, entities, userId, userGroups, session } = useSelector(
    (state) => ({
      totalCount: state.projects?.projectForEdit?.current?.files?.length,
      entities:
        filesUIProps.context === "lead"
          ? state?.leads?.leadForEdit?.saved?.project?.files
          : state.projects?.projectForEdit?.current?.files,
      userId: state.auth.session.id,
      userGroups: state.auth.groups,
      session: state.auth.session,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (filesUIProps.context !== "lead" && !entities) {
      dispatch(actions.fetchProject(filesUIContext.projectId));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesUIProps.projectId, filesUIProps.context]);
  useEffect(() => {
    setEntitiesFiltered(
      searchUtils(filesUIProps?.filters?.freeText, entities, selectField(filesUIProps), intl)
    );
  }, [entities, filesUIProps?.filters?.freeText, filesUIProps?.filters?.filterSelected]);
  const openDeleteFileDialog = (id) => {
    setDeleteEntityDialog({
      action: { fn: actions.deleteFile, props: { id, projectId: filesUIProps.projectId } },
      entityType: "PROJECT.FILE",
    });
  };

  const columns = [
    {
      dataField: "id",
      text: "",
      sort: false,
      formatter: DocumentTypeColumnFormatter,
    },
    {
      dataField: "friendlyName",
      text: intl.formatMessage({
        id: "COMMON.NAME",
      }),
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
    },
    {
      dataField: "fileType",
      text: intl.formatMessage({
        id: "COMMON.TYPE",
      }),
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      formatter: (cell) => (
        <span>{intl.formatMessage({ id: PROJECT_PRODUCT_FILE_TYPES[cell] })}</span>
      ),
      sortValue: (cell) => intl.formatMessage({ id: PROJECT_PRODUCT_FILE_TYPES[cell ?? "-"] }),
    },
    {
      dataField: "uploadedAt",
      text: intl.formatMessage({
        id: "COMMON.UPLOADED.DATE",
      }),
      sort: true,
      sortCaret: sortCaret,
      formatter: UploadedDateTimeColumnFormatter,
      formatExtraData: {
        openUploadFileDialog: filesUIProps.openUploadFileDialog,
        readOnly: filesUIProps.readOnly,
        userId,
        intl,
      },
      sortValue: (cell) => (cell ? DateUtils.format(new Date(cell), intl) : "-"),
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
    },
    {
      dataField: "action",
      text: intl.formatMessage({
        id: "ACTION.TITLE",
      }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditDialog: filesUIProps.openEditFileDialog,
        openRequestSignatureDialog: filesUIProps.openRequestSignatureDialog,
        openDeleteDialog: openDeleteFileDialog,
        actionList: ["SIGNATURE", "WRITE", "TRASH"],
        canRequestSignature: () => {
          return isAdmin(userGroups, session) || session?.userTypes?.includes(USER);
        },
        canDelete: (row) => {
          return canEdit(userGroups, session, "PROJECT");
        },
        canEdit: (row) => {
          return canEdit(userGroups, session, "PROJECT");
        },
        entityType: "FILE",
      },
      classes: "text-right",
      headerClasses: "text-right",
      style: {
        minWidth: "100px",
      },
      hidden: filesUIProps.readOnly,
    },
  ];
  displayColumnIsConfidential({ entitiesFiltered, columns });

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: filesUIProps.filters.pageSize,
    page: filesUIProps.filters.pageNumber,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center"
                bordered={false}
                bootstrap4
                keyField="id"
                data={entitiesFiltered}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                hover
                rowEvents={{
                  onClick: (e, row, rowIndex) => {
                    dispatch(actions.openFile(row));
                  },
                }}
                rowStyle={{ cursor: "pointer" }}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
