import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { formatDisplayNameIntl } from "../_utils/userUtils";
import { Switch } from "@material-ui/core";
import React, { FC, useMemo, useRef, useState } from "react";
import { UserDropdownItem } from "./UserDropdownItem";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

type Props = {
  useFilesUIContext: any;
  userList: any[];
  fileList: any[];
  requestFileSignature: Function;
};
export const RequestSignatureDialog: FC<Props> = ({
  useFilesUIContext,
  userList,
  fileList,
  requestFileSignature,
}) => {
  const uiContext = useFilesUIContext();
  const dispatch = useDispatch();
  const uiProps = useMemo(() => {
    return {
      id: uiContext.selectedId,
      showRequestSignatureDialog: uiContext.showRequestSignatureDialog,
      closeRequestSignatureDialog: uiContext.closeRequestSignatureDialog,
    };
  }, [uiContext]);

  const [sendSms, setSendSms] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(undefined);

  const intl = useIntl();
  const typeaheadRef = useRef<any>(null);
  const { actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: (state as any).users.actionsLoading,
    }),
    shallowEqual
  );

  const submit = () => {
    const file = fileList.find((f) => f.id === uiProps.id);
    const notifications = sendSms ? ["SMS"] : [];
    const body = {
      file,
      notifications,
      fileId: uiProps.id,
      assigneeId: selectedUser?.id,
    };
    dispatch(requestFileSignature(body)).then(() => uiProps.closeRequestSignatureDialog());
  };

  return (
    <Modal
      show={uiProps.showRequestSignatureDialog}
      onHide={uiProps.closeRequestSignatureDialog}
      aria-labelledby="request-signature-modal"
      centered
      backdrop="static"
    >
      {actionsLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="request-signature-modal">
          {intl.formatMessage({
            id: "FILE.ACTIONS.SIGNATURE.REQUEST",
          })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-6">
        <div data-cy="typeahead-request-signature-target-user">
          <Typeahead
            id="request-signature-typeahead-input"
            positionFixed
            paginate={false}
            ref={typeaheadRef}
            labelKey="displayName"
            filterBy={["displayName", "email"]}
            onChange={(selected) => {
              if (selected.length > 0) {
                setSelectedUser(selected[0]);
              } else {
                setSelectedUser(undefined);
              }
            }}
            options={userList?.map((u) => {
              const user = { ...u };
              user.displayName = formatDisplayNameIntl(intl, user, false);
              return user;
            })}
            placeholder={intl.formatMessage({ id: "COMMON.SELECT_USER" })}
            disabled={actionsLoading}
            renderMenu={(users, menuProps) => (
              <Menu {...menuProps}>
                {users.map((user, index) => (
                  <MenuItem option={user} position={index} key={index}>
                    <UserDropdownItem user={user} searchText={typeaheadRef?.current?.state?.text} />
                  </MenuItem>
                ))}
              </Menu>
            )}
          />
        </div>
        <div className={"mt-8"}>
          <Switch
            onChange={(e) => setSendSms(e.target.checked)}
            checked={sendSms}
            data-cy="switch-send-sms"
          />
          {intl.formatMessage({ id: "USER.ACTION.NOTIFY.SMS" })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            className="btn btn-light mr-4"
            onClick={uiProps.closeRequestSignatureDialog}
          >
            <FormattedMessage id="COMMON.ACTION.CANCEL" />
          </button>
          <button
            type="button"
            className="btn btn-primary"
            data-cy="button-send-signature"
            onClick={() => submit()}
            disabled={!selectedUser}
          >
            Send
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
