// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { cloneDeep } from "lodash";
import SVG from "react-inlinesvg";
import { Field, FieldArray, Form, Formik, FormikHandlers } from "formik";
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { NumberFormatValues } from "react-number-format";

import { IProduct, IPropertyDetails, IPropertySurfaceDetails } from "data/schemas";

import { Input, Select } from "_metronic/_partials/controls";

import { NumberInput } from "app/_utils/formUtils";
import { HEATING_TYPES, PROPERTY_TYPES } from "app/_utils/listUtils";

import { TextEditor } from "app/_components/TextEditor/TextEditor";
import { commentToolbar } from "app/_components/TextEditor/config";

import { useEntityDeleteDialogContext } from "app/modules/PropertiesManagement/pages/entity-delete-dialog/EntityDeleteDialogContext";

import { FormSection, FormSectionField, FormSectionRow } from "./FormSection";
import { ParkingFields } from "./CustomFields/ParkingFields";
import { OutdoorSurfaceFields } from "./CustomFields/OutdoorSurfaceFields";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { CustomCard } from "../../../../../Common/CustomCard";

//----------------------------------------------------------------------------//
const defaultItemParking = {
  type: "INDOOR",
  price: 0,
  parcel: "",
  landRegistryNumber: "",
  landArchitectNumber: "",
  thousands: 0,
  surface: 0,
  numberBlockStaircaseLevelCadastreCellar: "",
};

const defaultItemSurface = {
  status: "COMMON",
  parcel: "",
  landRegistryNumber: "",
  landArchitectNumber: "",
  thousands: 0,
  surface: 0,
  numberBlockStaircaseLevelCadastreCellar: "",
};
const MAX_SQM = 1000000000;
const YUP_MAX_SQM = Yup.number().max(MAX_SQM);

//----------------------------------------------------------------------------//

interface IInputField extends HTMLElement {
  name: string;
  value: any;
}

interface INumberFieldChangeHandlerParam {
  name: string;
  handleChange: FormikHandlers["handleChange"];
  defaultValue?: number;
  disabled?: boolean;
}

interface IBaseEntityButtonClickHandlerParam {
  values: IProduct;
  entityType: "parkings" | "cellars" | "gardens" | "terraces" | "balconies";
}

interface IRemoveLineButtonClickHandlerParam extends IBaseEntityButtonClickHandlerParam {
  index: number;
  remove<T>(index: number): T | undefined;
  entityTypeDialog?: string;
}

interface IAddEntityButtonClickHandlerParam extends IBaseEntityButtonClickHandlerParam {
  push: (obj: any) => void;
}

interface IButtonClickFieldChangeHandlerParam {
  name: string;
  value?: string;
}

//----------------------------------------------------------------------------//

export interface ProductEditInformationFormProps {
  submitProduct: Function;
  saveProductFields: Function;
  product: IProduct;
  disabled: boolean;
  isLoading?: boolean;
}

export const ProductEditInformationForm: React.FunctionComponent<
  ProductEditInformationFormProps
> = ({ submitProduct, saveProductFields, product, disabled }) => {
  const intl = useIntl();

  const { setDeleteEntityDialog } = useEntityDeleteDialogContext();

  const SUFFIX_UNIT_AREA = intl.formatMessage({ id: "COMMON.AREA.UNIT" });

  const YUP_MIN_1_MAX_150_CHARS = Yup.string()
    .min(1, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 1`)
    .max(150, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 150`);

  // Validation schema
  const ProductEditSchema = Yup.object().shape({
    propertyDetails: Yup.object().shape({
      rooms: YUP_MAX_SQM,
      bedrooms: YUP_MAX_SQM,
      bathrooms: YUP_MAX_SQM,

      livingSurface: YUP_MAX_SQM,
      acre: YUP_MAX_SQM,

      gardenSurface: YUP_MAX_SQM,
      balconySurface: YUP_MAX_SQM,
      terraceSurface: YUP_MAX_SQM,

      floor: YUP_MIN_1_MAX_150_CHARS,

      energyPerformanceClass: YUP_MIN_1_MAX_150_CHARS,
      environmentalPerformanceClass: YUP_MIN_1_MAX_150_CHARS,
      thermalInsulationClass: YUP_MIN_1_MAX_150_CHARS,
    }),
  });

  //--------------------------------------------------------------------------//

  const customFieldChangeHandler = (fieldName: string, fieldValue: any) => {
    if (saveProductFields !== undefined && fieldName) {
      saveProductFields(fieldName, fieldValue);
    }
  };

  const fieldChangeHandler =
    <T extends IInputField>(handleChange: FormikHandlers["handleChange"]) =>
    (e: React.ChangeEvent<T>) => {
      handleChange(e);
      customFieldChangeHandler(e.target.name, e.target.value);
    };

  const numberFieldChangeHandler =
    ({
      name,
      defaultValue = undefined,
      handleChange,
      disabled = false,
    }: INumberFieldChangeHandlerParam) =>
    ({ floatValue: fieldValue = defaultValue }: NumberFormatValues) => {
      handleChange({
        target: {
          name,
          value: fieldValue,
        },
      });

      if (disabled) {
        debugger;
        return;
      }

      customFieldChangeHandler(name, fieldValue);
    };

  //--------------------------------------------------------------------------//

  const removeLine =
    ({ values, index, remove, entityType }: IRemoveLineButtonClickHandlerParam) =>
    () => {
      remove(index);

      const clonedList = cloneDeep(values.propertyDetails?.[entityType]);
      Array.isArray(clonedList) && clonedList.length && clonedList.splice(index, 1);

      customFieldChangeHandler(`propertyDetails.${entityType}`, clonedList);
    };

  const removeLineButtonClickHandler =
    ({ values, index, remove, entityType, entityTypeDialog }: IRemoveLineButtonClickHandlerParam) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      setDeleteEntityDialog({
        entityType: entityTypeDialog,
        onDelete: removeLine({ values, index, remove, entityType }),
      });
    };

  const addEntityButtonClickHandler =
    ({ values, push, entityType }: IAddEntityButtonClickHandlerParam) =>
    (_: React.MouseEvent<HTMLButtonElement>) => {
      const defaultItem = entityType === "parkings" ? defaultItemParking : defaultItemSurface;
      push(defaultItem);
      const clonedList = cloneDeep(values.propertyDetails?.[entityType])!;
      clonedList.push(defaultItem as any);

      customFieldChangeHandler(`propertyDetails.${entityType}`, clonedList);
    };

  const buttonClickFieldChangeHandler =
    ({ name, value = "" }: IButtonClickFieldChangeHandlerParam) =>
    (_: React.MouseEvent<HTMLButtonElement>) => {
      customFieldChangeHandler(name, value);
    };

  //--------------------------------------------------------------------------//

  const onSubmitHandler = () => {
    if (!disabled && !!submitProduct) {
      submitProduct();
    }
  };

  //--------------------------------------------------------------------------//
  const AddLine = ({
    addLine,
    values,
    push,
    entityType,
  }: {
    addLine: Function;
    values: IProduct;
    push: Function;
    entityType: string;
  }) => (
    <>
      {!disabled && (
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-sm btn-light flex-grow-1 rounded-0 d-flex align-items-center  justify-content-center"
            onClick={addLine({ values, push, entityType })}
          >
            <i className="ki ki-plus icon-nm" />
            <FormattedMessage id="LEAD.ACTION.ADD.LINE" />
          </button>
        </div>
      )}
    </>
  );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={product}
      validationSchema={ProductEditSchema}
      onSubmit={onSubmitHandler}
    >
      {({ handleChange, values }) => (
        <Form className="form form-label-right">
          {/* @begin: info */}
          <FormSection>
            <FormSectionRow>
              <FormSectionField>
                <Select
                  name="propertyDetails.propertyType"
                  label={intl.formatMessage({
                    id: "PRODUCT.TYPE",
                  })}
                  onChange={fieldChangeHandler(handleChange)}
                  disabled={disabled}
                  customFeedbackLabel={true}
                >
                  {Object.keys(PROPERTY_TYPES).map((value) => (
                    <option key={value} value={value}>
                      {intl.formatMessage({ id: (PROPERTY_TYPES as any)?.[value] })}
                    </option>
                  ))}
                </Select>
              </FormSectionField>

              <FormSectionField>
                <Select
                  name="propertyDetails.heatingType"
                  label={intl.formatMessage({
                    id: "PRODUCT.INFO.HEATING_TYPE",
                  })}
                  onChange={fieldChangeHandler(handleChange)}
                  disabled={disabled}
                  customFeedbackLabel={true}
                >
                  {Object.keys(HEATING_TYPES).map((value) => (
                    <option key={value} value={value}>
                      {intl.formatMessage({ id: (HEATING_TYPES as any)[value] })}
                    </option>
                  ))}
                </Select>
              </FormSectionField>

              <FormSectionField label={<FormattedMessage id="PRODUCT.INFO.LIVING_SURFACE" />}>
                <NumberInput
                  className="form-control text-right"
                  value={values?.propertyDetails?.livingSurface}
                  onValueChange={numberFieldChangeHandler({
                    name: "propertyDetails.livingSurface",
                    defaultValue: 0,
                    handleChange,
                  })}
                  decimalScale={2}
                  disabled={disabled}
                  suffix={SUFFIX_UNIT_AREA}
                  placeholder=""
                />
              </FormSectionField>

              <FormSectionField label={<FormattedMessage id="PRODUCT.INFO.ACRE" />}>
                <NumberInput
                  className="form-control text-right"
                  value={values?.propertyDetails?.acre}
                  onValueChange={numberFieldChangeHandler({
                    name: "propertyDetails.acre",
                    defaultValue: 0,
                    handleChange,
                  })}
                  decimalScale={2}
                  disabled={disabled}
                  placeholder=""
                />
              </FormSectionField>
            </FormSectionRow>

            <FormSectionRow>
              {[
                { key: "housingProject", label: "PRODUCT.INFO.HOUSING_PROJECT" },
                { key: "parcel", label: "PRODUCT.INFO.LOT" },
                { key: "landRegistryNumber", label: "PRODUCT.INFO.LAND_REGISTRY_NUMBER" },
              ].map(({ key, label }, index) => (
                <FormSectionField key={index}>
                  <Field
                    key={index}
                    name={`propertyDetails.${key}`}
                    component={Input}
                    onChange={fieldChangeHandler(handleChange)}
                    label={intl.formatMessage({
                      id: label,
                    })}
                    disabled={disabled}
                  />
                </FormSectionField>
              ))}

              <FormSectionField label={<FormattedMessage id="PRODUCT.INFO.THOUSANDS" />}>
                <NumberInput
                  className="form-control text-right"
                  value={values?.propertyDetails.thousands}
                  onValueChange={numberFieldChangeHandler({
                    name: "propertyDetails.thousands",
                    handleChange,
                  })}
                  disabled={disabled}
                />
              </FormSectionField>
            </FormSectionRow>
          </FormSection>
          {/* @end: info */}

          {/* @begin: floor and rooms */}
          <FormSection title={<FormattedMessage id={"PROPERTY.INFO.FLOOR_AND_ROOMS"} />}>
            <FormSectionRow>
              {[
                { label: "PRODUCT.INFO.ROOMS", key: "rooms" },
                { label: "PRODUCT.INFO.BEDROOMS", key: "bedrooms" },
                { label: "PRODUCT.INFO.BATHROOMS", key: "bathrooms" },
                { label: "PRODUCT.INFO.FLOOR", key: "floor" },
              ].map((data, index) => (
                <FormSectionField key={index} label={<FormattedMessage id={data.label} />}>
                  <NumberInput
                    className={"form-control text-right"}
                    value={values.propertyDetails[data.key as keyof IPropertyDetails] ?? 0}
                    onValueChange={numberFieldChangeHandler({
                      name: `propertyDetails.${data.key}`,
                      handleChange,
                    })}
                    decimalScale={0}
                    disabled={disabled}
                    placeholder=""
                  />
                </FormSectionField>
              ))}
            </FormSectionRow>
          </FormSection>
          {/* @end: floor and rooms */}

          {/* @begin: parking */}
          <>
            <CustomCard
              header={
                <h4>
                  <FormattedMessage id={"PROPERTY.INFO.PARKING"} />
                  {` (${values?.propertyDetails?.parkings?.length ?? 0})`}
                </h4>
              }
              collapsable={true}
              isByDefaultCollapse={true}
              headerClassName={"bg-light-o-5 border-0"}
              parentClassName={"border-0 w-100"}
            >
              <FieldArray name={"propertyDetails.parkings"}>
                {({ remove, push }) => (
                  <div>
                    {values.propertyDetails.parkings?.map((parking, index) => (
                      <div key={index}>
                        <ParkingFields
                          {...{
                            parking,
                            parentName: "propertyDetails",
                            disabled,
                            index,
                            handle: { handleChange, fieldChangeHandler, numberFieldChangeHandler },
                            removeParking: (e: React.MouseEvent<HTMLButtonElement>) =>
                              removeLineButtonClickHandler({
                                values,
                                index,
                                remove,
                                entityType: "parkings",
                                entityTypeDialog: "PARKING",
                              })(e),
                          }}
                        />
                      </div>
                    ))}
                    <AddLine
                      {...{
                        addLine: addEntityButtonClickHandler,
                        values,
                        push,
                        entityType: "parkings",
                      }}
                    />
                  </div>
                )}
              </FieldArray>
            </CustomCard>
            <hr className={"my-8"} />
          </>
          {/* @end: parking */}

          {/* @begin: cellar|garden|terrace|balcony */}
          {(
            [
              { singularKey: "cellar", pluralKey: "cellars" },
              { singularKey: "garden", pluralKey: "gardens" },
              { singularKey: "terrace", pluralKey: "terraces" },
              { singularKey: "balcony", pluralKey: "balconies" },
            ] as {
              singularKey: string;
              pluralKey: "cellars" | "gardens" | "terraces" | "balconies";
            }[]
          ).map(({ singularKey, pluralKey }) => (
            <>
              <CustomCard
                header={
                  <h4>
                    <FormattedMessage id={`PRODUCT.INFO.${singularKey.toUpperCase()}`} />
                    {` (${values?.propertyDetails?.[pluralKey]?.length ?? 0})`}
                  </h4>
                }
                collapsable={true}
                isByDefaultCollapse={true}
                headerClassName={"bg-light-o-5 border-0"}
                parentClassName={"border-0 w-100"}
              >
                <FieldArray name={`propertyDetails.${pluralKey}`}>
                  {({ remove, push }) => (
                    <div>
                      {values.propertyDetails[pluralKey]?.map((entity, index) => (
                        <div key={index}>
                          <OutdoorSurfaceFields
                            {...{
                              entity,
                              parentName: `propertyDetails.${pluralKey}`,
                              disabled,
                              index,
                              handle: {
                                handleChange,
                                fieldChangeHandler,
                                numberFieldChangeHandler,
                              },
                              removeEntity: (e: React.MouseEvent<HTMLButtonElement>) =>
                                removeLineButtonClickHandler({
                                  values,
                                  index,
                                  remove,
                                  entityType: pluralKey,
                                  entityTypeDialog: singularKey.toUpperCase(),
                                })(e),
                            }}
                          />
                        </div>
                      ))}

                      <AddLine
                        {...{
                          addLine: addEntityButtonClickHandler,
                          values,
                          push,
                          entityType: pluralKey,
                        }}
                      />
                    </div>
                  )}
                </FieldArray>
              </CustomCard>
              <hr className={"my-8"} />
            </>
          ))}
          {/* @end: cellar|garden|terrace|balcony */}

          <FormSection
            title={
              <>
                <FormattedMessage id="PRODUCT.INFO.TOTAL_OUTER_SURFACE" />
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="layout-tooltip" className={"tooltip-auto-width"}>
                      <FormattedMessage id="PRODUCT.INFO.GARDEN" /> {" + "}
                      <FormattedMessage id="PRODUCT.INFO.TERRACE" /> {" + "}
                      <FormattedMessage id="PRODUCT.INFO.BALCONY" />
                    </Tooltip>
                  }
                >
                  <span className="svg-icon svg-icon-md svg-icon-info ml-2">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")} />
                  </span>
                </OverlayTrigger>
              </>
            }
          >
            <div className={"mb-4"}>
              <div className={"d-flex"}>
                {["COMMON.COMMON", "COMMON.PRIVATE"].map((label, index) => (
                  <div key={index} className={"col-6 h5 text-center font-weight-bold mt-5"}>
                    <FormattedMessage id={label} />
                  </div>
                ))}
              </div>
              <div className={"d-flex"}>
                {["common", "private"].map((key) => (
                  <div key={`totalSurface_${key}`} className={"col-6"}>
                    <NumberInput
                      className="form-control text-right font-weight-bold border-0"
                      suffix={SUFFIX_UNIT_AREA}
                      decimalScale={2}
                      value={["gardens", "terraces", "balconies"]
                        .map((value) =>
                          (values as any).propertyDetails?.[value]
                            .filter(
                              (entity: IPropertySurfaceDetails) =>
                                entity.status === key.toUpperCase()
                            )
                            .reduce(
                              (a: IPropertySurfaceDetails, b: IPropertySurfaceDetails) =>
                                (a.surface ?? 0) + (b.surface ?? 0),
                              0
                            )
                        )
                        .reduce((a: number, b: number) => a + b)}
                      disabled={true}
                    />
                  </div>
                ))}
              </div>
            </div>
          </FormSection>

          <FormSection
            title={<FormattedMessage id={"PROPERTY.INFO.SPECIFICATION"} />}
            renderDivider={false}
          >
            {/* @begin: specification */}
            <FormSectionRow className="form-group mt-3">
              <TextEditor
                name={"propertyDetails.specification"}
                data={values.propertyDetails.specification!}
                saveMethod={saveProductFields}
                disabled={disabled}
                toolbarOption={commentToolbar}
                data-cy={"input-product-details-specification"}
              />
            </FormSectionRow>
            {/* @end: specification */}

            {/* @begin: performance classes */}
            <FormSectionRow className="form-group form-row d-flex justify-content-around">
              {[
                {
                  label: "PRODUCT.INFO.ENERGY_PERFORMANCE_CLASS",
                  key: "energyPerformanceClass",
                },
                {
                  label: "PRODUCT.INFO.ENVIRONMENTAL_PERFORMANCE_CLASS",
                  key: "environmentalPerformanceClass",
                },
                {
                  label: "PRODUCT.INFO.THERMAL_INSULATION_CLASS",
                  key: "thermalInsulationClass",
                },
              ].map((data, index) => (
                <FormSectionField
                  label={<FormattedMessage id={data.label} />}
                  className={"my-2"}
                  key={index}
                >
                  <div className={"d-flex"}>
                    <ButtonGroup>
                      {["A+", "A", "B", "C", "D", "E", "F", "G", "H", "I"].map((value) => (
                        <Button
                          className={"p-3"}
                          variant={
                            values.propertyDetails[data.key as keyof IPropertyDetails] === value
                              ? "primary"
                              : "outline-secondary"
                          }
                          key={value}
                          disabled={disabled}
                          onClick={buttonClickFieldChangeHandler({
                            name: `propertyDetails.${data.key}`,
                            value,
                          })}
                        >
                          {value}
                        </Button>
                      ))}
                    </ButtonGroup>

                    <ButtonGroup className={"ml-2"}>
                      <Button
                        className={"p-3"}
                        variant={
                          !values.propertyDetails[data.key as keyof IPropertyDetails]
                            ? "primary"
                            : "outline-secondary"
                        }
                        disabled={disabled}
                        onClick={buttonClickFieldChangeHandler({
                          name: `propertyDetails.${data.key}`,
                        })}
                      >
                        N/A
                      </Button>
                    </ButtonGroup>
                  </div>
                </FormSectionField>
              ))}
            </FormSectionRow>
            {/* @end: performance classes */}
          </FormSection>
        </Form>
      )}
    </Formik>
  );
};

export default ProductEditInformationForm;
