/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BRAND_URL } from "../../../../configuration";
import { FormattedMessage } from "react-intl";

const backgroundImageUrl = BRAND_URL + "/client_card.png";

export function ClientsCounterWidget({ className, widgetHeight = "175px" }) {
  const { isLoading, counter } = useSelector((state) => ({
    isLoading: state.dashboard.countersLoading,
    counter:
      state.dashboard?.counters?.clientCounters?.find((c) => c.counterType === "TOTAL")?.value || 0,
  }));

  const history = useHistory();

  return (
    <div
      role={"button"}
      onClick={() => history.push("/clients")}
      className={`card card-custom bgi-no-repeat ${className}`}
      style={{
        height: widgetHeight,
        backgroundColor: "#663259",
        backgroundSize: "cover",
        backgroundImage: `url("${backgroundImageUrl}")`,
      }}
    >
      <div className="card-body d-flex align-items-center">
        <div>
          <h3 className="text-white font-weight-bolder line-height-lg mb-5">
            {isLoading ? (
              <span className="spinner spinner-lg spinner-success mr-12" />
            ) : (
              <span>{counter}</span>
            )}
            <span className="ml-2">
              <FormattedMessage id="CLIENT.TITLE" />
            </span>
          </h3>
          <div
            onClick={(e) => {
              e.stopPropagation();
              history.push("/clients/new");
            }}
            className="btn btn-success font-weight-bold px-6 py-3"
          >
            <FormattedMessage id="CLIENT.ONBOARD_NEW_CLIENT" />
          </div>
        </div>
      </div>
    </div>
  );
}
