import Select from "react-select";
import React, { useMemo } from "react";
import { useHtmlClassService } from "../../_metronic/layout";
import objectPath from "object-path";

export const MultiSelect = ({
  options,
  value,
  onChange,
  isClearable = false,
  placeholder = "",
}) => {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray300: objectPath.get(uiService.config, "js.colors.gray.gray300"),
      colorsGrayGray600: objectPath.get(uiService.config, "js.colors.gray.gray600"),
      colorsThemeBaseDanger: objectPath.get(uiService.config, "js.colors.theme.base.danger"),
      colorsThemeLightDanger: objectPath.get(uiService.config, "js.colors.theme.light.danger"),
      colorsThemeBasePrimary: objectPath.get(uiService.config, "js.colors.theme.base.primary"),
    };
  }, [uiService]);
  return (
    <Select
      styles={{
        control: (provided, state) => ({
          ...provided,
          "&:hover": { border: `1px solid ${layoutProps.colorsThemeBasePrimary}` },
          border: `1px solid ${
            state.isFocused ? layoutProps.colorsThemeBasePrimary : layoutProps.colorsGrayGray300
          }`,
          outline: "none",
          boxShadow: state.isFocused ? null : null,
          borderRadius: ".42rem",
          minWidth: 250,
        }),
        placeholder: (provided, state) => ({
          ...provided,
          color: "#B3B3C2",
        }),
        valueContainer: (defaultSyles) => ({ ...defaultSyles, display: "flex" }),
        multiValue: (base, state) => {
          return state.data.isFixed
            ? { ...base, backgroundColor: layoutProps.colorsGrayGray600 }
            : base;
        },
        multiValueLabel: (base, state) => {
          return state.data.isFixed
            ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
            : base;
        },
        multiValueRemove: (base, state) => {
          const resCSS = {
            ...base,
            cursor: "pointer",
            "&:hover": {
              backgroundColor: layoutProps.colorsThemeLightDanger,
              color: layoutProps.colorsThemeBaseDanger,
            },
          };
          if (state.data.isFixed) {
            resCSS.display = "none";
          }
          return resCSS;
        },
        option: (base, state) => {
          return {
            ...base,
            cursor: "pointer",
            backgroundColor: state.isFocused ? layoutProps.colorsGrayGray300 : undefined,
            ":active": {
              ...base[":active"],
              backgroundColor: layoutProps.colorsGrayGray300,
            },
          };
        },
      }}
      options={options}
      value={value}
      isClearable={isClearable}
      onChange={onChange}
      isMulti
      menuPosition="fixed"
      placeholder={placeholder}
    />
  );
};
