import React, { FC } from "react";
import cn from "clsx";
import { BudgetLineColumns } from "../../../../data/schemas";

type Props = {
  columnWidth: Record<BudgetLineColumns, number>;
  line: { label: React.ReactNode } & Record<BudgetLineColumns, React.ReactNode>;
  isHeader?: boolean;
  selectedBudget?: boolean;
};
export const BudgetLine: FC<Props> = ({ columnWidth, line, isHeader, selectedBudget }) => {
  return (
    <div className="d-flex flex-grow-1">
      <div
        className={cn("flex-shrink-0 d-flex align-items-center", !selectedBudget && "flex-grow-1")}
      >
        <div
          className={cn(
            "d-flex",
            selectedBudget ? "w-200px w-sm-250px w-md-300px w-lg-350px" : "w-100"
          )}
        >
          {line.label}
        </div>
      </div>
      <div
        className={cn(
          "px-2 d-flex align-items-center justify-content-end",
          isHeader ? "font-weight-bold" : "font-size-sm",
          selectedBudget && "col"
        )}
        style={{
          minWidth: columnWidth.planned,
          boxSizing: "content-box",
        }}
      >
        {line.planned}
      </div>
      {selectedBudget && (
        <>
          <div
            className={cn(
              "px-2 d-flex align-items-center justify-content-end col",
              isHeader ? "font-weight-bold" : "font-size-sm"
            )}
            style={{ minWidth: columnWidth.quotes, boxSizing: "content-box" }}
          >
            {line.quotes}
          </div>
          <div
            className={cn(
              "px-2 d-flex align-items-center justify-content-end col",
              isHeader ? "font-weight-bold" : "font-size-sm"
            )}
            style={{ minWidth: columnWidth.supplementary_agreements, boxSizing: "content-box" }}
          >
            {line.supplementary_agreements}
          </div>
          <div
            className={cn(
              "px-2 d-flex align-items-center justify-content-end col",
              isHeader ? "font-weight-bold" : "font-size-sm"
            )}
            style={{ minWidth: columnWidth.orders, boxSizing: "content-box" }}
          >
            {line.orders}
          </div>
          <div
            className={cn(
              "px-2 d-flex align-items-center justify-content-end col",
              isHeader ? "font-weight-bold" : "font-size-sm"
            )}
            style={{
              minWidth: columnWidth.realDifference,
              boxSizing: "content-box",
            }}
          >
            {line.realDifference}
          </div>
          <div
            className={cn(
              "px-2 d-flex align-items-center justify-content-end col",
              isHeader ? "font-weight-bold" : "font-size-sm"
            )}
            style={{
              minWidth: columnWidth.realDifferencePercentage,
              boxSizing: "content-box",
            }}
          >
            {line.realDifferencePercentage}
          </div>
          <div
            className={cn(
              "px-2 d-flex align-items-center justify-content-end col",
              isHeader ? "font-weight-bold" : "font-size-sm"
            )}
            style={{ minWidth: columnWidth.invoiced, boxSizing: "content-box" }}
          >
            {line.invoiced}
          </div>
          <div
            className={cn(
              "px-2 d-flex align-items-center justify-content-end col",
              isHeader ? "font-weight-bold" : "font-size-sm"
            )}
            style={{
              minWidth: columnWidth.invoicedPercentage,
              boxSizing: "content-box",
            }}
          >
            {line.invoicedPercentage}
          </div>
        </>
      )}
    </div>
  );
};
