import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { formatPhoneNumberIntl } from "react-phone-number-input";

interface GDPRProps {
  settings: undefined | { companyName: string; termsLink: string; privacyPolicyLink: string };
  session: undefined | { mobile: string; email: string };
  acceptGDPR: Function;
  logout?: Function;
}

export const GDPR: React.FC<GDPRProps> = ({ settings, session, acceptGDPR, logout }) => {
  const [agreeGDPR, setAgreeGDPR] = useState(false);
  const [agreeMobile, setAgreeMobile] = useState(false);
  const [agreeEmail, setAgreeEmail] = useState(false);

  return (
    <>
      {/*

      Note: company-settings must be configured before giving access to customers.
      Missing settings/settings-object causes the UI to remain in loading state.

      Company details/settings can be configured by accessing the UI using an admin account
      (e.g. master@immotool.lu) in settings tab (wheel icon in overlay menu on left side next
      to autosave icon)

      */}
      {settings && session ? (
        <div className="mx-8" style={{ maxWidth: "550px" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">
              <FormattedMessage id="AUTH.GDPR.TITLE" />
            </h3>
            <p className="text-muted font-weight-bold">
              <FormattedMessage id="AUTH.GDPR.SUBTITLE" />
            </p>
          </div>
          <div className={"font-size-lg font-weight-bold mb-4"}>
            <FormattedMessage
              id="AUTH.GDPR.TERMS_PRIVACY.TITLE"
              values={{ companyName: settings?.companyName }}
            />
          </div>
          <div className="d-flex align-items-center flex-grow-1 mb-4">
            <label className="checkbox align-items-start">
              <input
                type="checkbox"
                checked={agreeGDPR}
                onChange={() => setAgreeGDPR(!agreeGDPR)}
              />
              <span className="mr-4 flex-shrink-0" id="checkbox-privacy-policy" />
              <div>
                <FormattedMessage
                  id={"AUTH.GDPR.TERMS_PRIVACY"}
                  values={{
                    termsLink: (chunks: any) => (
                      <a
                        className="text-primary"
                        href={settings?.termsLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <u>{chunks}</u>
                      </a>
                    ),
                    privacyPolicyLink: (chunks: any) => (
                      <a
                        className="text-primary"
                        href={settings?.privacyPolicyLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <u>{chunks}</u>
                      </a>
                    ),
                    companyName: settings?.companyName || "",
                  }}
                />
              </div>
            </label>
          </div>
          <div className={"font-size-lg font-weight-bold mb-4"}>
            <FormattedMessage id="AUTH.GDPR.PERSONAL_MOBILE.TITLE" />
          </div>
          <div className="d-flex align-items-center flex-grow-1 mb-4">
            <label className="checkbox align-items-start">
              <input
                type="checkbox"
                checked={agreeMobile}
                onChange={() => setAgreeMobile(!agreeMobile)}
              />
              <span className="mr-4 flex-shrink-0" id="checkbox-phone-mobile" />
              <div>
                <FormattedMessage
                  id={"AUTH.GDPR.PERSONAL_MOBILE"}
                  values={{
                    span: (chunks: any) => (
                      <span className="text-nowrap font-weight-bold">{chunks}</span>
                    ),
                    mobile: formatPhoneNumberIntl(session.mobile),
                  }}
                />
              </div>
            </label>
          </div>
          <div className={"font-size-lg font-weight-bold mb-4"}>
            <FormattedMessage id="AUTH.GDPR.PERSONAL_EMAIL.TITLE" />
          </div>
          <div className="d-flex align-items-center flex-grow-1">
            <label className="checkbox align-items-start">
              <input
                type="checkbox"
                checked={agreeEmail}
                onChange={() => setAgreeEmail(!agreeEmail)}
              />
              <span className="mr-4 flex-shrink-0" id="checkbox-email" />
              <div>
                <FormattedMessage
                  id={"AUTH.GDPR.PERSONAL_EMAIL"}
                  values={{
                    span: (chunks: any) => (
                      <span className="text-nowrap font-weight-bold">{chunks}</span>
                    ),
                    email: session.email,
                  }}
                />
              </div>
            </label>
          </div>
          <div className="d-flex justify-content-end mt-8">
            {!!logout && (
              <button
                className="btn btn-outline-danger font-weight-bold mr-6"
                onClick={() => logout()}
              >
                <FormattedMessage id={"AUTH.GDPR.ACTION.DISAGREE"} />
              </button>
            )}
            <button
              disabled={!agreeGDPR || !agreeMobile || !agreeEmail}
              className="btn btn-primary font-weight-bold px-9 py-4"
              data-cy="button-gdpr-agree"
              onClick={() => {
                if (agreeGDPR && agreeMobile && agreeEmail) {
                  acceptGDPR();
                }
              }}
            >
              <FormattedMessage id={"AUTH.GDPR.ACTION.AGREE"} />
            </button>
          </div>
        </div>
      ) : (
        <div className={"spinner spinner-lg spinner-primary"} />
      )}
    </>
  );
};
