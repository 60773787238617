import * as React from "react";

/*
  Error Boundaries | React Docs
  https://reactjs.org/docs/error-boundaries.html

  React 16 Error handling with componentDidCatch() | LeanJS - Medium
  https://medium.com/leanjs/react-16-error-handing-with-componentdidcatch-fa4e248d45d7

  Error Handling Using Error Boundaries in React | JavaScript in Plain English - Medium
  https://medium.com/javascript-in-plain-english/error-handling-using-error-boundaries-in-react-e7c8f27d778e
*/
export class ErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    console.group("ErrorBoundary.componentDidCatch");
    errorInfo && errorInfo.componentStack && console.log(errorInfo.componentStack);
    console.error(error);
    console.groupEnd();
    console.log("\n");
  }

  render() {
    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
