import React, { ChangeEvent, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { ProductFiles } from "../../products/product-files/ProductFiles";
import { ProjectFiles } from "../../projects/project-files/ProjectFiles";
import { ProjectFilesUIProvider } from "../../projects/project-files/ProjectFilesUIContext";
import { ClientsFiles } from "../clients-tab/clients-files/ClientsFiles";
import { LeadFilesFiles } from "../lead-files/LeadFilesFiles";
import { ProductFilesUIProvider } from "../../products/product-files/ProductFilesUIContext";
import { LeadFilesUIProvider } from "../lead-files/LeadFilesUIContext";

interface FilesTabProps {
  leadForEdit: any;
  readOnly: boolean;
}

export const FilesTab = ({ leadForEdit, readOnly }: FilesTabProps) => {
  const intl = useIntl();
  const [selectedTab, setSelectedTab] = useState<string>("leadFiles");

  const typeFile: any = useMemo(() => {
    return {
      leadFiles: intl.formatMessage({ id: "LEAD.TITLE.FILE" }),
      projectFiles: intl.formatMessage({ id: "PROJECT.TITLE.FILE" }),
      productFiles: intl.formatMessage({ id: "PRODUCT.TITLE.FILE" }),
      clientsFiles: intl.formatMessage({ id: "CLIENT.TITLE.FILE" }),
    };
  }, [intl]);

  return (
    <div>
      <select
        className="form-control form-control-solid mb-5"
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
          setSelectedTab(e.target.value);
        }}
      >
        {Object.keys(typeFile).map((key: string) => (
          <option key={key} value={key}>
            {typeFile[key]}
          </option>
        ))}
      </select>

      {selectedTab === "leadFiles" && !!leadForEdit?.id && (
        <LeadFilesUIProvider currentLeadId={leadForEdit.id} readOnly={readOnly}>
          <LeadFilesFiles />
        </LeadFilesUIProvider>
      )}
      {selectedTab === "projectFiles" && leadForEdit?.projectId && (
        <ProjectFilesUIProvider currentProjectId={leadForEdit.projectId} readOnly context={"lead"}>
          <ProjectFiles />
        </ProjectFilesUIProvider>
      )}
      {selectedTab === "productFiles" && leadForEdit?.productId && (
        <ProductFilesUIProvider currentProductId={leadForEdit.productId} readOnly context={"lead"}>
          <ProductFiles />
        </ProductFilesUIProvider>
      )}
      {selectedTab === "clientsFiles" && !!leadForEdit?.id && <ClientsFiles />}
    </div>
  );
};
