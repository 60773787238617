const getTranslationFromRoute = (intl, pathname) => {
  switch (pathname) {
    case "projects":
      return intl.formatMessage({ id: "PROJECT.TITLE" });
    case "products":
      return intl.formatMessage({ id: "PRODUCT.TITLE" });
    case "users":
      return intl.formatMessage({ id: "USER.TITLE" });
    case "clients":
      return intl.formatMessage({ id: "CLIENT.TITLE" });
    case "subcontractors":
      return intl.formatMessage({ id: "SUBCONTRACTOR.TITLE" });
    case "leads":
      return intl.formatMessage({ id: "LEAD.TITLE" });
    case "tasks":
      return intl.formatMessage({ id: "TASK.TITLE" });
    default:
      return "";
  }
};

// TODO find a better way to do this without a big switch
export const getRouteBreadcrumb = (intl, pathname, title, active) => {
  const paths = pathname.split("/").filter((path) => !!path);
  const routeBreadcrumb = [];
  // Add global route first if it matches (e.g /projects/<id>)
  if (
    paths.length > 1 &&
    ["projects", "products", "users", "clients", "subcontractors", "leads", "tasks"].includes(
      paths[0]
    )
  ) {
    routeBreadcrumb.push({
      title: getTranslationFromRoute(intl, paths[0]),
      pathname: `/${paths[0]}`,
    });
  }
  const route = { title };
  if (!active) {
    route.pathname = pathname;
  }
  routeBreadcrumb.push(route);
  return routeBreadcrumb;
};

export const translateBreadcrumbs = (intl, breadcrumbs) => {
  return breadcrumbs
    ? breadcrumbs.map((title) => {
        return { title: intl.formatMessage({ id: title }) };
      })
    : "";
};
