import { useIntl } from "react-intl";
import { shallowEqual } from "react-redux";

import { useSubheader } from "_metronic/layout";
import { LoadingDialog } from "_metronic/_partials/controls";

import { useAppSelector } from "redux/hooks";
import { formatDisplayNameIntl } from "app/_utils/userUtils";
import { CLIENT, SUBCONTRACTOR, USER } from "app/_utils/userTypes";

import { UsersUIProvider } from "./UsersUIContext";
import { UsersCard } from "./users-table/UsersCard";

export function UsersPage({ history, action, userType }) {
  let userTypePath;
  switch (userType) {
    case USER:
      userTypePath = "users";
      break;
    case CLIENT:
      userTypePath = "clients";
      break;
    case SUBCONTRACTOR:
      userTypePath = "subcontractors";
      break;

    default:
      break;
  }
  const { isLoading } = useAppSelector(
    (state) => ({ isLoading: state.users.listLoading }),
    shallowEqual
  );
  const subheader = useSubheader();
  const intl = useIntl();

  const uiEvents = {
    newEntityButtonClick: () => {
      history.push(`/${userTypePath}/new`);
    },
    openEntityDetailsPage: (user) => {
      subheader.setTitle(formatDisplayNameIntl(intl, user));
      history.push(`/${userTypePath}/${user.id}`);
    },
    openCreateLeadPage: (ids) => {
      history.push(`/leads/new?c=${ids}${history.location.search.replace("?", "&")}`);
    },
    openTasksPage: (ids) => {
      history.push(`/tasks`);
    },
  };

  return (
    <UsersUIProvider uiEvents={uiEvents} action={action} userType={userType}>
      <LoadingDialog isLoading={isLoading} />
      <UsersCard />
    </UsersUIProvider>
  );
}
