import React, { createContext, FC, useContext, useState } from "react";

export const I18N_CONFIG_KEY = "i18nConfig";

export enum LanguageType {
  ENGLISH = "en-GB",
  FRENCH = "fr-FR",
  GERMAN = "de-DE"
}
export const browserLang = navigator.language;
export const supportedLanguages = Object.values(LanguageType) as string[];
export const selectLanguage = (lang: string) => lang && supportedLanguages.includes(lang) ? lang : LanguageType.ENGLISH;
export const setI18Language = (selectedLang: string) =>  {
  selectedLang = selectLanguage(selectedLang);
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify( selectedLang ));
  return selectedLang as LanguageType;
}
export const getI18Language = () => {
  let localLanguage = localStorage.getItem(I18N_CONFIG_KEY);
  // Handling old format of local storage of the selected language
  if (localLanguage) {
    localLanguage = JSON.parse(localLanguage) || {};
    if (localLanguage && localLanguage.hasOwnProperty("selectedLang")) {
      localLanguage = (localLanguage as any)["selectedLang"]
    }
  }
  return   (localLanguage || selectLanguage(browserLang))  as LanguageType;
}

export interface ContextProps {
  selectedLanguage: LanguageType;
  setI18nLanguage: Function;
}
const initialState: ContextProps = {
  selectedLanguage: selectLanguage(browserLang) as LanguageType,
  setI18nLanguage: () => undefined
};

// Side effect
export const setLanguage = (lang: LanguageType) => {
  setI18Language(lang)
  window.location.reload();
}

const I18nContext = createContext<ContextProps>(initialState);

export const MetronicI18nProvider: FC = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(getI18Language);

  const setI18nLanguage = (language: LanguageType) => {
    setSelectedLanguage(setI18Language(language));

  }
  return <I18nContext.Provider value={{
    selectedLanguage, setI18nLanguage
  }}>{children}</I18nContext.Provider>;
}

export const useLanguage = () => {
  return useContext(I18nContext);
}
