/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { FC, useEffect, useRef, useState } from "react";
import { shallowEqual } from "react-redux";
import * as actions from "./_redux/budgetsActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  ModalProgressBar,
} from "../../../_metronic/_partials/controls";
import { BudgetEditForm } from "./BudgetEditForm";
import { useSubheader } from "../../../_metronic/layout";
import { cloneDeep, mergeWith } from "lodash-es";
import { useQueryState } from "react-router-use-location-state";
import { v4 as uuid } from "uuid";
import { FormattedMessage, useIntl } from "react-intl";
import { canCreate, canEdit } from "../../_utils/authUtils";
import { useAutoSave } from "../../_utils/useAutoSave";
import { useCustomLocationState } from "../../_utils/useCustomLocationState";
import { BudgetsUIProvider } from "./BudgetsUIContext";
import { Route, RouteComponentProps } from "react-router-dom";
import { BudgetLineDialog } from "./components/BudgetLineDialog";
import * as projectActions from "../PropertiesManagement/_redux/projects/projectsActions";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

const initBudget = {
  id: undefined,
  name: "",
  projectId: "",
  projectName: "",
  sortedLines: [
    {
      id: uuid(),
      label: "",
      visible: true,
      lines: [{ id: uuid(), label: "", plannedBudget: 0 }],
    },
  ],
  budgetInstalments: [
    {
      id: uuid(),
      label: "",
      instalment: 0,
    },
  ],
};

const mergeWithInitBudget = (obj: any) => {
  return mergeWith(cloneDeep(initBudget), obj, (dest, src) => (src === null ? dest : undefined));
};

interface IBudgetEditMatchParams {
  id: string;
}

export const BudgetEdit: FC<RouteComponentProps<IBudgetEditMatchParams>> = ({
  history,
  match: {
    params: { id },
  },
  location,
}) => {
  const intl = useIntl();
  // Subheader
  const subheader = useSubheader();

  const [title, setTitle] = useState("");
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, budgetForEdit, originalBudgetForEdit, session, userGroups, project } =
    useAppSelector(
      (state) => ({
        actionsLoading: state.budgets.actionsLoading,
        originalBudgetForEdit:
          state?.budgets?.budgetForEdit?.saved ??
          state?.budgets?.entities?.find((entity) => entity.id === id),
        budgetForEdit:
          state?.budgets?.budgetForEdit?.current ??
          state?.budgets?.entities?.find((entity) => entity.id === id),
        project: state?.projects?.projectForEdit?.saved,
        userGroups: state.auth.groups,
        session: state.auth.session,
      }),
      shallowEqual
    );

  useAutoSave(budgetForEdit, originalBudgetForEdit, actions.updateBudget);

  const readOnly = id
    ? !canEdit(userGroups, session, "BUDGET")
    : !canCreate(userGroups, session, "BUDGET");

  const budgetEditFormCallbackRef = useRef<() => void>();
  const [projectId] = useQueryState("projectId", "");

  const dispatch = useAppDispatch();

  // Clean up active budget on unmount
  useEffect(() => () => dispatch(actions.fetchBudget()), []);

  // Fetch existing budget
  useEffect(() => {
    if (id) {
      dispatch(actions.fetchBudget(id));
    }
  }, [id]);

  const [firstLoadBudget, setFirstLoadBudget] = useState(false);

  // Fetch project from existing budget or for new budget with projectId query param
  useEffect(() => {
    if (
      originalBudgetForEdit &&
      ((!firstLoadBudget && originalBudgetForEdit.id === id) ||
        project?.id !== originalBudgetForEdit.projectId)
    ) {
      setFirstLoadBudget(true);
      dispatch(projectActions.fetchProject(originalBudgetForEdit.projectId));
    } else if (!originalBudgetForEdit && projectId) {
      dispatch(projectActions.fetchProject(projectId));
    }
  }, [originalBudgetForEdit, projectId, project?.id]);

  // Set default value for new budget from projectId
  useEffect(() => {
    if (project && project.id === projectId && !budgetForEdit?.projectName) {
      saveBudgetFields(["projectId", "projectName"], [project.id, project.name]);
    }
  }, [project, projectId]);

  // Set card title
  useEffect(() => {
    if (!id || originalBudgetForEdit?.id === id) {
      const _title = !id
        ? intl.formatMessage({ id: "BUDGET.TITLE.NEW" })
        : originalBudgetForEdit!.name;
      setTitle(_title);
      subheader.setTitle(_title);
    }
  }, [originalBudgetForEdit, id]);

  const saveBudgetFields = (key: string | string[], value: any) => {
    if (Array.isArray(key) && key.length === value.length) {
      for (let i = 0; i < key.length; i++) {
        dispatch(actions.updateBudgetFieldLocally(key[i], value[i]));
      }
    } else {
      dispatch(actions.updateBudgetFieldLocally(key, value));
    }
  };

  const submitBudget = () => {
    if (!id) {
      dispatch(actions.createBudget(mergeWithInitBudget(budgetForEdit))).then((budget) => {
        if (budget?.id) {
          history.push(`/budgets/${budget.id}`);
        }
      });
    }
  };

  const { goBack } = useCustomLocationState();
  const backButtonClick = () => {
    goBack(`/projects/${budgetForEdit?.projectId || projectId}?t=budgets`);
  };

  return (
    <>
      <Card>
        {actionsLoading && <ModalProgressBar />}
        <CardHeader title={title}>
          <CardHeaderToolbar>
            <button type="button" onClick={backButtonClick} className="btn btn-light">
              <i className="fa fa-arrow-left" />
              {id ? (
                <FormattedMessage id="COMMON.ACTION.BACK" />
              ) : (
                <FormattedMessage id="COMMON.ACTION.CANCEL" />
              )}
            </button>
            {!id && !readOnly && (
              <button
                type="submit"
                className="btn btn-primary ml-2"
                onClick={budgetEditFormCallbackRef?.current}
                data-cy="save-budget-btn"
                disabled={actionsLoading}
              >
                <FormattedMessage id="COMMON.ACTION.CREATE" />
              </button>
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="mt-5">
            <BudgetsUIProvider>
              {(!actionsLoading && !budgetForEdit) || budgetForEdit?.id === id ? (
                <>
                  <BudgetEditForm
                    submitBudget={submitBudget}
                    budget={mergeWithInitBudget(budgetForEdit)}
                    saveBudgetFields={saveBudgetFields}
                    disabled={readOnly}
                    onSubmitRef={(callback) => {
                      budgetEditFormCallbackRef.current = callback;
                    }}
                  />
                </>
              ) : (
                <div className="d-flex align-items-center justify-content-center">
                  <div className="spinner spinner-lg spinner-primary h-30px w-30px" />
                </div>
              )}
              {!!originalBudgetForEdit && !!project && (
                <>
                  <Route
                    path="/budgets/:id/:categoryId/:lineId"
                    exact
                    render={({ history, match }) => (
                      <BudgetLineDialog
                        show={match != null}
                        budgetId={match?.params?.id}
                        categoryId={match?.params?.categoryId}
                        lineId={match?.params?.lineId}
                        onHide={() => {
                          goBack(`/budgets/${originalBudgetForEdit.id}`);
                          subheader.setTitle(budgetForEdit!.name);
                        }}
                        history={history}
                      />
                    )}
                  />
                </>
              )}
            </BudgetsUIProvider>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
