/*
  https://react-select.com/typescript
*/
import Select, { GroupBase } from "react-select";

import { CustomSelectBaseProps } from "./definitions";

export const CustomSelectBase = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  disabled,
  clearable,
  selected,
  menuPosition = "fixed",
  captureMenuScroll = true,
  menuShouldBlockScroll = true,
  ...props
}: CustomSelectBaseProps<Option, IsMulti, Group>) => (
  <Select
    {...{
      ...props,

      menuPosition,
      captureMenuScroll,
      menuShouldBlockScroll,
      value: selected,
      isDisabled: disabled,
      isClearable: clearable,
    }}
  />
);

export default CustomSelectBase;
