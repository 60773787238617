/* eslint-disable no-restricted-imports */
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../../../_metronic/_partials/controls";

export function AddClientDialogHeader() {
  const { actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: state.users.actionsLoading,
    }),
    shallowEqual
  );

  return (
    <>
      {actionsLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Add client</Modal.Title>
      </Modal.Header>
    </>
  );
}
