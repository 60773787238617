import React from "react";

export const PhotoColumnFormatter = (
  cellContent: string,
  row: {
    pictureSetup: boolean;
    pictureUrl: string;
  },
  rowIndex: number,
  extraParams: { icon: string }
) => {
  return (
    <div className="symbol symbol-35 bg-white-o-30 symbol-light-primary flex-shrink-0">
      {row?.pictureSetup && row?.pictureUrl ? (
        <img src={row.pictureUrl} alt="Profile" />
      ) : (
        <span className="symbol-label font-size-h5 bg-white-o-30 font-weight-bold symbol-label">
          <i className={extraParams.icon} />
        </span>
      )}
    </div>
  );
};
