import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash-es";

const initialSettingsState = {
  listLoading: false,
  actionsLoading: false,
  settingsForEdit: {
    saved: undefined,
    current: undefined,
  },
  users: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState: initialSettingsState,
  reducers: {
    catchError: (state, action) => {
      state.listLoading = false;
      state.actionsLoading = false;
    },
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    settingsFetched: (state, action) => {
      state.listLoading = false;
      state.settingsForEdit = {
        saved: action.payload.settings,
        current: action.payload.settings,
      };
    },
    settingsFieldUpdatedLocally: (state, action) => {
      if (!state.settingsForEdit.current) {
        state.settingsForEdit.current = {};
      }
      set(state.settingsForEdit.current, action.payload.key, action.payload.value);
    },
    settingsUpdated: (state, action) => {
      state.actionsLoading = false;
      state.settingsForEdit = {
        saved: action.payload.settings,
        current: action.payload.settings,
      };
    },
  },
});
