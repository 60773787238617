/* eslint-disable no-unused-vars */
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { isEqual, isFunction } from "lodash-es";
import { initialFilter } from "./UserFilesUIHelper";

const UserFilesUIContext = createContext();

export function useUserFilesUIContext() {
  return useContext(UserFilesUIContext);
}

export const UserFilesUIConsumer = UserFilesUIContext.Consumer;

export function UserFilesUIProvider({ currentUserId, children, readOnly, canUpload, userTypes }) {
  const [userId, setUserId] = useState(currentUserId);
  const [newFile, setNewFile] = useState(false);
  const [filters, setFiltersBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const filterList = [
    { value: "friendlyName", label: "COMMON.NAME" },
    { value: "fileType", label: "COMMON.TYPE" },
    { value: "uploadedAt", label: "COMMON.UPDATED.AT" },
  ];
  const setFilters = useCallback((nextQueryParams) => {
    setFiltersBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);
  const [selectedId, setSelectedId] = useState(null);
  const [isUploadingFile, setUploadingFile] = useState(false);
  const [showEditFileDialog, setShowEditFileDialog] = useState(false);
  const initFile = {
    id: undefined,
    friendlyName: "",
    fileType: "OTHER",
    userId,
    userType: userTypes?.[0],
    visible: true,
    isConfidential: true,
    selectedForFinance: true,
  };
  useEffect(() => {
    initFile.userId = currentUserId;
    setUserId(currentUserId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId]);

  const openNewFileDialog = () => {
    setSelectedId(undefined);
    setUploadingFile(true);
    setShowEditFileDialog(true);
  };
  const openEditFileDialog = (id) => {
    setSelectedId(id);
    setUploadingFile(false);
    setShowEditFileDialog(true);
  };
  const openRequestFileDialog = () => {
    setSelectedId(undefined);
    setUploadingFile(false);
    setShowEditFileDialog(true);
  };
  const openUploadFileDialog = (id) => {
    setSelectedId(id);
    setUploadingFile(true);
    setShowEditFileDialog(true);
  };
  const closeEditFileDialog = () => {
    setSelectedId(undefined);
    setShowEditFileDialog(false);
  };
  const [showRequestSignatureDialog, setShowRequestSignatureDialog] = useState(false);
  const openRequestSignatureDialog = (id) => {
    setSelectedId(id);
    setShowRequestSignatureDialog(true);
  };
  const closeRequestSignatureDialog = () => {
    setSelectedId(undefined);
    setShowRequestSignatureDialog(false);
  };
  const value = {
    ids,
    setIds,
    userId,
    setUserId,
    filters,
    setFilters,
    initFile,
    selectedId,
    newFile,
    isUploadingFile,
    showEditFileDialog,
    openNewFileDialog,
    openRequestSignatureDialog,
    closeRequestSignatureDialog,
    showRequestSignatureDialog,
    openEditFileDialog,
    openRequestFileDialog,
    openUploadFileDialog,
    closeEditFileDialog,
    readOnly,
    canUpload,
    userTypes,
    filterList,
  };

  return <UserFilesUIContext.Provider value={value}>{children}</UserFilesUIContext.Provider>;
}
