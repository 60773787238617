import { FormattedMessage, IntlShape } from "react-intl";

import { DateUtils } from "app/_utils/DateUtils";

export const UploadedDateTimeColumnFormatter = (
  cellContent: string,
  row: { userId: string; status: string; signatures: any },
  rowIndex: number,
  extraParams: {
    openUploadFileDialog: Function;
    readOnly: boolean;
    userId: string;
    intl: IntlShape;
  }
) => {
  switch (row?.status) {
    case "REQUESTED_BY_CLIENT":
      return (
        <span>
          <FormattedMessage id="FILE.TITLE.UPLOAD_COMPLETION" />
        </span>
      );
    case "REQUESTED":
      return (
        <span onClick={(e) => e.stopPropagation()}>
          {row.userId !== extraParams.userId && extraParams.readOnly ? (
            <span>
              <FormattedMessage id="FILE.ACTION.UPLOAD_AWAITING" />
            </span>
          ) : (
            <button
              data-cy="button-file-upload-now"
              onClick={() => extraParams.openUploadFileDialog(row)}
              type={"button"}
              className={"btn btn-sm btn-primary"}
            >
              <FormattedMessage id="FILE.ACTION.UPLOAD_NOW" />
            </button>
          )}
        </span>
      );
    default:
      let content = "-";
      if (cellContent) {
        content = DateUtils.format(new Date(cellContent), extraParams.intl);
      }

      let signature: any;
      let signatureSigned: any;
      if (row.signatures) {
        signature = row.signatures
          .filter(
            (s: { assigneeId: string; status: string }) =>
              s.assigneeId === extraParams.userId && s.status !== "SIGNED"
          )
          .sort((s1: { createdAt: any }, s2: { createdAt: any }) => s2.createdAt - s1.createdAt);
        signatureSigned = row.signatures
          .filter((s: { signedByUserId: string; status: string }) => s.status === "SIGNED")
          .sort((s1: any, s2: any) => s2.createdAt - s1.createdAt);
      }
      return (
        <span>
          {content}
          {signature && signature.length > 0 && (
            <span className={"ml-4"} onClick={(e) => e.stopPropagation()}>
              <button
                onClick={() => window.open(`/sign/${signature[0].id}`, "_blank")}
                type={"button"}
                className={"btn btn-sm btn-primary"}
              >
                Sign now
              </button>
            </span>
          )}
          {signature && signatureSigned.length > 0 && (
            <div className={"italic"}>
              {extraParams.intl
                .formatMessage({ id: "COMMON.SIGNED.AT" })
                .replace(
                  "{date}",
                  DateUtils.format(
                    signatureSigned[signatureSigned.length - 1].signedDate,
                    extraParams.intl
                  )
                )}
            </div>
          )}
        </span>
      );
  }
};
