import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import { isEqual, isFunction } from "lodash-es";
import { initialFilters } from "./BudgetsUIHelpers";
import { BudgetLineColumns, IBudget } from "../../../data/schemas";

export interface IBudgetsUIContext extends IBudgetsUIEvents {
  filters: IBudgetFilters;
  setFilters: Dispatch<SetStateAction<IBudgetFilters>>;
  queryParams: Record<string, string>;
  setQueryParams: Dispatch<SetStateAction<Record<string, string>>>;
  ids: string[];
  setIds: Dispatch<SetStateAction<string[]>>;
  readOnly?: boolean;
  context?: string;
  columnWidth: Record<BudgetLineColumns, number>;
  setColumnWidth: Dispatch<SetStateAction<Record<BudgetLineColumns, number>>>;
  triggerExpandCategories: boolean;
  triggerCollapseCategories: boolean;
  expandCategories: () => void;
  collapseCategories: () => void;
  filterList: Object[];
}

export interface IBudgetsUIEvents {
  newBudgetButtonClick?: () => void;
  openBudgetPage?: (budget: IBudget) => void;
}

export interface IBudgetFilters {
  pageNumber: number;
  pageSize: number;
  filterSelected: Object[];
  freeText?: string;
}
const BudgetsUIContext = createContext<IBudgetsUIContext>({} as IBudgetsUIContext);

export function useBudgetsUIContext() {
  return useContext(BudgetsUIContext);
}

export const BudgetsUIConsumer = BudgetsUIContext.Consumer;

export interface BudgetsUIProviderProps {
  budgetsUIEvents?: IBudgetsUIEvents;
  queryParamsInit?: any;
  readOnly?: boolean;
  context?: string;
}

export const BudgetsUIProvider: FC<BudgetsUIProviderProps> = ({
  budgetsUIEvents,
  children,
  queryParamsInit,
  readOnly,
  context,
}) => {
  const [queryParams, setQueryParams] = useState<Record<string, string>>(queryParamsInit);
  const [filters, setFiltersBase] = useState<IBudgetFilters>(initialFilters);
  const [ids, setIds] = useState<string[]>([]);
  const filterList = [
    { value: "name", label: "COMMON.NAME" },
    { value: "budgetStatus", label: "COMMON.STATUS" },
  ];
  const setFilters = useCallback((nextFilters) => {
    setFiltersBase((prevFilters) => {
      if (isFunction(nextFilters)) {
        nextFilters = nextFilters(prevFilters);
      }

      if (isEqual(prevFilters, nextFilters)) {
        return prevFilters;
      }

      return nextFilters;
    });
  }, []);

  const [columnWidth, setColumnWidth] = useState<Record<BudgetLineColumns, number>>(
    {} as Record<BudgetLineColumns, number>
  );
  const [triggerExpandCategories, setTriggerExpandCategories] = useState(false);
  const [triggerCollapseCategories, setTriggerCollapseCategories] = useState(false);
  const expandCategories = () => {
    setTriggerExpandCategories(!triggerExpandCategories);
  };
  const collapseCategories = () => {
    setTriggerCollapseCategories(!triggerCollapseCategories);
  };

  const value: IBudgetsUIContext = {
    filters,
    setFilters,
    queryParams,
    setQueryParams,
    ids,
    setIds,
    newBudgetButtonClick: budgetsUIEvents?.newBudgetButtonClick,
    openBudgetPage: budgetsUIEvents?.openBudgetPage,
    readOnly,
    context,
    columnWidth,
    setColumnWidth,
    triggerExpandCategories,
    triggerCollapseCategories,
    expandCategories,
    collapseCategories,
    filterList,
  };

  return <BudgetsUIContext.Provider value={value}>{children}</BudgetsUIContext.Provider>;
};
