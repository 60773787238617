/*eslint no-useless-computed-key: "off"*/
/*eslint-env es6*/

import * as React from "react";
import objectPath from "object-path";
import { useIntl } from "react-intl";
import { Droppable } from "@react-forked/dnd";
import cn from "clsx";
import chroma from "chroma-js";

import { useHtmlClassService } from "_metronic/layout";

import { ITask } from "data/schemas";

import { lookupForValueFilterFn } from "app/_utils/searchUtils";

import { ILaneDefinition } from "app/_utils/listUtils";
import { ISelectedSort, TASK_ATTRIBUTES_TO_FILTER_WITH } from "app/_utils/tasks";

import { TaskItemProps } from "./TaskItem";
import { sortedTasksLaneItems, TaskLaneItem, TaskLaneItemProps } from "./TaskLaneItem";

import "./TasksLane.scss";

const DEFAULT_LANE_BG = "#fcfcfc";
const DARKEN_LANE_BG = chroma(DEFAULT_LANE_BG).darken(0.3).hex();

const grayChroma = chroma("#333333");
const LANE_ITEM_HEADER_TEXT_COLOR = grayChroma.brighten(0.85).hex();
const GANTT_CHART_ICON_COLOR = grayChroma.brighten(1.5).hex();

export interface TasksLaneProps {
  ind: number;
  hideLane: boolean;
  disableDrop?: boolean;
  laneDefinition: ILaneDefinition;
  filterText?: string;
  selectedSort: ISelectedSort;
  tasks: ITask[];
}

export const TasksLane: React.FunctionComponent<TasksLaneProps> = ({
  ind,
  hideLane,
  disableDrop = false,
  laneDefinition,
  filterText,
  selectedSort,
  tasks,
}) => {
  const intl = useIntl();
  const uiService = useHtmlClassService();

  const colorsThemeLightPrimary = objectPath.get(uiService.config, "js.colors.theme.light.primary");

  const [tasksToRender, setTasksToRender] = React.useState<TaskLaneItemProps[]>([]);

  React.useEffect(() => {
    if (tasks.length === 0) {
      setTasksToRender([]);
      return;
    }

    const laneId = laneDefinition.id;

    // build the initial data structure of the items to be rendered at the lane
    // with the same status of the lane id
    let taskLaneItems: TaskLaneItemProps[] = tasks.map((item) => {
      const { status, subtasks } = item;

      const renderItem = status === laneId;
      const subitems = subtasks
        .filter((subtask) => subtask.status === laneId)
        .map((item) => ({ item } as TaskItemProps));

      return {
        item,
        renderItem,
        subitems,
      };
    });

    const searchByText = (filterText && filterText.length > 0 && filterText.trim()) || "";

    taskLaneItems = sortedTasksLaneItems(taskLaneItems, selectedSort);

    // check the item (parent task) and also the subitems
    taskLaneItems = taskLaneItems.map((laneItem) => {
      if (laneItem.renderItem && searchByText.length > 0) {
        laneItem.renderItem = lookupForValueFilterFn(
          searchByText,
          TASK_ATTRIBUTES_TO_FILTER_WITH,
          intl
        )(laneItem.item);
      }

      laneItem.subitems = sortedTasksLaneItems(laneItem.subitems, selectedSort);
      if (searchByText.length > 0 && laneItem.subitems.length > 0) {
        laneItem.subitems = laneItem.subitems.filter(({ item }) =>
          lookupForValueFilterFn(searchByText, TASK_ATTRIBUTES_TO_FILTER_WITH, intl)(item)
        );
      }

      return laneItem;
    });

    // cleanup the array
    taskLaneItems = taskLaneItems.filter((item) => item.subitems.length > 0 || item.renderItem);

    // define the itemIndex (needed by the react-dnd draggable component)
    // and that index needs to be a sequential number
    let indexValue = 0;
    taskLaneItems = taskLaneItems.map((item) => {
      item.itemIndex = item.renderItem ? indexValue++ : -1;

      const { subitems } = item;
      if (subitems.length > 0) {
        item.subitems = subitems.map((subitem) => {
          return {
            ...subitem,
            itemIndex: indexValue++,
          };
        });
      }

      return item;
    });

    setTasksToRender(taskLaneItems);
  }, [intl, laneDefinition, filterText, selectedSort, tasks]);

  return (
    <Droppable key={ind} droppableId={`${ind}`} isDropDisabled={disableDrop}>
      {(provided, snapshot) => (
        <div
          data-cy={`task-board-lane-${laneDefinition?.id}`}
          ref={provided.innerRef}
          className={cn("tasks-lane w-100 rounded border flex-grow-1", {
            "border-primary": hideLane,
          })}
          {...provided.droppableProps}
          style={
            {
              ["--taskItemOpacity"]: hideLane ? 0 : 1,
              ["--darkenLaneBg"]: DARKEN_LANE_BG,
              ["--laneItemHeaderTextColor"]: LANE_ITEM_HEADER_TEXT_COLOR,
              ["--iconGanttChartColor"]: GANTT_CHART_ICON_COLOR,
              background: snapshot.isDraggingOver ? colorsThemeLightPrimary : DEFAULT_LANE_BG,
            } as any
          }
        >
          {tasksToRender.map((item) => (
            <TaskLaneItem
              {...{
                key: item.item.id,
                disableDrop: !hideLane,
                ...item,
              }}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default TasksLane;
