import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash-es";

const initialProductsState = {
  listLoading: false,
  actionsLoading: false,
  entities: [],
  productForEdit: {
    saved: undefined,
    current: undefined,
  },
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const productsSlice = (name) =>
  createSlice({
    name: name,
    initialState: initialProductsState,
    reducers: {
      catchError: (state, action) => {
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else {
          state.actionsLoading = false;
        }
      },

      startCall: (state, action) => {
        state.error = null;
        if (action.payload.callType === callTypes.list) {
          state.listLoading = true;
        } else {
          state.actionsLoading = true;
        }
      },

      productFetched: (state, action) => {
        const { productForEdit } = action.payload;
        state.actionsLoading = false;
        state.productForEdit.saved = productForEdit;
        state.productForEdit.current = productForEdit;
        state.error = null;
      },

      productsFetched: (state, action) => {
        const { entities } = action.payload;
        state.listLoading = false;
        state.error = null;
        state.entities = entities;
        state.productForEdit = initialProductsState.productForEdit;
      },

      productCreated: (state, action) => {
        state.actionsLoading = false;
        state.error = null;
        state.entities.push(action.payload.product);
        state.productForEdit = {
          saved: action.payload.product,
          current: action.payload.product,
        };
      },

      productFieldUpdatedLocally: (state, action) => {
        state.error = null;
        state.actionsLoading = false;

        if (!state.productForEdit.current) {
          state.productForEdit.current = {};
        }

        const { key, value } = action.payload;

        set(state.productForEdit.current, key, value);
      },

      productUpdated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.productForEdit.saved = action.payload.product;
        state.entities = state.entities.map((entity) => {
          if (entity.id === action.payload.product.id) {
            return action.payload.product;
          }
          return entity;
        });
      },

      productDeleted: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.entities = state.entities.filter((el) => el.id !== action.payload.id);
      },

      fileCreated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.productForEdit.current.files.push(action.payload.file);
        state.productForEdit.saved.files.push(action.payload.file);
      },

      fileUpdated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        const fileIndex = state.productForEdit.current.files.findIndex(
          (file) => file.id === action.payload.file.id
        );
        state.productForEdit.current.files[fileIndex] = action.payload.file;
        state.productForEdit.saved.files[fileIndex] = action.payload.file;
      },

      fileDeleted: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.productForEdit.current.files = state.productForEdit.current.files.filter(
          (file) => file.id !== action.payload.file.id
        );
        state.productForEdit.saved.files = state.productForEdit.saved.files.filter(
          (file) => file.id !== action.payload.file.id
        );
      },

      photosFetched: (state, action) => {
        const photos = action.payload;
        state.error = null;
        state.actionsLoading = false;
        state.productForEdit.current.photos = photos;
        state.productForEdit.saved.photos = photos;
      },

      photoCreated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        const { newPhoto } = action.payload;

        state.productForEdit.current.photos.push(newPhoto);
        state.productForEdit.saved.photos.push(newPhoto);

        state.productForEdit.current.photosOrder.push(newPhoto.id);
        state.productForEdit.saved.photosOrder.push(newPhoto.id);
      },

      photoUpdated: (state, action) => {
        state.actionsLoading = false;
        state.error = null;
        const { photo: updatedPhoto } = action.payload;
        const photoIndex = state.productForEdit.current.photos.findIndex(
          (photo) => photo.id === updatedPhoto.id
        );
        updatedPhoto.localPhoto = state.productForEdit.current.photos[photoIndex].localPhoto;
        state.productForEdit.current.photos[photoIndex] = updatedPhoto;
        state.productForEdit.saved.photos[photoIndex] = updatedPhoto;
        state.entities = state.entities.map((product) => {
          if (product.id === action.payload.photos.productId) {
            return state.productForEdit.current;
          }
          return product;
        });
      },

      photoDeleted: (state, action) => {
        const { photoId } = action.payload;
        state.error = null;
        state.actionsLoading = false;
        state.productForEdit.current.photos = state.productForEdit.current?.photos?.filter(
          (photo) => photo.id !== photoId
        );
        state.productForEdit.saved.photos = state.productForEdit.saved?.photos?.filter(
          (photo) => photo.id !== photoId
        );
        state.productForEdit.current.photosOrder =
          state.productForEdit.current?.photosOrder?.filter((id) => id !== photoId);
        state.productForEdit.saved.photosOrder = state.productForEdit.saved?.photosOrder?.filter(
          (id) => id !== photoId
        );
      },

      photosOrderUpdate: (state, action) => {
        const { photosOrder } = action.payload;
        state.error = null;
        state.actionsLoading = false;
        state.productForEdit.current.photosOrder = photosOrder;
        state.productForEdit.saved.photosOrder = photosOrder;
      },

      auditTrailFetched: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        state.productForEdit.events = action.payload.events;
      },

      signatureRequestCreated: (state, action) => {
        state.error = null;
        state.actionsLoading = false;
        const currentFileIndex = state.productForEdit.current.files.findIndex(
          (file) => file.id === action.payload.signature.fileId
        );
        state.productForEdit.current.files[currentFileIndex].signatures.push(
          action.payload.signature
        );
        const savedFileIndex = state.productForEdit.current.files.findIndex(
          (file) => file.id === action.payload.signature.fileId
        );
        state.productForEdit.saved.files[savedFileIndex].signatures.push(action.payload.signature);
      },

      photoSharingCreated: (state, action) => {
        const photoSharing = action.payload.photoSharing;
        state.error = null;
        state.actionsLoading = false;
        const photoSharingId = photoSharing.photoId;

        const photosMap = (photo) => {
          if (photo.id === photoSharingId) {
            photo.photoSharings.push(photoSharing);
          }
          return photo;
        };

        state.productForEdit.current.photos = state.productForEdit.current?.photos?.map(photosMap);

        if (state.productForEdit.saved) {
          state.productForEdit.saved.photos = state.productForEdit.saved?.photos?.map(photosMap);
        }
      },

      photoSharingRemoved: (state, action) => {
        const photoSharing = action.payload.photoSharing;
        state.error = null;
        state.actionsLoading = false;
        state.productForEdit.current.photos = state.productForEdit.current.photos.map((photo) => {
          if (photo.id === photoSharing.photoId) {
            photo.photoSharings = photo.photoSharings.filter(
              (ps) => ps.userId !== photoSharing.userId
            );
          }
          return photo;
        });
        state.productForEdit.saved.photos = state.productForEdit.saved.photos.map((photo) => {
          if (photo.id === photoSharing.photoId) {
            photo.photoSharings = photo.photoSharings.filter(
              (ps) => ps.userId !== photoSharing.userId
            );
          }
          return photo;
        });
      },
    },
  });
