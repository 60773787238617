import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Input } from "../../_metronic/_partials/controls";
import { COUNTRY_CODES, LEAD_STATUS } from "./listUtils";

export function NumberInput(props) {
  const [value, setValue] = useState("");
  return (
    <NumberFormat
      isNumericString={true}
      thousandSeparator={true}
      value={value}
      onFocus={(event) => event.target.select()}
      onValueChange={(values) => setValue(values.formattedValue)}
      {...props}
    />
  );
}

const IBAN_CODE_LENGTHS = {
  AD: 24,
  AE: 23,
  AT: 20,
  AZ: 28,
  BA: 20,
  BE: 16,
  BG: 22,
  BH: 22,
  BR: 29,
  CH: 21,
  CY: 28,
  CZ: 24,
  DE: 22,
  DK: 18,
  DO: 28,
  EE: 20,
  ES: 24,
  FI: 18,
  FO: 18,
  FR: 27,
  GB: 22,
  GI: 23,
  GL: 18,
  GR: 27,
  GT: 28,
  HR: 21,
  HU: 28,
  IE: 22,
  IL: 23,
  IS: 26,
  IT: 27,
  JO: 30,
  KW: 30,
  KZ: 20,
  LB: 28,
  LI: 21,
  LT: 20,
  LU: 20,
  LV: 21,
  MC: 27,
  MD: 24,
  ME: 22,
  MK: 19,
  MR: 27,
  MT: 31,
  MU: 30,
  NL: 18,
  NO: 15,
  PK: 24,
  PL: 28,
  PS: 29,
  PT: 25,
  QA: 29,
  RO: 24,
  RS: 22,
  SA: 24,
  SE: 24,
  SI: 19,
  SK: 24,
  SM: 27,
  TN: 24,
  TR: 26,
  AL: 28,
  BY: 28,
  CR: 22,
  EG: 29,
  GE: 22,
  IQ: 23,
  LC: 32,
  SC: 31,
  ST: 25,
  SV: 28,
  TL: 23,
  UA: 29,
  VA: 22,
  VG: 24,
  XK: 20,
};
// IBAN Validator by MaVRoSCy https://stackoverflow.com/questions/21928083/iban-validation-check
/*
 * Returns 1 if the IBAN is valid
 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 */
export function isValidIBANNumber(input) {
  let iban = String(input)
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, ""), // keep only alphanumeric characters
    code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
    digits;
  // check syntax and length
  if (!code || iban.length !== IBAN_CODE_LENGTHS[code[1]]) {
    return false;
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55;
  });
  // final check
  return mod97(digits);
}

function mod97(str) {
  let checksum = str.slice(0, 2),
    fragment;
  for (let offset = 2; offset < str.length; offset += 7) {
    fragment = String(checksum) + str.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}

export const ValidationErrorsBIC = {
  NoBICProvided: "Bic is too short",
  NoBICCountry: "Bic country not present",
  WrongBICFormat: "Bic format is incorrect",
};

//https://github.com/Simplify/ibantools/blob/30dc212/src/IBANTools.ts#L416
export function isValidBIC(bic, intl) {
  if (bic !== undefined && bic !== null && bic !== "") {
    const spec = COUNTRY_CODES.find((shortCode) => shortCode === bic.toUpperCase().slice(4, 6));
    if (spec === undefined) {
      return ValidationErrorsBIC.NoBICCountry;
    } else {
      const reg = new RegExp("^[a-zA-Z]{6}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?$", "");
      if (!reg.test(bic)) {
        return ValidationErrorsBIC.WrongBICFormat;
      }
    }
  } else {
    return ValidationErrorsBIC.NoBICProvided;
  }
  return true;
}

export function formatIBAN(iban) {
  if (!iban) {
    return "";
  }

  const cleanIban = iban
    .replace(/\s\s+/g, " ")
    .replace(/[^0-9a-zA-Z]/gi, "")
    .toUpperCase();

  const parts = [];
  for (let i = 0; i < Math.ceil(cleanIban.length / 4); i++) {
    parts.push(cleanIban.substring(i * 4, i * 4 + 4));
  }
  return parts.join(" ");
}

export function IBANInput({ field, onChange, ...props }) {
  const [ibanCountry, setIbanCountry] = useState();
  const [ibanSize, setIbanSize] = useState();

  field.value = formatIBAN(field.value);

  useEffect(() => {
    if (field.value?.length > 2) {
      const countryCode = field.value.substring(0, 2).toUpperCase();
      if (countryCode !== ibanCountry) {
        setIbanCountry(countryCode);
        let ibanLength = IBAN_CODE_LENGTHS[countryCode];
        // Add spaces
        ibanLength += Math.ceil(ibanLength / 5);
        setIbanSize(ibanLength || 30);
      }
    }
  }, [field.value]);

  return (
    <Input
      maxLength={ibanSize}
      onChange={(e) => {
        e.target.value = formatIBAN(e.target.value);
        onChange(e);
      }}
      field={field}
      {...props}
    />
  );
}

export const selectedLeadStatus = (leadStatus) => {
  if (typeof leadStatus === "string") {
    return LEAD_STATUS[leadStatus];
  }
  let status = "-";
  if (leadStatus?.WON > 0) {
    status = "WON";
  } else if (leadStatus.OPEN > 0) {
    status = "OPEN";
  } else if (leadStatus.QUALIFIED > 0) {
    status = "QUALIFIED";
  }
  return LEAD_STATUS[status];
};
