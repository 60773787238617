import React, { useState } from "react";
import { ModalProgressBar } from "../../../../../../../_metronic/_partials/controls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import * as actions from "../../../../_redux/usersActions";
import { SMS } from "../../../../../../_utils/notificationTypes";

import { Switch } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";

export function SendAmlKycRequestModal({ user, actionsLoading, showModal, setShowModal }) {
  const intl = useIntl();
  const [sendSms, setSendSms] = useState(false);
  const [notifyMethod, setNotifyMethod] = useState(SMS);

  const { session } = useSelector(
    (state) => ({
      session: state.auth.session,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const sendRequest = () => {
    const { protocol, host, port } = window.location;

    const userActionParams = {
      createdByUserId: session.id,
      userId: user.id,
      actionType: "FILLOUT_AML_KYC_FORM",
      messageDestinationTypes: user?.isDisabled ? [notifyMethod] : sendSms ? ["SMS"] : [],
      url: [`${protocol}//${host}`].join(`${port}`),
    };
    dispatch(actions.createUserAction(userActionParams));
    setShowModal(false);
  };

  const smsSwitchChange = (e) => {
    setSendSms(e.target.checked);
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="user-request-info-modal"
      centered
      backdrop="static"
    >
      {actionsLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="request-kyc-aml-modal-title">
          <FormattedMessage id="USER.ACTION.REQUEST.FILLOUT.KYC_AML" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!actionsLoading && (
          <div className="form-group row">
            <div className="col-lg-12">
              {user.isDisabled ? (
                <>
                  <label className="my-auto mr-2">
                    <FormattedMessage id={"COMMON.ACTION.NOTIFY_USER_BY"} />
                  </label>
                  <select
                    data-cy="select-notify-method"
                    className="form-control form-control-solid w-auto px-3 py-2 mt-2 min-w-110px"
                    value={notifyMethod}
                    onChange={(e) => setNotifyMethod(e.target.value)}
                  >
                    {/* TODO: add email option in front and in back the email template (KYC) */}
                    <option value={SMS}>{intl.formatMessage({ id: "COMMON.SMS" })}</option>
                  </select>
                </>
              ) : (
                <>
                  {/* TODO: add switch for the email (KYC) */}
                  <Switch onChange={smsSwitchChange} checked={sendSms} data-cy="switch-send-sms" />
                  <FormattedMessage id="USER.ACTION.NOTIFY.SMS" />
                </>
              )}
            </div>
          </div>
        )}
        {actionsLoading && <span>Request is being sent...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setShowModal(false)}
            className="btn btn-light btn-elevate"
          >
            <FormattedMessage id="COMMON.ACTION.CANCEL" />
          </button>
          <> </>
          <button
            type="button"
            onClick={sendRequest}
            data-cy="button-user-action-confirm"
            className="btn btn-primary font-weight-bold"
          >
            <FormattedMessage id="COMMON.ACTION.REQUEST" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
