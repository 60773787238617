import { useCallback, useEffect, useState } from "react";
import { isEqual } from "lodash-es";
import { useDispatch } from "react-redux";

const defaultIgnoredProps = ["updatedAt", "updatedByUserId", "eventId"];

export const useAutoSave = (entityForEdit, originalEntity, actionToCall, ignoredProps = []) => {
  const [totalIgnoredProps, setTotalIgnoredProps] = useState(defaultIgnoredProps);
  const [updateTimeout, setUpdateTimeout] = useState();
  const action = useCallback((values) => actionToCall(values), [actionToCall]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (entityForEdit && entityForEdit.id && originalEntity) {
      const filteredEntityForEdit = removeProperties(entityForEdit, totalIgnoredProps);
      const filteredOriginalEntity = removeProperties(originalEntity, totalIgnoredProps);
      if (!isEqual(filteredEntityForEdit, filteredOriginalEntity)) {
        clearTimeout(updateTimeout);
        setUpdateTimeout(
          setTimeout(async () => {
            await dispatch(action(entityForEdit));
          }, 1000)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, entityForEdit, originalEntity, totalIgnoredProps]);

  useEffect(() => {
    setTotalIgnoredProps([...defaultIgnoredProps, ...ignoredProps]);
  }, []);
};

const removeProperties = (obj, ignoredProps) =>
  Object.keys(obj).reduce((accumulator, key) => {
    if (!ignoredProps.includes(key)) {
      accumulator[key] = obj[key];
    }
    return accumulator;
  }, {});
