import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/leads/leadsActions";
import { LeadFileEditDialogHeader } from "./LeadFileEditDialogHeader";
import { LeadFileEditForm } from "./LeadFileEditForm";
import { useLeadFilesUIContext } from "../LeadFilesUIContext";
import { useIntl } from "react-intl";

export function LeadFileEditDialog() {
  const leadFilesUIContext = useLeadFilesUIContext();
  const leadFilesUIProps = useMemo(() => {
    return {
      id: leadFilesUIContext.selectedId,
      setIds: leadFilesUIContext.setIds,
      queryParams: leadFilesUIContext.queryParams,
      showEditFileDialog: leadFilesUIContext.showEditFileDialog,
      closeEditFileDialog: leadFilesUIContext.closeEditFileDialog,
      initFile: leadFilesUIContext.initFile,
      newFile: leadFilesUIContext.newFile,
    };
  }, [leadFilesUIContext]);
  const intl = useIntl();
  const dispatch = useDispatch();
  const { actionsLoading, leadFileForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.leads.actionsLoading,
      leadFileForEdit: state.leads.leadForEdit?.current?.files?.find(
        (file) => file.id === leadFilesUIProps.id
      ),
    }),
    shallowEqual
  );

  useEffect(() => {
    // dispatch(actions.fetchFile(leadFilesUIProps.id));
  }, [leadFilesUIProps.id]);

  const saveFile = (leadFile) => {
    if (!leadFilesUIProps.id) {
      dispatch(actions.uploadFile(leadFile)).then(() => {
        leadFilesUIProps.setIds([]);
        leadFilesUIProps.closeEditFileDialog();
      });
    } else {
      leadFile.language = intl.locale;
      dispatch(actions.updateFile(leadFile)).then(() => {
        leadFilesUIProps.setIds([]);
        leadFilesUIProps.closeEditFileDialog();
      });
    }
  };

  return (
    <Modal
      show={leadFilesUIProps.showEditFileDialog}
      onHide={leadFilesUIProps.closeEditFileDialog}
      centered
      scrollable
      backdrop="static"
      size={"lg"}
    >
      <LeadFileEditDialogHeader
        leadFileForEdit={leadFileForEdit}
        actionsLoading={actionsLoading}
        showEditFileDialog={leadFilesUIProps.showEditFileDialog}
      />
      <LeadFileEditForm
        saveFile={saveFile}
        actionsLoading={actionsLoading}
        file={leadFileForEdit || leadFilesUIProps.initFile}
        onHide={leadFilesUIProps.closeEditFileDialog}
        newFile={leadFilesUIProps.newFile}
      />
    </Modal>
  );
}
