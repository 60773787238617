import type { IntlShape } from "react-intl";

import {
  IUserRight,
  UserRoleType,
  TUserRoleTypes,
  UserEntityType,
  TUserEntityTypes,
  UserRightType,
  TUserRightTypes,
} from "data/schemas";

//----------------------------------------------------------------------------//

type TEntityDisabledFields = Record<TUserEntityTypes, TUserRightTypes[]>;

type TEntityRights = [TUserEntityTypes, TUserRightTypes[]];

type TPreDefinedRolesRights = Record<TUserRoleTypes, TEntityRights[]>;

//----------------------------------------------------------------------------//
// @begin: mapping configurations

/** map the disable fields for each entity */
const mapEntityDisabledFields: Omit<TEntityDisabledFields, "*"> = {
  PROJECT: ["HIDDEN_LINE"],

  BUDGET: [
    "SHARE",
    "ANALYSIS",
    "AUDIT_TRAIL",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "NOTE",
    "CONFIDENTIAL_DOCUMENT",
  ],

  PRODUCT: ["SHARE", "ANALYSIS", "HIDDEN_LINE"],

  USER: [
    "SHARE",
    "ANALYSIS",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "HIDDEN_LINE",
    "NOTE",
  ],

  CLIENT: ["SHARE", "ANALYSIS", "UPLOAD_PHOTO", "UPDATE_PHOTO", "DELETE_PHOTO", "HIDDEN_LINE"],

  SUBCONTRACTOR: [
    "SHARE",
    "ANALYSIS",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "HIDDEN_LINE",
  ],

  LEAD: ["SHARE", "ANALYSIS", "UPLOAD_PHOTO", "UPDATE_PHOTO", "DELETE_PHOTO", "HIDDEN_LINE"],

  TASK: [
    "SHARE",
    "ANALYSIS",
    "AUDIT_TRAIL",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "HIDDEN_LINE",
    "CONFIDENTIAL_DOCUMENT",
  ],

  CHAT: [
    "READ",
    "UPDATE",
    "DELETE",
    "SHARE",
    "ANALYSIS",
    "AUDIT_TRAIL",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "NOTE",
    "HIDDEN_LINE",
    "CONFIDENTIAL_DOCUMENT",
  ],

  USER_ACTION: [
    "READ",
    "UPDATE",
    "DELETE",
    "SHARE",
    "ANALYSIS",
    "AUDIT_TRAIL",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "NOTE",
    "HIDDEN_LINE",
    "CONFIDENTIAL_DOCUMENT",
  ],

  USER_RIGHTS: [
    "CREATE",
    "DELETE",
    "SHARE",
    "ANALYSIS",
    "AUDIT_TRAIL",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "NOTE",
    "HIDDEN_LINE",
    "CONFIDENTIAL_DOCUMENT",
  ],

  PROPCO: [
    "SHARE",
    "ANALYSIS",
    "AUDIT_TRAIL",
    "UPLOAD_PHOTO",
    "UPDATE_PHOTO",
    "DELETE_PHOTO",
    "NOTE",
    "HIDDEN_LINE",
    "CONFIDENTIAL_DOCUMENT",
  ],
};

//---

/** map the entities -> rights for each pre defined role */
const mapPreDefinedRoleEntityRights: TPreDefinedRolesRights = {
  ADMIN: [
    [
      UserEntityType.PROJECT,
      [
        "READ",
        "CREATE",
        "UPDATE",
        "AUDIT_TRAIL",
        "SHARE",
        "ANALYSIS",
        "NOTE",
        "UPLOAD_PHOTO",
        "UPDATE_PHOTO",
        "DELETE_PHOTO",
        "CONFIDENTIAL_DOCUMENT",
      ],
    ],

    [UserEntityType.BUDGET, ["READ", "CREATE", "UPDATE", "HIDDEN_LINE"]],

    [
      UserEntityType.PRODUCT,
      [
        "READ",
        "CREATE",
        "UPDATE",
        "AUDIT_TRAIL",
        "NOTE",
        "UPLOAD_PHOTO",
        "UPDATE_PHOTO",
        "DELETE_PHOTO",
        "CONFIDENTIAL_DOCUMENT",
      ],
    ],

    [UserEntityType.USER, ["READ", "CREATE", "UPDATE", "AUDIT_TRAIL", "CONFIDENTIAL_DOCUMENT"]],

    [
      UserEntityType.CLIENT,
      ["READ", "CREATE", "UPDATE", "AUDIT_TRAIL", "NOTE", "CONFIDENTIAL_DOCUMENT"],
    ],

    [
      UserEntityType.SUBCONTRACTOR,
      ["READ", "CREATE", "UPDATE", "AUDIT_TRAIL", "NOTE", "CONFIDENTIAL_DOCUMENT"],
    ],

    [
      UserEntityType.LEAD,
      ["READ", "CREATE", "UPDATE", "AUDIT_TRAIL", "NOTE", "CONFIDENTIAL_DOCUMENT"],
    ],

    [UserEntityType.TASK, ["READ", "CREATE", "UPDATE", "DELETE", "NOTE"]],

    [UserEntityType.CHAT, ["CREATE"]],

    [UserEntityType.USER_ACTION, ["CREATE"]],

    [UserEntityType.USER_RIGHTS, ["CREATE"]],

    [UserEntityType.PROPCO, ["READ", "CREATE", "UPDATE", "DELETE"]],
  ],

  COMPLIANCE: [
    [UserEntityType.PROJECT, ["READ", "AUDIT_TRAIL", "ANALYSIS", "NOTE", "CONFIDENTIAL_DOCUMENT"]],

    [UserEntityType.BUDGET, ["READ", "HIDDEN_LINE"]],

    [UserEntityType.PRODUCT, ["READ", "AUDIT_TRAIL", "NOTE", "CONFIDENTIAL_DOCUMENT"]],

    [UserEntityType.USER, ["READ", "AUDIT_TRAIL", "CONFIDENTIAL_DOCUMENT"]],

    [UserEntityType.CLIENT, ["READ", "AUDIT_TRAIL", "NOTE", "CONFIDENTIAL_DOCUMENT"]],

    [UserEntityType.SUBCONTRACTOR, ["READ", "AUDIT_TRAIL", "NOTE", "CONFIDENTIAL_DOCUMENT"]],

    [UserEntityType.LEAD, ["READ", "AUDIT_TRAIL", "NOTE", "CONFIDENTIAL_DOCUMENT"]],

    [UserEntityType.TASK, ["READ", "NOTE"]],

    [UserEntityType.USER_RIGHTS, ["READ"]],
  ],

  CONTENT_MANAGER: [
    [UserEntityType.PROJECT, ["READ", "UPDATE", "NOTE", "UPLOAD_PHOTO", "UPDATE_PHOTO"]],

    [UserEntityType.PRODUCT, ["READ", "UPDATE", "NOTE", "UPLOAD_PHOTO", "UPDATE_PHOTO"]],

    [UserEntityType.USER, ["READ", "UPDATE"]],

    [UserEntityType.CLIENT, ["READ", "UPDATE", "NOTE"]],

    [UserEntityType.SUBCONTRACTOR, ["READ", "UPDATE", "NOTE"]],

    [UserEntityType.LEAD, ["READ", "UPDATE", "NOTE"]],

    [UserEntityType.TASK, ["READ", "NOTE"]],

    [UserEntityType.USER_RIGHTS, ["READ"]],
  ],

  CONTRIBUTOR: [
    [
      UserEntityType.PROJECT,
      ["READ", "CREATE", "UPDATE", "SHARE", "NOTE", "UPLOAD_PHOTO", "UPDATE_PHOTO", "DELETE_PHOTO"],
    ],

    [
      UserEntityType.PRODUCT,
      ["READ", "CREATE", "UPDATE", "NOTE", "UPLOAD_PHOTO", "UPDATE_PHOTO", "DELETE_PHOTO"],
    ],

    [UserEntityType.USER, ["READ", "CREATE", "UPDATE"]],

    [UserEntityType.CLIENT, ["READ", "CREATE", "UPDATE", "NOTE"]],

    [UserEntityType.SUBCONTRACTOR, ["READ", "CREATE", "UPDATE", "NOTE"]],

    [UserEntityType.LEAD, ["READ", "CREATE", "UPDATE", "NOTE"]],

    [UserEntityType.TASK, ["READ", "CREATE", "UPDATE", "NOTE"]],

    [UserEntityType.CHAT, ["CREATE"]],

    [UserEntityType.USER_ACTION, ["CREATE", "READ"]],
  ],

  FINANCIAL_CONTROLLER: [
    [UserEntityType.PROJECT, ["READ", "UPDATE", "ANALYSIS"]],

    [UserEntityType.BUDGET, ["READ", "CREATE", "UPDATE", "DELETE", "HIDDEN_LINE"]],

    [UserEntityType.PRODUCT, ["READ", "UPDATE", "CONFIDENTIAL_DOCUMENT"]],

    [UserEntityType.USER, ["READ", "UPDATE", "CONFIDENTIAL_DOCUMENT"]],

    [UserEntityType.CLIENT, ["READ", "UPDATE", "CONFIDENTIAL_DOCUMENT"]],

    [UserEntityType.SUBCONTRACTOR, ["READ", "UPDATE", "CONFIDENTIAL_DOCUMENT"]],

    [UserEntityType.LEAD, ["READ", "UPDATE", "CONFIDENTIAL_DOCUMENT"]],

    [UserEntityType.TASK, ["READ", "CREATE", "UPDATE", "DELETE", "NOTE"]],

    [UserEntityType.CHAT, ["CREATE"]],
  ],

  MASTER: [["*", ["*"]]],

  PROJECT_MANAGER: [
    [
      UserEntityType.PROJECT,
      [
        "READ",
        "CREATE",
        "UPDATE",
        "SHARE",
        "NOTE",
        "UPLOAD_PHOTO",
        "UPDATE_PHOTO",
        "DELETE_PHOTO",
        "CONFIDENTIAL_DOCUMENT",
      ],
    ],

    [UserEntityType.BUDGET, ["READ"]],

    [
      UserEntityType.PRODUCT,
      [
        "READ",
        "CREATE",
        "UPDATE",
        "NOTE",
        "UPLOAD_PHOTO",
        "UPDATE_PHOTO",
        "DELETE_PHOTO",
        "CONFIDENTIAL_DOCUMENT",
      ],
    ],

    [UserEntityType.USER, ["READ", "CREATE", "UPDATE", "CONFIDENTIAL_DOCUMENT"]],

    [UserEntityType.CLIENT, ["READ", "CREATE", "UPDATE", "NOTE", "CONFIDENTIAL_DOCUMENT"]],

    [UserEntityType.SUBCONTRACTOR, ["READ", "CREATE", "UPDATE", "NOTE", "CONFIDENTIAL_DOCUMENT"]],

    [
      UserEntityType.LEAD,
      ["READ", "CREATE", "UPDATE", "AUDIT_TRAIL", "NOTE", "CONFIDENTIAL_DOCUMENT"],
    ],

    [UserEntityType.TASK, ["READ", "CREATE", "UPDATE", "DELETE", "NOTE"]],

    [UserEntityType.CHAT, ["CREATE"]],

    [UserEntityType.USER_ACTION, ["CREATE", "READ", "UPDATE"]],
  ],

  PROJECT_RESELLER: [
    [UserEntityType.CLIENT, ["CREATE", "UPDATE"]],

    [UserEntityType.LEAD, ["CREATE", "UPDATE"]],
  ],

  SALES_MANAGER: [
    [UserEntityType.PROJECT, ["READ", "NOTE", "UPLOAD_PHOTO", "UPDATE_PHOTO"]],

    [UserEntityType.PRODUCT, ["READ", "NOTE", "UPLOAD_PHOTO", "UPDATE_PHOTO"]],

    [UserEntityType.USER, ["READ"]],

    [UserEntityType.CLIENT, ["READ", "CREATE", "UPDATE", "NOTE"]],

    [UserEntityType.SUBCONTRACTOR, ["READ"]],

    [UserEntityType.LEAD, ["READ", "CREATE", "UPDATE", "NOTE"]],

    [UserEntityType.TASK, ["READ", "NOTE"]],

    [UserEntityType.USER_ACTION, ["CREATE", "READ"]],
  ],

  VIEWER: [
    [UserEntityType.PROJECT, ["READ", "NOTE"]],

    [UserEntityType.BUDGET, ["READ"]],

    [UserEntityType.PRODUCT, ["READ", "NOTE"]],

    [UserEntityType.USER, ["READ"]],

    [UserEntityType.CLIENT, ["READ", "NOTE"]],

    [UserEntityType.SUBCONTRACTOR, ["READ", "NOTE"]],

    [UserEntityType.LEAD, ["READ", "NOTE"]],

    [UserEntityType.TASK, ["READ", "NOTE"]],

    [UserEntityType.USER_RIGHTS, ["READ"]],
  ],

  NONE: [],

  CUSTOM: [],
};

// @end: mapping configurations
//----------------------------------------------------------------------------//
//  @begin: RIGHTS LISTS

export const CRUD_RIGHTS_LIST: TUserRightTypes[] = ["READ", "CREATE", "UPDATE", "DELETE"];

export const ADDITIONAL_RIGHTS_LIST: TUserRightTypes[] = [
  "AUDIT_TRAIL",
  "SHARE",
  "ANALYSIS",
  "HIDDEN_LINE",
  "NOTE",
  "UPLOAD_PHOTO",
  "UPDATE_PHOTO",
  "DELETE_PHOTO",
  "CONFIDENTIAL_DOCUMENT",
];

//  @end: RIGHTS LISTS
//----------------------------------------------------------------------------//

export const RIGHTS_ATTR = "rights";

export const RIGHT_USER_I18N_PREFIX_KEY = "RIGHT.USER";

export const ADDITIONAL_RIGHTS_LIST_I18N_LABEL_KEY = `${RIGHT_USER_I18N_PREFIX_KEY}.ADDITIONAL.RIGHT`;

//----------------------------------------------------------------------------//

type TEntityType = keyof Omit<TEntityDisabledFields, "*">;

export interface IRightItem {
  label: string;
  entityType: TEntityType;
  disabledFields: TUserRightTypes[];
}

export interface IPreDefinedRole {
  label: string;
  value: string;
  rights: IUserRight[];
}

//---

const getRoleI18NLabelKey = (roleName: TUserRoleTypes) => {
  if (roleName === UserRoleType.NONE) return "COMMON.NONE";
  return `ROLES.${roleName}`;
};

const buildPredefinedRoleRights = (roleKey: TUserRoleTypes) => {
  const rightsOptions: IUserRight[] = [];
  const entityRights = mapPreDefinedRoleEntityRights[roleKey] as TEntityRights[];

  for (const [entityType, rights] of entityRights) {
    for (const rightType of rights) {
      rightsOptions.push({ entityType, rightType });
    }
  }

  return rightsOptions;
};

//---
// @begin: build the objects on the expected shape

export const RIGHTS_LIST: IRightItem[] = Object.keys(UserEntityType).reduce((acc, key) => {
  if (key === "ALL") return acc;

  const entityType = key as TEntityType;
  const rightItem: IRightItem = {
    label: `${RIGHT_USER_I18N_PREFIX_KEY}.${entityType}`,
    entityType,
    disabledFields: mapEntityDisabledFields[entityType] || [],
  };

  acc.push(rightItem);

  return acc;
}, [] as IRightItem[]);

export const ALL_RIGHTS_KEYS = Object.keys(UserRightType).reduce((acc, key) => {
  if (key === "ALL") return acc;

  acc.push(key as UserRightType);

  return acc;
}, [] as UserRightType[]);

export const ALL_RIGHTS: IUserRight[] = RIGHTS_LIST.reduce(
  (result, right) =>
    result.concat(
      ALL_RIGHTS_KEYS.filter((r) => !right.disabledFields?.includes(r)).map((rightType) => ({
        entityType: right.entityType,
        rightType,
      }))
    ),
  [] as IUserRight[]
);

export const MASTER_ROLE_RIGHTS = buildPredefinedRoleRights(UserRoleType.MASTER);

export const PRE_DEFINED_ROLES: IPreDefinedRole[] = Object.keys(UserRoleType).reduce((acc, key) => {
  const roleKey = key as TUserRoleTypes;

  const rights =
    key === UserRoleType.MASTER ? MASTER_ROLE_RIGHTS : buildPredefinedRoleRights(roleKey);

  const role: IPreDefinedRole = {
    label: getRoleI18NLabelKey(roleKey),
    value: key === UserRoleType.CUSTOM ? "" : key,
    rights,
  };

  acc.push(role);

  return acc;
}, [] as IPreDefinedRole[]);

// @end: build the objects on the expected shape
//---

export const getTranslatedLists = (intl: IntlShape) => {
  const mapFunction = <T extends IRightItem | IPreDefinedRole>({ label: id, ...attrs }: T) =>
    ({
      label: intl.formatMessage({ id }),
      ...attrs,
    } as T);

  const rightsList = RIGHTS_LIST.map<IRightItem>(mapFunction);

  const preDefinedRoles = PRE_DEFINED_ROLES.map<IPreDefinedRole>(mapFunction);

  return { rightsList, preDefinedRoles };
};
