/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { ContentRoute } from "../../../../_metronic/layout";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { BRAND_URL } from "../../../../configuration";
import { KycAmlPage } from "./KycAmlPage";
import { PublicRequestsProvider } from "../contexts/PublicRequestsContext";

export const RequestsPage = () => (
  <PublicRequestsProvider>
    <div className="d-flex flex-column flex-root">
      {/*begin::Login*/}
      <div
        className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
        id="kt_login"
      >
        {/*begin::Content*/}
        <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
          {/* begin::Content body */}
          <div className="d-flex flex-column flex-column-fluid flex-center mb-10 mb-sm-20">
            <img
              src={`${BRAND_URL}/logo_dark.png`}
              alt="Logo"
              className="max-w-180px max-w-lg-200px max-h-120px mb-10 mb-sm-20"
            />

            <ContentRoute
              path="/requests/:requestId/kyc-aml"
              component={KycAmlPage}
              children={undefined}
              render={undefined}
            />
          </div>
          {/*end::Content body*/}

          {/* begin::Mobile footer */}
          <div className="d-flex flex-column-auto align-items-center mt-5">
            <div className="text-dark-75 d-flex">
              <span className="text-muted font-weight-bold mr-2">
                {new Date().getFullYear()} &copy;
              </span>
              {` `}
              <a
                href="https://www.kodehyve.com/"
                rel="noopener noreferrer"
                target="_blank"
                className="text-dark-75 text-hover-primary pr-3 font-weight-bold"
              >
                kodehyve
              </a>
            </div>
            <a
              href="https://y.at/%F0%9F%8E%AE%F0%9F%8C%95%F0%9F%90%9D"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link pl-3 pr-0"
            >
              🎮🌕🐝
            </a>
          </div>
          {/* end::Mobile footer */}
        </div>
        {/*end::Content*/}
      </div>
      {/*end::Login*/}
    </div>
  </PublicRequestsProvider>
);
