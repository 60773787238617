import React from "react";
import { ProgressBar } from "react-bootstrap";

export function ModalProgressBar({ variant = "success" }) {
  return (
    <ProgressBar
      variant={variant}
      animated
      now={100}
      style={{ width: "100%", borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
      data-cy={"progress-bar"}
    />
  );
}
