import * as React from "react";
import cn from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual } from "react-redux";
import { useDebounce } from "rooks";

import { useSubheader } from "_metronic/layout";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "_metronic/_partials/controls";

import { useAppDispatch, useAppSelector } from "redux/hooks";

import { CustomSelect } from "app/_components/CustomSelect";
import { Sorter } from "app/_components/Sorter";

import { defaultSelectedSort, ISelectedSort } from "app/_utils/tasks";
import { canCreate, canReadAll } from "app/_utils/authUtils";
import { TASK_SORTER_OPTION } from "app/_utils/listUtils";

import * as actions from "app/modules/PropertiesManagement/_redux/tasks/tasksActions";

import { useTasksUIContext } from "app/modules/PropertiesManagement/pages/tasks/TasksUIContext";

import { TasksCardContent, TasksContentViewMode, TasksSwitchContentView } from "./TasksCardContent";

export enum TasksCardDisplayMode {
  PAGE = "page",
  TAB = "tab",
}

export type TTasksCardDisplayMode = `${TasksCardDisplayMode}`;

export interface TasksCardProps {
  relatedTo?: string;
  selectedSort?: ISelectedSort;
  displayMode?: TTasksCardDisplayMode;
}

export const TasksCard: React.FunctionComponent<TasksCardProps> = ({
  relatedTo,
  selectedSort: externalSelectedSort = defaultSelectedSort,
  displayMode = TasksCardDisplayMode.PAGE,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [selectedSort, setSelectedSort] = React.useState<ISelectedSort>(externalSelectedSort);

  const [selectedProject, setSelectedProject] = React.useState("");

  const [filterText, setFilterText] = React.useState("");

  const { relatedToItemOptions } = useAppSelector((state) => ({
    relatedToItemOptions: state.tasks.relatedToItemOptions,
  }));

  const {
    contentView,
    toggleContentView = () => undefined,
    newTaskButtonClick,
  } = useTasksUIContext();

  const { groups, session } = useAppSelector(
    (state) => ({
      groups: state.auth.groups,
      session: state.auth.session,
    }),
    shallowEqual
  );

  const subheader = useSubheader();

  React.useEffect(() => {
    if (!displayMode) {
      subheader.setTitle(
        intl.formatMessage({
          id: "TASK.TITLE",
        })
      );
    }
  }, [subheader]);

  React.useEffect(() => {
    const hasSelectedProject = selectedProject && selectedProject.length > 0;
    dispatch(
      actions.fetchTasks({
        groups: groups,
        relatedTo: hasSelectedProject ? selectedProject : relatedTo,
        deeplyRelated: hasSelectedProject || relatedTo?.startsWith("PROJECT"),
      })
    );
  }, [selectedProject, relatedTo]);

  React.useEffect(() => {
    if (displayMode === TasksCardDisplayMode.TAB) return;
    //fetch options for project filter (only available in global tasks view)
    dispatch(actions.fetchRelatedToItems(intl));
  }, []);

  const [relatedToOptionsLoading, setRelatedToOptionsLoading] = React.useState(false);
  const openRelatedToSelect = () => {
    setRelatedToOptionsLoading(true);
    actions
      .fetchRelatedToItems(intl)(dispatch)
      .then(() => setRelatedToOptionsLoading(false));
  };

  const setFilterTextDebounced = useDebounce((searchText) => setFilterText(searchText), 300);

  const isTab = displayMode === TasksCardDisplayMode.TAB;

  const customOption = {
    id: "custom",
    label: filterText,
  };
  const searchOptions = [
    customOption,
    ...relatedToItemOptions.filter((o: any) => o.id === "projects"),
  ];

  const cardStyles: React.CSSProperties = {
    ...(isTab ? { boxShadow: "none", minHeight: "60vh" } : {}),
  };

  return (
    <Card className={"h-100"} style={cardStyles}>
      {!isTab && (
        <CardHeader
          className={"pt-4"}
          title={intl.formatMessage({
            id: "TASK.TITLE.DASHBOARD",
          })}
        >
          <CardHeaderToolbar className={"w-100 pb-3 pt-0"}>
            <div className={"d-md-flex w-100 justify-content-md-between"}>
              <div className={"mx-md-2 my-4 my-md-0 w-100"}>
                <CustomSelect
                  placeholder={`${intl.formatMessage({
                    id: "COMMON.SEARCH.TITLE.EXTENDED",
                  })}`}
                  styles={{ control: { width: "100%", maxWidth: "100%", minHeight: "40px" } }}
                  noOptionsMessage={() => null}
                  disabled={Boolean(relatedTo)}
                  options={searchOptions}
                  clearable={true}
                  onFocus={openRelatedToSelect}
                  openMenuOnClick={false}
                  onInputChange={(newValue, actionMeta) => {
                    if (actionMeta.action !== "input-change") return;
                    setFilterTextDebounced(newValue);
                  }}
                  onChange={(selected: any) => {
                    if (selected === null) {
                      setFilterText("");
                      setSelectedProject("");
                      return;
                    }

                    const id = selected?.value?.id;
                    if (!id) return;
                    setSelectedProject(`${selected.type}#${id}`);
                    setFilterText("");
                  }}
                  isLoading={relatedToOptionsLoading}
                  loadingMessage={() => intl.formatMessage({ id: "COMMON.LOADING" })}
                  getOptionValue={(o: any) => o.value?.id}
                  defaultValue={undefined}
                  selected={filterText && !selectedProject ? customOption : undefined}
                />
              </div>
              <div className={"mx-md-2 my-4 my-md-0 w-100 w-md-auto min-w-md-225px"}>
                <Sorter
                  disabled={contentView === TasksContentViewMode.GANTT_CHART}
                  selectedSort={selectedSort}
                  setSelectedSort={setSelectedSort}
                  option={TASK_SORTER_OPTION}
                  style={{ width: "100%", maxWidth: "100%" }}
                  parentStyle={{ width: "100%", maxWidth: "100%" }}
                />
              </div>

              <TasksSwitchContentView
                {...{
                  mode: contentView,
                  onChange: toggleContentView,
                }}
              />

              {canCreate(groups, session, "TASK") && canReadAll(groups, session, "TASK") && (
                <div className={"mx-md-2"}>
                  <button
                    type="button"
                    data-cy="button-tasks-new"
                    className="btn btn-primary text-nowrap w-100 w-md-auto"
                    onClick={newTaskButtonClick}
                  >
                    <FormattedMessage id="TASK.ACTION.NEW" />
                  </button>
                </div>
              )}
            </div>
          </CardHeaderToolbar>
        </CardHeader>
      )}

      <div className={"position-relative flex-grow-1"}>
        <CardBody
          className={cn("w-100 h-100 position-absolute d-flex flex-column", {
            "p-0": isTab,
          })}
        >
          <TasksCardContent
            {...{
              isTab,
              filterText,
              contentMode: contentView,
              selectedSort: isTab ? externalSelectedSort : selectedSort,
            }}
          />
        </CardBody>
      </div>
    </Card>
  );
};

export default TasksCard;
