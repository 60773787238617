import React, { createContext, useCallback, useContext, useState } from "react";
import { isEqual, isFunction } from "lodash-es";
import { initialFilters } from "./LeadsUIHelpers";

const LeadsUIContext = createContext();

export function useLeadsUIContext() {
  return useContext(LeadsUIContext);
}

export const LeadsUIConsumer = LeadsUIContext.Consumer;

export function LeadsUIProvider({ leadsUIEvents, children, queryParamsInit, readOnly, context }) {
  const [queryParams, setQueryParams] = useState(queryParamsInit);
  const [filters, setFiltersBase] = useState(initialFilters);
  const [ids, setIds] = useState([]);
  const filterList = [
    { value: "name", label: "COMMON.NAME" },
    { value: "status", label: "ADDRESS.CITY" },
    { value: "createdAt", label: "COMMON.CREATED.AT" },
    { value: "createdByUserId", label: "COMMON.CREATED.BY" },
  ];
  const setFilters = useCallback((nextFilters) => {
    setFiltersBase((prevFilters) => {
      if (isFunction(nextFilters)) {
        nextFilters = nextFilters(prevFilters);
      }

      if (isEqual(prevFilters, nextFilters)) {
        return prevFilters;
      }

      return nextFilters;
    });
  }, []);
  const value = {
    queryParams,
    setQueryParams,
    ids,
    setIds,
    filters,
    setFilters,
    newLeadButtonClick: leadsUIEvents?.newLeadButtonClick,
    openLeadPage: leadsUIEvents?.openLeadPage,
    openDeleteLeadDialog: leadsUIEvents?.openDeleteLeadDialog,
    openDeleteLeadsDialog: leadsUIEvents?.openDeleteLeadsDialog,
    openFetchLeadsDialog: leadsUIEvents?.openFetchLeadsDialog,
    readOnly,
    context,
    filterList,
  };

  return <LeadsUIContext.Provider value={value}>{children}</LeadsUIContext.Provider>;
}
