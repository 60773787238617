import React, { useState } from "react";

import * as taskActions from "../../modules/PropertiesManagement/_redux/tasks/tasksActions";

import TimeAgo from "react-timeago";
import { getTimeAgoStringForIntl, TIME_MODES } from "app/_utils/tasks";
import { FormattedMessage, useIntl } from "react-intl";
import { CommentItemEdit } from "./CommentItemEdit";
import { useEntityDeleteDialogContext } from "../../modules/PropertiesManagement/pages/entity-delete-dialog/EntityDeleteDialogContext";
import { formatDisplayNameIntl } from "../../_utils/userUtils";
import { isAdmin } from "../../_utils/authUtils";
import { ProfilePictureThumbnail } from "../ProfilePictureThumbnail";

export const CommentItem = ({ comment, task, session, groups }) => {
  const intl = useIntl();

  const [mode, setMode] = useState("read");

  const { setDeleteEntityDialog } = useEntityDeleteDialogContext();

  const deleteComment = (e, comment) => {
    setDeleteEntityDialog({
      action: { fn: taskActions.deleteComment, props: comment },
      entityType: "COMMENT",
    });
  };

  const { createdByUser, createdAt, content, createdByUserId } = comment;
  return (
    <div className="form-group row" data-cy="comment-item">
      <div className="col-sm-2 col-md-2 col-lg-1">
        <ProfilePictureThumbnail user={createdByUser} />
      </div>
      <div className="col-sm-10 col-md-10 col-lg-11 pl-0">
        <div className="mb-4">
          <span className="font-weight-bold">
            {formatDisplayNameIntl(intl, createdByUser, false)}
          </span>
          {"  "}
          <TimeAgo
            className="text-muted"
            date={createdAt}
            formatter={(value, unit, suffix) => {
              return getTimeAgoStringForIntl(undefined, value, unit, intl, TIME_MODES.NOTIFICATION);
            }}
          />
        </div>
        {mode === "edit" ? (
          <CommentItemEdit comment={comment} setMode={setMode} task={task} />
        ) : (
          <div>
            <span
              className="mt-2"
              style={{ whiteSpace: "pre-wrap", overflowWrap: "anywhere" }}
              data-cy="text-comment-item"
              dangerouslySetInnerHTML={{ __html: content }}
            />
            {((session.id && createdByUserId === session.id) || isAdmin(groups, session)) && (
              <span>
                <div className="text-muted text-left mt-4">
                  <button
                    data-cy="button-task-comment-edit"
                    className="btn btn-text p-0 text-primary font-weight-bold"
                    onClick={() => setMode("edit")}
                  >
                    <FormattedMessage id="COMMON.ACTION.EDIT" />
                  </button>
                  {" · "}
                  <button
                    data-cy="button-task-comment-delete"
                    className="btn btn-text p-0 text-primary font-weight-bold"
                    onClick={(e) => deleteComment(e, comment)}
                  >
                    <FormattedMessage id="COMMON.ACTION.DELETE" />
                  </button>
                </div>
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
