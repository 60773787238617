import React from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export function SignatureInformationModal({ show, onHide, validateSignatureModal }) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="signature-information-modal"
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id={"SIGN.VALIDATE_IDENTITY.TITLE"} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage id={"SIGN.VALIDATE_IDENTITY"} />
      </Modal.Body>
      <Modal.Footer>
        <div className={"text-right"}>
          <button
            data-cy="button-signature-send-otp"
            type="button"
            className="btn btn-primary"
            onClick={validateSignatureModal}
          >
            <FormattedMessage id={"SIGN.SEND_CODE"} />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
