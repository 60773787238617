/* eslint-disable no-unused-vars */
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { isEqual, isFunction } from "lodash-es";
import { initialFilter } from "./LeadFilesUIHelper";

const LeadFilesUIContext = createContext();

export function useLeadFilesUIContext() {
  return useContext(LeadFilesUIContext);
}

export const LeadFilesUIConsumer = LeadFilesUIContext.Consumer;

export function LeadFilesUIProvider({ currentLeadId, children, readOnly }) {
  const [leadId, setLeadId] = useState(currentLeadId);
  const [filters, setFiltersBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const filterList = [
    { value: "friendlyName", label: "COMMON.NAME" },
    { value: "fileType", label: "COMMON.TYPE" },
    { value: "uploadedAt", label: "COMMON.UPDATED.AT" },
  ];
  const setFilters = useCallback((nextQueryParams) => {
    setFiltersBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);
  const [selectedId, setSelectedId] = useState(null);
  const [newFile, setNewFile] = useState(false);
  const [showEditFileDialog, setShowEditFileDialog] = useState(false);

  const initFile = {
    id: undefined,
    friendlyName: "",
    fileType: "-",
    leadId,
    isConfidential: true,
  };
  useEffect(() => {
    initFile.leadId = currentLeadId;
    setLeadId(currentLeadId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLeadId]);
  const openNewFileDialog = () => {
    setSelectedId(undefined);
    setNewFile(true);
    setShowEditFileDialog(true);
  };
  const openEditFileDialog = (id) => {
    setSelectedId(id);
    setNewFile(false);
    setShowEditFileDialog(true);
  };
  const closeEditFileDialog = () => {
    setSelectedId(undefined);
    setShowEditFileDialog(false);
  };
  const [showInvoiceDialog, setShowInvoiceDialog] = useState(false);
  const openNewInvoiceDialog = () => {
    setSelectedId(undefined);
    setNewFile(true);
    setShowInvoiceDialog(true);
  };
  const openEditInvoiceDialog = (id) => {
    setSelectedId(id);
    setNewFile(false);
    setShowInvoiceDialog(true);
  };
  const closeInvoiceFileDialog = () => {
    setSelectedId(undefined);
    setShowInvoiceDialog(false);
  };
  const [showRequestSignatureDialog, setShowRequestSignatureDialog] = useState(false);

  const openRequestSignatureDialog = (id) => {
    setSelectedId(id);
    setShowRequestSignatureDialog(true);
  };

  const closeRequestSignatureDialog = () => {
    setSelectedId(undefined);
    setShowRequestSignatureDialog(false);
  };

  const value = {
    ids,
    setIds,
    leadId,
    setLeadId,
    filters,
    setFilters,
    initFile,
    selectedId,
    newFile,
    showEditFileDialog,
    openNewFileDialog,
    openRequestSignatureDialog,
    closeRequestSignatureDialog,
    showRequestSignatureDialog,
    openEditFileDialog,
    closeEditFileDialog,
    readOnly,
    openNewInvoiceDialog,
    openEditInvoiceDialog,
    showInvoiceDialog,
    closeInvoiceFileDialog,
    filterList,
  };

  return <LeadFilesUIContext.Provider value={value}>{children}</LeadFilesUIContext.Provider>;
}
