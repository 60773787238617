import { IntlShape } from "react-intl";

import { DateUtils } from "app/_utils/DateUtils";

export const DateTimeColumnFormatter = (
  cellContent: string,
  row: Object,
  rowIndex: number,
  extraParams: { intl: IntlShape; showTime: boolean }
) => {
  return (
    <span>{DateUtils.format(new Date(cellContent), extraParams.intl, extraParams.showTime)}</span>
  );
};
