/* eslint-disable no-unused-vars */
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { isEqual, isFunction } from "lodash-es";
import { initialFilter } from "./ProductFilesUIHelper";

const ProductFilesUIContext = createContext();

export function useProductFilesUIContext() {
  return useContext(ProductFilesUIContext);
}

export const ProductFilesUIConsumer = ProductFilesUIContext.Consumer;

export function ProductFilesUIProvider({ currentProductId, children, readOnly, context }) {
  const [productId, setProductId] = useState(currentProductId);
  const [filters, setFiltersBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const filterList = [
    { value: "friendlyName", label: "COMMON.NAME" },
    { value: "fileType", label: "COMMON.TYPE" },
    { value: "uploadedAt", label: "COMMON.UPDATED.AT" },
  ];
  const setFilters = useCallback((nextQueryParams) => {
    setFiltersBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);
  const [selectedId, setSelectedId] = useState(null);
  const [newFile, setNewFile] = useState(false);
  const [showEditFileDialog, setShowEditFileDialog] = useState(false);
  const initFile = {
    id: undefined,
    friendlyName: "",
    fileType: "OTHER",
    productId,
    isConfidential: true,
  };

  useEffect(() => {
    initFile.productId = currentProductId;
    setProductId(currentProductId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProductId]);

  const openNewFileDialog = () => {
    setSelectedId(undefined);
    setNewFile(true);
    setShowEditFileDialog(true);
  };

  const openEditFileDialog = (id) => {
    setSelectedId(id);
    setNewFile(false);
    setShowEditFileDialog(true);
  };

  const closeEditFileDialog = () => {
    setSelectedId(undefined);
    setShowEditFileDialog(false);
  };

  const [showRequestSignatureDialog, setShowRequestSignatureDialog] = useState(false);

  const openRequestSignatureDialog = (id) => {
    setSelectedId(id);
    setShowRequestSignatureDialog(true);
  };

  const closeRequestSignatureDialog = () => {
    setSelectedId(undefined);
    setShowRequestSignatureDialog(false);
  };

  const value = {
    ids,
    setIds,
    productId,
    setProductId,
    filters,
    setFilters,
    initFile,
    selectedId,
    newFile,
    showEditFileDialog,
    openNewFileDialog,
    openRequestSignatureDialog,
    closeRequestSignatureDialog,
    showRequestSignatureDialog,
    openEditFileDialog,
    closeEditFileDialog,
    readOnly,
    context,
    filterList,
  };

  return <ProductFilesUIContext.Provider value={value}>{children}</ProductFilesUIContext.Provider>;
}
