import { FormatDateOptions } from "@formatjs/intl/src/types";
import { IntlShape } from "react-intl";

export const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

export class DateUtils {
  static formatDateString(date: string, intl: IntlShape, showTime = true) {
    if (dateFormat.test(date)) {
      return DateUtils.format(new Date(date), intl, showTime);
    }
    return date;
  }

  static format(date: Date, intl: IntlShape, showTime = true) {
    if (!date) {
      return "-";
    }
    const config: FormatDateOptions = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    if (showTime) {
      config.hour = "numeric";
      config.minute = "numeric";
    }
    return intl.formatDate(date, config);
  }

  static datesAreOnSameDay = (first: Date, second: Date) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

  static diffAgeInYears(birthDate: Date) {
    birthDate = new Date(birthDate);
    const otherDate = new Date();

    let years = otherDate.getFullYear() - birthDate.getFullYear();

    if (
      otherDate.getMonth() < birthDate.getMonth() ||
      (otherDate.getMonth() === birthDate.getMonth() && otherDate.getDate() < birthDate.getDate())
    ) {
      years--;
    }

    return years;
  }

  static diffBetweenDates(startDate: Date, endDate: Date, intl?: IntlShape) {
    const diff = Math.abs(startDate.getTime() - endDate.getTime());
    const daysDiff = Math.round(diff / (1000 * 3600 * 24));
    return intl ? intl.formatMessage({ id: "TIMEAGO.LEFT.DAY" }, { time: daysDiff }) : daysDiff;
  }
}
