// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/products/productsActions";
import * as uiHelpers from "../ProductsUIHelpers";
import { NoRecordsFoundMessage, sortCaret } from "../../../../../../_metronic/_helpers";
import {
  ActionsColumnFormatter,
  DateTimeColumnFormatter,
  LeadCreationActionsColumnFormatter,
  PhotoColumnFormatter,
  StatusColumnFormatter,
} from "../../../../../_components/column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useProductsUIContext } from "../ProductsUIContext";
import { canDelete, canReadAll } from "../../../../../_utils/authUtils";
import { useIntl } from "react-intl";
import { useEntityDeleteDialogContext } from "../../entity-delete-dialog/EntityDeleteDialogContext";
import { searchUtils, selectField } from "../../../../../_utils/searchUtils";
import { formatCurrency } from "../../../../../_utils/mathUtils";
import { NumberInput, selectedLeadStatus } from "../../../../../_utils/formUtils";
import { DateUtils } from "../../../../../_utils/DateUtils";

export function ProductsTable() {
  const intl = useIntl();
  const SUFFIX_UNIT_AREA = intl.formatMessage({ id: "COMMON.AREA.UNIT" });

  const productsUIContext = useProductsUIContext();

  const productsUIProps = useMemo(() => {
    return {
      ids: productsUIContext.ids,
      setIds: productsUIContext.setIds,
      queryParams: productsUIContext.queryParams,
      filters: productsUIContext.filters,
      setFilters: productsUIContext.setFilters,
      openProductPage: productsUIContext.openProductPage,
      openCreateLeadPage: productsUIContext.openCreateLeadPage,
      status: productsUIContext.status,
      readOnly: productsUIContext.readOnly,
      context: productsUIContext.context,
      filterList: productsUIContext.filterList,
    };
  }, [productsUIContext]);

  const { currentState, userGroups, session, projectProducts } = useSelector(
    (state) => ({
      currentState: productsUIContext.context ? state[productsUIContext.context] : state.products,
      userGroups: state.auth.groups,
      session: state.auth.session,
      //this object is related to the scenario, where the user wants to see the products(=properties)
      // associated to a project by clicking on the properties tab inside the project detail view.
      //In that case, a query param (projectsId) is passed into this component. Here, ite is NOT required
      // to fetch products via API, since they already present in the project-object.
      // -> The following line basically extracts the products array from the project
      // object if "projectId" is present in query params
      projectProducts: productsUIProps.queryParams?.projectId
        ? state.projects?.projectForEdit?.current?.products
        : undefined,
    }),
    shallowEqual
  );
  const { entities, listLoading } = currentState;

  const dispatch = useDispatch();
  useEffect(() => {
    productsUIProps.setIds([]);

    if (projectProducts) {
      //no external call, products loaded from project object
      dispatch(
        actions.fetchProducts(
          productsUIProps.queryParams,
          projectProducts,
          productsUIContext.context,
          !!(productsUIProps?.status === "LEAD_CREATION")
        )
      );
    } else {
      //products data not available/preloaded. Fetch from API
      if (canReadAll(userGroups, session, "PRODUCT") || true) {
        // TODO: fix this if; testing purpose
        dispatch(
          actions.fetchProducts(
            productsUIProps.queryParams,
            projectProducts,
            productsUIContext.context,
            !!(productsUIProps?.status === "LEAD_CREATION")
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsUIProps.queryParams]);

  const { setDeleteEntityDialog } = useEntityDeleteDialogContext();
  const openDeleteProductDialog = (id) => {
    setDeleteEntityDialog({
      action: { fn: actions.deleteProduct, props: { context: productsUIProps.context, id } },
      entityType: "PRODUCT",
    });
  };

  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "",
      sort: false,
      formatter: PhotoColumnFormatter,
      formatExtraData: {
        icon: "fa fa-home text-info icon-md",
      },
    },
    {
      dataField: "name",
      text: intl.formatMessage({
        id: "COMMON.NAME",
      }),
      sort: true,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      sortCaret: sortCaret,
    },
    {
      dataField: "financialDetails.price",
      text: intl.formatMessage({
        id: "COMMON.PRICE",
      }),
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      formatter: (cell, row) => formatCurrency(cell ?? 0, 0, intl),
    },
    {
      dataField: "propertyDetails.livingSurface",
      text: intl.formatMessage({
        id: "PRODUCT.INFO.SURFACE",
      }),
      sort: true,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      sortCaret: sortCaret,
      formatter: (cell, row) => (
        <NumberInput value={cell} displayType="text" decimalScale={2} suffix={SUFFIX_UNIT_AREA} />
      ),
    },
    {
      dataField: "address.city",
      text: intl.formatMessage({
        id: "ADDRESS.CITY",
      }),
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "leadStatus",
      text: intl.formatMessage({
        id: "LEAD.TITLE.SINGLE",
      }),
      sort: true,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      sortCaret: sortCaret,
      formatter: StatusColumnFormatter,
      formatExtraData: {
        intl,
      },
      sortValue: (cell) => selectedLeadStatus(cell ?? {}).priority,
    },
    {
      dataField: "updatedAt",
      text: intl.formatMessage({
        id: "COMMON.UPDATED.AT",
      }),
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      formatter: DateTimeColumnFormatter,
      formatExtraData: { intl },
      sortValue: (cell) => DateUtils.format(new Date(cell), intl),
    },
    {
      dataField: "action",
      text: intl.formatMessage({
        id: "COMMON.ACTION",
      }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openDeleteDialog: openDeleteProductDialog,
        actionList: ["TRASH"],
        canDelete: (row) => {
          return canDelete(userGroups, session, "PRODUCT");
        },
        entityType: "PRODUCT",
      },
      classes: "text-right",
      headerClasses: "text-right",
      style: {
        minWidth: "100px",
      },
      hidden: productsUIContext.readOnly || !!productsUIProps.status,
    },
    {
      dataField: "action",
      text: intl.formatMessage({
        id: "COMMON.ACTION",
      }),
      formatter: LeadCreationActionsColumnFormatter,
      formatExtraData: {
        openCreateLeadPage: productsUIProps.openCreateLeadPage,
      },
      classes: "text-right",
      headerClasses: "text-right",
      style: {
        minWidth: "100px",
      },
      hidden: productsUIContext.readOnly || productsUIProps?.status !== "LEAD_CREATION",
    },
  ];

  const [entitiesFiltered, setEntitiesFiltered] = useState([]);

  useEffect(() => {
    setEntitiesFiltered(
      searchUtils(productsUIProps?.filters?.freeText, entities, selectField(productsUIProps), intl)
    );
  }, [entities, productsUIProps?.filters?.freeText, productsUIProps?.filters?.filterSelected]);

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: entitiesFiltered.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: productsUIProps.filters.pageSize,
    page: productsUIProps.filters.pageNumber,
  };
  return (
    <>
      <div data-cy="table-list">
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <Pagination isLoading={listLoading} paginationProps={paginationProps}>
                <BootstrapTable
                  id="products-table"
                  wrapperClasses="table-responsive"
                  classes="table table-head-custom table-vertical-center"
                  bootstrap4
                  bordered={false}
                  keyField="id"
                  data={entitiesFiltered || []}
                  columns={columns}
                  defaultSorted={uiHelpers.defaultSorted}
                  noDataIndication={() => <NoRecordsFoundMessage entities={entitiesFiltered} />}
                  // selectRow={getSelectRow({
                  //   entities,
                  //   ids: productsUIProps.ids,
                  //   setIds: productsUIProps.setIds,
                  // })}
                  hover
                  rowEvents={{
                    onClick: (e, row, rowIndex) => {
                      if (
                        !productsUIProps.readOnly &&
                        productsUIProps?.status === "LEAD_CREATION"
                      ) {
                        productsUIProps.openCreateLeadPage(row.id);
                      } else {
                        if (canReadAll(userGroups, session, "PRODUCT")) {
                          productsUIProps.openProductPage(row);
                        }
                      }
                    },
                  }}
                  rowStyle={{ cursor: "pointer" }}
                  rowClasses={"product-row"}
                  {...paginationTableProps}
                />
              </Pagination>
            );
          }}
        </PaginationProvider>
      </div>
    </>
  );
}
