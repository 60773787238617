import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export const DocumentTypeColumnFormatter = (cellContent, row) => {
  function getIconFilePath(row) {
    const fileIconsBasePath = "/media/svg/icons/Files/";

    const mapMimeTypeFilename = new Map([
      ["text/csv", "csv"],
      ["application/gzip", "zip"],
      ["application/zip", "zip"],
      ["application/x-7z-compressed", "zip"],
      ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "xls"],
      ["application/vnd.openxmlformats-officedocument.presentationml.presentation", "ppt"],
      ["application/msword", "doc"],
      ["application/vnd.openxmlformats-officedocument.wordprocessingml.document", "doc"],
      ["application/pdf", "pdf"],
      ["application/xml", "xml"],
      ["text/xml", "xml"],
      ["image/jpeg", "jpg"],
      ["image/png", "png"],
      ["image/bmp", ""],
      ["application/vnd.ms-powerpoint", "ppt"],
      ["application/vnd.ms-excel", "xls"],
      ["application/xhtml+xml", "html"],
      ["text/html", "html"],
      ["video/mp4", "mp4"],
      ["video/mpeg", ""],
      ["text/plain", "txt"],
    ]);

    let iconFilename = row?.mimeType ? mapMimeTypeFilename.get(row.mimeType) : "file";

    //if no specific svg available, use default file.svg
    if (!iconFilename || iconFilename === "") {
      iconFilename = "file";
    }

    if (row?.status?.includes("REQUEST")) {
      iconFilename = "file";
    }

    return toAbsoluteUrl(fileIconsBasePath + iconFilename + ".svg");
  }

  return (
    <span className="svg-icon svg-icon-3x">
      <SVG src={getIconFilePath(row)} />
    </span>
  );
};
