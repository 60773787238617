import React from "react";
import { Modal } from "react-bootstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Select } from "../../../../../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";

export function AddClientForm({ addClient, actionsLoading, onHide, clients }) {
  const intl = useIntl();
  const AddClientSchema = Yup.object().shape({
    clientId: Yup.string().required(intl.formatMessage({ id: "AUTH.VALIDATION.CLIENT.REQUIRED" })),
  });
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ client: "" }}
        validationSchema={AddClientSchema}
        onSubmit={(values) => addClient(clients.find((c) => c.id === values.clientId))}
      >
        {({ handleSubmit, values }) => (
          <>
            <Modal.Body className="overlay">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Select name="clientId" label="Client" value={values.clientId}>
                      <option value={""} />
                      {clients.map((client) => (
                        <option key={client.id} value={client.id}>
                          {client.user.lastName.toUpperCase()} {client.user.firstName} -{" "}
                          {client.user.email}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
                <FormattedMessage id="COMMON.ACTION.CANCEL" />
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
                disabled={actionsLoading}
              >
                <FormattedMessage id="COMMON.ACTION.ADD" />
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
