import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AddClientDialogHeader } from "./AddClientDialogHeader";
import { AddClientForm } from "./AddClientForm";
import * as actions from "../../../../../UsersManagement/_redux/usersActions";
import { CLIENT } from "../../../../../../_utils/userTypes";

export function AddClientDialog({ show, onHide, leadId, productId }) {
  const { actionsLoading, clients, leadClients } = useSelector(
    (state) => ({
      actionsLoading: state.users.actionsLoading,
      clients: state.users.entities,
      leadClients: state.leadClients.entities,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  useEffect(() => {
    // server call by queryParams
    if (show) {
      dispatch(actions.fetchUsersByUserType({ userType: CLIENT }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const addClient = (client) => {
    if (client) {
      dispatch(
        actions.addClientToLead({
          userId: client.id,
          leadId,
          productId,
        })
      ).then(() => {
        onHide();
      });
    } else {
      onHide();
    }
  };

  return (
    <Modal show={show} onHide={() => onHide()} centered size={"lg"} backdrop="static">
      <AddClientDialogHeader />
      <AddClientForm
        addClient={addClient}
        actionsLoading={actionsLoading}
        clients={
          clients.filter(
            (c) => leadClients.findIndex((lc) => lc.id === c.id) === -1 && c.user?.username
          ) || []
        }
        onHide={() => onHide()}
      />
    </Modal>
  );
}
