import { snackBarNotificationsSlice } from "./snackBarNotificationsSlice";

const { actions } = snackBarNotificationsSlice;

export const createNotification = (notification) => (dispatch) => {
  dispatch(actions.addNotification({ notification }));
};

export const displayNotification = (id) => (dispatch) => {
  dispatch(actions.showNotification({ notification: { id } }));
};

export const hideNotification = (id) => (dispatch) => {
  dispatch(actions.hideNotification({ notification: { id } }));
};

export const removeNotification = (id) => (dispatch) => {
  dispatch(actions.removeNotification({ notification: { id } }));
};
