import { TimeUnitTypes } from "../timeUnitTypes";

export const TASK_STATES = {
  PLANNED: "TASK.TITLE.STATUS.PLANNED",
  IN_PROGRESS: "TASK.TITLE.STATUS.IN_PROGRESS",
  STUCK: "TASK.TITLE.STATUS.STUCK",
  DONE: "TASK.TITLE.STATUS.DONE",
};

export const getTaskRelatedToAppearance = (relatedToParameter) => {
  if (relatedToParameter) {
    switch (true) {
      case relatedToParameter.startsWith("PROJECT"):
        return { icon: "layer-group", color: "#4BADE8" };
      case relatedToParameter.startsWith("PRODUCT"):
        return { icon: "home", color: "#64BA3C" };
      case relatedToParameter.startsWith("LEAD"):
        return { icon: "fire", color: "#904EE2" };
      case relatedToParameter.startsWith("USER"):
        return { icon: "user", color: "#865439" };
      default:
        return {};
    }
  }
};

export const isCurrentTimeBefore = (dueDate) => {
  return new Date() < new Date(dueDate);
};

export const TIME_MODES = {
  REMAINING: "REMAINING",
  NOTIFICATION: "NOTIFICATION",
};

export const getTimeAgoStringForIntl = (
  messageId,
  value,
  unit,
  intl,
  mode = TIME_MODES.REMAINING
) => {
  switch (mode) {
    case TIME_MODES.REMAINING:
      return intl.formatMessage(
        { id: `${messageId || "TIMEAGO.LEFT"}.${unit.toUpperCase()}` },
        { time: value }
      );
    case TIME_MODES.NOTIFICATION:
      // This method returns "In X <unit>" or "X <unit> ago" whether value is positive or not.
      return intl.formatRelativeTime(-value, unit, { numeric: "auto" });
    default:
      return "";
  }
};

export function getTimeAgoCaption(task, value, unit, intl) {
  if (task.taskType === "CUSTOM") {
    if (task.status !== "DONE" && isCurrentTimeBefore(task.dueDate)) {
      return getTimeAgoStringForIntl(undefined, value, unit, intl);
    }
    if (task.status !== "DONE" && !isCurrentTimeBefore(task.dueDate)) {
      return getTimeAgoStringForIntl("TIMEAGO.OVERDUE.SINCE", value, unit, intl);
    }
  }

  if (task.taskType !== "CUSTOM" && task.status !== "DONE") {
    return getTimeAgoStringForIntl("TIMEAGO.WAITING.SINCE", value, unit, intl);
  }

  // default / unhandled case(s)
  return intl.formatNumber(value, { style: "unit", unit, unitDisplay: "long" });
}

export const getDesiredDate = (task) => {
  if (
    task.status === "DONE" &&
    !!task.doneDate &&
    new Date(task["dueDate"]) < new Date(task["doneDate"])
  ) {
    return task.dueDate;
  }

  if (task.status === "DONE" && !!task.doneDate) {
    return task.doneDate;
  }

  if (task.taskType !== "CUSTOM" && task.status !== "DONE" && task.createdAt) {
    return task.createdAt;
  }

  if (task.taskType === "CUSTOM" && task.status !== "DONE" && task.dueDate) {
    return task.dueDate;
  }
};

export const isShowTimeAgo = (task) => {
  if (task.status === "DONE" && new Date(task["dueDate"]) < new Date(task["doneDate"])) {
    return true;
  }
  if (task.status === "DONE") {
    return false;
  }
  if (task.taskType !== "CUSTOM" && task.status !== "DONE" && task.createdAt) {
    return true;
  }

  if (task.taskType !== "CUSTOM" && task.status === "DONE" && task.doneDate) {
    return false;
  }

  if (task.taskType === "CUSTOM" && task.status !== "DONE" && task.dueDate) {
    return true;
  }

  if (task.taskType === "CUSTOM" && task.status === "DONE" && !!task.doneDate) {
    return true;
  }

  // default / unhandled case(s)
  return false;
};

export const getTimeDiffNowInSeconds = (otherDate) => {
  //if minus -> other date in future
  //if plus -> other date in past
  return new Date(otherDate).getTime() - new Date().getTime();
};

export const getColorForTimeAgo = (task, layoutProps) => {
  const tdDueDate = getTimeDiffNowInSeconds(task.dueDate);

  if (task.taskType === "CUSTOM") {
    if (task.status !== "DONE" && isCurrentTimeBefore(task.dueDate)) {
      //less than 3 days
      if (tdDueDate <= 3 * TimeUnitTypes.DAY.value)
        return { color: layoutProps.colorsThemeBaseWarning };

      //more than 3 days
      if (tdDueDate > 3 * TimeUnitTypes.DAY.value)
        return { color: layoutProps.colorsThemeBaseSuccess };
    }
    if (task.status !== "DONE" && !isCurrentTimeBefore(task.dueDate)) {
      // less than 0 -> overdue
      if (tdDueDate < 0) return { color: layoutProps.colorsThemeBaseDanger };
    }
    if (task.status === "DONE" && new Date(task?.dueDate) < new Date(task?.doneDate)) {
      return { color: layoutProps.colorsThemeBaseDanger };
    }
  }

  const tdCreatedDate = getTimeDiffNowInSeconds(task.createdAt);

  if (task.taskType !== "CUSTOM" && task.status !== "DONE") {
    //less than 3 days
    if (tdCreatedDate <= 2 * TimeUnitTypes.DAY.value)
      return { color: layoutProps.colorsGrayGray700 };

    //between 3 days and 1 week
    if (tdCreatedDate > 2 * TimeUnitTypes.DAY.value)
      return { color: layoutProps.colorsThemeBaseWarning };

    //more than 1 days
    if (tdCreatedDate < 7) return { color: layoutProps.colorsThemeBaseDanger };
  }

  return { color: layoutProps.colorsGrayGray700 };
};

export const getColorForTimeAgoIcon = (task, layoutProps) => {
  const tdDueDate = getTimeDiffNowInSeconds(task.dueDate);

  if (task.taskType === "CUSTOM") {
    if (task.status !== "DONE" && isCurrentTimeBefore(task.dueDate)) {
      //less than 4 days
      if (tdDueDate <= 3 * TimeUnitTypes.DAY.value) return layoutProps.colorsThemeBaseWarning;

      //more than 3 days
      if (tdDueDate > 3 * TimeUnitTypes.DAY.value) return layoutProps.colorsThemeBaseSuccess;
    }
    if (task.status !== "DONE" && !isCurrentTimeBefore(task.dueDate)) {
      // less than 0 -> overdue -> red
      if (tdDueDate < 0) return layoutProps.colorsThemeBaseDanger;
    }
    if (task.status === "DONE" && new Date(task?.dueDate) < new Date(task?.doneDate)) {
      return layoutProps.colorsThemeBaseDanger;
    }
  }

  const tdCreatedDate = getTimeDiffNowInSeconds(task.createdAt);

  if (task.taskType !== "CUSTOM" && task.status !== "DONE") {
    //less than 3 days
    if (tdCreatedDate <= 2 * TimeUnitTypes.DAY.value) return layoutProps.colorsGrayGray700;

    //between 3 days and 1 week -> orange
    if (tdCreatedDate > 2 * TimeUnitTypes.DAY.value) return layoutProps.colorsThemeBaseWarning;

    //more than 1 days-> red
    if (tdCreatedDate < 7) return layoutProps.colorsThemeBaseDanger;
  }

  return layoutProps.colorsGrayGray700;
};

export function getCurrentDateMidnight() {
  let currentDateTime = new Date();
  currentDateTime.setHours(0);
  currentDateTime.setMinutes(0);
  currentDateTime.setSeconds(0);
  return currentDateTime;
}

export function getMessageIds(text) {
  const regexMessageId = new RegExp("[A-Z._]+", "g");
  return text ? Array.from(text.matchAll(regexMessageId)) : [];
}

export const getTaskText = (intl, val, attachments /*, params*/) => {
  /*
  params =
    attachments && attachments.length > 0
      ? {
          friendlyName: attachments && attachments.length > 0 ? attachments?.[0].name : "unknown",
          ...params,
        }
      : { ...params };

  if (!val) {
    return "";
  }
  if (intl.messages[val] && params) {
    return intl.formatMessage({ id: val }, { ...params });
  }
  */
  const params =
    attachments && attachments.length > 0
      ? {
          friendlyName: attachments && attachments.length > 0 ? attachments?.[0].name : "unknown",
        }
      : {};

  if (!val) {
    return "";
  }

  if (intl.messages[val] && params) {
    return intl.formatMessage({ id: val }, { ...params });
  }

  const messages = getMessageIds(val);

  if (messages && Array.isArray(messages) && messages.length > 0) {
    for (let i = 0; i < messages.length; i++) {
      const currId = messages?.[i]?.[0];
      if (currId.length > 0 && intl.messages[currId]) {
        val = val.replace(currId, intl.formatMessage({ id: currId }, { ...params }));
      }
    }
  }
  return val || "";
};
