import React, { useMemo } from "react";
import { useLeadFilesUIContext } from "./LeadFilesUIContext";
import { FormattedMessage } from "react-intl";
import { useAppSelector } from "../../../../../../redux/hooks";
import { Filter } from "../../../../../_components/Filter";
import { isAdmin } from "../../../../../_utils/authUtils";

export function LeadFilesFilter() {
  const leadFilesUIContext = useLeadFilesUIContext();
  const leadFilesUIProps = useMemo(() => {
    return {
      setQueryParams: leadFilesUIContext.setQueryParams,
      openNewFileDialog: leadFilesUIContext.openNewFileDialog,
      openNewInvoiceDialog: leadFilesUIContext.openNewInvoiceDialog,
      queryParams: leadFilesUIContext.queryParams,
      readOnly: leadFilesUIContext.readOnly,
    };
  }, [leadFilesUIContext]);

  const { session, groups } = useAppSelector((state) => ({
    session: state.auth.session,
    groups: state.auth.groups,
  }));

  return (
    <>
      <div className="form-filtration d-md-flex">
        <div className={"margin-bottom-10-mobile w-100"} style={{ maxWidth: "100%" }}>
          <Filter useEntityUIContext={useLeadFilesUIContext} />
        </div>
        <div className="text-right margin-bottom-10-mobile d-flex align-items-start mx-md-2">
          {!leadFilesUIProps.readOnly && (
            <div className="d-flex justify-content-end align-items-center">
              {(isAdmin(groups, session) || session?.userTypes?.includes("USER")) && (
                <button
                  type="button"
                  className="btn btn-light w-100 w-md-auto mx-1"
                  onClick={() => leadFilesUIProps.openNewInvoiceDialog()}
                >
                  <FormattedMessage id="INVOICE.ACTION.CREATE.NEW" />
                </button>
              )}
              <button
                type="button"
                className="btn btn-primary w-100 w-md-auto mx-1"
                onClick={() => leadFilesUIProps.openNewFileDialog()}
              >
                <FormattedMessage id="FILE.ACTIONS.UPLOAD.NEW" />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
