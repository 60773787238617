import { Field, FieldInputProps } from "formik";
import { FormattedMessage } from "react-intl";
import React, { FC } from "react";
import cn from "clsx";

export interface CheckboxProps {
  name: string;
  label: string;
  customClasses?: string;
}
export const Checkbox: FC<CheckboxProps & Partial<FieldInputProps<boolean>>> = ({
  name,
  label,
  customClasses,
  ...props
}) => {
  return (
    <div className={cn("row", customClasses)}>
      <div className={"col-lg-12 d-flex"}>
        <label className="checkbox checkbox-lg checkbox-single mr-2">
          <Field type="checkbox" name={name} className={"mr-2"} {...props} />
          <span className={"mr-2"} />
          <FormattedMessage id={label} />
        </label>
      </div>
    </div>
  );
};
