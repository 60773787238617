import { canCreate, canEdit } from "../../../_utils/authUtils";
import { Filter } from "../../../_components/Filter";
import { BudgetsUIProvider, useBudgetsUIContext } from "../BudgetsUIContext";
import { FormattedMessage } from "react-intl";
import { BudgetsTable } from "../BudgetsTable";
import React from "react";
import { useSubheader } from "../../../../_metronic/layout";
import { useCustomLocationState } from "../../../_utils/useCustomLocationState";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "../../../../redux/hooks";
import { useParams } from "react-router-dom";
import { IBudget } from "../../../../data/schemas";

type HistoryParams = {
  id: string;
};

export const BudgetTab = () => {
  const { setFromUrlTo } = useCustomLocationState();

  const { id } = useParams<HistoryParams>();

  const { projectForEdit, userGroups, session } = useAppSelector(
    (state) => ({
      projectForEdit: state.projects.projectForEdit.current
        ? state.projects.projectForEdit.current
        : state.projects.entities?.find((entity) => entity.id === id),
      userGroups: state.auth.groups,
      session: state.auth.session,
    }),
    shallowEqual
  );

  const subheader = useSubheader();
  const budgetsUIEvents = {
    openBudgetPage: (budget: IBudget) => {
      subheader.setTitle(budget.name);
      setFromUrlTo({ url: `/budgets/${budget.id}`, name: projectForEdit?.name });
    },
    newBudgetButtonClick: () => {
      setFromUrlTo({ url: `/budgets/new?projectId=${id}`, name: projectForEdit?.name });
    },
  };
  return (
    <BudgetsUIProvider
      queryParamsInit={{ projectId: id }}
      budgetsUIEvents={budgetsUIEvents}
      readOnly={
        !(canCreate(userGroups, session, "BUDGET") || canEdit(userGroups, session, "BUDGET"))
      }
      context={"projectBudgets"}
    >
      <div className="form-filtration">
        <div className="row align-items-center">
          <div className="col-md-6 margin-bottom-10-mobile">
            <Filter useEntityUIContext={useBudgetsUIContext} />
          </div>
          <div className="col-md-2 margin-bottom-10-mobile" />
          <div className="col-md-4 text-right margin-bottom-10-mobile ">
            {id && canCreate(userGroups, session, "BUDGET") && (
              <button
                data-cy="new-budget-btn"
                type="button"
                className="btn btn-primary ml-2"
                onClick={budgetsUIEvents.newBudgetButtonClick}
              >
                <FormattedMessage id="BUDGET.ACTION.NEW" />
              </button>
            )}
          </div>
          <BudgetsTable />
        </div>
      </div>
    </BudgetsUIProvider>
  );
};
