import { shallowEqual, useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import "./SnackBarNotificationsDialog.scss";
import { Toast } from "react-bootstrap";
import * as notificationsActions from "../_redux/snackBarNotificationsActions";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useIntl } from "react-intl";

export function SnackBarNotificationsDialog() {
  const intl = useIntl();
  const { notifications } = useSelector(
    (state) => ({ notifications: state.snackBarNotifications?.list }),
    shallowEqual
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (notifications && notifications !== {}) {
      Object.keys(notifications)
        .filter(
          (notificationKey) =>
            !notifications[notificationKey].show && !notifications[notificationKey].deleted
        )
        .forEach((notificationKey) =>
          setTimeout(() => {
            dispatch(notificationsActions.displayNotification(notificationKey));
          }, 100)
        );

      Object.keys(notifications)
        .filter((notificationKey) => !!notifications[notificationKey].deleted)
        .forEach((notificationKey) =>
          setTimeout(() => {
            dispatch(notificationsActions.removeNotification(notificationKey));
          }, 800)
        );
    }
  }, [notifications]);

  const closeToast = (id) => {
    dispatch(notificationsActions.hideNotification(id));
  };

  return (
    <div id={"notifications"}>
      <>
        {notifications &&
          notifications !== {} &&
          Object.keys(notifications).map((notificationKey) => (
            <Toast
              key={notificationKey}
              className={"mb-4"}
              show={!!notifications[notificationKey].show}
              delay={4000}
              autohide
              onClose={() => closeToast(notificationKey)}
            >
              <Toast.Header className={"px-4 py-2"}>
                {notifications[notificationKey].type === "success" && (
                  <>
                    <span className="svg-icon svg-icon-xl svg-icon-success mr-2">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")} />
                    </span>
                    <span className={"font-weight-bold mr-auto text-success"}>
                      {intl.formatMessage({ id: notifications[notificationKey].body })}
                    </span>
                  </>
                )}
                {notifications[notificationKey].type === "danger" && (
                  <>
                    <span className="svg-icon svg-icon-xl svg-icon-danger mr-2">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} />
                    </span>
                    <span className={"font-weight-bold mr-auto text-danger"}>
                      {intl.formatMessage({ id: notifications[notificationKey].body })}
                    </span>
                  </>
                )}
                {notifications[notificationKey].type === "info" && (
                  <>
                    <span className="svg-icon svg-icon-xl svg-icon-info mr-2">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")} />
                    </span>
                    <span className={"font-weight-bold mr-auto text-primary"}>
                      {intl.formatMessage({ id: notifications[notificationKey].body })}
                    </span>
                  </>
                )}
              </Toast.Header>
            </Toast>
          ))}
      </>
    </div>
  );
}
