import {
  ETaskStatus,
  TASK_STATUS_COLOR,
  TASK_STATUS_I18N_TITLE,
  TASK_STATUS_ICON_NAME,
} from "data/schemas/Task";

export const PROPERTY_TYPES = {
  "-": "EMPTY.STRING",
  STUDIO: "PROPERTY.TYPES.STUDIO",
  APARTMENT: "PROPERTY.TYPES.APARTMENT",
  PENTHOUSE: "PROPERTY.TYPES.PENTHOUSE",
  DUPLEX: "PROPERTY.TYPES.DUPLEX",
  TRIPLEX: "PROPERTY.TYPES.TRIPLEX",
  LOFT: "PROPERTY.TYPES.LOFT",
  HOUSE: "PROPERTY.TYPES.HOUSE",
  DETACHED_HOUSE: "PROPERTY.TYPES.DETACHED_HOUSE",
  SEMI_DETACHED_HOUSE: "PROPERTY.TYPES.SEMI_DETACHED_HOUSE",
  VILLA: "PROPERTY.TYPES.VILLA",
  OFFICE: "PROPERTY.TYPES.OFFICE",
  LAND: "PROPERTY.TYPES.LAND",
  INDOOR_PARKING: "PROPERTY.TYPES.INDOOR_PARKING",
  OUTDOOR_PARKING: "PROPERTY.TYPES.OUTDOOR_PARKING",
  CELLAR: "PROPERTY.TYPES.CELLAR",
};

export const LEAD_FILE_TYPES = {
  "-": "EMPTY.STRING",
  PLAN_CHANGES: "LEAD.FILE.TYPES.PLAN_CHANGES",
  EXTRA_REQUESTS: "LEAD.FILE.TYPES.EXTRA_REQUESTS",
  LEGAL: "LEAD.FILE.TYPES.LEGAL",
  INVOICE: "LEAD.FILE.TYPES.INVOICE",
  QUOTE: "LEAD.FILE.TYPES.QUOTE",
  CONTRACT: "LEAD.FILE.TYPES.CONTRACT",
  SALES_AGREEMENT: "LEAD.FILE.TYPES.SALES_AGREEMENT",
  SUPPLEMENTARY_AGREEMENT: "LEAD.FILE.TYPES.SUPPLEMENTARY_AGREEMENT",
  STATUS_REPORT: "LEAD.FILE.TYPES.STATUS_REPORT",
  ORDER: "LEAD.FILE.TYPES.ORDER",
  VARIOUS: "LEAD.FILE.TYPES.VARIOUS",
};

export const PROJECT_PRODUCT_FILE_TYPES = {
  "-": "EMPTY.STRING",
  ARCHITECT_PLANS: "PROJECT.PRODUCT.FILE.TYPES.ARCHITECT_PLANS",
  INSTALMENTS: "PROJECT.PRODUCT.FILE.TYPES.INSTALMENTS",
  SALES_BOOKLET: "PROJECT.PRODUCT.FILE.TYPES.SALES_BOOKLET",
  VISUALISATIONS: "PROJECT.PRODUCT.FILE.TYPES.VISUALISATIONS",
  TECHNICAL_SPECIFICATIONS: "PROJECT.PRODUCT.FILE.TYPES.TECHNICAL_SPECIFICATIONS",
  ANALYSIS: "PROJECT.PRODUCT.FILE.TYPES.ANALYSIS",
  BUILDING_PERMIT: "PROJECT.PRODUCT.FILE.TYPES.BUILDING_PERMIT",
  AUTHORISATION: "PROJECT.PRODUCT.FILE.TYPES.AUTHORISATION",
  CADASTRE: "PROJECT.PRODUCT.FILE.TYPES.CADASTRE",
  FINANCE: "PROJECT.PRODUCT.FILE.TYPES.FINANCE",
  NOTARIAL_DEED: "PROJECT.PRODUCT.FILE.TYPES.NOTARIAL_DEED",
  MINUTES: "PROJECT.PRODUCT.FILE.TYPES.MINUTES",
  QUOTE: "PROJECT.PRODUCT.FILE.TYPES.QUOTE",
  INVOICE: "PROJECT.PRODUCT.FILE.TYPES.INVOICE",
  CONTRACT: "PROJECT.PRODUCT.FILE.TYPES.CONTRACT",
  SALES_AGREEMENT: "PROJECT.PRODUCT.FILE.TYPES.SALES_AGREEMENT",
  SUPPLEMENTARY_AGREEMENT: "PROJECT.PRODUCT.FILE.TYPES.SUPPLEMENTARY_AGREEMENT",
  PROGRESS: "PROJECT.PRODUCT.FILE.TYPES.PROGRESS",
  TECHNICAL_DATA_SHEET: "PROJECT.PRODUCT.FILE.TYPES.TECHNICAL_DATA_SHEET",
  CORRESPONDENCE: "PROJECT.PRODUCT.FILE.TYPES.CORRESPONDENCE",
  DOCUMENTATION: "PROJECT.PRODUCT.FILE.TYPES.DOCUMENTATION",
  STATUS_REPORT: "PROJECT.PRODUCT.FILE.TYPES.STATUS_REPORT",
  ORDER: "PROJECT.PRODUCT.FILE.TYPES.ORDER",
  PLANS: "PROJECT.PRODUCT.FILE.TYPES.PLANS",
  VARIOUS: "PROJECT.PRODUCT.FILE.TYPES.VARIOUS",
  OTHER: "PROJECT.PRODUCT.FILE.TYPES.OTHER",
};

export const USER_FILE_TYPES = {
  CLIENT: {
    "-": "EMPTY.STRING",
    ID: "USER.FILE.TYPES.CLIENT.ID",
    RESIDENCE_CERTIFICATE: "USER.FILE.TYPES.CLIENT.RESIDENCE_CERTIFICATE",
    BANK_GUARANTEE: "USER.FILE.TYPES.CLIENT.BANK_GUARANTEE",
    SOCIAL_SECURITY_CARD: "USER.FILE.TYPES.CLIENT.SOCIAL_SECURITY_CARD",
    PAYSLIP: "USER.FILE.TYPES.CLIENT.PAYSLIP",
    EMPLOYMENT_CONTRACT: "USER.FILE.TYPES.CLIENT.EMPLOYMENT_CONTRACT",
    PROOF_OF_FUNDS: "USER.FILE.TYPES.CLIENT.PROOF_OF_FUNDS",
    AML_KYC_FORM: "USER.FILE.TYPES.CLIENT.AML_KYC_FORM",
    FILLOUT_AML_KYC_FORM: "USER.FILE.TYPES.CLIENT.FILLOUT_AML_KYC_FORM",
    BANK: "USER.FILE.TYPES.CLIENT.BANK",
    CONTRACT: "USER.FILE.TYPES.CLIENT.CONTRACT",
    CRIMINAL_RECORD: "USER.FILE.TYPES.CLIENT.CRIMINAL_RECORD",
    DECLARATION_OF_BENEFICIAL_OWNER: "USER.FILE.TYPES.CLIENT.DECLARATION_OF_BENEFICIAL_OWNER",
    VARIOUS: "USER.FILE.TYPES.CLIENT.VARIOUS",
  },
  SUBCONTRACTOR: {
    "-": "EMPTY.STRING",
    QUOTE: "USER.FILE.TYPES.SUBCONTRACTOR.QUOTE",
    INVOICE: "USER.FILE.TYPES.SUBCONTRACTOR.INVOICE",
    SUPPLEMENTARY_AGREEMENT: "USER.FILE.TYPES.SUBCONTRACTOR.SUPPLEMENTARY_AGREEMENT",
    ARCHITECT_PLANS: "USER.FILE.TYPES.SUBCONTRACTOR.ARCHITECT_PLANS",
    MINUTES: "USER.FILE.TYPES.SUBCONTRACTOR.MINUTES",
    PROGRESS: "USER.FILE.TYPES.SUBCONTRACTOR.PROGRESS",
    LEGAL: "USER.FILE.TYPES.SUBCONTRACTOR.LEGAL",
    CONTRACT: "USER.FILE.TYPES.SUBCONTRACTOR.CONTRACT",
    VISUALISATIONS: "USER.FILE.TYPES.SUBCONTRACTOR.VISUALISATIONS",
    STATUS_REPORT: "USER.FILE.TYPES.SUBCONTRACTOR.STATUS_REPORT",
    TECHNICAL_DATA_SHEET: "USER.FILE.TYPES.SUBCONTRACTOR.TECHNICAL_DATA_SHEET",
    CORRESPONDENCE: "USER.FILE.TYPES.SUBCONTRACTOR.CORRESPONDENCE",
    DOCUMENTATION: "USER.FILE.TYPES.SUBCONTRACTOR.DOCUMENTATION",
    ORDER: "USER.FILE.TYPES.SUBCONTRACTOR.ORDER",
    PLANS: "USER.FILE.TYPES.SUBCONTRACTOR.PLANS",
    VARIOUS: "USER.FILE.TYPES.SUBCONTRACTOR.VARIOUS",
  },
  USER: {
    "-": "EMPTY.STRING",
    CONTRACT: "USER.FILE.TYPES.USER.CONTRACT",
    ID: "USER.FILE.TYPES.USER.ID",
    VARIOUS: "USER.FILE.TYPES.USER.VARIOUS",
  },
};

export const LEGAL_ENTITY_TYPES = [
  {
    value: "NATURAL",
    label: "CLIENT.TYPES.NATURAL",
  },
  {
    value: "LEGAL",
    label: "CLIENT.TYPES.LEGAL",
  },
];

export const TITLES = [
  {
    value: "-",
    label: "EMPTY.STRING",
  },
  {
    value: "Mr",
    label: "TITLES.MR",
  },
  {
    value: "Mrs",
    label: "TITLES.MRS",
  },
];

export const HEATING_TYPES = {
  "-": "EMPTY.STRING",
  ELECTRICITY: "HEATING.TYPES.ELECTRICITY",
  FUEL_OIL: "HEATING.TYPES.FUEL_OIL",
  GAS: "HEATING.TYPES.GAS",
  HEAT_PUMP: "HEATING.TYPES.HEAT_PUMP",
  OTHER: "HEATING.TYPES.OTHER",
};

export const PRODUCT_TYPES = {
  "-": "EMPTY.STRING",
  RENTAL: "PRODUCT.TYPES.RENTAL",
  SALE: "PRODUCT.TYPES.SALE",
  OTHER: "PRODUCT.TYPES.OTHER",
};

export const INVOICE_STATUS = {
  TO_BE_PAID: "INVOICE.STATUS.TO_BE_PAID",
  PARTIALLY_PAID: "INVOICE.STATUS.PARTIALLY_PAID",
  PAID: "INVOICE.STATUS.PAID",
  OVERDUE: "INVOICE.STATUS.OVERDUE",
};

export const PRODUCT_STATUS = ["PRODUCT.STATUS.SELLING", "PRODUCT.STATUS.SOLD"];

export const SOURCE_OF_FUNDS = [
  "EMPTY.STRING",
  "SOURCE.OF.FUNDS.SELLING_HERITAGE",
  "SOURCE.OF.FUNDS.PROFESSIONAL_INCOME",
  "SOURCE.OF.FUNDS.REALISATION_OF_ASSETS",
  "SOURCE.OF.FUNDS.OTHER_SOURCE_OF_FUNDS",
];

export const EMPLOYMENT_STATUS = [
  "EMPTY.STRING",
  "EMPLOYMENT.STATUS.EMPLOYEE",
  "EMPLOYMENT.STATUS.SELF_EMPLOYED",
  "EMPLOYMENT.STATUS.LIBERAL_PROFESSION",
  "EMPLOYMENT.STATUS.EUROPEAN_CIVIL_SERVANT",
  "EMPLOYMENT.STATUS.LIFE_ANNUITANT",
  "EMPLOYMENT.STATUS.RETIRED",
  "EMPLOYMENT.STATUS.UNEMPLOYED",
  "EMPLOYMENT.STATUS.STUDENT",
  "EMPLOYMENT.STATUS.OTHER_ACTIVITY",
];

export const EMPLOYER_SECTOR = [
  "EMPTY.STRING",
  "EMPLOYER.SECTOR.ADULT_INDUSTRY",
  "EMPLOYER.SECTOR.AGRICULTURE",
  "EMPLOYER.SECTOR.BANK_INSURANCE",
  "EMPLOYER.SECTOR.BUSINESS_SERVICES",
  "EMPLOYER.SECTOR.CAR_TRADE",
  "EMPLOYER.SECTOR.CASINOS_AND_GAMBLING",
  "EMPLOYER.SECTOR.CATERING",
  "EMPLOYER.SECTOR.CHARITY",
  "EMPLOYER.SECTOR.CHEMISTRY_PARACHEMISTRY",
  "EMPLOYER.SECTOR.CONSTRUCTION",
  "EMPLOYER.SECTOR.ELECTRONICS",
  "EMPLOYER.SECTOR.EXPRESS_SHIPPING_COMPANIES",
  "EMPLOYER.SECTOR.FAIRGROUND",
  "EMPLOYER.SECTOR.FOOD",
  "EMPLOYER.SECTOR.IT_TELECOM",
  "EMPLOYER.SECTOR.IMPORT_EXPORT_OF_GOODS",
  "EMPLOYER.SECTOR.LAWYERS_OFFICE",
  "EMPLOYER.SECTOR.LIVESTOCK_TRADE",
  "EMPLOYER.SECTOR.MACHINERY_AND_EQUIPMENT_AUTOMOTIVE",
  "EMPLOYER.SECTOR.METALLURGY",
  "EMPLOYER.SECTOR.NOTARY_OFFICE",
  "EMPLOYER.SECTOR.PHARMACEUTICAL_INDUSTRY",
  "EMPLOYER.SECTOR.PLASTIC_RUBBER",
  "EMPLOYER.SECTOR.POLITICAL_PARTIES",
  "EMPLOYER.SECTOR.PROFESSIONAL_SPORT",
  "EMPLOYER.SECTOR.PUBLIC_ADMINISTRATION",
  "EMPLOYER.SECTOR.PUBLISHING_COMMUNICATION_MULTIMEDIA",
  "EMPLOYER.SECTOR.STOCKBROKER",
  "EMPLOYER.SECTOR.STUDIES_AND_ADVISORY",
  "EMPLOYER.SECTOR.TEXTILE_CLOTHING_SHOES",
  "EMPLOYER.SECTOR.TOBACCO",
  "EMPLOYER.SECTOR.TRADE_COMMERCE_DISTRIBUTION",
  "EMPLOYER.SECTOR.TRADE_ANTIQUES_OR_WORKS_OF_ART",
  "EMPLOYER.SECTOR.TRADE_MILITARY_EQUIPMENT_OIL",
  "EMPLOYER.SECTOR.TRADE_ELECTRONIC_AND_COMPUTER_EQUIPMENT",
  "EMPLOYER.SECTOR.TRANSPORT_LOGISTICS",
  "EMPLOYER.SECTOR.WASTE_AND_METALS_TREATMENT",
  "EMPLOYER.SECTOR.WHOLESALE_TRADE",
  "EMPLOYER.SECTOR.WOOD_PAPER_CARDBOARD_PRINTING",
  "EMPLOYER.SECTOR.OTHER_SECTOR",
];

export const LEAD_STATUS = {
  "-": {
    name: "LEAD.STATUS.N_A_NAME",
    className: "warning",
    priority: 1,
  },
  LOST: {
    name: "LEAD.STATUS.LOST_NAME",
    description: "LEAD.STATUS.LOST_DESCRIPTION",
    className: "red",
    priority: 2,
  },
  OPEN: {
    name: "LEAD.STATUS.OPEN_NAME",
    description: "LEAD.STATUS.OPEN_DESCRIPTION",
    className: "info",
    priority: 3,
  },
  QUALIFIED: {
    name: "LEAD.STATUS.QUALIFIED_NAME",
    description: "LEAD.STATUS.QUALIFIED_DESCRIPTION",
    className: "warning",
    priority: 4,
  },
  WON: {
    name: "LEAD.STATUS.WON_NAME",
    description: "LEAD.STATUS.WON_DESCRIPTION",
    className: "success",
    priority: 5,
  },
};

export const ROLES = {
  "-": {
    displayName: "EMPTY.STRING",
    roles: [],
  },
  ADMIN: {
    displayName: "ROLES.ADMIN",
  },
  COMPLIANCE: {
    displayName: "ROLES.COMPLIANCE",
  },
  CONTENT_MANAGER: {
    displayName: "ROLES.CONTENT_MANAGER",
  },
  CONTRIBUTOR: {
    displayName: "ROLES.CONTRIBUTOR",
  },
  FINANCIAL_CONTROLLER: {
    displayName: "ROLES.FINANCIAL_CONTROLLER",
  },
  MASTER: {
    displayName: "ROLES.MASTER",
  },
  PROJECT_MANAGER: {
    displayName: "ROLES.PROJECT_MANAGER",
  },
  PROJECT_RESELLER: {
    displayName: "ROLES.PROJECT_RESELLER",
  },
  SALES_MANAGER: {
    displayName: "ROLES.SALES_MANAGER",
  },
  VIEWER: {
    displayName: "ROLES.VIEWER",
  },
};

export const COUNTRY_CODES = [
  "LU",
  "FR",
  "BE",
  "DE",
  "GB",
  "PT",
  "IT",
  "ES",
  "CH",
  "LI",
  "RU",
  "AF",
  "AL",
  "AX",
  "DZ",
  "AS",
  "AD",
  "AO",
  "AI",
  "AQ",
  "AG",
  "AR",
  "AM",
  "AW",
  "AU",
  "AT",
  "AZ",
  "BH",
  "BS",
  "BD",
  "BB",
  "BY",
  "BZ",
  "BJ",
  "BM",
  "BT",
  "BO",
  "BQ",
  "BA",
  "BW",
  "BV",
  "BR",
  "IO",
  "VG",
  "BN",
  "BG",
  "BF",
  "BI",
  "CV",
  "KH",
  "CM",
  "CA",
  "KY",
  "CF",
  "TD",
  "CL",
  "CN",
  "HK",
  "MO",
  "CX",
  "CC",
  "CO",
  "KM",
  "CG",
  "CK",
  "CR",
  "HR",
  "CU",
  "CW",
  "CY",
  "CZ",
  "CI",
  "KP",
  "CD",
  "DK",
  "DJ",
  "DM",
  "DO",
  "EC",
  "EG",
  "SV",
  "GQ",
  "ER",
  "EE",
  "SZ",
  "ET",
  "FK",
  "FO",
  "FJ",
  "FI",
  "GF",
  "PF",
  "TF",
  "GA",
  "GM",
  "GE",
  "GH",
  "GI",
  "GR",
  "GL",
  "GD",
  "GP",
  "GU",
  "GT",
  "GG",
  "GN",
  "GW",
  "GY",
  "HT",
  "HM",
  "VA",
  "HN",
  "HU",
  "IS",
  "IN",
  "ID",
  "IR",
  "IQ",
  "IE",
  "IM",
  "IL",
  "JM",
  "JP",
  "JE",
  "JO",
  "KZ",
  "KE",
  "KI",
  "KW",
  "KG",
  "LA",
  "LV",
  "LB",
  "LS",
  "LR",
  "LY",
  "LT",
  "MG",
  "MW",
  "MY",
  "MV",
  "ML",
  "MT",
  "MH",
  "MQ",
  "MR",
  "MU",
  "YT",
  "MX",
  "FM",
  "MC",
  "MN",
  "ME",
  "MS",
  "MA",
  "MZ",
  "MM",
  "NA",
  "NR",
  "NP",
  "NL",
  "NC",
  "NZ",
  "NI",
  "NE",
  "NG",
  "NU",
  "NF",
  "MP",
  "NO",
  "OM",
  "PK",
  "PW",
  "PA",
  "PG",
  "PY",
  "PE",
  "PH",
  "PN",
  "PL",
  "PR",
  "QA",
  "KR",
  "MD",
  "RO",
  "RW",
  "RE",
  "BL",
  "SH",
  "KN",
  "LC",
  "MF",
  "PM",
  "VC",
  "WS",
  "SM",
  "ST",
  "SA",
  "SN",
  "RS",
  "SC",
  "SL",
  "SG",
  "SX",
  "SK",
  "SI",
  "SB",
  "SO",
  "ZA",
  "GS",
  "SS",
  "LK",
  "PS",
  "SD",
  "SR",
  "SJ",
  "SE",
  "SY",
  "TJ",
  "TH",
  "MK",
  "TL",
  "TG",
  "TK",
  "TO",
  "TT",
  "TN",
  "TR",
  "TM",
  "TC",
  "TV",
  "UG",
  "UA",
  "AE",
  "TZ",
  "TW",
  "UM",
  "VI",
  "US",
  "UY",
  "UZ",
  "VU",
  "VE",
  "VN",
  "WF",
  "EH",
  "YE",
  "ZM",
  "ZW",
];

export interface ILaneDefinition {
  id: string;
  title: string;
  color: string;
  faIconName: string;
}

export const LANES_DEFINITION: ILaneDefinition[] = [
  {
    id: ETaskStatus.PLANNED,
    title: TASK_STATUS_I18N_TITLE.PLANNED,
    color: TASK_STATUS_COLOR.PLANNED,
    faIconName: TASK_STATUS_ICON_NAME.PLANNED,
  },
  {
    id: ETaskStatus.IN_PROGRESS,
    title: TASK_STATUS_I18N_TITLE.IN_PROGRESS,
    color: TASK_STATUS_COLOR.IN_PROGRESS,
    faIconName: TASK_STATUS_ICON_NAME.IN_PROGRESS,
  },
  {
    id: ETaskStatus.STUCK,
    title: TASK_STATUS_I18N_TITLE.STUCK,
    color: TASK_STATUS_COLOR.STUCK,
    faIconName: TASK_STATUS_ICON_NAME.STUCK,
  },
  {
    id: ETaskStatus.DONE,
    title: TASK_STATUS_I18N_TITLE.DONE,
    color: TASK_STATUS_COLOR.DONE,
    faIconName: TASK_STATUS_ICON_NAME.DONE,
  },
];

export const TASK_SORTER_OPTION = [
  { type: "DUE_DATE", label: "TASK.LABEL.DATE.DUE" },
  { type: "UPDATED_AT", label: "COMMON.DATE.UPDATED" },
];

export const URL_LABEL = {
  youtube: {
    logo: "/media/logos/black/youtube.png",
  },
  facebook: {
    logo: "/media/logos/black/facebook.png",
  },
  instagram: {
    logo: "/media/logos/black/instagram.svg",
  },
  linkedin: {
    logo: "/media/logos/black/linkedin.png",
  },
  twitter: {
    logo: "/media/logos/black/twitter.svg",
    label: "Twitter",
  },
  crunchbase: {
    logo: "/media/logos/black/crunchbase.svg",
  },
  "y.at": {
    logo: "/media/logos/black/y.at.png",
  },
  neon: {
    logo: "/media/logos/black/neon.png",
  },
  medium: {
    logo: "/media/logos/black/medium.png",
  },
};

export const PARKING_TYPE = [
  { label: "PROPERTY.TYPES.INDOOR_PARKING", key: "INDOOR" },
  { label: "PROPERTY.TYPES.OUTDOOR_PARKING", key: "OUTDOOR" },
];

export const OUTDOOR_STATUS = [
  { label: "COMMON.PRIVATE", key: "PRIVATE" },
  { label: "COMMON.COMMON", key: "COMMON" },
];
