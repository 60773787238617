import * as React from "react";
import cn from "clsx";

import { Icon, IconType } from "app/_components/Icon";

import {
  TasksContentViewMode,
  TasksSwitchContentViewProps,
  TTasksContentViewMode,
} from "./definitions";

export const TasksSwitchContentView: React.FunctionComponent<TasksSwitchContentViewProps> = ({
  className,
  mode = TasksContentViewMode.COLUMNS,
  onChange,
}) => {
  const radioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.target.value as TTasksContentViewMode);
  };

  return (
    <div className={cn("btn-group btn-group-toggle", className)} data-toggle="buttons">
      {[`${TasksContentViewMode.COLUMNS}`, `${TasksContentViewMode.GANTT_CHART}`].map((view) => (
        <label key={view} className={cn("btn btn-light", { active: view === mode })}>
          <input type="radio" name="contentView" id={view} value={view} onChange={radioHandler} />
          <Icon name={view as IconType} width={17} />
        </label>
      ))}
    </div>
  );
};

export default TasksSwitchContentView;
