import React from "react";

export const RowHighlightIndicatorColumnFormatter = (
  cellContent: any,
  row: { id: string },
  rowIndex: number,
  extraParams: { highlightId: string }
) => {
  if (row && row.id === extraParams.highlightId) {
    return <i className="fa fa-circle text-primary" data-cy="indicator-row" />;
  }
};
