import { IImmotoolEntity } from "./ImmotoolEntity";
import { SubcontractorFinanceType } from "./Project";

export interface IBudget extends IImmotoolEntity {
  projectId: string;
  projectName: string;
  name: string;
  sortedLines: TBudgetSortedLines;
  budgetInstalments: TBudgetInstalments;
  hiddenLineExist?: boolean;
  plannedTotal?: number;
  budgetStatus?: string;
}

export type TBudgetSortedLines = IBudgetSortedCategory[];

export type TBudgetInstalments = IBudgetInstalment[];

export interface IBudgetSortedCategory extends IImmotoolEntity {
  id: string;
  budgetId?: string;
  label: string;
  sequence: number;
  visible: boolean;
  lines: IBudgetLine[];
  chosen?: boolean;
  selected?: boolean;
}

export interface IBudgetLine extends IImmotoolEntity {
  id: string;
  label: string;
  budgetId?: string;
  categoryId?: string;
  plannedBudget: number;
  sequence: number;
  chosen?: boolean;
  selected?: boolean;
}

export interface IFormattedBudgetCategory extends TFormattedBudgetLine {
  lines: TFormattedBudgetLine[];
  unspecifiedLines?: TFormattedBudgetLine[];
}

export enum BudgetLineColumns {
  PLANNED = "planned",
  QUOTES = "quotes",
  SUPPLEMENTARY_AGREEMENTS = "supplementary_agreements",
  ORDERS = "orders",
  REAL_DIFFERENCE = "realDifference",
  REAL_DIFFERENCE_PERCENTAGE = "realDifferencePercentage",
  INVOICED = "invoiced",
  INVOICED_PERCENTAGE = "invoicedPercentage",
}
export type BudgetLineColumnsTypes = `${BudgetLineColumns}`;

export type TFormattedBudgetLine = {
  id?: string;
  label?: any;
} & Record<BudgetLineColumns, string>;

export enum BudgetFinanceType {
  QUOTE = "quotes",
  SUPPLEMENTARY_AGREEMENT = "supplementary_agreements",
  ORDER = "orders",
  INVOICED = "invoiced",
}

export const SFFRelatedBudgetFinanceType = {
  [SubcontractorFinanceType.QUOTE]: BudgetFinanceType.QUOTE,
  [SubcontractorFinanceType.SUPPLEMENTARY_AGREEMENT]: BudgetFinanceType.SUPPLEMENTARY_AGREEMENT,
  [SubcontractorFinanceType.ORDER]: BudgetFinanceType.ORDER,
  [SubcontractorFinanceType.INVOICE]: BudgetFinanceType.INVOICED,
} as const;

export type SFFRelatedBudgetFinanceTypes =
  typeof SFFRelatedBudgetFinanceType[keyof typeof SFFRelatedBudgetFinanceType];

export interface IBudgetInstalment extends IImmotoolEntity {
  id: string;
  budgetId?: string;
  label: string;
  instalment: number;
  sequence: number;
  chosen?: boolean;
  selected?: boolean;
}
