import { ConfidentialColumnFormatter } from "../_components/column-formatters";
import { sortCaret } from "../../_metronic/_helpers";

export const displayColumnIsConfidential = ({
  entitiesFiltered,
  columns,
}: {
  entitiesFiltered: { isConfidential: boolean }[];
  columns: {
    dataField: string;
    text: string;
    sort: boolean;
    sortCaret: Function;
    formatter: any;
  }[];
}) => {
  if (entitiesFiltered.filter((file) => file?.isConfidential).length > 0) {
    columns.unshift({
      dataField: "isConfidential",
      text: "",
      sort: true,
      sortCaret: sortCaret,
      formatter: ConfidentialColumnFormatter,
    });
  }
};
