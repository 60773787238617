// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../../../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";
import { LEAD_FILE_TYPES } from "../../../../../../_utils/listUtils";
import { canReadAllDocument, isOwner } from "../../../../../../_utils/authUtils";
import { shallowEqual, useSelector } from "react-redux";
import { Checkbox } from "../../../../../../_components/Checkbox";
import { FileDropzone } from "app/_components/FileDropzone";

export function LeadFileEditForm({ saveFile, file, actionsLoading, onHide, newFile }) {
  const intl = useIntl();

  // Validation schema
  const FileEditSchema = Yup.object().shape({
    friendlyName: Yup.string()
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_SYMBOLS" })} 2`)
      .max(200, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_SYMBOLS" })} 200`)
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.FILE.NAME.REQUIRED" })),
    fileType: Yup.string().required(intl.formatMessage({ id: "AUTH.VALIDATION.TYPE.REQUIRED" })),
  });

  const { session, groups } = useSelector(
    (state) => ({
      session: state.auth.session,
      groups: state.auth.groups,
    }),
    shallowEqual
  );

  const [selectedFile, setSelectedFile] = useState(null);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={file}
        validationSchema={FileEditSchema}
        onSubmit={(values) => saveFile({ ...values, file: selectedFile })}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            <Modal.Body className="overlay">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="friendlyName"
                      component={Input}
                      placeholder={intl.formatMessage({
                        id: "COMMON.NAME",
                      })}
                      label={intl.formatMessage({
                        id: "COMMON.NAME",
                      })}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Select
                      name="fileType"
                      label={intl.formatMessage({
                        id: "COMMON.TYPE",
                      })}
                    >
                      {Object.keys(LEAD_FILE_TYPES).map((value) => (
                        <option key={value} value={value}>
                          {intl.formatMessage({ id: LEAD_FILE_TYPES[value] })}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>

                {newFile && (
                  <div className="form-group row">
                    <div className="col-lg-12">
                      {actionsLoading && !!selectedFile ? (
                        <div className="upload-file-container upload-file-container--disabled">
                          <div className="d-flex justify-content-center align-middle">
                            <div className="spinner-grow text-primary mr-4" />
                            <div>
                              <FormattedMessage id="FILE.ACTION.UPLOADING" />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <FileDropzone
                          values={values}
                          propsSetFieldValue={setFieldValue}
                          selectedFile={selectedFile}
                          setSelectedFile={setSelectedFile}
                        />
                      )}
                    </div>
                  </div>
                )}

                {(isOwner(session, values) ||
                  newFile ||
                  canReadAllDocument(groups, session, "LEAD")) && (
                  <Checkbox name={"isConfidential"} label={"COMMON.CONFIDENTIAL.DOCUMENT"} />
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                disabled={actionsLoading}
                className="btn btn-light btn-elevate"
              >
                <FormattedMessage id="COMMON.ACTION.CANCEL" />
              </button>
              <> </>
              <button
                disabled={(!selectedFile && newFile) || actionsLoading}
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                <FormattedMessage id="COMMON.ACTION.SAVE" />
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
