import React from "react";
import { UserAvatarImg } from "../UserAvatarImg";

export const ProfilePictureColumnFormatter = (
  cellContent: string,
  row: {
    profilePictureSetup: any;
    profilePictureUrl: string;
    firstName: string;
    lastName: string;
    email: string;
  }
) => {
  const user = row;
  if (!user?.profilePictureSetup && !user.profilePictureUrl) {
    return (
      <div className="symbol symbol-35 symbol-light-primary flex-shrink-0">
        <span className="symbol-label font-size-h5 font-weight-bold symbol-label">
          {user?.firstName || user?.lastName ? (
            <>
              {!!user?.firstName && user.firstName[0].toUpperCase()}
              {!!user?.lastName && user.lastName[0].toUpperCase()}
            </>
          ) : (
            <>{!!user?.email ? user.email[0].toUpperCase() : "-"}</>
          )}
        </span>
      </div>
    );
  } else {
    return (
      <div className="symbol symbol-35 bg-white-o-30 flex-shrink-0">
        <UserAvatarImg user={user} />
      </div>
    );
  }
};
