import * as React from "react";
import { useDidUpdate, useToggle } from "rooks";
import cn from "clsx";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";

import { toAbsoluteUrl } from "_metronic/_helpers";

import { TooltipHandler } from "app/_components/TooltipHandler";
import { CustomSelectStatus } from "app/_components/CustomSelect/CustomSelectStatus";

import { UpdateSubtaskNameField } from "./UpdateSubtaskNameField";

import { ITask } from "data/schemas";

import "./SubtaskItem.scss";

export interface SubtaskItemSharedProps {
  onUpdate: (subtask: ITask) => void;
  onDelete: (subtask: ITask) => void;
}

export interface SubtaskItemProps extends SubtaskItemSharedProps {
  subtask: ITask;
  setEditingSubtaskId: (id: string) => void;
  disabled?: boolean;
}

export const InnerSubtaskItem: React.FunctionComponent<SubtaskItemProps> = ({
  subtask,
  disabled = false,
  setEditingSubtaskId,
  onDelete,
  onUpdate,
}) => {
  const { id = "", name, status } = subtask;
  const [isEditing, toggleEdit] = useToggle(false);

  const updateButtonRef = React.useRef<HTMLButtonElement>(null);

  const [subtaskTitle, setSubtaskTitle] = React.useState(name);
  const onSubtaskTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubtaskTitle(event.currentTarget.value);
  };

  const [subtaskStatus, setSubtaskStatus] = React.useState(status);

  useDidUpdate(() => {
    const { name, status } = subtask;
    setSubtaskTitle(name);
    setSubtaskStatus(status);
  }, [subtask]);

  const disableSaveButton = Boolean(
    subtaskTitle && (subtaskTitle.trim().length === 0 || subtaskTitle === name)
  );

  const deleteClickHandler = (subtask: ITask) => () => {
    onDelete(subtask);
  };

  const editClickHandler = (id: string) => () => {
    setEditingSubtaskId(id);
    toggleEdit(true);
  };

  const cancelEditClickHandler = React.useCallback(() => {
    setSubtaskTitle(name);
    setEditingSubtaskId("");
    toggleEdit(false);
  }, [name]);

  const saveNameUpdatedClickHandler = React.useCallback(() => {
    const editedSubtask = { ...subtask, name: subtaskTitle };
    onUpdate(editedSubtask);
    cancelEditClickHandler();
  }, [subtaskTitle, subtask]);

  const saveStatusUpdatedChangeHandler = React.useCallback(
    (selected: any) => {
      const status = selected?.value;
      setSubtaskStatus(status);
      const editedSubtask = { ...subtask, status };
      onUpdate(editedSubtask);
    },
    [subtask]
  );

  //--------------------------------------------------------------------------//

  const enterPressedHandler = () => {
    const { current: updateButton } = updateButtonRef;
    if (!updateButton) return;
    updateButton.focus();

    window.setTimeout(() => {
      updateButton.dispatchEvent(new Event("click", { bubbles: true, cancelable: true }));
    }, 100);
  };

  return (
    <div className="subtask-item">
      {isEditing ? (
        <div className="edit-name">
          <UpdateSubtaskNameField
            {...{
              value: subtaskTitle,
              onChange: onSubtaskTitleChange,
              onEnterPressed: !disableSaveButton ? enterPressedHandler : undefined,
              onEscPressed: cancelEditClickHandler,
            }}
          />
        </div>
      ) : (
        <>
          {disabled ? (
            <div className="name disabled">
              <div className="text-ellipsis">{name}</div>
            </div>
          ) : (
            <a className="name" href={`/tasks/${id}/edit`}>
              <div className="text-ellipsis">{name}</div>
            </a>
          )}
        </>
      )}
      {!disabled && (
        <div className={cn("actions", { editing: isEditing })}>
          {isEditing ? (
            <>
              <TooltipHandler
                disabled={disableSaveButton}
                id="save-subtask-title-tooltip"
                message={<FormattedMessage id="COMMON.ACTION.SAVE" />}
              >
                <button
                  data-cy="save-subtask-name-update"
                  ref={updateButtonRef}
                  className="btn btn-icon btn-light btn-hover-secondary btn-sm"
                  type="button"
                  onClick={saveNameUpdatedClickHandler}
                  disabled={disableSaveButton}
                >
                  <i className="fas fa-check icon-md" />
                </button>
              </TooltipHandler>

              <TooltipHandler
                id="cancel-edit-subtask-title-tooltip"
                message={<FormattedMessage id="COMMON.ACTION.CANCEL" />}
              >
                <button
                  data-cy="cancel-subtask-name-update"
                  className="btn btn-icon btn-light btn-hover-secondary btn-sm"
                  type="button"
                  onClick={cancelEditClickHandler}
                >
                  <i className="fas fa-times icon-md" />
                </button>
              </TooltipHandler>
            </>
          ) : (
            <>
              <TooltipHandler
                id="edit-subtask-title-tooltip"
                message={<FormattedMessage id="COMMON.ACTION.EDIT" />}
              >
                <button
                  data-cy="edit-subtask-name"
                  className="btn btn-icon btn-light btn-hover-secondary btn-sm"
                  type="button"
                  onClick={editClickHandler(id)}
                >
                  <span className="svg-icon svg-icon-md">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Edit.svg")} />
                  </span>
                </button>
              </TooltipHandler>

              <TooltipHandler
                id="delete-subtask-tooltip"
                message={<FormattedMessage id="COMMON.ACTION.DELETE" />}
              >
                <button
                  data-cy="delete-subtask-name"
                  className="btn btn-icon btn-light btn-hover-danger btn-sm"
                  type="button"
                  onClick={deleteClickHandler(subtask)}
                >
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                  </span>
                </button>
              </TooltipHandler>
            </>
          )}
        </div>
      )}

      {/* <div className="assignee">[?]</div> */}

      <div className="status">
        <CustomSelectStatus
          name="subtaskStatus"
          selected={subtaskStatus}
          onChange={saveStatusUpdatedChangeHandler}
          small
          disabled={disabled || isEditing}
        />
      </div>
    </div>
  );
};

export const SubtaskItem = React.memo(InnerSubtaskItem);

export default SubtaskItem;
