import React, { useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { LeadsTable } from "./leads-table/LeadsTable";
import { useLeadsUIContext } from "./LeadsUIContext";
import { FormattedMessage, useIntl } from "react-intl";
import { useSubheader } from "../../../../../_metronic/layout";
import { Filter } from "../../../../_components/Filter";
import { shallowEqual, useSelector } from "react-redux";
import { canCreate, canReadAll } from "../../../../_utils/authUtils";

export function LeadsCard() {
  const intl = useIntl();
  const leadsUIContext = useLeadsUIContext();
  const leadsUIProps = useMemo(() => {
    return {
      ids: leadsUIContext.ids,
      queryParams: leadsUIContext.queryParams,
      setQueryParams: leadsUIContext.setQueryParams,
      newLeadButtonClick: leadsUIContext.newLeadButtonClick,
      openDeleteLeadsDialog: leadsUIContext.openDeleteLeadsDialog,
      openUpdateLeadsStatusDialog: leadsUIContext.openUpdateLeadsStatusDialog,
      openFetchLeadsDialog: leadsUIContext.openFetchLeadsDialog,
    };
  }, [leadsUIContext]);

  const subheader = useSubheader();
  useEffect(() => {
    subheader.setTitle(
      intl.formatMessage({
        id: "LEAD.TITLE",
      })
    );
  }, [subheader]);
  const { groups, session } = useSelector(
    (state) => ({
      groups: state.auth.groups,
      session: state.auth.session,
    }),
    shallowEqual
  );
  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({
          id: "LEAD.TITLE.LIST",
        })}
      >
        <CardHeaderToolbar>
          {canCreate(groups, session, "LEAD") && canReadAll(groups, session, "CLIENT") && (
            <button
              data-cy="button-new-lead"
              type="button"
              className="btn btn-primary"
              onClick={leadsUIProps.newLeadButtonClick}
            >
              <FormattedMessage id="LEAD.ACTION.NEW" />
            </button>
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Filter useEntityUIContext={useLeadsUIContext} />
        <LeadsTable />
      </CardBody>
    </Card>
  );
}
