import * as React from "react";

import { ITask } from "data/schemas";

import { useAppSelector } from "redux/hooks";

import { ISelectedSort, tasksWithSubtasksUtils } from "app/_utils/tasks";

import { TasksContentViewMode, TTasksContentViewMode } from "./TasksSwitchContentView";
import { TasksBoard } from "./TasksBoard";
import { TasksGanttChart } from "./TasksGanttChart";

export interface TasksCardContentProps {
  isTab?: boolean;
  filterText?: string;
  selectedSort: ISelectedSort;
  contentMode: TTasksContentViewMode;
}

export const InnerTasksCardContent: React.FunctionComponent<TasksCardContentProps> = ({
  isTab = false,
  filterText,
  selectedSort,
  contentMode,
}) => {
  const { isTasksLoading, rawTasks = [] } = useAppSelector((state) => ({
    isTasksLoading: state.tasks.listLoading as boolean,
    rawTasks: state.tasks.entities as ITask[],
  }));

  const tasks = React.useMemo(
    () => (rawTasks.length > 1 ? tasksWithSubtasksUtils(rawTasks) : rawTasks),
    [rawTasks]
  );

  return contentMode === TasksContentViewMode.COLUMNS ? (
    <TasksBoard {...{ isTab, filterText, selectedSort, isTasksLoading, tasks, rawTasks }} />
  ) : (
    <TasksGanttChart {...{ filterText, selectedSort, isTasksLoading, tasks }} />
  );
};

export const TasksCardContent = React.memo(InnerTasksCardContent);

export default TasksCardContent;
