import * as React from "react";

import { TooltipHandler } from "app/_components/TooltipHandler";

import { TaskItem, TaskItemProps } from "../TaskItem";

import "./TaskLaneItem.scss";

/** the item attribute represents the parent task */
export interface TaskLaneItemProps extends TaskItemProps {
  disableDrop?: boolean;
  renderItem?: boolean;
  subitems: TaskItemProps[];
}

export const TaskLaneItem: React.FunctionComponent<TaskLaneItemProps> = ({
  disableDrop = false,
  renderItem,
  item,
  itemIndex = 0,
  subitems,
}) => {
  const hasSubitems = subitems.length > 0;

  if (!hasSubitems) return <TaskItem item={item} itemIndex={itemIndex} />;

  const taskName = item.name;
  const displayTooltipTaskName = taskName.length > 32;

  return (
    <div className="task-lane-item">
      {renderItem ? (
        <TaskItem item={item} itemIndex={itemIndex} disableDrop={disableDrop} />
      ) : (
        <TooltipHandler
          id={`tooltip:task-id:${item.id}`}
          message={taskName}
          disabled={!displayTooltipTaskName}
        >
          <div className="task-lane-item---header ellipsis">{taskName}</div>
        </TooltipHandler>
      )}
      <div className="task-lane-item--subitems">
        {subitems.map((subitem) => (
          <TaskItem key={subitem.item.id} {...subitem} disableDrop={disableDrop} />
        ))}
      </div>
    </div>
  );
};

export default TaskLaneItem;
