import { createSlice } from "@reduxjs/toolkit";

const initialSnackBarNotificationState = {
  counter: 0,
  list: {},
};

export const snackBarNotificationsSlice = createSlice({
  name: "snackBarNotifications",
  initialState: initialSnackBarNotificationState,
  reducers: {
    addNotification: (state, action) => {
      const newCounter = state.counter + 1;
      state.counter = newCounter;
      state.list[newCounter] = {
        type: action.payload?.type,
        header: action.payload?.header,
        body: action.payload?.body,
        show: false,
        deleted: false,
      };
    },
    removeNotification: (state, action) => {
      delete state.list[action.payload?.notification?.id];
    },
    showNotification: (state, action) => {
      state.list[action.payload?.notification?.id].show = true;
    },
    hideNotification: (state, action) => {
      state.list[action.payload?.notification?.id].show = false;
      state.list[action.payload?.notification?.id].deleted = true;
    },
  },
});
