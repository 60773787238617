import React, { useState } from "react";
import { ModalProgressBar } from "../../../../../../../_metronic/_partials/controls";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import * as actions from "../../../../_redux/usersActions";
import { FormattedMessage, useIntl } from "react-intl";
import { EMAIL, SMS } from "../../../../../../_utils/notificationTypes";

export function SendUserCredentialsModal({
  user,
  actionsLoading,
  showSendUserCredentialsModal,
  setShowSendUserCredentialsModal,
}) {
  const dispatch = useDispatch();
  const [notifyMethod, setNotifyMethod] = useState(EMAIL);
  const sendUserCredentials = () => {
    dispatch(actions.createOrResetUserAccess(user.id, notifyMethod));
    setShowSendUserCredentialsModal(false);
  };
  const intl = useIntl();
  return (
    <Modal
      show={showSendUserCredentialsModal}
      onHide={() => setShowSendUserCredentialsModal(false)}
      aria-labelledby="user-credentials-modal"
      centered
      backdrop="static"
    >
      {actionsLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="user-credentials-modal-title">
          <FormattedMessage id="COMMON.ACTION.ACCESS.CREDENTIALS.USER" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!actionsLoading && (
          <>
            <span>
              <FormattedMessage
                id={
                  user?.isDisabled
                    ? "USER.ACTION.ACCESS.CREATE.CONFIRMATION.USER"
                    : "USER.ACTION.ACCESS.RESET.CONFIRMATION.USER"
                }
              />
            </span>
            <div>
              <i>
                <small className="text-primary">
                  <FormattedMessage id="USER.ACTION.ACCESS.CANNOT_BE_UNDONE" />
                </small>
              </i>
            </div>
            <div className={"mt-4"}>
              <label className="my-auto mr-2">
                <FormattedMessage id={"COMMON.ACTION.NOTIFY_USER_BY"} />
              </label>
              <select
                data-cy="select-notify-method"
                className="form-control form-control-solid w-auto px-3 py-2 mt-2 min-w-110px"
                value={notifyMethod}
                onChange={(e) => setNotifyMethod(e.target.value)}
              >
                <option value={EMAIL}>{intl.formatMessage({ id: "COMMON.EMAIL" })}</option>
                <option value={SMS}>{intl.formatMessage({ id: "COMMON.SMS" })}</option>
              </select>
            </div>
          </>
        )}
        {actionsLoading && (
          <span>
            <FormattedMessage id="USER.ACTION.SENDING" />
          </span>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setShowSendUserCredentialsModal(false)}
            className="btn btn-light btn-elevate"
          >
            <FormattedMessage id="COMMON.ACTION.CANCEL" />
          </button>
          <> </>
          <button
            type="button"
            onClick={sendUserCredentials}
            className="btn btn-primary font-weight-bold"
            data-cy="button-user-create-access-confirm"
            disabled={actionsLoading}
          >
            {user?.isDisabled ? (
              <FormattedMessage id="COMMON.ACTION.CREATE.AND.SEND" />
            ) : (
              <FormattedMessage id="COMMON.ACTION.RESET.AND.SEND" />
            )}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
