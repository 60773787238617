import { settingsSlice } from "./settingsSlice";
import { callTypes } from "../../UsersManagement/_redux/usersSlice";
import * as settingsCrud from "./settingsCrud";
import { cloneDeep } from "lodash-es";
import {
  processSackbarNotification,
  SNACKBAR_MESSAGE,
} from "../../Common/SnackbarNotificationsHandler";

const actions = settingsSlice.actions;

export const updateSettingsFieldLocally = (key, value) => (dispatch) => {
  dispatch(actions.settingsFieldUpdatedLocally({ key, value }));
};

export const fetchSettings =
  (onError = null) =>
  (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return settingsCrud
      .fetchSettings()
      .then((response) => {
        dispatch(actions.settingsFetched({ settings: response }));
      })
      .catch((error) => {
        if (onError) {
          onError();
        }
        dispatch(actions.catchError({ error }));
        processSackbarNotification(SNACKBAR_MESSAGE.DANGER.FETCH_SETTINGS, dispatch);
      });
  };

export const submitSettings = (settings) => (dispatch) => {
  //Remove IBAN formatting for database
  const settingsForDB = cloneDeep(settings);
  settingsForDB.bankAccounts = settingsForDB.bankAccounts?.map((bankAccount) => ({
    ...bankAccount,
    IBAN: bankAccount.IBAN.replace(/[^A-Z0-9]/g, ""),
  }));
  dispatch(actions.startCall({ callType: callTypes.action }));
  return settingsCrud
    .updateSettings(settingsForDB)
    .then((response) => {
      console.log("updateSettings response: ", response);
      processSackbarNotification(SNACKBAR_MESSAGE.SUCCESS.UPDATE_SETTINGS, dispatch);
      dispatch(actions.settingsUpdated({ settings: response }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_SETTINGS, dispatch);
    });
};
