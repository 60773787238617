import React from "react";
import { ProjectsCard } from "./ProjectsCard";
import { ProjectsUIProvider } from "./ProjectsUIContext";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { LoadingDialog } from "../../../../../_metronic/_partials/controls";
import { projectsSlice } from "../../_redux/projects/projectsSlice";

export function ProjectsPage({ history }) {
  const dispatch = useDispatch();
  const { actions } = projectsSlice;

  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.projects.listLoading }),
    shallowEqual
  );
  const projectsUIEvents = {
    newProjectButtonClick: () => {
      history.push("/projects/new");
    },
    openProjectPage: (project) => {
      dispatch(actions.resetProjectForEdit());
      history.push(`/projects/${project.id}`);
    },
  };

  return (
    <ProjectsUIProvider projectsUIEvents={projectsUIEvents}>
      <LoadingDialog isLoading={isLoading} />
      <ProjectsCard />
    </ProjectsUIProvider>
  );
}
