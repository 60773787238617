import { Card, CardBody, CardFooter, CardHeader } from "../../_metronic/_partials/controls";
import { PdfViewer } from "../modules/Common/PdfViewer/PdfViewer";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { FormattedMessage, useIntl } from "react-intl";
import { DateUtils } from "../_utils/DateUtils";
import * as React from "react";

interface CardPDFSignedViewerProps {
  signatureInfo: { signedDate?: Date; signedBy?: string; id?: string };
  fileUrl: string;
  setModalState?: Function;
}

export const CardPDFSignedViewer: React.FC<CardPDFSignedViewerProps> = ({
  signatureInfo,
  fileUrl,
  setModalState,
}) => {
  const intl = useIntl();

  return (
    <>
      <Card className={"h-100"}>
        <CardHeader title={intl.formatMessage({ id: "SIGN.TITLE" })} />
        <CardBody
          className={"p-0 d-flex align-items-center justify-content-center position-relative"}
          style={{ minHeight: "40vh" }}
        >
          <div className={"w-100 h-100 position-absolute"}>
            <PdfViewer fileUrl={fileUrl} />
          </div>
        </CardBody>
        <CardFooter className={"p-4 p-sm-6"}>
          <div className={"text-right"} data-cy="text-signature-signed-confirmation">
            {signatureInfo?.signedDate ? (
              <>
                <span className="svg-icon svg-icon-2x svg-icon-success mr-1">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")} />
                </span>
                <FormattedMessage
                  id="SIGN.SIGNED_AT"
                  values={{
                    b: (chunks: any) => <b>{chunks}</b>,
                    date: DateUtils.format(new Date(signatureInfo.signedDate), intl, true),
                    user: signatureInfo.signedBy,
                  }}
                />
              </>
            ) : (
              <>
                {!!setModalState && (
                  <button
                    data-cy="button-signature-sign"
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setModalState("INFO")}
                    disabled={!signatureInfo?.id}
                  >
                    <FormattedMessage id={"SIGN.ACTION"} />
                  </button>
                )}
              </>
            )}
          </div>
        </CardFooter>
      </Card>
    </>
  );
};
