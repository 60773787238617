import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { requestProductFileSignature } from "../../../../_redux/products/productsActions";
import { fetchUsersByUserType } from "../../../../../UsersManagement/_redux/usersActions";
import { useProductFilesUIContext } from "../ProductFilesUIContext";
import { RequestSignatureDialog } from "../../../../../../_components/RequestSignatureDialog";
import { CLIENT, SUBCONTRACTOR, USER } from "../../../../../../_utils/userTypes";

export function ProductRequestSignature() {
  useEffect(() => {
    dispatch(fetchUsersByUserType({ userTypes: [USER, CLIENT, SUBCONTRACTOR] }));
  }, []);

  const dispatch = useDispatch();
  const { users, productFile } = useSelector(
    (state: any) => ({
      users: state.users.entities,
      productFile: state.products?.productForEdit?.current?.files,
    }),
    shallowEqual
  );

  return (
    <RequestSignatureDialog
      useFilesUIContext={useProductFilesUIContext}
      userList={[...users]}
      fileList={productFile}
      requestFileSignature={requestProductFileSignature}
    />
  );
}
