import React from "react";
import { FormattedMessage } from "react-intl";
import { ColumnFormatter } from "react-bootstrap-table-next";
import { IBudget } from "../../../data/schemas";

export const SelectedColumnFormatter: ColumnFormatter<IBudget> = (
  cellContent,
  row,
  rowIndex,
  extraParams: { selectedBudget: string }
) => (
  <>
    {extraParams.selectedBudget === row.id ? (
      <span className={`label label-lg label-light-success label-inline`}>
        <FormattedMessage id="COMMON.ACTION.SELECTED" />
      </span>
    ) : (
      <span className={`label label-lg label-light-warning label-inline`}>
        <FormattedMessage id="COMMON.ACTION.DRAFT" />
      </span>
    )}
  </>
);
