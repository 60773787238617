import { INVOICE_STATUS } from "app/_utils/listUtils";

export const InvoiceStatusColumnFormatter = (
  cellContent: string,
  row: { fileType: string },
  rowIndex: number,
  extraParams: { intl: any }
) => {
  let color = "text-info";
  switch (cellContent) {
    case "PAID": {
      color = "text-success";
      break;
    }
    case "PARTIALLY_PAID": {
      color = "text-info";
      break;
    }
    case "TO_BE_PAID": {
      color = "text-warning";
      break;
    }
    case "OVERDUE": {
      color = "text-danger";
      break;
    }
  }
  return (
    <>
      {cellContent && (
        <span className={color}>
          {extraParams.intl.formatMessage({
            id: (INVOICE_STATUS as any)[cellContent] || "-",
          })}
        </span>
      )}
    </>
  );
};
