import { TITLES } from "./listUtils";
import { CLIENT } from "./userTypes";

export const formatDisplayName = (user, displayTitle = true, translated = false) => {
  if (!user) {
    return "Undefined User";
  }

  if (!!user.firstName || !!user.lastName) {
    let displayName = "";
    const title = GetIntlTitle(user.title);
    if (!!user.title && displayTitle) {
      displayName += translated && title ? `${title} ` : `${user.title} `;
    }
    displayName += `${user.firstName} ${user.lastName}`;
    return displayName.replace("  ", " ").trim();
  }

  return !!user?.email ? user?.email : "";
};

export const formatDisplayNameMeIntl = (intl, user, sessionId, displayTitle = false) => {
  if (user?.userTypes?.includes(CLIENT)) {
    displayTitle = true;
  }

  let displayName = formatDisplayNameIntl(intl, user);
  if (sessionId && sessionId === user.id) {
    displayName += ` ${getMeText(intl)}`;
  }
  return displayName;
};

export const formatDisplayNameIntl = (intl, user, displayTitle = true) => {
  if (!user) {
    return intl.formatMessage({ id: "USER.UNKNOWN" });
  }

  if (
    !!user.firstName ||
    !!user.lastName ||
    !!user.company?.name ||
    (!!user.title && displayTitle)
  ) {
    let displayName = [];
    if (!!user.company?.name) {
      displayName.push(user.company.name);
    }
    if (!!user.firstName || !!user.lastName) {
      const titleTranslated = getIntlTitle(intl, user.title);
      if (!!user.title && displayTitle && user.title !== "-" && titleTranslated) {
        displayName.push(`${titleTranslated} ${`${user.firstName} ${user.lastName}`.trim()}`);
      } else {
        displayName.push(`${user.firstName} ${user.lastName}`.trim());
      }

      return displayName.join(" - ").trim();
    }
  }
  return !!user?.email ? user?.email : "";
};

export const formatDisplayNameColumn = (user) => {
  if (!!user.firstName || !!user.lastName || !!user.company?.name) {
    let displayName = [];

    if (!!user.company?.name) {
      displayName.push(user.company.name);
    }
    if (!!user.firstName || !!user.lastName) {
      displayName.push(`${user.firstName} ${user.lastName}`.trim());
    }
    return displayName.join(" - ").trim();
  }
  return "-";
};

export const formatDisplayNameIntlWithTitle = (intl, title, displayName, userId, session) => {
  let formattedDisplayName = displayName;
  if (!displayName) {
    return intl.formatMessage({ id: "USER.UNKNOWN" });
  }
  if (!!title) {
    const titleTranslated = getIntlTitle(intl, title);
    formattedDisplayName = `${titleTranslated && titleTranslated + " "} ` + displayName;
  }

  if (userId && userId === session.id) {
    formattedDisplayName += getMeText(intl);
  }

  return formattedDisplayName.replace("  ", " ").trim();
};

export const GetIntlTitle = (title) => {
  const titleTranslated = TITLES.filter((userTitle) => userTitle.value === title);

  return titleTranslated && titleTranslated.length > 0 ? titleTranslated[0] : title;
};

export const getIntlTitle = (intl, title) => {
  //TODO check if locale required
  // const lang = intl?.locale || "en-GB";

  const titleTranslated = TITLES?.filter((userTitle) => userTitle.value === title);
  return titleTranslated && titleTranslated.length > 0 && titleTranslated[0].label
    ? intl.formatMessage({ id: titleTranslated[0].label })?.trim()
    : title;
};

export const getMeText = (intl) => {
  return ` (${intl.formatMessage({
    id: "PROFILE.ME",
  })})`;
};

export const formatAddress = (intl, address, delimiter = " ", shouldDelimiterPrefix = false) => {
  let formattedAddress = "";
  if (!!address) {
    //
    if (address.addressLine1) {
      formattedAddress += (shouldDelimiterPrefix ? delimiter : "") + address.addressLine1;
    }
    if (address.city) {
      formattedAddress += delimiter + address.city;
    }
    if (address.stateOrRegion) {
      formattedAddress += ", " + address.stateOrRegion;
    }

    if (address.postalCode) {
      formattedAddress += delimiter + address.postalCode;
      if (address.countryCode) {
        formattedAddress += " " + intl.formatDisplayName(address.countryCode, { type: "region" });
      }
    }
  }
  return formattedAddress;
};

export const isSameUser = (session, entity) => {
  return entity?.id === session.id;
};
