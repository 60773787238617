import React, { useEffect, useState } from "react";
import TimeAgo from "react-timeago";
import { formatDisplayNameIntl } from "../_utils/userUtils";
import { FormattedMessage, useIntl } from "react-intl";
import { getTimeAgoStringForIntl } from "app/_utils/tasks";
import { formatDataUtils } from "../_utils/formatDataUtils";

export const AuditTrail = ({ events }) => {
  const intl = useIntl();
  const [eventsToDisplay, setEventsToDisplay] = useState([]);

  useEffect(() => {
    const newEventsToDisplay = [];
    for (const ev of [...events].sort(
      (e1, e2) => new Date(e1.createdAt) - new Date(e2.createdAt)
    )) {
      if (
        Object.keys(eventTypes).includes(ev.eventName) &&
        (!eventTypes[ev.eventName].hideDuplicated ||
          !newEventsToDisplay.find((e) => {
            const xMinBefore = new Date(ev.createdAt);
            xMinBefore.setMinutes(xMinBefore.getMinutes() - 5);
            return (
              e.eventName === ev.eventName &&
              e.objectIdRelatedTo === ev.objectIdRelatedTo &&
              e.objectTypeRelatedTo === ev.objectTypeRelatedTo &&
              e.rootObjectIdRelatedTo === ev.rootObjectIdRelatedTo &&
              e.rootObjectTypeRelatedTo === ev.rootObjectTypeRelatedTo &&
              xMinBefore < new Date(e.createdAt)
            );
          }))
      ) {
        newEventsToDisplay.push(ev);
      }
    }
    setEventsToDisplay(newEventsToDisplay.reverse());
  }, [events]);

  const eventTypes = {
    INSERT: {
      label: intl.formatMessage({
        id: "HISTORY.ACTION.CREATED",
      }),
      icon: (
        <span className="svg-icon svg-icon-primary svg-icon-2x">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1" />
              <rect
                fill="#000000"
                opacity="0.3"
                transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                x="4"
                y="11"
                width="16"
                height="2"
                rx="1"
              />
            </g>
          </svg>
        </span>
      ),
    },
    READ: {
      label: intl.formatMessage({
        id: "HISTORY.ACTION.VIEWED",
      }),
      icon: (
        <span className="svg-icon svg-icon-primary svg-icon-2x">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <rect x="0" y="0" width="24" height="24" />
              <path
                d="M3,12 C3,12 5.45454545,6 12,6 C16.9090909,6 21,12 21,12 C21,12 16.9090909,18 12,18 C5.45454545,18 3,12 3,12 Z"
                fill="#000000"
                fillRule="nonzero"
                opacity="0.3"
              />
              <path
                d="M12,15 C10.3431458,15 9,13.6568542 9,12 C9,10.3431458 10.3431458,9 12,9 C13.6568542,9 15,10.3431458 15,12 C15,13.6568542 13.6568542,15 12,15 Z"
                fill="#000000"
                opacity="0.3"
              />
            </g>
          </svg>
        </span>
      ),
      hideDuplicated: true,
    },
    LIST: {
      label: intl.formatMessage({
        id: "HISTORY.ACTION.VIEWED",
      }),
      icon: (
        <span className="svg-icon svg-icon-primary svg-icon-2x">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <rect x="0" y="0" width="24" height="24" />
              <path
                d="M3,12 C3,12 5.45454545,6 12,6 C16.9090909,6 21,12 21,12 C21,12 16.9090909,18 12,18 C5.45454545,18 3,12 3,12 Z"
                fill="#000000"
                fillRule="nonzero"
                opacity="0.3"
              />
              <path
                d="M12,15 C10.3431458,15 9,13.6568542 9,12 C9,10.3431458 10.3431458,9 12,9 C13.6568542,9 15,10.3431458 15,12 C15,13.6568542 13.6568542,15 12,15 Z"
                fill="#000000"
                opacity="0.3"
              />
            </g>
          </svg>
        </span>
      ),
      hideDuplicated: true,
    },
    MODIFY: {
      label: intl.formatMessage({
        id: "HISTORY.ACTION.UPDATED",
      }),
      icon: (
        <span className="svg-icon svg-icon-primary svg-icon-2x">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <rect x="0" y="0" width="24" height="24" />
              <path
                d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                fill="#000000"
                fillRule="nonzero"
                transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "
              />
              <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1" />
            </g>
          </svg>
        </span>
      ),
    },
    REMOVE: {
      label: intl.formatMessage({
        id: "HISTORY.ACTION.DELETED",
      }),
      icon: (
        <span className="svg-icon svg-icon-primary svg-icon-2x">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <rect x="0" y="0" width="24" height="24" />
              <path
                d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z"
                fill="#000000"
                fillRule="nonzero"
              />
              <path
                d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                fill="#000000"
                opacity="0.3"
              />
            </g>
          </svg>
        </span>
      ),
    },
  };

  const filterDiff = (diff) => {
    if (!diff) {
      return [];
    }
    return diff.filter(
      (d) =>
        !d.path.find(
          (p) =>
            typeof p === "string" &&
            (p.includes("PK") ||
              p.includes("SK") ||
              ["eventId", "updatedAt", "updatedBy", "updatedByUserId", "notes"].includes(p))
        )
    );
  };

  return (
    <div>
      {eventsToDisplay.map((event, index) => (
        <div className={"mt-4"} key={`${event.createdAt}-${index}`}>
          <div className={"d-flex align-items-center"}>
            <div className={"mr-4"}>{eventTypes[event.eventName].icon}</div>
            <div className={"d-flex flex-column"}>
              <div className={"d-flex"}>
                <div className={"font-weight-bold mr-1"}>
                  {event.user?.id ? (
                    <a href={`/users/${event.user?.id}`}>
                      {formatDisplayNameIntl(intl, event.user)}
                    </a>
                  ) : (
                    <div>{formatDisplayNameIntl(intl, event.user)}</div>
                  )}
                </div>
                <div className={"mr-1"}>{eventTypes[event.eventName].label}</div>
                <div className={"mr-1"}>
                  {event.objectIdRelatedTo ? (
                    <a
                      href={`/${event.objectTypeRelatedTo.toLowerCase()}s/${
                        event.objectIdRelatedTo
                      }`}
                    >
                      {event.objectTypeRelatedTo?.toLowerCase().split("_").join(" ")}
                    </a>
                  ) : (
                    <div>{event.objectTypeRelatedTo?.toLowerCase().split("_").join(" ")}s</div>
                  )}
                </div>
                {event.rootObjectTypeRelatedTo && (
                  <div className={"d-flex"}>
                    <div className={"mr-1"}>
                      <FormattedMessage id="COMMON.OF" />
                    </div>
                    <div className={"mr-1"}>
                      {event.rootObjectIdRelatedTo ? (
                        <a
                          href={`/${event.rootObjectTypeRelatedTo.toLowerCase()}s/${
                            event.rootObjectIdRelatedTo
                          }`}
                        >
                          {event.rootObjectTypeRelatedTo?.toLowerCase().split("_").join(" ")}
                        </a>
                      ) : (
                        <div>
                          {event.rootObjectTypeRelatedTo?.toLowerCase().split("_").join(" ")}s
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className={"text-muted"}>
                {intl.formatMessage(
                  { id: `TIME.AGO` },
                  {
                    data: (
                      <TimeAgo
                        date={event.createdAt}
                        formatter={(value, unit) => {
                          return getTimeAgoStringForIntl("TIMEAGO.LEFT", value, unit, intl);
                        }}
                      />
                    ),
                  }
                )}
              </div>
            </div>
          </div>
          {event.eventName === "MODIFY" && (
            <div className={"px-18 d-sm-flex align-items-center text-justify"}>
              {filterDiff(event.diff).map((d, i) => (
                <React.Fragment key={i}>
                  <div className={"font-italic mr-10"}>{d.path.join(".")}</div>
                  <div
                    style={{ minWidth: "32px" }}
                    dangerouslySetInnerHTML={{ __html: formatDataUtils(d.oldValue, intl) }}
                  />
                  <span className={"mx-4"}>
                    <span className="svg-icon svg-icon-primary svg-icon-2x">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <rect
                            fill="#000000"
                            opacity="0.3"
                            transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) "
                            x="11"
                            y="5"
                            width="2"
                            height="14"
                            rx="1"
                          />
                          <path
                            d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                            fill="#000000"
                            fillRule="nonzero"
                            transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997) "
                          />
                        </g>
                      </svg>
                    </span>
                  </span>
                  <div
                    style={{ minWidth: "32px" }}
                    dangerouslySetInnerHTML={{ __html: formatDataUtils(d.newValue, intl) }}
                  />
                </React.Fragment>
              ))}
            </div>
          )}
          {/*{JSON.stringify(event)}*/}
        </div>
      ))}
    </div>
  );
};
