// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import { Field } from "formik";
import { Input, Select } from "../../_metronic/_partials/controls";
import { useIntl } from "react-intl";
import { CountryDropdownOptions } from "./Country/CountryDropdownOptions";

export function AddressEditForm({
  customHandleChange,
  disabled,
  handleChange,
  prefix = "",
  hideGPSCoordinates = true,
}) {
  const intl = useIntl();

  return (
    <>
      <div className="form-group row">
        <div className="col-lg-9">
          <Field
            data-cy="input-address-line1"
            name={`${prefix}address.addressLine1`}
            component={Input}
            onChange={(e) => customHandleChange(e)(handleChange)}
            label={intl.formatMessage({
              id: "ADDRESS.LINE.ONE",
            })}
            disabled={disabled}
          />
        </div>
        <div className="mt-5 mt-lg-0 col-lg-3">
          <Field
            data-cy="input-address-postal-code"
            name={`${prefix}address.postalCode`}
            component={Input}
            onChange={(e) => customHandleChange(e)(handleChange)}
            placeholder={intl.formatMessage({ id: "ADDRESS.POSTAL_CODE.PLACEHOLDER" })}
            label={intl.formatMessage({
              id: "ADDRESS.POSTAL_CODE",
            })}
            disabled={disabled}
          />
        </div>
      </div>

      <div className="form-group row">
        <div className="col-lg-12">
          <Field
            data-cy="input-address-line2"
            name={`${prefix}address.addressLine2`}
            component={Input}
            onChange={(e) => customHandleChange(e)(handleChange)}
            label={intl.formatMessage({
              id: "ADDRESS.LINE.TWO",
            })}
            disabled={disabled}
          />
        </div>
      </div>

      <div className="form-group row justify-content-between justify-content-lg-start">
        <div className="mt-5 mt-lg-0 col-sm-6 col-lg-3 mt-3">
          <Field
            data-cy="input-address-city"
            name={`${prefix}address.city`}
            component={Input}
            onChange={(e) => customHandleChange(e)(handleChange)}
            label={intl.formatMessage({
              id: "ADDRESS.CITY",
            })}
            disabled={disabled}
          />
        </div>
        <div className="mt-5 mt-lg-0 col-sm-6 col-lg-3 mt-3">
          <Field
            data-cy="input-address-region"
            name={`${prefix}address.stateOrRegion`}
            component={Input}
            onChange={(e) => customHandleChange(e)(handleChange)}
            label={intl.formatMessage({
              id: "ADDRESS.STATE_OR_REGION",
            })}
            disabled={disabled}
          />
        </div>
        <div className="mt-5 mt-lg-0 col-sm-6 col-lg-3 mt-3">
          <Select
            data-cy="select-address-country"
            name={`${prefix}address.countryCode`}
            label={intl.formatMessage({
              id: "ADDRESS.COUNTRY",
            })}
            onChange={(e) => customHandleChange(e)(handleChange)}
            disabled={disabled}
          >
            <CountryDropdownOptions />
          </Select>
        </div>
      </div>
      {!hideGPSCoordinates && (
        <div className="form-group row justify-content-between justify-content-lg-start">
          <div className="mt-5 mt-lg-0 col-sm-6 col-lg-3">
            <Field
              name={`${prefix}address.latitude`}
              component={Input}
              onChange={(e) => customHandleChange(e)(handleChange)}
              label={intl.formatMessage({
                id: "ADDRESS.LATITUDE",
              })}
              disabled={disabled}
            />
          </div>
          <div className="mt-5 mt-lg-0 col-sm-6 col-lg-3">
            <Field
              name={`${prefix}address.longitude`}
              component={Input}
              onChange={(e) => customHandleChange(e)(handleChange)}
              label={intl.formatMessage({
                id: "ADDRESS.LONGITUDE",
              })}
              disabled={disabled}
            />
          </div>
        </div>
      )}
    </>
  );
}
