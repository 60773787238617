import React, { useEffect, useRef } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { DatePickerField, Input } from "../../../../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";
import { AddressEditForm } from "../../../../../_components/AddressEditForm";
import { TextEditor } from "../../../../../_components/TextEditor/TextEditor";
import { Urls } from "../../../../../_components/Urls";
import { commentToolbar } from "../../../../../_components/TextEditor/config";

export function ProjectEditForm({
  project,
  submitProject,
  saveProjectFields,
  disabled,
  onSubmitRef,
}) {
  const intl = useIntl();

  const ProjectEditSchema = Yup.object().shape({
    name: Yup.string()
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.PROJECT.NAME.REQUIRED" }))
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 2`)
      .max(250, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 250`),
    description: Yup.string(),
    address: Yup.object().shape({
      addressLine1: Yup.string(),
      addressLine2: Yup.string(),
      city: Yup.string(),
      stateOrRegion: Yup.string(),
      postalCode: Yup.string(),
      countryCode: Yup.string(),
    }),
    startDate: Yup.date(),
    availability: Yup.date(),
    constructionYear: Yup.number(),
  });

  const customHandleChange = (e) => (handleChange) => {
    if (!!saveProjectFields && !disabled) {
      handleChange(e);
      saveProjectFields(e.target.name, e.target.value);
    }
  };
  const formRef = useRef();

  useEffect(() => {
    if (onSubmitRef) {
      onSubmitRef(() => formRef.current.submitForm());
    }
  }, []);

  return (
    <>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={project}
        validationSchema={ProjectEditSchema}
        onSubmit={() => {
          if (!disabled && !!submitProject) {
            submitProject();
          }
        }}
      >
        {({ handleChange, values }) => (
          <Form className="form form-label-right" data-cy="form-project">
            <div className="form-group row">
              <div className="col-lg-12">
                <Field
                  name="name"
                  data-cy="input-project-name"
                  component={Input}
                  onChange={(e) => customHandleChange(e)(handleChange)}
                  placeholder={
                    disabled
                      ? ""
                      : intl.formatMessage({
                          id: "PROJECT.TITLE.NAME",
                        })
                  }
                  label={intl.formatMessage({
                    id: "PROJECT.TITLE.NAME",
                  })}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="form-group form-row">
              <div className="col-4 d-flex flex-column">
                <Field
                  className={"form-control react-datepicker-ignore-onclickoutside no-validation"}
                  name="startDate"
                  component={DatePickerField}
                  onChange={(val) =>
                    customHandleChange({
                      target: { name: "startDate", value: val?.toISOString() ?? null },
                    })(handleChange)
                  }
                  label={intl.formatMessage({
                    id: "COMMON.START.DATE",
                  })}
                  withFeedbackLabel={false}
                  disabled={disabled}
                  showTimeSelect={false}
                  dateFormat={"P"}
                  popperPlacement={"bottom-start"}
                  autoComplete={"off"}
                />
              </div>
              <div className="col-4 d-flex flex-column">
                <Field
                  className={"form-control react-datepicker-ignore-onclickoutside no-validation"}
                  name="availability"
                  component={DatePickerField}
                  onChange={(val) =>
                    customHandleChange({
                      target: { name: "availability", value: val?.toISOString() ?? null },
                    })(handleChange)
                  }
                  label={intl.formatMessage({
                    id: "PROJECT.INFO.AVAILABILITY_DATE",
                  })}
                  withFeedbackLabel={false}
                  disabled={disabled}
                  showTimeSelect={false}
                  dateFormat={"P"}
                  popperPlacement={"bottom-start"}
                  autoComplete={"off"}
                />
              </div>
              <div className="col-4">
                <Field
                  name="constructionYear"
                  component={Input}
                  type={"number"}
                  onChange={(e) => customHandleChange(e)(handleChange)}
                  label={intl.formatMessage({
                    id: "PROJECT.INFO.CONSTRUCTION_YEAR",
                  })}
                  disabled={disabled}
                />
              </div>
            </div>

            <div className="form-group">
              <label>
                <FormattedMessage id="COMMON.DESCRIPTION" />
              </label>
              <TextEditor
                data-cy="input-project-description"
                name={"description"}
                data={values?.description}
                saveMethod={saveProjectFields}
                disabled={disabled}
              />
            </div>
            <AddressEditForm
              customHandleChange={customHandleChange}
              handleChange={handleChange}
              disabled={disabled}
              hideGPSCoordinates={false}
            />
            <Urls disabled={disabled} values={values} saveFields={saveProjectFields} />

            <div className={"my-8"}>
              <h4>
                <FormattedMessage id={"PROPERTY.INFO.SPECIFICATION"} />
              </h4>
              <TextEditor
                name={"specification"}
                data={values?.specification}
                saveMethod={saveProjectFields}
                disabled={disabled}
                toolbarOption={commentToolbar}
                data-cy={"input-project-details-specification"}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
