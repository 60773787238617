import { callTypes, dashboardSlice } from "./dashboardSlice";
import * as tasksCrud from "../../PropertiesManagement/_redux/tasks/tasksCrud";
import {
  processSackbarNotification,
  SNACKBAR_MESSAGE,
} from "../../Common/SnackbarNotificationsHandler";
import * as dashboardCrud from "./dashboardCrud";

const contextualActions = {
  default: dashboardSlice("dashboard").actions,
};

const actions = contextualActions.default;

export const loadCounters = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.counters }));
  return dashboardCrud
    .fetchCounters()
    .then((response) => {
      console.log("fetchCounters:", response);
      dispatch(actions.countersFetched({ counters: response }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.counters }));
    });
};

export const loadDashboardData =
  (queryParams = {}) =>
  (dispatch) => {
    // dispatch(actions.startCall({ callType: callTypes.tasks, queryParams }));
    // return tasksCrud
    //   .listTasks(queryParams)
    //   .then((entities) => {
    //     dispatch(actions.tasksFetched({ tasks: entities }));
    //     return entities;
    //   })
    //   .catch((error) => {
    //     console.log("error:", error);
    //     dispatch(actions.catchError({ error, callType: callTypes.tasks }));
    //     dispatch(
    //       snackBarNotificationsActions.addNotification({
    //         type: "danger",
    //         body: "Failed to query tasks",
    //       })
    //     );
    //   });
  };

//TODO counters need to be adapted in the code above -> this is supposed to be done in "Dasboard"- ticket
// export const loadDashboardData = (userUsername, groups) => (dispatch) => {
//   dispatch(actions.startCall({ callTypes: callTypes.tasks }));
//   return Promise.all([
//     taskActions.fetchTasks({ userId: userUsername, groups }),
//     dashboardCrud.fetchCounters(),
//   ])
//     .then((results) => {
//       const tasksResponse = results[0];
//       console.log("countersResponse", results[0])
//       const countersResponse = results[1];
//       const counters = {};
//       countersResponse.items.forEach((item) => (counters[item.id] = item.counterValue));
//       dispatch(actions.countersFetched({ counters }));
//       dispatch(actions.tasksFetched({ tasks: tasksResponse.items }));
//     })
//     .catch((error) => {
//       console.log("loadDashboardData error:", error);
//       dispatch(
//         snackBarNotificationsActions.addNotification({
//           type: "danger",
//           body: "Failed to load dashboard information",
//         })
//       );
//     });
// };

// export const createUserTask = (task) => (dispatch) => {
//   return tasksCrud
//     .createTask(task)
//     .then((taskResponse) => {
//       dispatch(actions.taskCreated({ task: taskResponse }));
//       dispatch(
//         snackBarNotificationsActions.addNotification({ type: "success", body: "Task created" })
//       );
//     })
//     .catch((error) => {
//       console.log("createTask error:", error);
//       dispatch(
//         snackBarNotificationsActions.addNotification({
//           type: "danger",
//           body: "Failed to create task",
//         })
//       );
//     });
// };

export const createUserTask = (task) => (dispatch) => {
  return tasksCrud
    .createTask(task)
    .then((taskResponse) => {
      dispatch(actions.taskCreated({ task: taskResponse }));
      processSackbarNotification(SNACKBAR_MESSAGE.SUCCESS.CREATE_TASK, dispatch);
    })
    .catch((error) => {
      console.log("createTask error:", error);
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.CREATE_TASK, dispatch);
    });
};

export const updateUserTask = (task) => (dispatch) => {
  return tasksCrud
    .updateTask(task)
    .then((taskResponse) => {
      dispatch(actions.taskUpdated({ task: taskResponse }));
      processSackbarNotification(SNACKBAR_MESSAGE.SUCCESS.UPDATE_TASK, dispatch);
    })
    .catch((error) => {
      console.log("updateTask error:", error);
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_TASK, dispatch);
    });
};

export const deleteUserTask = (taskId) => (dispatch) => {
  return tasksCrud
    .deleteTask(taskId)
    .then((taskResponse) => {
      dispatch(actions.taskDeleted({ taskId: taskId }));
      processSackbarNotification(SNACKBAR_MESSAGE.SUCCESS.DELETE_TASK, dispatch);
    })
    .catch((error) => {
      console.log("deleteTask error:", error);
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.DELETE_TASK, dispatch);
    });
};
