/* eslint-disable no-restricted-imports */
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import { ModalProgressBar } from "_metronic/_partials/controls";

export function EntityDeleteDialog({ deleteEntityDialog, unSetDeleteEntityDialog }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState();

  const deleteEntity = () => {
    const { onDelete, action } = deleteEntityDialog;
    const triggerOnDelete = onDelete ? onDelete : () => undefined;

    if (!action?.fn || !action?.props) {
      if (onDelete) {
        triggerOnDelete();
        unSetDeleteEntityDialog();
      }
      return;
    }

    setIsLoading(true);
    dispatch(action.fn(action.props)).then(() => {
      setIsLoading(false);
      triggerOnDelete();
      unSetDeleteEntityDialog();
    });
  };

  /*
    https://zellwk.com/blog/css-values-in-js/
    https://attacomsian.com/blog/javascript-get-css-styles
  */
  const adjustBackdropZindex = () => {
    const modalElement = document.querySelector(".modal.entity-delete-dialog");
    const modalBackdropElement = document.querySelector(
      ".modal-backdrop.entity-delete-dialog-backdrop"
    );

    const modalElementStyles = getComputedStyle(modalElement);

    const modalElementZIndex = modalElementStyles.getPropertyValue("z-index");

    modalBackdropElement.style["z-index"] = Number(modalElementZIndex);
  };

  // https://react-bootstrap.github.io/components/modal/
  return (
    <Modal
      show={!!deleteEntityDialog}
      onShow={() => adjustBackdropZindex()}
      onHide={() => unSetDeleteEntityDialog()}
      centered
      backdrop="static"
      className="entity-delete-dialog"
      backdropClassName="entity-delete-dialog-backdrop"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <FormattedMessage
            id={
              "COMMON.ACTION.DELETE.ARE_YOU_SURE.TITLE." +
              deleteEntityDialog.entityType?.toUpperCase()
            }
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <>
            <span>
              <FormattedMessage
                id={
                  "COMMON.ACTION.DELETE.ARE_YOU_SURE." +
                  deleteEntityDialog.entityType?.toUpperCase()
                }
              />
            </span>
            {deleteEntityDialog.extraContent}
          </>
        )}
        {isLoading && (
          <span>
            <FormattedMessage id="COMMON.ACTION.DELETE.IN_PROGRESS" />
          </span>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => unSetDeleteEntityDialog()}
            className="btn btn-light btn-elevate"
          >
            <FormattedMessage id="COMMON.ACTION.CANCEL" />
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteEntity}
            data-cy="button-delete-item-confirm"
            className="btn btn-delete btn-danger btn-elevate"
            disabled={isLoading}
          >
            <FormattedMessage id={deleteEntityDialog.customSubmitLabel ?? "COMMON.ACTION.DELETE"} />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
