/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { FormattedMessage } from "react-intl";

export const LeadCreationActionsColumnFormatter = (
  cellContent: any,
  row: { id: string },
  rowIndex: number,
  extraParams: { openCreateLeadPage: Function }
) => (
  <span onClick={(e) => e.stopPropagation()}>
    <a
      className="btn btn-primary btn-sm font-weight-bold px-6"
      onClick={() => extraParams.openCreateLeadPage(row.id)}
    >
      <FormattedMessage id="COMMON.ACTION.SELECT" />
    </a>
  </span>
);
