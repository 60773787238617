import React from "react";

const inputLabel = ({ label, touched, error, customFeedbackLabel, value }) => {
  if (touched && error) {
    return <div className="invalid-feedback">{error}</div>;
  }

  //TODO: disabled the feedback with additional "was entered correct". It should be "was entered correctly", in addition, it is not very useful at this state. to be reviewed
  // if (touched && !error && label && !!value) {
  //   return <div className="valid-feedback">{label} was entered correct</div>;
  // }

  return (
    <div className="feedback">
      {customFeedbackLabel && <>{customFeedbackLabel}</>}
      {!customFeedbackLabel && (
        <>
          {/*TODO: disabled feedback labels, duplicated info, useless for now*/}
          {/*Please enter <b>{label}</b>*/}
        </>
      )}
    </div>
  );
};

const selectLabel = ({ label, touched, error, customFeedbackLabel }) => {
  if (touched && error) {
    return <div className="invalid-feedback d-block">{error}</div>;
  }

  return (
    <div className="feedback">
      {customFeedbackLabel && <>{customFeedbackLabel}</>}
      {!customFeedbackLabel && label && (
        <>
          {/*TODO: disabled feedback labels, duplicated info, useless for now*/}
          {/*Please select <b>{label}</b>*/}
        </>
      )}
    </div>
  );
};

export function FieldFeedbackLabel({ label, touched, error, type, customFeedbackLabel, value }) {
  switch (type) {
    case "text":
      return inputLabel({ label, touched, error, customFeedbackLabel, value });
    case "email":
      return inputLabel({ label, touched, error, customFeedbackLabel });
    case "password":
      return inputLabel({ label, touched, error, customFeedbackLabel });
    default:
      return selectLabel({ label, touched, error, customFeedbackLabel });
  }
}
