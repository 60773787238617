import axios from "axios";
import { Analytics, Auth } from "aws-amplify";
import { masterEmailsRestriction } from "../../../_utils/authUtils";
import * as usersCrud from "../../UsersManagement/_redux/usersCrud";

export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export function login(email, password) {
  return Auth.signIn(email, password);
}

export function changePassword(user, newPassword) {
  return Auth.completeNewPassword(user, newPassword, {});
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export async function forgotPassword(email) {
  return await Auth.forgotPassword(email);
}

export function forgotPasswordSubmit(username, code, new_password) {
  return Auth.forgotPasswordSubmit(username, code, new_password);
}

export function getUserTokens() {
  return Auth.currentSession();
}

export async function getUserInformation(id, email) {
  let response = { userTypes: [] };
  if (!masterEmailsRestriction.includes(email)) {
    response = await usersCrud.getMeByToken();
    const user = { ...response, lastSeenAt: new Date().toISOString() };
    if (user.id) {
      await usersCrud.update(user);
    }
  }
  await Analytics.updateEndpoint({
    address: email,
    demographic: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    optOut: "ALL",
    userId: id,
    userAttributes: {
      userTypes: masterEmailsRestriction.includes(email)
        ? ["MASTER", ...response.userTypes]
        : [...response.userTypes],
    },
  });
  return response;
}
