import React, { useMemo } from "react";
import { useProductFilesUIContext } from "./ProductFilesUIContext";
import { FormattedMessage } from "react-intl";
import { Filter } from "../../../../../_components/Filter";

export function ProductFilesFilter() {
  const productFilesUIContext = useProductFilesUIContext();
  const productFilesUIProps = useMemo(() => {
    return {
      setQueryParams: productFilesUIContext.setQueryParams,
      openNewFileDialog: productFilesUIContext.openNewFileDialog,
      queryParams: productFilesUIContext.queryParams,
      readOnly: productFilesUIContext.readOnly,
    };
  }, [productFilesUIContext]);

  return (
    <>
      <div className="form-filtration d-md-flex">
        <div className={"margin-bottom-10-mobile w-100"} style={{ maxWidth: "100%" }}>
          <Filter useEntityUIContext={useProductFilesUIContext} />
        </div>
        <div className="text-right margin-bottom-10-mobile d-flex align-items-start mx-md-2">
          {!productFilesUIProps.readOnly && (
            <>
              <button
                type="button"
                className="btn btn-primary w-100 w-md-auto"
                onClick={() => productFilesUIProps.openNewFileDialog()}
              >
                <FormattedMessage id="FILE.ACTIONS.UPLOAD.NEW" />
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
}
