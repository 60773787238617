import React, { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { API } from "@aws-amplify/api";
import { FormattedMessage } from "react-intl";
import { useFormsUIContext } from "../../PropertiesManagement/pages/forms/FormsUIProvider";
import { downloadFileBase64 } from "../../UsersManagement/_redux/usersCrud";

export function OtpModalSignature({ id, show, onHide, resendCode, setFileBase64 }) {
  const [nextResendCode, setNextResendCode] = useState(undefined);
  const [code, setCode] = useState("");
  const [isLoading, setLoading] = useState(false);

  const formsUIContext = useFormsUIContext();
  const formsUiProps = useMemo(() => {
    return {
      setSignature: formsUIContext?.setSignature,
    };
  }, [formsUIContext]);

  const { setSignature } = formsUiProps;

  const buttonResendCodeClick = () => {
    resendCode();
    const fewMinutesLater = new Date();
    fewMinutesLater.setMinutes(fewMinutesLater.getMinutes() + 2);
    setNextResendCode(fewMinutesLater);
  };

  const validateAndSign = () => {
    setLoading(true);
    API.post("API", `/signatures/${id}`, { body: { code: code } }).then((response) => {
      downloadFileBase64(response?.fileUrlSigned).then((res) => setFileBase64(res));
      setSignature(response);
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="signature-information-modal"
      centered
      size={"lg"}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id={"SIGN.ENTER_CODE"} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label>
          <FormattedMessage id={"SIGN.CODE"} />
        </label>
        <input
          data-cy="input-signature-otp"
          type={"text"}
          className={"form-control"}
          value={code}
          onChange={(e) => setCode(e.target.value.toUpperCase())}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className={"text-right"}>
          <button
            type="button"
            className="btn btn-outline-primary mr-4"
            onClick={() => buttonResendCodeClick()}
            disabled={(nextResendCode && nextResendCode > new Date()) || isLoading}
          >
            <FormattedMessage id={"SIGN.RESEND_CODE"} />
          </button>
          <button
            //8 = current otp length
            disabled={!code || (code && code.length !== 8) || isLoading}
            data-cy="button-signature-validate-and-sign"
            type="button"
            onClick={() => validateAndSign()}
            className={`btn btn-primary btn-elevate ml-4 ${
              isLoading && "spinner spinner-white spinner-right"
            }`}
          >
            <FormattedMessage id={"SIGN.VALIDATE_AND_SIGN"} />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
