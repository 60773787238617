import { createContext, FunctionComponent, useContext } from "react";

import {
  TasksContentViewMode,
  TTasksContentViewMode,
} from "./TasksCard/TasksCardContent/TasksSwitchContentView";

export interface ITasksUIEvents {
  newTaskButtonClick?: () => void;
  openEditTaskPage?: (id: string) => void;

  toggleContentView?: (contentView?: TTasksContentViewMode) => void;
  toggleGanttChartDetails?: (showDetails?: boolean) => void;
}

export interface ITasksUIContext extends ITasksUIEvents {
  ganttShowDetails: boolean;
  contentView: TTasksContentViewMode;
}

const TasksUIContext = createContext<ITasksUIContext>({
  ganttShowDetails: false,
  contentView: TasksContentViewMode.COLUMNS,
});

export interface TasksUIProviderProps {
  ganttShowDetails?: boolean;
  contentView?: TTasksContentViewMode;
  tasksUIEvents?: ITasksUIEvents;
}

export const TasksUIProvider: FunctionComponent<TasksUIProviderProps> = ({
  ganttShowDetails = false,
  contentView = TasksContentViewMode.COLUMNS,
  tasksUIEvents,
  children,
}) => {
  const value = {
    ganttShowDetails,
    contentView,
    newTaskButtonClick: tasksUIEvents?.newTaskButtonClick,
    openEditTaskPage: tasksUIEvents?.openEditTaskPage,
    toggleContentView: tasksUIEvents?.toggleContentView,
    toggleGanttChartDetails: tasksUIEvents?.toggleGanttChartDetails,
  };

  return <TasksUIContext.Provider value={value}>{children}</TasksUIContext.Provider>;
};

export const useTasksUIContext = () => useContext(TasksUIContext);

export default TasksUIProvider;
