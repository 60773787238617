import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import React, { FC } from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler } from "../../../_metronic/_partials/dropdowns";

export interface DropdownMenuProps {
  icon?: string;
  disabled?: boolean;
  toggleClass?: string;
  buttonRef?: any;
}
export const DropdownMenu: FC<DropdownMenuProps> = ({
  icon = "/media/svg/icons/General/Other1.svg",
  disabled,
  toggleClass,
  children,
  buttonRef,
}) => {
  return (
    <>
      <Dropdown drop={"up"}>
        <Dropdown.Toggle
          as={DropdownCustomToggler}
          disabled={disabled}
          customClass={toggleClass}
          ref={buttonRef}
        >
          <span className="svg-icon svg-icon-md cursor-pointer">
            <SVG src={toAbsoluteUrl(icon)} />
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu
          popperConfig={{ strategy: "fixed" }}
          alignRight
          className="dropdown-menu dropdown-menu-left dropdown-menu-anim dropdown-menu-anim-down"
          rootCloseEvent={"mousedown"}
        >
          {children}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
