import React from "react";
import { Formik } from "formik";
import { FormattedMessage } from "react-intl";

import * as tasksActions from "../../modules/PropertiesManagement/_redux/tasks/tasksActions";
import { useDispatch } from "react-redux";
import { TextEditor } from "../TextEditor/TextEditor";
import { commentToolbar } from "../TextEditor/config";

export const CommentItemEdit = ({ comment, setMode }) => {
  const dispatch = useDispatch();

  const submitEntity = (updatedEntity) => {
    updatedEntity.updatedAt = new Date();
    dispatch(tasksActions.updateComment(updatedEntity));
  };

  return (
    <>
      <Formik
        initialValues={comment}
        onSubmit={(values) => {
          submitEntity(values);
          setMode("read");
        }}
      >
        {({ values, handleSubmit, handleBlur, handleChange, setFieldValue }) => (
          <>
            <div className="form-group row">
              <div className="col-lg-12">
                <TextEditor
                  data={comment.content}
                  name={"content"}
                  data-cy="input-task-comment-edit"
                  saveMethod={setFieldValue}
                  toolbarOption={commentToolbar}
                  size={"100px"}
                  id={`ckeditor-comment-edit-${comment.id}`}
                />
              </div>
            </div>

            <div className={"text-right"}>
              <button
                data-cy="input-comment-edit-cancel"
                type="button"
                onClick={() => setMode("read")}
                className="btn btn-light btn-elevate"
              >
                <FormattedMessage id="COMMON.ACTION.CANCEL" />
              </button>
              <> </>
              <button
                type="button"
                onClick={() => handleSubmit()}
                data-cy="button-task-comment-item-save"
                className="btn btn-primary btn-elevate"
              >
                <FormattedMessage id="COMMON.ACTION.SAVE" />
              </button>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};
